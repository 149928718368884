import React from "react";
import tabletFrame from "../img/large_tablet_frame.png";
import tabletFrameSmall from "../img/large_tablet_frame_sm.png";
import tabletBG from "../img/tablet_background.jpg";
import tabletBGSmall from "../img/tablet_background_sm.jpg";
import "components/UIComponents.css";
import { Droppable, Draggable } from "react-beautiful-dnd";
// import { ListManager } from "react-beautiful-dnd-grid";
// import imgCheckboxCheck from "img/checkbox_check.svg";
import Image from "lqip-react";
import styled from "@emotion/styled/macro";

export const moveAt = (target, pageX, pageY) => {
  target.style.left = pageX - (0 + target.offsetWidth / 2) + "px";
  target.style.top = pageY - (0 + target.offsetHeight / 2) + "px";
};
export const fnOnDrag = e => {
  e.preventDefault();
  moveAt(e.target, e.pageX, e.pageY);
};

export const fnOnMouseDown = e => {
  console.log("Mouse is Down:", e.target);
  e.target.style.zIndex = 1000;
  document.body.append(e.target);
  moveAt(e.target, e.pageX, e.pageY);
};
export const fnDragStart = e => {
  console.log("Drag Starting, e:", e);
  moveAt(e.target, e.pageX, e.pageY);
  console.log("target:", e.target);
  e.dataTransfer.setData("text/plain", e.target.id);
  document.getElementById("ph_testing").setAttribute("dropped", false);
};
export const fnOnDrop = e => {
  console.log("On Drop, e:", e);
  e.target.classList.add("can_drop_here");
};
export const fnDragOver = e => {
  console.log("Drag Over, e:", e.clientX, ",", e.clientY);
  e.preventDefault();
  e.target.classList.add("can_drop_here");
};
export const fnDragLeave = e => {
  console.log("Drag Leave, e:", e);
  e.preventDefault();
  e.target.classList.remove("can_drop_here");
};
export const OImg = props => {
  // console.log(props);

  if (props.optimize === true) {
    return (
      <div className="zoomable_image" style={props.style}>
        <Image
          src={props.src}
          thumbnail={props.thumbnail}
          aspectRatio={props.aspectRatio}
          alt={props.alt}
          color={props.color}
          draggable={props.draggable}
        />
      </div>
    );
  } else {
    return (
      <div className="zoomable_image">
        <img src={props.src} alt={props.alt} draggable={props.draggable} />
      </div>
    );
  }
};
export const Join = (arrClasses, separator) => {
  let j = 0;
  let str = "";
  for (let i = 0; i < arrClasses.length; i++) {
    if (
      arrClasses[i] === null ||
      arrClasses[i] === "" ||
      arrClasses[i] === undefined
    ) {
      continue;
    }
    if (j > 0) {
      str += separator;
    }
    str += arrClasses[i];
    j = j + 1;
  }

  return str === "" ? null : str;
};
export const LargeTablet = props => {
  const showTablet = props.showTablet;

  return showTablet ? (
    <div className="large_tablet">
      <div className="large_tablet_frame">
        <Image
          src={tabletFrame}
          thumbnail={tabletFrameSmall}
          color="#13296200"
          aspectRatio={"925x1080"}
          alt="Tablet Frame"
          draggable={false}
          blur={10}
        />
      </div>
      <div className="tablet_background">
        <Image
          src={tabletBG}
          thumbnail={tabletBGSmall}
          color="#13296200"
          aspectRatio={"766x826"}
          alt="Large Tablet - Screen"
          draggable={false}
        />
      </div>
      {props.children}
    </div>
  ) : null;
};
export const NewLargeTablet = props => {
  const showTablet = props.showTablet;

  return showTablet ? (
    <>
      <div className="large_tablet_frame">
        <Image
          src={tabletFrame}
          thumbnail={tabletFrameSmall}
          color="#13296200"
          aspectRatio={"925x1080"}
          alt="Tablet Frame"
          blur={5}
        />
      </div>
      {props.children}
    </>
  ) : null;
};

const DistanceSelector = props => {
  if (!props.item.numeric) return null;

  const increaseValue = e => {
    const input = e.target.parentNode.querySelector("input[type=number]");
    input.stepUp(1);
    input.dispatchEvent(new Event("change", { bubbles: true }));
    // console.log("input value=", input.value);
  };
  const decreaseValue = e => {
    const input = e.target.parentNode.querySelector("input[type=number]");
    input.stepDown(1);
    input.dispatchEvent(new Event("change", { bubbles: true }));
    // console.log("target=", e.target.parentNode);
  };
  return (
    <div className="auv_distance_container">
      <div id="inc-button" className="spinner-button" onClick={increaseValue}>
        &and;
      </div>
      <input
        // className="auv_distance_selector"
        type="number"
        name="distance"
        min="1"
        max="9"
        value={props.item.value}
        onChange={e => {
          props.mSend("CHANGEDISTANCE", {
            item: props.item,
            value: e.target.value,
            itemIndex: props.itemIndex,
            collection: props.collection
          });
        }}
      />
      <div id="dec-button" className="spinner-button" onClick={decreaseValue}>
        &or;
      </div>
    </div>
  );
};

export const Copyable = props => {
  return (
    <Droppable droppableId={props.droppableId} isDropDisabled={true}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className={props.className}>
          {props.items.map((item, index) =>
            commandButton(item, index, props, true)
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const AUVControlPad = props => {
  // console.log("AUVControlPad, props=", props);

  return (
    <Copyable
      {...props}
      droppableId="MasterCommands"
      className="auv_control_pad"
      items={props.items}
      collection="MasterCommands"
    />
  );
};

export const DeletePad = props => {
  // console.log("AUVControlPad, props=", props);

  return (
    <Droppable droppableId="DELETEPAD">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className="auv_delete_pad"
          id="auv_delete_pad"
        >
          {provided.placeholder}
          <div>Delete Command</div>
        </div>
      )}
    </Droppable>
  );
};
export const AUVProgramPad = props => {
  const column1 = props.items;
  return (
    <>
      <Droppable droppableId="PROGRAMPADLEFT">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="auv_program_pad left">
            {column1 &&
              column1
                .slice(0, 7)
                .map((item, index) => commandButton(item, index, props, false))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Droppable droppableId="PROGRAMPADRIGHT">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="auv_program_pad right">
            {column1 &&
              column1
                .slice(7)
                .map((item, index) =>
                  commandButton(item, index + 7, props, false)
                )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export const DisplayObtainedItem = props => {
  console.log("obtainable:", props.obtainable);
  return (
    <div
      className="clickable"
      onClick={() => props.mSend("STORE")}
      style={{
        position: "absolute",

        borderRadius: "999px",
        width: "54%",
        height: "18.6%",
        left: "21%",
        top: "76%",
        backgroundColor: "#132962B3"
      }}
    >
      <div
        style={{
          width: "15%",
          margin: "2.5% 0% 2.5% 4%",
          float: "left"
        }}
      >
        <img
          style={{ width: "100%" }}
          src={props.obtainable.src}
          alt={props.obtainable.title}
        />
      </div>
      <div
        style={{
          margin: "4% 2%",
          height: "70%",
          float: "left",
          width: "70%"
          // border: "1px solid green"
        }}
      >
        <h3
          style={{
            marginBlockStart: "0",
            marginBlockEnd: "1vw",
            textTransform: "uppercase",
            fontSize: "1vw",
            color: "#ffffffB1"
          }}
        >
          Item Obtained
        </h3>
        <h1
          style={{
            fontSize: "1.8vw",
            lineHeight: "1.8vw",
            marginBlockStart: "0",
            marginBlockEnd: ".5vw"
          }}
        >
          {props.obtainable.title}
        </h1>
        <h2
          style={{
            fontSize: "1vw",
            lineHeight: "1.4vw",
            color: "#ffffffB1",
            marginBlockStart: "0",
            marginBlockEnd: "0"
          }}
        >
          {props.obtainable.description}
        </h2>
      </div>
    </div>
  );
};

export const AppWrapper = styled.div`
  box-sizing: border-box;
  background-image: url(${props => props.background || tabletBG});
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #001064,
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 2vw;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  color: white;
  padding: 2%;

  h1,
  h2,
  h3,
  h4,
  p,
  label,
  span {
    text-align: left;
    white-space: normal;
    word-break: break-all;
  }
  span {
    font-size: .9vw;
    text-transform: uppercase;
  }
`;
export const QuizTitle = styled.h1`
  font-size: 1.6vw;
  text-transform: uppercase;
  /* border: 1px solid green; */
  line-height: 2vw;
  padding: 0;
  margin-top: 5%;
  width: 35%;
`;
export const QuizQuestion = styled.h2`
  font-size: 1.4vw;
  line-height: 1.5vw;
`;

export const QuizIcon = styled.img`
  display: block;
  float: right;
  width: 15%;
  opacity: 0.3;
  margin: 0%;
  padding: 0;
`;
export const QuizImage = styled.img`
  width: 100%;
  margin: 0%;
  padding: 0;
`;
export const QuizQuestionList = styled.div`
  height: auto;
  width: 100%;
  padding: 2% 0px 0px 0px;
  box-sizing: border-box;
  /* border: 1px solid red; */
`;
export const QuizItem = styled.div`
  background-color: #ffffff33;
  margin-bottom: 3%;
  text-align: left;
`;
export const QuizInput = props => {
  return (
    <div>
      {/* <img src={imgCheckboxCheck} alt="Check Mark" /> */}
      {/* <input type="checkbox" className={props.className} style={props.style} /> */}
    </div>
  );
};
export const QuizWrapper = styled.div`
  button {
    float: right;
  }
`;
export const QuizCountdown = styled.div`
  float: right;
  font-size: 1.6vw;
  line-height: 2vw;
  padding: 0;
  opacity: 0.5;
  width: 9%;
  word-break: break-word;
  /* border: 1px solid red; */

  span {
    float: left;
    /* border: 1px solid red; */
    white-space: nowrap;
    font-size: 1.6vw;
  }
`;
export const QuizIntroWrapper = styled.div`
  text-align: center;
  h1 {
    font-size: 1.6vw;
    text-transform: uppercase;
    /* border: 1px solid green; */
    line-height: 2vw;
    margin-bottom: 8%;
  }
  p {
    font-size: 0.75vw;
    margin: 0 14% 0 14%;
    line-height: 2vw;
    display: block;
    word-break: break-word;
  }
  h1,
  p {
    text-align: center;
  }
  img {
    padding-top: 20%;
    width: 15%;
    height: 15%;
  }
  button {
    margin-top: 8%;
  }
`;
const commandButton = (item, index, props, allowDrag) => {
  const buttonClassStringDefault = "auv_control_button";
  const buttonClassString = ss => {
    return Join(
      [
        item.type,
        "auv_control_button",
        item.selectable === false ? "not_selectable" : null,
        ss.isDragging ? "auv_dragging" : null
      ],
      " "
    );
  };

  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={!item.selectable}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <CommandButton
            {...props}
            provided={provided}
            snapshot={snapshot}
            buttonClassString={
              allowDrag ? buttonClassString(snapshot) : buttonClassStringDefault
            }
            item={item}
            index={index}
          />
          {allowDrag && (
            <CommandButton
              {...props}
              provided={provided}
              snapshot={snapshot}
              buttonClassString={buttonClassString(snapshot)}
              item={item}
              index={index}
              showDrag={allowDrag}
            />
          )}
        </React.Fragment>
      )}
    </Draggable>
  );
};

const Icon = props => {
  if (!props.item.icon) return null;
  return (
    <div className={"auv_button_icon"}>
      <img
        src={props.item.icon}
        alt={props.item.label}
        className={props.item.rotate ? " r" + props.item.rotate : null}
      />
    </div>
  );
};

const ButtonWrapper = props => {
  const { provided, snapshot, buttonClassString, item, showDrag } = props;
  if (showDrag) {
    if (!snapshot.isDragging) return null;
    return (
      <div className={item.type + " dragging_object_copy auv_control_button"}>
        {props.children}
      </div>
    );
  } else
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
        className={buttonClassString}
      >
        {props.children}
      </div>
    );
};
const CommandButton = props => {
  const { item, index } = props;

  return (
    <ButtonWrapper {...props}>
      <Icon item={item} />
      {item.label}
      <DistanceSelector {...props} item={item} itemIndex={index} />
    </ButtonWrapper>
  );
};
