import React from "react";
import ExplorerBadge from "./ExplorerBadge";

import { OImg, DisplayObtainedItem, Join } from "components/UIComponents";

const RenderClickables = props => {
  const layers = props.layers;
  let visibilityStyle = {};
  switch (props.type) {
    case "marker":
      const classValue = Join(
        [
          props.showHover ? "show_hover" : null,
          props.visible ? null : "hidden"
        ],
        " "
      );
      return (
        <div
          className={classValue}
          style={props.style}
          onClick={() => props.mSend({ type: props.action, id: props.id })}
          key={props.key}
        >
          {layers.map((layer, key) => {
            return (
              <img
                className={props.visible ? null : "hidden"}
                src={layer.src}
                alt={layer.alt}
                key={key}
                style={layer.style}
                id={layer.id}
              />
            );
          })}
        </div>
      );

    case "markertag":
      return (
        <>
          {layers.map((layer, key) => {
            const classValue = Join(
              [
                layer.className ? layer.className : null,
                props.showHover && layer.handlesHover ? "show-hover" : null
              ],
              " "
            );

            switch (layer.type) {
              case "tag":
                return (
                  <div className={classValue} style={layer.style} key={key}>
                    {props.data}
                  </div>
                );
              case "label":
                return (
                  <h1 className={classValue} style={layer.style} key={key}>
                    {layer.data}
                  </h1>
                );
              default:
              case "rect":
                return (
                  <div className={classValue} style={layer.style} key={key} />
                );
            }
          })}
        </>
      );

    default:
    case "img":
      return layers.map((layer, key) => {
        visibilityStyle = {};
        //if (props.visible === false && layer.name !== "background") return "";
        const hiddenClass = layer.visible === false ? "hidden" : null;
        const hoverClass =
          props.showHover && layer.handlesHover ? "show_hover" : null;
        const ringClass =
          layer.handlesRing && props.ringing ? "blinking" : null;
        const className = Join([hoverClass, ringClass, hiddenClass], " ");

        const sampleID =
          props.type === "info"
            ? {
                clickableName: props.clickableName,
                sampleID: props.sampleID
              }
            : null;

        const onClick =
          props.isClickable && layer.handlesClick
            ? () => props.mSend(props.action, sampleID)
            : null;
        // console.log("ISCLICKABLE:", props.name);
        // if (props.visible === false) visibilityStyle = { display: "none" };
        if (props.showOverlay === false && layer.name === "overlay") {
          // console.log("1: Set visibility to none");
          visibilityStyle = { display: "none" };
        }

        if (layer.handlesHighlight === true && props.showHighlight === false) {
          // console.log("2: Set visibility to none");
          visibilityStyle = { display: "none" };
        }
        if (layer.handlesLock === true && props.locked === false) {
          // console.log("3: Set visibility to none");
          visibilityStyle = { display: "none" };
        }
        // console.log(
        //   `layer.name: ${layer.name} layer.visible: ${layer.visible}`
        // );

        switch (layer.type) {
          case "message":
            // console.log("message");
            return (
              <div className={Join(["message", ringClass], " ")}>
                {layer.text}
              </div>
            );
          case "lcd_display":
            // console.log("lcd_display");
            return (
              <div className={Join(["lcd_screen", ringClass], " ")}>
                <div>
                  {layer.data.ph} <small>pH</small>
                </div>
                <div>
                  {layer.data.tempF}
                  <small>F</small> {layer.data.tempC}
                  <small>C</small>
                </div>
              </div>
            );
          case "counter":
            return (
              <div
                className={Join(["counter", ringClass], " ")}
                style={{ ...layer.style, ...visibilityStyle }}
              >
                <div>
                  {layer.data[0]} / {layer.data[1]}
                </div>
              </div>
            );
          default:
            return (
              <img
                id={layer.id}
                style={{ ...layer.style, ...visibilityStyle }}
                src={layer.src}
                alt={layer.alt}
                key={key}
                className={className}
                onClick={onClick}
                dropclass={props.droppable ? props.dropClass : null}
                draggable={props.draggable && layer.handlesDrag ? true : false}
              />
            );
        }
      });
  }
};

export const Environment = props => {
  const { context } = props;

  return (
    <div
      className={
        "mbari_container " +
        context.environment.className +
        (context.environment.shiftBackground ? " shift" : "")
      }
      id={context.environment.id}
    >
      <OImg {...context.environment.background} {...props} />
      {context.showBadge !== false && (
        <ExplorerBadge objective="Explore MBARI" {...props} />
      )}
      <div className="clickables">
        {context.environment.clickables &&
          Object.keys(context.environment.clickables).map(
            (clickableName, index) => {
              const clickable = context.environment.clickables[clickableName];

              clickable["mSend"] = props.mSend;
              let fnOnDrop = null;
              let fnOnDragLeave = null;
              let fnOnDragOver = null;
              let fnOnDragStart = null;
              let fnOnDragEnd = null;

              let fnOnClick = null;
              // console.log(clickable.type);

              if (clickable.isClickable !== false) {
                fnOnClick =
                  clickable.type === "markertag"
                    ? () =>
                        props.mSend(clickable.action, {
                          clickableName: clickableName
                        })
                    : null;
              }

              const draggable = clickable.draggable ? true : false;

              if (draggable) {
                // console.log("Draggable");
                fnOnDragStart = e => {
                  const { target } = e;
                  e.dataTransfer.setData("id", target.id);
                  // console.log("Draggable:target.id:", target.id);
                  setTimeout(() => {
                    target.style.display = "none";
                  }, 100);
                  target.style.zIndex = 1000;
                  target.classList.add("being_dragged");
                };
                fnOnDragEnd = e => {
                  e.target.style.display = "";
                  e.target.classList.remove("being_dragged");
                };
              }
              if (clickable.droppable) {
                fnOnDragOver = e => {
                  e.preventDefault();
                  e.target.classList.add("can_drop_here");
                };
                fnOnDragLeave = e => {
                  e.preventDefault();
                  e.target.classList.remove("can_drop_here");
                };
                fnOnDrop = e => {
                  e.preventDefault();

                  const id = e.dataTransfer.getData("id");
                  // console.log("Dropped, id is:", id);

                  // e.target.appendChild(document.getElementById(id));
                  const droppedObj = document.getElementById(id);
                  const strDropClass = e.target.getAttribute("dropclass");
                  droppedObj.style.display = ""; // The "root" object that was "left behind" was hidden, now unhide the object
                  droppedObj.classList.remove("being_dragged");
                  if (clickable.hideTargetsOnDrop)
                    droppedObj.classList.add("hidden");
                  if (strDropClass) {
                    droppedObj.classList.add("measuring", strDropClass, "test");
                    droppedObj.style.zIndex = 0;
                  }
                  if (clickable.dropAction)
                    props.mSend(clickable.dropAction, { id: id });
                  // else
                  // console.log("clickable(" + clickableName + ") has no dropAction");
                };
              }
              const classValue = Join(
                [
                  clickable.className ? clickable.className : null,
                  clickable.visible ? null : "hidden"
                ],
                " "
              );

              // console.log("clickable:", clickable);
              return (
                <div
                  className={classValue}
                  onDragStart={fnOnDragStart}
                  onDragEnd={fnOnDragEnd}
                  onDrop={fnOnDrop}
                  onDragOver={fnOnDragOver}
                  onDragLeave={fnOnDragLeave}
                  draggable={draggable}
                  onClick={fnOnClick}
                  id={clickable.id}
                  style={clickable.style}
                  key={index}
                >
                  <RenderClickables
                    {...clickable}
                    name={clickableName}
                    key={index + 1000}
                    sampleID={clickable.sampleID}
                    clickableName={clickableName}
                  />
                </div>
              );
            }
          )}
      </div>
      {context.itemObtained && (
        <DisplayObtainedItem
          obtainable={context.environment.obtainable[context.itemObtained]}
          {...props}
        />
      )}
      {props.children}
    </div>
  );
};
