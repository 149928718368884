import { Machine, assign } from "xstate";
import tabletBG from "img/tablet_background.jpg";
import axios from "axios";
import imgBackground from "img/nb_tidepool_bg.jpg";
import imgBackgroundSmall from "img/nb_tidepool_bg_sm.jpg";

const DateToLocal = strDate => {
  const dateUTC = new Date(strDate); // - offsetMs);
  return dateUTC.toLocaleString();
};
const morePages = (context, event) => {
  return context.page > 1;
};
// const fnLoadReflectionImages = (context, event) => {
//   console.log(
//     "NOTES, fnLoadReflectionImages:",
//     context.api + "/api/reflectionimages/" + context.userID
//   );
//   console.log("NOTES, event:", event);

//   return axios.get(context.api + "/api/reflectionimages/" + context.userID); // Not sure why I have a data structure under the data structure.
// };
// const fnLoadReflectionAnswers = (context, event) => {
//   console.log("fnLoadReflections:", context.userID, context.notesID);

//   return axios.get(
//     context.api + `/api/reflections/${context.userID}/${context.notesID}`
//   );
// };
const fnLoadReflections = (context, event) => {
  console.log("fnLoadReflections:", context.userID, context.notesID);

  return axios
    .get(context.api + `/api/reflections/${context.userID}`)
    .then(result => {
      console.log("result=", result.data);
      return result.data;
    });
};
const fnSaveReflection = (context, event) => {
  // since axios returns a promise this will return a promise and therefore can be declared as asyncronous
  const imageID =
    context.reflection && context.reflection[context.notesID]
      ? context.reflection[context.notesID].imageID
      : null;

  const questionIDs =
    context.reflectionAnswers && context.notesID in context.reflectionAnswers
      ? Object.keys(context.reflectionAnswers[context.notesID])
      : null;
  const answers =
    context.reflectionAnswers && context.notesID in context.reflectionAnswers
      ? questionIDs.map(qid => {
          return context.reflectionAnswers[context.notesID][qid].text;
        })
      : null;
  console.log("Saving:", answers);
  let objReflections = {
    notesid: context.notesID,
    questionids: questionIDs,
    answers: answers,
    userid: context.userID,
    imageid: imageID
  };
  console.log("RI, objReflections:", objReflections);

  return axios
    .post(context.api + "/api/reflections", objReflections)
    .then(data => data.data);
};

const context = {
  environment: {
    className: "env_elkhorn",
    shiftBackground: false,
    // shiftLeft: "8.6%",
    background: {
      optimize: true,
      src: imgBackground,
      thumbnail: imgBackgroundSmall,
      alt: "Natural Bridges-",
      color: "#132962",
      aspectRatio: "1920x1080"
    },
    clickables: {},
    obtainable: {}
  },
  callStep: 0,
  tabletBG: tabletBG,
  showTablet: true,
  itemObtained: null,
  // savedImage: null,
  reflectionPhoto: { MBARI: null, ELKHORN: null, TIDEPOOLS: null },
  videoAfterCall: true,
  reflectionAnswers: {}
};

export const notesMachine = Machine(
  {
    id: "NotesMachine",
    context: context,
    on: {
      UPDATE_USER: { actions: ["setupUserInfo"] }
    },
    initial: "appScreen",
    states: {
      waitForMainScene: {
        after: {
          300: { target: "loadReflections", actions: ["setupUserInfo"] }
        }
      },
      loadReflections: {
        invoke: {
          src: (context, event) => fnLoadReflections(context, event),
          onDone: {
            target: "notesSelection",
            actions: ["notesSettings", "loadedReflections"]
          },
          onError: {
            target: "appScreen",
            actions: ["couldNotConnect"]
          }
        }
      },
      loadReflectionsFromIntro: {
        invoke: {
          src: (context, event) => fnLoadReflections(context, event),
          onDone: {
            target: "notesSelection",
            actions: ["notesSettings", "loadedReflections"]
          },
          onError: {
            target: "intro",
            actions: ["couldNotConnect"]
          }
        }
      },
      appScreen: {
        on: {
          INTRO: {
            target: "intro",
            actions: assign({
              backClickable: true,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          NOTES: {
            target: "loadReflections"
          },
          CANCEL: {
            target: "appScreen",
            actions: assign({ showModal: false })
          }
        }
      },
      intro: {
        on: {
          NOTES: {
            target: "loadReflectionsFromIntro"
            // actions: ["notesSettings"]
          },
          BACK: [
            {
              target: "intro",
              cond: morePages
            },
            {
              target: "notesSelection",
              actions: assign({
                page: 1,
                backClickable: true
              })
            }
          ],
          BACKTOAPPSCREEN: [
            {
              target: "appScreen"
            }
          ],
          CANCEL: {
            target: "intro",
            actions: assign({ showModal: false })
          }
        }
      },
      notesSelection: {
        on: {
          BACKTOAPPSCREEN: {
            target: "appScreen",
            actions: assign({
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          BACK: {
            target: "intro",
            actions: assign({
              backClickable: true,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          INTRO: {
            target: "intro",
            actions: assign({
              backClickable: true,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          REFLECT: {
            target: "reflection",
            actions: ["reflectionSettings"]
          },
          DONE: {
            target: "done",
            actions: assign({
              backClickable: true,
              currentFile: "MBARI Reflection",
              parentDir: "Notes",
              done: true
            })
          }
        }
      },
      reflection: {
        on: {
          TEXTFINISHED: [
            {
              target: "reflection",
              cond: morePages,
              actions: assign({
                qNum: context => context.qNum + 1
              })
            },
            {
              target: "reflection",
              actions: assign({
                canSave: true
              })
            }
          ],
          TEXTCHANGED: {
            target: "reflection",
            actions: "changeAnswer"
          },
          IMAGECHOOSE: {
            target: "chooseImage",
            actions: ["chooseImage"]
          },
          BACK: {
            target: "saveReflections",
            actions: () => console.log("Should save")
          },
          NOTES: {
            target: "saveReflections",
            actions: () => console.log("Should save")
          },
          SAVE: {
            target: "saveReflections"
          }
        }
      },
      saveReflections: {
        invoke: {
          id: "saveReflections",
          src: (context, event) => fnSaveReflection(context, event),
          onDone: {
            target: "notesSelection",
            actions: ["reflectionSaved"]
          },
          onError: {
            target: "reflection",
            actions: ["reflectionNotSaved"]
          }
        }
      },
      chooseImage: {
        on: {
          SELECT: {
            target: "reflection",
            actions: ["chooseImageFromGallery"]
          },
          BACK: {
            target: "reflection"
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      couldNotConnect: (context, event) => {
        context.errorMessage = "Could Not Load Notes";
        context.errorInstructions = "Press OK to try again.";
        context.showModal = true;
        context.okCommand = "NOTES";
      },
      loadedReflections: (context, event) => {
        console.log("loadedReflections:", event);
        if (event.data) {
          if (event.data.reflectionimages) {
            context.reflection = {
              MBARI: null,
              ELKHORN: null,
              TIDEPOOLS: null
            };
            for (let i = 0; i < event.data.reflectionimages.length; i++) {
              let rImg = event.data.reflectionimages[i];
              context.reflection[rImg.notesid] = {
                imageID: rImg.imageid,
                savedDateTime: DateToLocal(rImg.modified)
              };
            }
          }
          if (event.data.reflectionanswers) {
            console.log("REFLECTION ANSWERS:", event.data.reflectionanswers);
            context.reflectionAnswers = {};
            for (let i = 0; i < event.data.reflectionanswers.length; i++) {
              let rData = event.data.reflectionanswers[i];
              if (!(rData.notesid in context.reflectionAnswers)) {
                console.log(
                  "reflectionAnswers is now:",
                  context.reflectionAnswers
                );
              }
              if (!context.reflectionAnswers[rData.notesid]) {
                context.reflectionAnswers[rData.notesid] = {};
              }
              context.reflectionAnswers[rData.notesid][rData.questionid] = {
                text: rData.answer,
                saved: true
              };
              context.reflectionSavedDateTime = DateToLocal(rData.modified);
            }
          }
        }
        console.log(
          "axios, context.reflectionAnswers=",
          context.reflectionAnswers
        );
      },
      reflectionSaved: (context, event) => {
        console.log("reflection Saved: ", event.data);
        context.showModal = false;
        context.backClickable = true;
        context.currentFile = "Notes";
        context.parentDir = null;
        if (
          context.reflectionAnswers &&
          context.reflectionAnswers[context.notesID]
        )
          Object.keys(context.reflectionAnswers[context.notesID]).forEach(
            qid => {
              context.reflectionAnswers[context.notesID][qid].saved = true;
            }
          );
        let rData = event.data.reflections[0];
        context.reflectionSavedDateTime = DateToLocal(rData.modified);
        for (let i = 0; i < event.data.reflections.length; i++) {
          let rData = event.data.reflections[i];
          context.reflection[rData.notesid] = {
            ...context.reflection[rData.notesid],
            savedDateTime: DateToLocal(rData.modified)
          };
        }
      },
      reflectionNotSaved: (context, event) => {
        console.log("reflection NOT Saved");
        context.showModal = true;
        context.okCommand = "SAVE";
        context.errorMessage = "Your Notes Did Not Save";
        context.errorInstructions = "Press OK to try again.";
      },
      setupUserInfo: (context, event) => {
        console.log("CI, setupUserInfo event:", event);
        if ("userID" in event) context.userID = event.userID;
        if ("api" in event) context.api = event.api;
        console.log("CI, context:", context);
      },
      notesSettings: (context, event) => {
        console.log("CI, event:", event);
        context.showModal = false;
        // context.backClickable = false;
        context.currentFile = "Notes";
        context.forwardArrowClickable = true;
        context.page = 1;
      },
      reflectionSettings: (context, event) => {
        context.notesID = event.notesID;
        context.currentFile = event.currentFile;
        context.backClickable = true;
        context.parentDir = "Notes";
      },
      // typing: assign((ctx, e) => ({ answer: e.value })),
      changeAnswer: (context, event) => {
        console.log("CI, event:", event);
        if (!context.reflectionAnswers[context.notesID]) {
          context.reflectionAnswers[context.notesID] = {};
        }
        context.reflectionAnswers[context.notesID][event.questionID] = {
          text: event.value
        };
        // Might want to load text from database into "originalText", to detect when code needs saving.
        // context.reflectionAnswers[event.questionID].originalText;
        context.reflectionAnswers[context.notesID][
          event.questionID
        ].saved = false;
        console.log("CI, answers:", context.reflectionAnswers[context.notesID]);
      },
      chooseImage: (context, event) => {
        // context.notesID = event.notesID;
        context.backClickable = true;
        //context.currentFile = context.currentFile + ">Chooose Image";
        // context.parentDir = "Notes";
      },
      chooseImageFromGallery: (context, event) => {
        console.log("CI, event is:", event);
        // context.reflectionPhotoImage = event.selection.src;
        context.reflection[event.notesID] = {
          imageID: event.selectionID
        };
        context.savedImage = event.selectionID;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.done = true;
      }
    },
    guards: {
      morePages
    }
  }
);
