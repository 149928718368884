/** @jsx jsx */
import { jsx } from "@emotion/core";
//eslint-disable-next-line
import React, { useRef, useState } from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import imgBackground from "img/auv_bg.jpg";
import imgBackgroundSmall from "img/auv_bg_sm.jpg";
import subIcon from "img/sub_icon.png";
import { LargeTablet } from "../UIComponents";
import auv from "img/auvgame/auv.svg";
import bluefish1 from "img/auvgame/bluefish1.png";
import bluefish2 from "img/auvgame/bluefish2.png";
import redfish1 from "img/auvgame/redfish1.png";
import redfish2 from "img/auvgame/redfish2.png";
import yellowfish1 from "img/auvgame/yellowfish1.png";
import yellowfish2 from "img/auvgame/yellowfish2.png";
import auvVideoStill from "img/auvgame/auv_video_still.png";
import targetOpen from "img/auvgame/target_open.png";
import finalTarget from "img/auvgame/final_target.png";
import startTarget from "img/auvgame/final_target.png";
import runIcon from "img/auvgame/play_icon.png";
import stopIcon from "img/auvgame/stop_icon.png";
import arrowIcon from "img/auvgame/arrow_icon.png";
import subSound from "img/auvgame/Submarine.mp3";
import gsap from "gsap";
import { CSSPlugin } from "gsap";
import { Draggable } from "gsap/all";
import styled from "@emotion/styled";
import { ButtonCenter, AppButton } from "components/Tablet";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { v4 as uuid } from "uuid";
import { DragDropContext } from "react-beautiful-dnd";
import {
  AUVControlPad,
  AUVProgramPad,
  DeletePad
} from "components/UIComponents";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";

//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(Draggable);

const fnMapToPath = commands => {
  const path = [];
  commands.forEach(item => {
    if (item.label === "Motor On" || item.label === "Motor Off")
      path.push({ [item.label]: true });
    else path.push({ [item.label]: parseInt(item.value) });
  });

  return path;
};
const menuMachine = Machine(
  {
    context: {
      environment: {
        className: "env_tidepool",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgBackground,
          thumbnail: imgBackgroundSmall,
          alt: "Pacific Grove Tide Pool",
          color: "#132962",
          aspectRatio: "1920x1080"
        }
      },
      buttonDisabled: true,
      showTablet: true,
      showIntro: false,
      gameStarted: true,
      runningAUV: false,
      pathAUV: [],
      MasterCommands: [
        {
          id: uuid(),
          label: "Motor On",
          icon: runIcon,
          selectable: true
        },
        {
          id: uuid(),
          label: "Motor Off",
          icon: stopIcon,
          selectable: false
        },
        {
          id: uuid(),
          label: "Forward",
          type: "new_section",
          value: 1,
          icon: arrowIcon,
          selectable: false,
          numeric: true
        },
        {
          id: uuid(),
          label: "Rise",
          // type: "movement",
          value: 1,
          icon: arrowIcon,
          rotate: 270,
          selectable: false,
          numeric: true
        },
        {
          id: uuid(),
          label: "Back",
          // type: "movement",
          value: 1,
          icon: arrowIcon,
          rotate: 180,
          selectable: false,
          numeric: true
        },
        {
          id: uuid(),
          label: "Dive",
          // type: "movement",
          value: 1,
          icon: arrowIcon,
          rotate: 90,
          selectable: false,
          numeric: true
        }
      ],
      programPadItems: [],
      positionAUV: [0, 7],
      runNeeded: true,
      done: false
    },
    initial: "programming",
    states: {
      programming: {
        on: {
          REORDER: {
            actions: ["reorderCommands"]
          },
          DROPCOMMAND: {
            actions: ["addCommand"]
          },
          DROPMOTORON: {
            actions: ["initializePath"]
          },
          CHANGEDISTANCE: {
            actions: ["changeDistance"]
          },
          CLEARINSTRUCTIONS: {
            target: "returnToHome",
            actions: ["clearInstructions"]
          },
          DELETE: {
            actions: ["deleteInstruction"]
          },
          RUN: { target: "running", actions: ["startRunning"] },
          DONE: {
            target: "done",
            actions: ["finishUp"]
          }
        }
      },
      returnToHome: {
        invoke: {
          src: "fnAnimateAUV",
          onDone: { target: "programming" }
        }
      },
      running: {
        invoke: {
          src: "fnAnimateAUV",
          onDone: { target: "checkResults" }
        },
        on: {
          STOP: "stopping",
          COMPLETE: { target: "checkResults", actions: ["enableButton"] }
        }
      },
      checkResults: {
        on: {
          "": {
            target: "programming"
          }
        }
      },
      stopping: {
        invoke: {
          src: "fnStopAUV",
          onDone: { target: "programming" }
        },
        on: {
          RUN: "running"
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      enableButton: (context, event) => {
        console.log("Enable Button");
        context.buttonDisabled = false;
      },
      reorderCommands: (context, event) => {
        // console.log("REORDER, instruction:", event);
        const result = Array.from(context.programPadItems);
        const [removed] = result.splice(event.sourceIndex, 1);
        // console.log("removed=", removed);
        result.splice(event.destinationIndex, 0, removed);
        context.programPadItems = result;

        context.pathAUV = fnMapToPath(context.programPadItems);
      },
      addCommand: (context, event) => {
        // console.log("DROPCOMMAND, instruction:", event);
        if (event.instruction.label === "Motor On") {
          context.pathAUV = [{ "Motor On": true }];
          context.programPadItems[0] = { ...event.instruction, id: uuid() };
          context.runNeeded = false;

          context.MasterCommands.forEach(command => {
            command.selectable = command.selectable === true ? false : true;
          });
        } else {
          if (
            event.destination.droppableId === "PROGRAMPADRIGHT" &&
            context.programPadItems.length < 8
          ) {
            context.programPadItems.push({
              ...event.instruction,
              id: uuid()
            });
          } else {
            const offset =
              event.destination.droppableId === "PROGRAMPADRIGHT" ? 7 : 0;
            context.programPadItems.splice(
              event.destination.index + offset,
              0,
              {
                ...event.instruction,
                id: uuid()
              }
            );
          }
          // console.log("programPadItems:", context.programPadItems);
          context.pathAUV = fnMapToPath(context.programPadItems);
          // console.log("pathAUV:", context.pathAUV);
          // context.programPadItems.splice(droppableDestination.index, 0, {
          //   ...item,
          //   id: uuid()
          // });
        }
      },
      changeDistance: (context, event) => {
        // console.log("========changeDistance, event=", event);
        switch (event.collection) {
          case "MasterCommands":
          default:
            context.MasterCommands[event.itemIndex].value = event.value;
            break;
          case "ProgrammingPad":
            context.programPadItems[event.itemIndex].value = event.value;
            context.pathAUV = fnMapToPath(context.programPadItems);
            // console.log("pathAUV:", context.pathAUV);
            break;
        }
      },
      programmingModeInitialize: (context, event) => {
        // console.log("========programmingModeInitialize");
      },
      startRunning: (context, event) => {
        // console.log("startRunning");
      },
      finishUp: (context, event) => {
        context.done = true;
      },
      initializePath: (context, event) => {
        console.log("Resetting Path");
        context.pathAUV = [];
        context.runNeeded = false;

        context.MasterCommands.forEach((command, count) => {
          command.selectable = command.selectable === true ? false : true;
        });
      },
      clearInstructions: (context, event) => {
        console.log("Clearing Instruction:", event);
        context.programPadItems = [];
        context.pathAUV = [];
        context.MasterCommands.forEach((command, count) => {
          command.selectable = command.selectable === true ? false : true;
        });
      },
      addInstructions: (context, event) => {
        console.log("Adding Instruction:", event);
        // context.pathAUV = [];
        console.log("addInstruction=", event.instruction.label);
        event.instruction.label !== "Run" &&
          context.pathAUV.push({
            [event.instruction.label]: parseInt(event.instruction.value)
          });
      },
      deleteInstruction: (context, event) => {
        console.log("Deleting Instruction:", event);
        context.programPadItems.splice(event.sourceIndex, 1);
        context.pathAUV = fnMapToPath(context.programPadItems);
      }
    }
  }
);

const arrFishLocations = [
  { location: [0, 0], fish: { bluefish2 }, size: 0.4 },
  { location: [0, 4], fish: { bluefish1 }, size: 1 },
  { location: [1, 2], fish: { yellowfish1 }, size: 0.47 },
  { location: [3, 6], fish: { redfish1 }, size: 0.36 },
  { location: [4, 1], fish: { redfish1 }, size: 0.61 },
  { location: [4, 2], fish: { bluefish2 }, size: 0.76 },
  { location: [4, 6], fish: { yellowfish1 }, size: 0.76 },
  { location: [5, 4], fish: { yellowfish2 }, size: 0.65 },
  { location: [5, 7], fish: { bluefish1 }, size: 0.87 },
  { location: [6, 3], fish: { bluefish2 }, size: 0.91 },
  { location: [7, 5], fish: { redfish1 }, size: 0.91 },
  { location: [7, 7], fish: { redfish2 }, size: 0.36 },
  { location: [8, 9], fish: { yellowfish2 }, size: 0.77 },
  { location: [9, 0], fish: { bluefish1 }, size: 0.65 },
  { location: [9, 3], fish: { bluefish2 }, size: 1 },
  { location: [9, 6], fish: { bluefish2 }, size: 0.5 }
];
const targetLocations = [
  { location: [1, 0], id: "auv_target_0" },
  { location: [2, 5], id: "auv_target_1" },
  { location: [5, 2], id: "auv_target_2" },
  { location: [7, 3], id: "auv_target_3" },
  { location: [9, 4], id: "auv_target_4", end: true }
];

const AUVGame = props => {
  const auvElement = useRef();
  const [tl] = useState(gsap.timeline({ paused: true }));
  const auvOffset = [-6, 1];
  const auvHomeU = [0, 7];

  const checkHit = e => {
    const auv = document.getElementById("auv_sub");
    const auvGrid = document.getElementById("auv_grid");
    const auvT = auv.getBoundingClientRect();
    const gridRect = auvGrid.getBoundingClientRect();
    const auvCenterX = auvT.left + (auvT.right - auvT.left) / 2;
    const auvCenterY = auvT.top + (auvT.bottom - auvT.top) / 2;
    // console.log(e.parent.vars);
    const pixelsToUnits = (pX, pY) => {
      return [
        Math.floor((pX / gridRect.width) * 10),
        10 - Math.floor((pY / gridRect.height) * 10)
      ];
    };
    const auvCenterU = pixelsToUnits(auvCenterX, auvCenterY);

    const percentPosition = (uX, uY) => {
      return [uX * 10 + auvOffset[0], (9 - uY) * 10 + auvOffset[1]];
    };
    const newPosition = () => {
      return [auvOffset[0], auvOffset[1], 90 + auvOffset[0], 90 + auvOffset[1]];
    };

    if (
      auvCenterX < gridRect.left ||
      auvCenterX > gridRect.right ||
      auvCenterY < gridRect.top ||
      auvCenterY > gridRect.bottom
    ) {
      tl.killTweensOf(auvElement.current);
      const [pXMin, pYMin, pXMax, pYMax] = newPosition();
      // console.log(`pXMin:${pXMin}, pYMin:${pYMin}`);
      const offset = {};
      if (auvCenterX < gridRect.left) offset["left"] = pXMin + "%";
      if (auvCenterX > gridRect.right) offset["left"] = pXMax + "%";
      if (auvCenterY < gridRect.top) offset["top"] = pYMin + "%";
      if (auvCenterY > gridRect.bottom) offset["top"] = pYMax + "%";

      // console.log(`newLeft: ${offset["left"]}, newTop: ${offset["top"]}`);
      gsap.to(auv, { ...offset });
      return;
    }

    const checkCollisions = (auvSub, obstacles) => {
      for (let i = 0; i < obstacles.length; i++) {
        const obstLoc = obstacles[i].location;
        if (auvSub[0] === obstLoc[0] && auvSub[1] === obstLoc[1]) {
          return obstacles[i];
        }
      }
      return false;
    };

    if (checkCollisions(auvCenterU, arrFishLocations)) {
      const shadow = { x: 0, y: 0, blur: 1 };
      gsap.set("#auv_sub", {
        webkitFilter: `drop-shadow(${shadow.x}vw ${shadow.y}vw ${shadow.blur}vw #ff0000cc)`
      });

      gsap.to("#auv_sub", { duration: 1, clearProps: "webkitFilter" });
      tl.killTweensOf(auvElement.current);
      const [pX, pY] = percentPosition(auvHomeU[0], auvHomeU[1]);
      gsap.to("#auv_sub", {
        delay: 1,
        duration: 1,
        left: `${pX}%`,
        top: `${pY}%`
      });
      return;
    }

    const target = checkCollisions(auvCenterU, targetLocations);
    if (target) {
      // console.log("e=", e);
      // console.log("e.parent.progress()", e.parent.progress());
      const playSound = filename => {
        var mp3Source = '<source src="' + filename + '" type="audio/mpeg">';
        var embedSource =
          '<embed hidden="true" autostart="true" loop="false" src="' +
          filename +
          '.mp3">';
        document.getElementById("sound").innerHTML =
          '<audio autoplay="autoplay">' + mp3Source + embedSource + "</audio>";
      };

      playSound(subSound);
      const shadow = { x: 0, y: 0, blur: 1 };
      gsap.set("#" + target.id, {
        webkitFilter: `drop-shadow(${shadow.x}vw ${shadow.y}vw ${shadow.blur}vw #00ff00cc)`
      });
      gsap.to("#" + target.id, {
        // delay: 0.25,
        webkitFilter: `drop-shadow(${shadow.x}vw ${shadow.y}vw ${shadow.blur}vw #00000000)`
      });
      if (target.end === true) {
        mSend("COMPLETE");
      }
    }
  };

  const generateTimeline = () => {
    // console.log("initializing timeLine");
    const convertToLeftTop = instruction => {
      const move = Object.keys(instruction)[0];
      const count = Object.values(instruction)[0] * 10;

      // console.log("Count=", count);
      switch (move) {
        case "Motor On":
          return {};
        case "Motor Off":
          return {};
        case "Forward":
          return { left: "+=" + count + "%" };
        case "Dive":
          return { top: "+=" + count + "%" };
        case "Rise":
          return { top: "-=" + count + "%" };
        case "Back":
        default:
          return { left: "-=" + count + "%" };
      }
    };

    tl.killTweensOf(auvElement.current);
    // console.log("tl=", tl);
    let tlTemp = tl;
    tlTemp.to(auvElement.current, {
      duration: 0.1,
      left: `${(((positionAUV[0] / 10) * 1920) / 1920) * 100 + auvOffset[0]}%`,
      top: `${((((9 - positionAUV[1]) / 10) * 1080) / 1080) * 100 +
        auvOffset[1]}%`
      // onUpdate: checkHit
    });
    // console.log("RECALCULATING timeline animation", current.context.pathAUV);
    current.context.pathAUV.forEach(function(instruction, stepCount) {
      // console.log("instruction=", instruction);
      const speed = Math.abs(Object.values(instruction)[0]);
      tlTemp = tlTemp.to(auvElement.current, {
        duration: speed / 2,
        onUpdate: checkHit,
        ...convertToLeftTop(instruction)
        // ...{ delay: 0.5 }
      });
    });
  };
  const fnAnimateAUV = (context, event) => {
    generateTimeline();
    return new Promise(resolve => {
      tl.eventCallback("onComplete", function() {
        resolve();
      });
      tl.restart();
    });
  };

  const fnStopAUV = (context, event) => {
    return new Promise(resolve => {
      tl.pause();
    });
  };

  const [current, mSend] = useMachine(menuMachine, {
    services: {
      fnAnimateAUV,
      fnStopAUV
    }
  });

  console.log("Current=", current.value);

  const {
    // pathAUV,
    positionAUV,
    showIntro,
    gameStarted,
    // runNeeded,
    done
  } = current.context;

  // console.log("pathAUV=", pathAUV);
  // console.log("done=", done);

  if (done) {
    return <Redirect to={props.nextStep} />;
  }

  const Fish = props => {
    const preScaleX = 15;
    const preScaleY = 15;

    const schoolOffsetX = 4.5;
    const schoolOffsetY = 6;
    const schoolOfFish = arrFishLocations.map((objFish, fishCount) => (
      <div
        key={fishCount}
        style={{
          width: `${objFish.size * preScaleX}%`,
          height: `${objFish.size * preScaleY}%`,
          position: "absolute",
          left: `${(((objFish.location[0] / 10) * 1920) / 1920) * 100 +
            schoolOffsetX}%`,
          top: `${((((9 - objFish.location[1]) / 10) * 1080) / 1080) * 100 +
            schoolOffsetY}%`
          // border: `1px solid white`
        }}
      >
        <div
          style={{
            width: `100%`,
            height: `100%`,
            position: "relative",
            left: `-50%`,
            top: `-50%`
            // border: `1px solid red`
          }}
        >
          <img
            src={Object.values(objFish.fish)[0]}
            alt={Object.keys(objFish.fish)[0]}
            style={{ width: "100%" }}
            className="auv_obstacle"
            id={"auv_obstacle_" + fishCount}
          />
        </div>
      </div>
    ));

    return schoolOfFish;
  };
  const Targets = props => {
    const preScaleX = 15;
    const preScaleY = 15;
    const targetWidth = 1;
    const targetHeight = 1;

    const offsetX = 5.1;
    const offsetY = 5.1;
    const numOfTargets = targetLocations.length;
    // console.log(numOfTargets);

    const Target = props => {
      const startStyle =
        props.startLocation === true
          ? {
              WebkitFilter: "drop-shadow(0 0 10px rgba(0,255,0,0.9))",
              filter: "drop-shadow(0 0 10px rgba(0,255,0,0.9))"
            }
          : null;
      const imgStyle = { width: "100%", ...startStyle };
      // const imgStyle = {
      //   "-webkit-filter": "drop-shadow(0 0 10px rgba(0,255,0,0.9))",
      //   filter: "drop-shadow(0 0 10px rgba(0,255,0,0.9))"
      // };

      const classValue = props.startLocation ? "auv_start" : "target";
      const imgClassValue = props.startLocation
        ? "auv_start_img"
        : "target_img";

      return (
        <div
          className={classValue}
          id={props.targetId}
          key={props.targetCount}
          style={{
            width: `${targetWidth * preScaleX}%`,
            height: `${targetHeight * preScaleY}%`,
            position: "absolute",
            left: `${(((props.arrTarget[0] / 10) * 1920) / 1920) * 100 +
              offsetX}%`,
            top: `${((((9 - props.arrTarget[1]) / 10) * 1080) / 1080) * 100 +
              offsetY}%`
            // border: `1px solid white`
          }}
        >
          <div
            style={{
              width: `100%`,
              height: `100%`,
              position: "relative",
              left: `-50%`,
              top: `-50%`
              // border: `1px solid red`
            }}
          >
            <img
              src={
                props.startLocation
                  ? startTarget
                  : props.targetCount < numOfTargets - 1
                  ? targetOpen
                  : finalTarget
              }
              alt={
                "Target " +
                (props.targetCount ? props.targetCount + 1 : "Start")
              }
              style={imgStyle}
              className={imgClassValue}
            />
          </div>
        </div>
      );
    };

    const targets = targetLocations.map((arrTarget, targetCount) => {
      return (
        <Target
          arrTarget={arrTarget.location}
          targetCount={targetCount}
          targetId={arrTarget.id}
          key={targetCount}
        />
      );
    });

    return (
      <>
        <Target arrTarget={[0, 7]} startLocation={true} />
        {targets}
      </>
    );
  };
  const Icon = props => {
    const IconDiv = styled.div`
      display: inline-block;
      position: absolute;
      width: 8%;
      height: 8%;
      img {
        position: relative;
        bottom: 20%;
        left: 20%;
        width: 100%;
      }
    `;
    return (
      <IconDiv>
        <img alt={props.alt} {...props} />
      </IconDiv>
    );
  };
  const ShowIntro = props => {
    return (
      <div className="video_tablet_container">
        <h1>
          AUV JOURNEY
          <Icon alt="Submarine Icon" src={subIcon} />
        </h1>

        <p>Code the AUV to go through the targets, without hitting any fish.</p>
        <div className="video_screen">
          <img alt="AUV Video" src={auvVideoStill} />
        </div>
        <p>
          Start each program with a RUN block and end it with an END block.
          Direction blocks move the ROV through the pool. LOOP repeats the code
          between two loop blocks. Use the drop-down menu to select the number
          of times you want to program that action.
        </p>
        <ButtonCenter>
          <AppButton onClick={() => mSend("CLICK")}>Got it</AppButton>
        </ButtonCenter>
      </div>
    );
  };

  const AUVProgrammer = props => {
    const GameContainer = styled.div`
      position: absolute;
      width: 100%;
      width: calc(720 / 1920 * 100%);
      height: calc(780 / 1080 * 100%);
      right: calc(93 / 1920 * 100%);
      top: calc(144 / 1080 * 100%);

      h1 {
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.5vw;
        font-weight: bold;
        font-style: normal;
        line-height: 140%;
        /* border: 1px solid red; */
      }
      h3 {
        display: inline-block;
        position: relative;
        left: 15%;
        width: 50%;
        text-transform: uppercase;
        font-size: 0.5vw;
        font-weight: bold;
        font-style: normal;
        line-height: 140%;
        opacity: 0.5;
        /* border: 1px solid red; */
      }
    `;

    // const reorder = (list, startIndex, endIndex) => {
    //   const result = Array.from(list);
    //   const [removed] = list.splice(startIndex, 1);
    //   result.splice(endIndex, 0, removed);
    //   return result;
    // };

    // const copy = (
    //   source,
    //   destination,
    //   droppableSource,
    //   droppableDestination
    // ) => {
    //   const item = source[droppableSource.index];
    //   console.log("item=", item);
    //   destination.splice(droppableDestination.index, 0, {
    //     ...item,
    //     id: uuid()
    //   });
    //   return destination;
    // };

    // const move = (
    //   source,
    //   destination,
    //   droppableSource,
    //   droppableDestination
    // ) => {
    //   const sourceClone = Array.from(source);
    //   const destClone = Array.from(destination);
    //   const [removed] = sourceClone.splice(droppableSource.index, 1);

    //   destClone.splice(droppableDestination.index, 0, removed);

    //   const result = {};
    //   result[droppableSource.droppableId] = sourceClone;
    //   result[droppableDestination.droppableId] = destClone;

    //   return result;
    // };

    const fnOnDragUpdate = result => {
      const { source, destination } = result;
      console.log("source.droppableId=", source.droppableId);
      document.getElementById("auv_delete_pad").classList.remove("can_drop");
      if (!destination) {
        return;
      }
      if (destination.droppableId === "DELETEPAD") {
        document.getElementById("auv_delete_pad").classList.add("can_drop");
        // console.log("destination=", destination);
      }
    };
    const fnOnDragEnd = result => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }
      // console.log("source.droppableId=", source.droppableId);
      // console.log("destination.droppableId=", destination.droppableId);

      if (destination.droppableId === "DELETEPAD") {
        // console.log("Delete command:", source);
        // const offset = source.droppableId === "PROGRAMPADRIGHT" ? 7 : 0;
        mSend("DELETE", {
          sourceIndex: source.index
        });
      } else
        switch (source.droppableId) {
          case "PROGRAMPADLEFT":
          case "PROGRAMPADRIGHT":
            // console.log("destination.droppableId:", destination.droppableId);
            // const sourceOffset =
            //   source.droppableId === "PROGRAMPADRIGHT" ? 7 : 0;
            // const destOffset =
            //   destination.droppableId === "PROGRAMPADRIGHT" ? 7 : 0;
            mSend("REORDER", {
              sourceIndex: source.index,
              destinationIndex: destination.index
            });
            break;
          case "MasterCommands":
            // console.log("programPadItems=", current.context.programPadItems);
            // console.log(
            //   "programPadItems.length=",
            //   current.context.programPadItems.length
            // );
            mSend("DROPCOMMAND", {
              instruction: current.context.MasterCommands[source.index],
              destination: destination
            });
            break;
          default:
            // console.log("default reached.  Should not happen:", source.drop);
            break;
        }
    };
    // console.log("programPadItems=", current.context.programPadItems);
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <GameContainer>
            <h1>
              AUV JOURNEY
              <Icon alt="Submarine Icon" src={subIcon} />
            </h1>
            <h3>
              Hint: Drag’n’drop code blocks onto the coding pad. When you are
              ready to try your code, hit RUN.
            </h3>
            <DragDropContext
              onDragEnd={fnOnDragEnd}
              onDragUpdate={fnOnDragUpdate}
            >
              <AUVControlPad
                items={current.context.MasterCommands}
                mSend={mSend}
              />

              <AUVProgramPad
                items={current.context.programPadItems}
                mSend={mSend}
                collection="ProgrammingPad"
              />
              <DeletePad mSend={mSend} />
            </DragDropContext>

            <div className="auv_program_control">
              <button
                onClick={() => {
                  mSend("CLEARINSTRUCTIONS");
                }}
              >
                Erase Commands
              </button>
              <button
                onClick={() => {
                  console.log("RUN pressed");
                  mSend("RUN");
                }}
              >
                Run Program
              </button>
            </div>
            <div className="button_holder">
              <button
                className="app_button"
                onClick={() => mSend("DONE")}
                disabled={props.buttonDisabled}
                style={{ margin: "0 auto" }}
              >
                Done
              </button>
            </div>
          </GameContainer>
        </DndProvider>
      </>
    );
  };

  const arrLocation = positionAUV;
  const preScale = 20;

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <LargeTablet showTablet={true}>
          {showIntro && <ShowIntro />}
          {gameStarted && (
            <AUVProgrammer
              {...props}
              buttonDisabled={current.context.buttonDisabled}
            />
          )}
        </LargeTablet>

        <div className="auv_grid" id="auv_grid">
          <div id="sound"></div>
          <Fish />
          {gameStarted && <Targets />}
          <img
            ref={auvElement}
            src={auv}
            alt="AUV"
            style={{
              width: `${preScale}%`,
              position: "absolute",
              left: `${(((arrLocation[0] / 10) * 1920) / 1920) * 100 +
                auvOffset[0]}%`,
              top: `${((((9 - arrLocation[1]) / 10) * 1080) / 1080) * 100 +
                auvOffset[1]}%`
              // border: `1px solid white`
            }}
            id="auv_sub"
          />
        </div>
      </NewLargeTablet>
    </Environment>
  );
};

export default AUVGame;
