import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import {
  stationMachine as envMachine,
  StationContent as TabletContent
} from "./StationEnvironment";

const KayakStation = props => {
  const [current, mSend] = useMachine(envMachine);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakStation;
