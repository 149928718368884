import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import { testingMachine as envMachine } from "./NaturalBridgesEnvironment";
import { InfoPage } from "components/TabletApps";
import imgPHTestingBeakers from "img/ph_testing_beakers.svg";

const Review = props => {
  // const [objUser] = useContext(UserContext);
  // const ringRef = useRef();

  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      default:
        return (
          <InfoPage
            {...props} // mSend is already set
            title="pH Testing No 2"
            image={imgPHTestingBeakers}
            buttonText="I'm ready"
            alt="pH Testing"
            message="Let's collect some additional samples."
            action="PRESS"
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
