import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import UserContext from "context/User";
import appData from "components/appCall/CallingCharlesNye";
import { TabletContent, envMachine } from "./TableEnvironment";

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }
const context = {
  id: "intro",
  scientistSubTitles: [
    "We are going to explore two tidepools next. But before we do, let’s join our Social Media Manager, Patrick, as he is currently on the field exploring a tide pool in one of our nearby state parks."
    // "“We are going to explore two tide pools next: Pacific Grove and Natural Bridges. Tide pools are a part of fascinating ecosystems with ever-changing conditions. The adaptations of the organisms...”"
  ],
  scientistData: appData,
  clipboardIsTest: true,
  videoAfterCall: true
};

const TidePoolIntro = props => {
  const [objUser] = useContext(UserContext);
  // const ringRef = useRef();
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default TidePoolIntro;
