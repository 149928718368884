import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
// import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
// import imgKayakStation from "img/ks_background.jpg";
// import imgKayakStationSmall from "img/ks_background_sm.jpg";
import imgKayakStationTablet from "img/ks_background_tablet.jpg";
import imgKayakStationTabletSmall from "img/ks_background_tablet_sm.jpg";
import imgTableWTablet from "img/table_w_tablet.png";
import imgTableTabletHighlight from "img/table_tablet_highlight.svg";
import imgKayakShackDoorHighlight from "img/ks_door_highlight.svg";
import imgCrate from "img/ks_crate.png";
// import tabletBG from "img/tablet_background.jpg";
import imgQPCR from "img/ks_qPCR_obtained.png";
import appData from "components/appCall/CallingCharlesNye";
import { NewLargeTablet } from "components/UIComponents";
// import { AppScreen } from "components/TabletApps";
// import {
//   NotesIntro,
//   NotesSelection,
//   Reflection,
//   GallerySelection
// } from "components/NotesApp";
import { notesMachine as envMachine } from "components/NotesMachine";
import { TabletContent } from "components/NotesApp";
import { Modal } from "antd";

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }
// const morePages = (context, event) => {
//   return context.page > 1;
// };

const contextEnv = {
  environment: {
    className: "env_elkhorn",
    shiftBackground: true,
    // shiftLeft: "8.6%",
    background: {
      optimize: true,
      src: imgKayakStationTablet,
      thumbnail: imgKayakStationTabletSmall,
      alt: "Elkorn Slough Kayak Station",
      color: "#132962",
      aspectRatio: "1920x1080"
    },
    clickables: {
      tableTablet: {
        visible: true,
        clickable: true,
        showHighlight: false,
        ringing: true,
        action: "pickUpTablet",
        className: "table_tablet",
        layers: [
          {
            name: "background",
            optimize: false,
            src: imgTableWTablet,
            style: { width: "100%" },
            aspectRatio: "671x273"
          },
          {
            name: "overlay",
            optimize: false,
            src: appData.imgOnTableTablet,
            style: {
              position: "absolute",
              left: "46.2%",
              width: "11%",
              top: "2%"
            },
            aspectRatio: "76x84"
          },
          {
            name: "highlight",
            handlesClick: true,
            handlesHighlight: true,
            optimize: false,
            src: imgTableTabletHighlight,
            style: {
              position: "absolute",
              left: "44.5%",
              width: "15%",
              top: "-1%"
            },
            aspectRatio: "113x126"
          }
        ]
      },
      crateWSensor: {
        visible: true,
        clickable: false,
        showHighlight: false,
        ringing: false,
        action: "TAKE",
        className: "crate_w_sensor",
        layers: [
          {
            name: "background",
            optimize: false,
            src: imgCrate,
            style: { width: "100%" }
          }
        ]
      },
      shackDoor: {
        visible: false,
        clickable: true,
        ringing: false,
        showHighlight: true,
        action: "ENTER",
        className: "shack_door",
        layers: [
          {
            name: "highlight",
            handlesClick: true,
            handlesHighlight: true,

            optimize: false,
            src: imgKayakShackDoorHighlight,
            style: {
              width: "100%"
            }
          }
        ]
      }
    },
    obtainable: {
      title: "Handheld qPCR Device",
      src: imgQPCR,
      description:
        "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
    }
  }
};

// export const envMachine = Machine(
//   {
//     id: "elkhornNotes",
//     context: {
//       environment: {
//         className: "env_elkhorn",
//         shiftBackground: true,
//         // shiftLeft: "8.6%",
//         background: {
//           optimize: true,
//           src: imgKayakStationTablet,
//           thumbnail: imgKayakStationTabletSmall,
//           alt: "Elkorn Slough Kayak Station",
//           color: "#132962",
//           aspectRatio: "1920x1080"
//         },
//         clickables: {
//           tableTablet: {
//             visible: true,
//             clickable: true,
//             showHighlight: false,
//             ringing: true,
//             action: "pickUpTablet",
//             className: "table_tablet",
//             layers: [
//               {
//                 name: "background",
//                 optimize: false,
//                 src: imgTableWTablet,
//                 style: { width: "100%" },
//                 aspectRatio: "671x273"
//               },
//               {
//                 name: "overlay",
//                 optimize: false,
//                 src: appData.imgOnTableTablet,
//                 style: {
//                   position: "absolute",
//                   left: "46.2%",
//                   width: "11%",
//                   top: "2%"
//                 },
//                 aspectRatio: "76x84"
//               },
//               {
//                 name: "highlight",
//                 handlesClick: true,
//                 handlesHighlight: true,
//                 optimize: false,
//                 src: imgTableTabletHighlight,
//                 style: {
//                   position: "absolute",
//                   left: "44.5%",
//                   width: "15%",
//                   top: "-1%"
//                 },
//                 aspectRatio: "113x126"
//               }
//             ]
//           },
//           crateWSensor: {
//             visible: true,
//             clickable: false,
//             showHighlight: false,
//             ringing: false,
//             action: "TAKE",
//             className: "crate_w_sensor",
//             layers: [
//               {
//                 name: "background",
//                 optimize: false,
//                 src: imgCrate,
//                 style: { width: "100%" }
//               }
//             ]
//           },
//           shackDoor: {
//             visible: false,
//             clickable: true,
//             ringing: false,
//             showHighlight: true,
//             action: "ENTER",
//             className: "shack_door",
//             layers: [
//               {
//                 name: "highlight",
//                 handlesClick: true,
//                 handlesHighlight: true,

//                 optimize: false,
//                 src: imgKayakShackDoorHighlight,
//                 style: {
//                   width: "100%"
//                 }
//               }
//             ]
//           }
//         },
//         obtainable: {
//           title: "Handheld qPCR Device",
//           src: imgQPCR,
//           description:
//             "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
//         }
//       },
//       tabletBG: tabletBG,
//       showTablet: true,
//       itemObtained: null,
//       saved: { "Lesson 2": null, "Lesson 3": null }
//     },
//     initial: "appScreen",
//     states: {
//       appScreen: {
//         on: {
//           NOTES: {
//             target: "notesSelection",
//             actions: assign({
//               backClickable: false,
//               currentFile: "Notes",
//               forwardArrowClickable: true,
//               page: 1
//             })
//           }
//         }
//       },
//       intro: {
//         on: {
//           NOTES: {
//             target: "notesSelection",
//             actions: assign({
//               backClickable: true,
//               currentFile: "Notes",
//               parentDir: null
//             })
//           },
//           FORWARDARROW: [
//             {
//               target: "intro",
//               cond: morePages,
//               actions: ["nextPage"]
//             },
//             {
//               target: "notesSelection",
//               actions: assign({
//                 page: 1,
//                 backClickable: true
//               })
//             }
//           ],
//           BACKARROW: [
//             {
//               target: "intro",
//               cond: morePages,
//               actions: ["prevPage"]
//             },
//             {
//               target: "notesSelection",
//               actions: assign({
//                 page: 1,
//                 backClickable: true
//               })
//             }
//           ]
//         }
//       },
//       notesSelection: {
//         on: {
//           BACK: {
//             target: "appScreen",
//             actions: assign({
//               backClickable: false,
//               currentFile: "Foreword",
//               parentDir: "Notes"
//             })
//           },
//           INTRO: {
//             target: "intro",
//             actions: assign({
//               backClickable: true,
//               currentFile: "Foreword",
//               parentDir: "Notes"
//             })
//           },
//           MBARIREFLECT: {
//             target: "reflection",
//             actions: assign({
//               notesID: "MBARI",
//               backClickable: true,
//               currentFile: "MBARI Reflection",
//               parentDir: "Notes"
//             })
//           },
//           ELKHORNREFLECT: {
//             target: "reflection",
//             actions: assign({
//               notesID: "ELKHORN",
//               backClickable: true,
//               currentFile: "Elkhorn Slough Reflection",
//               parentDir: "Notes"
//             })
//           },
//           DONE: {
//             target: "done",
//             actions: assign({
//               backClickable: true,
//               currentFile: "MBARI Reflection",
//               parentDir: "Notes",
//               done: true
//             })
//           }
//         }
//       },
//       reflection: {
//         on: {
//           TEXTFINISHED: [
//             {
//               target: "reflection",
//               cond: morePages,
//               actions: assign({
//                 qNum: context => context.qNum + 1
//               })
//             },
//             {
//               target: "reflection",
//               actions: assign({
//                 canSave: true
//               })
//             }
//           ],
//           IMAGECHOOSE: {
//             target: "chooseImage",
//             actions: ["chooseImage"]
//           },
//           BACK: {
//             target: "notesSelection",
//             actions: () => console.log("Should save")
//           },
//           SAVE: {
//             target: "notesSelection",
//             actions: assign({
//               backClickable: true,
//               currentFile: "Notes",
//               parentDir: null
//             })
//           }
//         }
//       },
//       chooseImage: {
//         on: {
//           SELECT: {
//             target: "reflection",
//             actions: ["chooseImageFromGallery"]
//           }
//         }
//       },
//       done: {
//         type: "final"
//       }
//     }
//   },
//   {
//     actions: {
//       chooseImage: (context, event) => {
//         context.notesID = event.notesID;
//         context.backClickable = true;
//         context.currentFile = event.currentFile;
//         context.parentDir = "Notes";
//       },
//       chooseImageFromGallery: (context, event) => {
//         console.log("event is:", event);

//         // context.saved[event.lesson].imageID = event.selectionID;
//         context.saved[event.lesson] = {
//           ...context.saved[event.lesson],
//           imageID: event.selectionID
//         };
//       },
//       createShiftedStyle: (context, event) => {
//         // console.log("HERE!");
//         Object.keys(context.environment.clickables).forEach(function(key) {
//           const clickable = context.environment.clickables[key];
//           console.log(clickable);
//           clickable.shiftedStyle = {};
//           Object.assign(clickable.shiftedStyle, { ...clickable.style });
//           clickable.shiftedStyle.left = subTwoPercentages(
//             clickable.style.left,
//             context.environment.shiftLeft
//           );
//           // console.log("HERE! shiftedStyle = ", clickable.shiftedStyle);
//           // console.log("HERE! style = ", clickable.style);
//         });
//       },
//       storeItem: (context, event) => {
//         context.itemStored = context.itemObtained;
//         context.itemObtained = null;
//         context.environment.clickables.shackDoor.showHighlight = true;
//         context.environment.clickables.shackDoor.visible = true;
//       },
//       pickUpTablet: (context, event) => {
//         context.showTablet = true;
//         context.environment.background.src = imgKayakStationTablet;
//         context.environment.background.thumbnail = imgKayakStationTabletSmall;
//         context.environment.shiftBackground = true;
//         // console.log("HERE:", context.environment.background);
//         context.callInProgress = true;
//         context.callConnected = false;
//         context.environment.clickables.tableTablet.showHighlight = false;
//         context.environment.clickables.tableTablet.ringing = false;
//       },
//       obtainItem: (context, event) => {
//         context.itemObtained = context.environment.obtainable;
//         context.environment.clickables.crateWSensor.visible = false;
//       },
//       finishCall: (context, event) => {
//         context.callStep = 0;
//         context.callInProgress = false;
//         context.showTablet = false;
//         context.environment.clickables.crateWSensor.showHighlight = true;
//         context.environment.background.src = imgKayakStation;
//         context.environment.background.thumbnail = imgKayakStationSmall;
//         context.environment.shiftBackground = false;
//         context.done = true;
//         // console.log("CONTEXT IS NOW:", context);
//       }
//     },
//     guards: {
//       morePages
//     }
//   }
// );

const Notes = props => {
  // const [current, mSend] = useMachine(envMachine);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...contextEnv }
  });
  const { context } = current;
  console.log("Notes, current page:", current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  // const TabletContent = props => {
  //   const { current } = props;
  //   console.log(current.value);
  //   switch (current.value) {
  //     case "appScreen":
  //       return <AppScreen mSend={mSend} focus="Notes" />;
  //     case "notesSelection":
  //       return <NotesSelection {...props} />;
  //     case "reflection":
  //       return <Reflection {...props} notesID={current.context.notesID} />;
  //     case "chooseImage":
  //       return (
  //         <GallerySelection
  //           {...props}
  //           notesID={current.context.notesID}
  //           lesson={"Lesson " + props.lesson}
  //         />
  //       );
  //     case "intro":
  //     default:
  //       return <NotesIntro {...props} />;
  //   }
  // };
  // return (
  //   <Environment {...props} context={current.context} mSend={mSend}>
  //     <NewLargeTablet showTablet={current.context.showTablet}>
  //       <TabletContent {...props} mSend={mSend} current={current} />
  //     </NewLargeTablet>
  //   </Environment>
  // );
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          startWithIntro={false}
        />
      </NewLargeTablet>
      <Modal
        title={context.errorMessage}
        visible={context.showModal}
        onOk={() => mSend(context.okCommand)}
        onCancel={() => mSend("CANCEL")}
      >
        <p>{context.errorInstructions}</p>
      </Modal>
    </Environment>
  );
};

export default Notes;
