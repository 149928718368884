import React, { useState, useContext } from "react";
import { Form, Input, Icon, Row, Col, Button, Layout, message } from "antd";
import axios from "axios";
import UserContext from "context/User.js";
import { OImg } from "components/UIComponents";
import imgBG from "img/bgLogin.jpg";
import imgBGSmall from "img/bgLogin_sm.jpg";
const { Content } = Layout;

var objForm = {
  first: "",
  last: "",
  email: "",
  pw: "",
  confirm: "",
  coupon: ""
};
var arrErrors = [];
/* TODO: Terms and Conditions */

const Register = props => {
  const [objUser] = useContext(UserContext);
  console.log("objUser=", objUser);
  const [objTmp, fnUpdate] = useState({
    first: "",
    last: "",
    email: "",
    pw: "",
    confirm: "",
    coupon: ""
  });
  const fnUpdateField = function(k, v) {
    fnUpdate({ ...objTmp, [k]: v });
  };
  const fnSubmit = function(e) {
    e.preventDefault();
    //one last check over every field
    var arrKeys = Object.keys(objForm);
    var ok = true;
    for (var i = 0; i < arrKeys.length; i++) {
      var strError = false;
      strError = fnValidate(arrKeys[i], objForm[arrKeys[i]]);
      if (strError) {
        message.error(strError);
        ok = false;
        break;
      }
    }
    for (i = 0; i < arrKeys.length; i++) {
      if (objTmp[arrKeys[i]]) {
        ok = false;
        break;
      }
    }
    console.log(strError);
    if (ok && !strError) {
      console.log(objForm);
      axios
        .post(objUser.api + "/api/useradd", { d: objForm, jwt: undefined })
        .then(objResponse => {
          console.log("fnSubmit useradd: ", objResponse);
          arrErrors = objResponse.data.user.errors;
          if (arrErrors && arrErrors.length > 0) {
            for (var i = 0; i < arrErrors.length; i++) {
              message.error(arrErrors[i]);
            }
          } else {
            // objUser.fnUpdateField("user", { email: objForm.email });
            props.history.push("/map");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const fnValidate = function(strField, strValue) {
    //console.log(objForm,strField,strValue);
    //required
    var strError = false;
    if (
      ["first", "last", "email", "pw", "confirm"].indexOf(strField) > -1 &&
      strValue.length === 0
    ) {
      strError = "Please fill in value";
    } else if (strField === "pw" && strValue.length < 5) {
      strError = "A password needs to be longer than 5 characters";
    } else if (strField === "confirm" && strValue !== objForm.pw) {
      strError = "Password confirmation doesn't match";
    }

    if (strError) {
      //console.log('field error');
      fnUpdateField(strField, strError);
      return strError;
    } else {
      //console.log('field ok');
      objForm[strField] = strValue;
      fnUpdateField(strField, false);
      return false;
    }
  };

  return (
    <div className="admin_page">
      <OImg
        src={imgBG}
        thumbnail={imgBGSmall}
        aspectRatio="1920x1080"
        alt="Login Page"
        color={"#132962"}
      />

      <Content className="contentPane">
        <div className="admin_form">
          <Row>
            <Col className="container">
              <h2>Sign up {objTmp.i} </h2>
              <Form onSubmit={fnSubmit} className="login-form">
                <Form.Item>
                  <div className="inputLabel">First name*</div>
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder=""
                    onBlur={e => fnValidate("first", e.target.value)}
                  />
                  {objTmp.first !== "" && (
                    <div className="inputError"> {objTmp.first} </div>
                  )}
                </Form.Item>
                <Form.Item>
                  <div className="inputLabel">Last name*</div>
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder=""
                    onBlur={e => fnValidate("last", e.target.value)}
                  />
                  {objTmp.last && (
                    <div className="inputError"> {objTmp.last} </div>
                  )}
                </Form.Item>
                <Form.Item>
                  <div className="inputLabel">Email*</div>
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder=""
                    onBlur={e => fnValidate("email", e.target.value)}
                  />
                  {objTmp.email && (
                    <div className="inputError"> {objTmp.email} </div>
                  )}
                </Form.Item>
                <Form.Item>
                  <div className="inputLabel">Password*</div>
                  <Input.Password
                    visibilityToggle={true}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder=""
                    onBlur={e => fnValidate("pw", e.target.value)}
                  />
                  {objTmp.pw && <div className="inputError"> {objTmp.pw} </div>}
                </Form.Item>
                <Form.Item>
                  <div className="inputLabel">Confirm Password*</div>
                  <Input.Password
                    visibilityToggle={true}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder=""
                    onBlur={e => fnValidate("confirm", e.target.value)}
                  />
                  {objTmp.confirm && (
                    <div className="inputError"> {objTmp.confirm} </div>
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="fullWidth b"
                    span={6}
                  >
                    Sign up
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Content>
    </div>
  );
};

export default Register;
