import React, { useState, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";
import { askMachine as envMachine } from "./NaturalBridgesEnvironment";

import {
  ShowVideo,
  AskScientist,
  AppScreen,
  CallWSubTitles,
  ManualInitiateCall
} from "components/TabletApps";
//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

const AskScientistApp = props => {
  const [objUser] = useContext(UserContext);
  // const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  objUser.level = props.lesson;
  objUser.objective = "Elkhorn Slough & Kayak Station";
  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );
  const [current, mSend] = useMachine(envMachine, {
    services: { animateRing }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "showVideo":
        return (
          <ShowVideo
            videoId="BasEbaUGqvA"
            videoTitle="Measuring Ocean Change"
            videoIntroduction="New technology gives a view of the changing oceans."
            videoCaption="How would you test the ocean?"
            onEnd={() => mSend("VIDEODONE")}
            start={16}
            mSend={mSend}
            noButton={true}
          />
        );
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.scientistData.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "askScientist":
      case "waitForPage":
        return (
          <AskScientist
            mSend={mSend}
            current={current}
            ringTimeLine={ringTimeLine}
          />
        );
      case "pickUpTablet":
      case "ringScientist":
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={current.context.scientistData.callImg}
          />
        );
      default:
        return <AppScreen mSend={mSend} focus="AskScientist" />;
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default AskScientistApp;
