import React from "react";
import { Redirect } from "react-router-dom";
import { useMachine } from "@xstate/react";
import appData from "components/appCall/CallingBlastQuiz";
import { Environment } from "components/Environment";
import { TabletContent, envMachine } from "./TestingAEnvironment";
import { NewLargeTablet } from "components/UIComponents";

const context = {
  scientistData: appData,
  backPackUnlocked: true
};

const TestSamplesA = props => {
  console.log("scientistData:", context.scientistData);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default TestSamplesA;
