import React, { useContext } from "react";
import UserContext from "context/User";
import DragAndMatchTest from "components/DragAndMatchTest";
import LessonTest from "custom/lab/DragAndDropTest";
import imgTide from "img/pg_dnm_tide.jpg";
import imgIntertidal from "img/pg_dnm_intertidal_zone.jpg";
import imgTidePool from "img/pg_dnm_tide_pool.jpg";
import imgLowTide from "img/pg_dnm_low_tide.jpg";
import imgPh from "img/pg_dnm_ph.jpg";
import imgHighTide from "img/pg_dnm_high_tide.jpg";

import imgBackground from "img/pg_table_tablet_blank_bg.jpg";
import imgBackgroundSmall from "img/pg_table_tablet_blank_bg_sm.jpg";
import imgAnswerOverlay from "img/pg_drag_n_match_overlay_1.svg";

const objAnswerKey = {
  TIDE: { text: "Tide", img_src: imgTide },
  INTERTIDAL: { text: "Intertidal Zone", img_src: imgIntertidal },
  TIDEPOOL: { text: "Tide Pool", img_src: imgTidePool },
  LOWTIDE: { text: "Low Tide", img_src: imgLowTide },
  PH: { text: "pH", img_src: imgPh },
  HIGHTIDE: { text: "High Tide", img_src: imgHighTide }
};
// const objAnswerKey = {
//   SLOUGH: { text: "Slough" },
//   ESTUARY: { text: "Estuary" },
//   PESTICIDES: {
//     text: "Pesticides"
//   },
//   PEAT: {
//     text: "Peat"
//   }
// };

const objTest = new LessonTest(objAnswerKey);

const PicnicDnD = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) objUser.user.objective = "Pacific Grove Tide Pool";

  return (
    <DragAndMatchTest
      {...props}
      parentClass={"picnic"}
      overlay={imgAnswerOverlay}
      objTest={objTest}
      background={imgBackground}
      backgroundSmall={imgBackgroundSmall}
      check="green"
    />
  );
};

export default PicnicDnD;
