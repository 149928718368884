import React from "react";
import { Redirect } from "react-router-dom";
import { useMachine } from "@xstate/react";
// import styled from "@emotion/styled";
// import backgroundTablet from "img/lab_background_tablet.jpg";
// import { Tablet } from "components/Tablet";
import multipleChoice from "custom/lab/BlastQuiz";
import qzimgLowTide from "img/pg_quiz/pg_quiz_low_tide.jpg";
import qzimgHighTide from "img/pg_quiz/pg_quiz_high_tide.jpg";
import qzimgChemicals from "img/pg_quiz/pg_quiz_chemicals.jpg";
import qzimgPH from "img/pg_quiz/pg_quiz_ph.jpg";
import qzimgTidePool from "img/pg_quiz/pg_quiz_tide_pool.jpg";

import appData from "components/appCall/CallingBlastQuiz";
import { Environment } from "components/Environment";
import { TabletContent, envMachine } from "./TableEnvironment";
import { NewLargeTablet } from "components/UIComponents";

const quizItems = [
  {
    imgSrc: [qzimgLowTide, qzimgHighTide],
    question: "Why do tides occur?",
    choices: {
      1: "Movement of undersea creatures",
      2: "The wind",
      3: "The gravitational pull of the moon and the sun",
      4: "The weather"
    },
    answers: [3]
  },
  {
    imgSrc: qzimgTidePool,
    question: "What is one adaptation of tide pool creatures?",
    choices: {
      1: "They have hard shells to hold water in",
      2: "They are big in size",
      3: "They have good eyesight",
      4: "They have no predators"
    },

    answers: [1]
  },
  {
    imgSrc: qzimgPH,
    question: "What is pH?",
    choices: {
      1: "A measure of how much animals eat",
      2: "A measure of how acidic or basic a substance is",
      3: "A measure of how far you can see underwater",
      4: "A measure of how much salt is in the ocean"
    },
    answers: [2]
  },
  {
    imgSrc: qzimgChemicals,
    question: "What has caused acidification of the water?",
    choices: {
      1: "Chemicals from plastic",
      2: "Chemical pollution from farms",
      3: "Carbon dioxide has increased from burning fossil fuels",
      4: "Fossil fuels have spilled in the bay"
    },
    answers: [3]
  }
];
const theQuiz = new multipleChoice(quizItems);

const context = {
  id: "blastQuiz",
  scientistData: appData,
  itemStored: "phCharts",
  blastQuiz: true
};

const BlastQuiz = props => {
  console.log("scientistData:", context.scientistData);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });
  console.log("State:", current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          quiz={theQuiz}
        />
      </NewLargeTablet>
    </Environment>
  );
};

export default BlastQuiz;
