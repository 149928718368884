import React from "react";
import { NavLink } from "react-router-dom";
import { OImg } from "components/UIComponents";
import imgBG from "img/bgLogin.jpg";
import imgBGSmall from "img/bgLogin_sm.jpg";

// const { Content } = Layout;

const NotFinished = props => {
  return (
    <div className="admin_page">
      <OImg
        src={imgBG}
        thumbnail={imgBGSmall}
        aspectRatio="1920x1080"
        alt="Login Page"
        color={"#132962"}
      />
      <div
        style={{
          position: "absolute",
          top: "12.5%",
          left: "5%",
          backgroundColor: "white",
          color: "black",
          width: "20%",
          height: "75%",
          borderRadius: "1vw",
          padding: "1vw"
        }}
      >
        <h1>Not Finished</h1>
        <p>{props.message || "Work in Progress."}</p>
        <p>
          <NavLink to={props.nextStep}>{props.nextStep}</NavLink>
        </p>
      </div>
    </div>
  );
};

export default NotFinished;
