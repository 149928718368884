import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
// import UserContext from "context/User";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";
import appData from "components/appCall/CallingEmeryNolasco";

const context = {
  id: "intro",
  scientistSubTitles: [
    "Hi, I am Emery Nolasco and I am an AUV Operations Engineer. My role at MBARI involves maintaining and operating AUVs.   There are many responsibilities associated with my position, but it is exciting to be creating new technology for exploring the ocean.",
    "If you like adventure and technology, you would love a career as an Operations Engineer of one of our many underwater vehicles.  Check out how we use our AUVs and other underwater tools here at MBARI."
  ],
  scientistData: appData,
  askAScientist: true,
  videoAfterCall: true,
  videoId: "Hq-XERexZL4",
  videoTitle: "Deep-Sea Exploration",
  videoIntroduction:
    "MBARI's scientists encountered a problem with their ROV when studying deep-sea animals.",
  videoCaption: "Watch and listen to see their clever solution."
  // autoAnswer: true,
  // doneAfterCall: true
};

const AskScientistApp = props => {
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  console.log(current.context);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default AskScientistApp;
