import callImg from "img/appCall/alexandra_zoe_worden_calling.jpg";
import callWithScientist from "img/appCall/call_with_alexandra_zoe_worden.jpg";
import videoStill from "img/appCall/call_with_alexandra_zoe_worden.jpg";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Dr. Alexandra Zoe Worden",
  scientistTitle: "MBARI Microbial Ecologist and Biologist",
  scientistTitleShort: "Microbial Ecologist and Biologist",
  videoStill: videoStill
};
export default appData;
