import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakStation from "img/ks_background_blur.jpg";
import largeRings from "img/ks_large_rings.svg";
import largePCR from "img/ks_large_qpcr.svg";
import imgTesttube1 from "img/ks_testtube1.svg";
import imgTesttube2 from "img/ks_testtube2.svg";
import imgTesttube3 from "img/ks_testtube3.svg";
import imgTesttube4 from "img/ks_testtube4.svg";
import imgTesttube5 from "img/ks_testtube5.svg";
import imgVideoStill from "img/ks_testing_video_still.svg";
import tabletBG from "img/tablet_background.jpg";
import appData from "components/appCall/CallingCharlesNye";
import { LessonPage, TestingPage } from "components/TabletApps";

export const envMachine = Machine(
  {
    id: "waterTesting",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: false,
          src: imgKayakStation,
          // src: imgKayakStationTablet,
          // thumbnail: imgKayakStationTabletSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          largeRings: {
            visible: true,
            className: "large_rings",
            layers: [
              {
                name: "background",
                optimize: false,
                src: largeRings,
                style: {
                  position: "absolute",
                  top: "18%",
                  left: "6%",
                  width: "40%",
                  opacity: "0.5"
                }
              }
            ]
          },
          largePCR: {
            id: "largePCR",
            visible: true,
            className: "large_pcr",
            droppable: true,
            hideTargetsOnDrop: true,
            dropAction: "DROP",
            layers: [
              {
                name: "background",
                optimize: false,
                src: largePCR,
                style: {
                  position: "absolute",
                  top: "38%",
                  left: "20%",
                  width: "12%"
                }
              }
            ]
          },
          testtube_1: {
            visible: true,
            draggable: false,
            className: "testtube",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube1,
                id: "testtube_1",
                handlesDrag: true,
                style: {
                  position: "absolute",
                  top: "50%",
                  left: "1%",
                  width: "18%"
                }
              }
            ]
          },
          testtube_2: {
            visible: true,
            draggable: false,
            className: "testtube",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube2,
                id: "testtube_2",
                handlesDrag: true,
                style: {
                  position: "absolute",
                  top: "15%",
                  left: "4%",
                  width: "18%"
                }
              }
            ]
          },
          testtube_3: {
            visible: true,
            draggable: false,
            className: "testtube",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube3,
                id: "testtube_3",
                handlesDrag: true,
                style: {
                  position: "absolute",
                  top: "8%",
                  left: "25%",
                  width: "18%"
                }
              }
            ]
          },
          testtube_4: {
            visible: true,
            draggable: false,
            className: "testtube",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube4,
                id: "testtube_4",
                handlesDrag: true,
                style: {
                  position: "absolute",
                  top: "45%",
                  left: "35%",
                  width: "18%"
                }
              }
            ]
          },
          testtube_5: {
            visible: true,
            draggable: false,
            className: "testtube",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube5,
                id: "testtube_5",
                handlesDrag: true,
                style: {
                  position: "absolute",
                  top: "68%",
                  left: "18%",
                  width: "18%"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      videoStill: appData.callWithScientist,
      scientistSubTitles: [
        "“Glad to see you once again. Remember that blue box that you found earlier? That is qPCR device. I've sent you video instructions on how to use it. Follow the instructions and help us test the samples.”"
      ],
      showTablet: true,
      itemObtained: null,
      samplesData: [
        { index: 0, color: "#FFB802", marker: "#1", data: 143 },
        { index: 1, color: "#029BFA", marker: "#2", data: 150 },
        { index: 2, color: "#8618C8", marker: "#3", data: 498 },
        { index: 3, color: "#DB493D", marker: "#4", data: 425 },
        { index: 4, color: "#1A9B5D", marker: "#5", data: 302 }
      ],
      samplesDropped: [],
      submitDisabled: false,
      videoAutoplay: true
    },
    initial: "howToSample",
    states: {
      howToSample: {
        on: {
          VIDEODONE: {
            actions: ["enableSubmit"]
          },
          READY: {
            target: "testingSamples",
            actions: ["disableSubmit", "allowDrag"]
          }
        }
      },
      playVideo: {
        on: {
          "": [
            {
              target: "howToSample",
              actions: []
            }
          ]
        }
      },
      testingSamples: {
        on: {
          DROP: [
            {
              target: "testingSamples",
              actions: ["addSample"]
            }
          ],
          TEST: [
            {
              target: "testResults",
              actions: ["disableSubmit"]
            }
          ]
        }
      },
      testResults: {
        on: {
          ALLOWTRANSFER: {
            target: "testResultsCanTransfer",
            actions: ["enableSubmit"]
          }
        }
      },
      testResultsCanTransfer: {
        on: {
          TRANSFER: [
            {
              target: "done"
            }
          ]
        }
      },
      done: {
        entry: "finishScene",
        type: "final"
      }
    }
  },
  {
    actions: {
      enableSubmit: (context, event) => {
        console.log("Transfer is now allowed");
        context.submitDisabled = false;
        context.videoAutoplay = false;
      },
      disableSubmit: (context, event) => {
        context.submitDisabled = true;
      },
      addSample: (context, event) => {
        const clickableId = event.id;
        const idIndex = clickableId.replace("testtube_", "") - 1;
        context.samplesDropped[idIndex] = context.samplesData[idIndex];
        if (Object.keys(context.samplesDropped).length > 4) {
          context.submitDisabled = false;
        }
      },
      finishScene: (context, event) => {
        context.done = true;
      },
      allowDrag: (context, event) => {
        const clickables = context.environment.clickables;
        clickables.testtube_1.draggable = true;
        clickables.testtube_2.draggable = true;
        clickables.testtube_3.draggable = true;
        clickables.testtube_4.draggable = true;
        clickables.testtube_5.draggable = true;
      }
    }
  }
);

const Review = props => {
  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "testingSamples":
        return (
          <TestingPage
            title="Water Testing"
            instructions="Drag'n'Drop sampling tubes onto the qPCR device to start testing."
            buttonText="Test Samples"
            buttonAction="TEST"
            samples={current.context.samplesDropped}
            buttonDisabled={current.context.submitDisabled}
            mSend={mSend}
          />
        );
      case "testResultsCanTransfer":
        return (
          <TestingPage
            title="Water Testing"
            instructions="Here are the results.  CFU stands for Colony Forming Units."
            buttonText="Transfer to the map"
            buttonAction="TRANSFER"
            showData={true}
            samples={current.context.samplesDropped}
            buttonDisabled={current.context.submitDisabled}
            pageName={current.value}
            mSend={mSend}
          />
        );
      case "testResults":
        return (
          <TestingPage
            title="Water Testing"
            instructions="Here are the results.  CFU stands for Colony Forming Units."
            buttonText="Transfer to the map"
            buttonAction="TRANSFER"
            showData={true}
            delayResults={300}
            samples={current.context.samplesDropped}
            buttonDisabled={current.context.submitDisabled}
            pageName={current.value}
            mSend={mSend}
          />
        );
      case "howToSample":
      default:
        return (
          <LessonPage
            title="Water Testing"
            introduction="How we prepare the water samples in the lab for testing"
            videoStillAlt="Lab technician, testing samples"
            videoStill={imgVideoStill}
            videoCaption='Since you are in the field, drag each water sample to the qPCR device.
        Once you are done, hit "TEST SAMPLES" and the device will read the samples and deliver the data.'
            buttonText="I'm ready"
            buttonAction="READY"
            buttonDisabled={current.context.submitDisabled}
            mSend={mSend}
            videoId="246982160"
            videoHost="Vimeo"
            autoplay={current.context.videoAutoplay}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
