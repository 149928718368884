import callImg from "img/appCall/calling_emery_nolasco.jpg";
import callWithScientist from "img/appCall/call_with_emery_nolasco.jpg";
import imgVideoStill from "img/appCall/call_with_emery_nolasco.jpg";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Emerinciana “Emery” Nolasco",
  scientistTitle: "MBARI AUV Operations Engineer",
  videoStill: imgVideoStill
};
export default appData;
