import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakShack from "img/ks_shack_background.jpg";
import imgKayakShackSmall from "img/ks_shack_background_sm.jpg";
import imgKayakShackTablet from "img/ks_shack_background_tablet.jpg";
import imgKayakShackTabletSmall from "img/ks_shack_background_tablet_sm.jpg";
import imgClipboardHighlight from "img/ks_shack_clipboard_highlight.svg";
import imgFloorTabletHighlight from "img/ks_shack_floor_tablet_highlight.svg";
import imgLockedKayak from "img/ks_shack_locked_kayak.svg";
import imgKayakHighlight from "img/ks_shack_kayak_highlight.svg";
import imgSampleMap from "img/elk_samples_bg.jpg";
import imgSampleMapSmall from "img/elk_samples_bg_sm.jpg";
import imgSampleOverlay from "img/elk_sample_map_overlay.svg";
import tabletBG from "img/tablet_background.jpg";

import { CallWSubTitles, ManualInitiateCall } from "components/TabletApps";
import appData from "components/appCall/CallingCharlesNye";

export const envMachine = Machine(
  {
    id: "kayakShack",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: true,
        background: {
          optimize: true,
          src: imgKayakShackTablet,
          thumbnail: imgKayakShackTabletSmall,
          alt: "Elkorn Slough Kayak Shack",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          mapOverlay: {
            visible: false,
            clickable: false,
            className: "sample_overlay",
            layers: [
              {
                name: "overlay",
                src: imgSampleOverlay
              }
            ]
          },
          floorTablet: {
            visible: false,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "pickUpTablet",
            className: "floor_tablet",
            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnFloorTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  bottom: "10%"
                },
                aspectRatio: "179x211"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgFloorTabletHighlight,
                style: {
                  position: "absolute",
                  left: "45.5%",
                  width: "12%",
                  bottom: "8%"
                },
                aspectRatio: "179x211"
              }
            ]
          },
          clipboard: {
            visible: true,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "TOUCH",
            className: "clipboard",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgClipboardHighlight,
                style: {
                  position: "absolute",
                  left: "46%",
                  width: "18%",
                  top: "73.0%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          kayak: {
            visible: true,
            clickable: true,
            ringing: false,
            locked: true,
            showHighlight: true,
            showHover: true,
            action: "TAKE",
            className: "locked_kayak",

            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                handlesClick: false,
                handlesHighlight: false,
                handlesLock: true,
                optimize: false,
                src: imgLockedKayak,
                style: {
                  width: "100%"
                }
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesLock: false,
                handlesRing: true,
                optimize: false,
                handlesHover: true,
                src: imgKayakHighlight,
                style: {
                  position: "absolute",
                  width: "100%",
                  left: "0",
                  top: "0"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistData: appData,
      scientistSubTitles: [
        "“FIRSTNAME, we need your help! We detected an increase in enterococci bacteria in the Bay. A little girl even came down with a sickness from bacteria. She is in the hospital, but she will be alright...”",
        "“Take one of the kayaks out and collect water samples so that we can help figure out the source of the bacterial contamination.”"
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "shackScene",
    states: {
      shackScene: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: ["changeBackground"]
          },
          TAKE: {
            target: "done"
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "shackScene",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      changeBackground: (context, event) => {
        // console.log("HERE!");
        context.environment.background.src = imgSampleMap;
        context.environment.background.thumbnail = imgSampleMapSmall;
        context.environment.clickables.mapOverlay.visible = true;
        context.callConnected = true;
        context.environment.clickables.kayak.visible = false;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakShackTablet;
        context.environment.background.thumbnail = imgKayakShackTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.floorTablet.showHighlight = false;
        context.environment.clickables.floorTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },
      finishCall: (context, event) => {
        context.environment.clickables.mapOverlay.visible = false;
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.kayak.visible = true;
        context.environment.clickables.kayak.locked = false;
        context.environment.clickables.kayak.showHighlight = true;
        context.environment.clickables.kayak.isClickable = true;
        context.environment.background.src = imgKayakShack;
        context.environment.background.thumbnail = imgKayakShackSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const KayakUnlocked = props => {
  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "pickUpTablet":
      case "waitForPage":
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={current.context.callImg}
          />
        );
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.scientistData.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "ringScientist":
      default:
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={current.context.scientistData.callImg}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakUnlocked;
