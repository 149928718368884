import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakStation from "img/ks_background.jpg";
import imgKayakStationSmall from "img/ks_background_sm.jpg";
import imgKayakStationTablet from "img/ks_background_tablet.jpg";
import imgKayakStationTabletSmall from "img/ks_background_tablet_sm.jpg";
import imgTableWTablet from "img/table_w_tablet.png";
import imgTableTabletHighlight from "img/table_tablet_highlight.svg";
import imgKayakShackDoorHighlight from "img/ks_door_highlight.svg";
import imgCrate from "img/ks_crate.png";
import tabletBG from "img/tablet_background.jpg";
import imgQPCR from "img/ks_qPCR_obtained.png";
import appData from "components/appCall/CallingCharlesNye";
import { CallWSubTitles, ManualInitiateCall } from "components/TabletApps";

function subTwoPercentages(one, two) {
  return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
}
export const envMachine = Machine(
  {
    id: "kayakReview",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: true,
        background: {
          optimize: true,
          src: imgKayakStationTablet,
          thumbnail: imgKayakStationTabletSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            clickable: false,
            showHighlight: false,
            ringing: true,
            action: "pickUpTablet",
            className: "table_tablet",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              }
            ]
          },
          shackDoor: {
            visible: false,
            clickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          title: "Handheld qPCR Device",
          src: imgQPCR,
          description:
            "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistData: appData,
      scientistSubTitles: [
        "“It seems like there is nothing more to explore here. You’ve been to every corner and now you're Estuary expert... Almost.”"
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "pickUpTablet",
    states: {
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "done",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
          // console.log("HERE! shiftedStyle = ", clickable.shiftedStyle);
          // console.log("HERE! style = ", clickable.style);
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.shackDoor.showHighlight = true;
        context.environment.clickables.shackDoor.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakStationTablet;
        context.environment.background.thumbnail = imgKayakStationTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.crateWSensor.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.crateWSensor.showHighlight = true;
        context.environment.background.src = imgKayakStation;
        context.environment.background.thumbnail = imgKayakStationSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const Review = props => {
  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.scientistData.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "pickUpTablet":
      case "waitForPage":
      case "ringScientist":
      default:
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={props.current.context.scientistData.callImg}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
