import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { useMachine } from "@xstate/react";
import { NewLargeTablet } from "components/UIComponents";
import { notesMachine as envMachine } from "components/NotesMachine";
import { TabletContent } from "components/NotesApp";
import { Modal } from "antd";
import imgBackground from "img/nb_tidepool_bg.jpg";
import imgBackgroundSmall from "img/nb_tidepool_bg_sm.jpg";

const contextEnv = {
  environment: {
    className: "env_elkhorn",
    shiftBackground: false,
    background: {
      optimize: true,
      src: imgBackground,
      thumbnail: imgBackgroundSmall,
      alt: "Natural Bridges",
      color: "#132962",
      aspectRatio: "1920x1080"
    },
    clickables: {},
    obtainable: {}
  }
};

// //https://stackoverflow.com/questions/25275696/javascript-format-date-time/25276435
// function formatDate(date) {
//   if (date === null || date === undefined) return null;
//   var hours = date.getHours();
//   var minutes = date.getMinutes();
//   var ampm = hours >= 12 ? "pm" : "am";
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? "0" + minutes : minutes;
//   var strTime = hours + ":" + minutes + " " + ampm;
//   return (
//     date.getMonth() +
//     1 +
//     "/" +
//     date.getDate() +
//     "/" +
//     date.getFullYear() +
//     "  " +
//     strTime
//   );
// }

const Notes = props => {
  // console.log("CI, userID:", props.userContext);
  const [current, mSend] = useMachine(envMachine, {
    context: {
      ...envMachine.context,
      ...contextEnv
    }
  });
  const { context } = current;

  console.log("Notes, current page:", current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Environment {...props} context={context} mSend={mSend}>
      <NewLargeTablet showTablet={context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          // answers={answers}
          // setAnswers={setAnswers}
        />
      </NewLargeTablet>
      <Modal
        title={context.errorMessage}
        visible={context.showModal}
        onOk={() => mSend(context.okCommand)}
        onCancel={() => mSend("CANCEL")}
      >
        <p>{context.errorInstructions}</p>
      </Modal>
    </Environment>
  );
};

export default Notes;
