import React, { useContext } from "react";
import { Form, Input, Icon, Button } from "antd";
import axios from "axios";
import { OImg } from "components/UIComponents";
import imgBG from "img/mbari_blur.jpg";
import imgBGSmall from "img/bgLogin_sm.jpg";
import UserContext from "context/User";

const Login = props => {
  const { destination } = props;
  const objective = props.objective || "Welcome!";
  const [context] = useContext(UserContext);
  var objForm = {
    un: "",
    pw: "",
    level: 1,
    destination: null,
    loggedIn: false
  };

  console.log("props are:", props);
  console.log("context.api is:", context.api);
  const fnLogin = e => {
    e.preventDefault();
    if (objForm.un.length > 0 && objForm.pw.length > 0) {
      axios
        .post(context.api + "/api/login", objForm)
        .then(res => {
          console.log("login: ", res);
          var arrBadges = ["welcome"];
          if (res.data.user.json) {
            var objJson = JSON.parse(res.data.user.json);
          }
          if (objJson && objJson.badges && objJson.badges.length > 1) {
            arrBadges = objJson.badges;
          }
          var objUser = {
            username: res.data.user.first + " " + res.data.user.last,
            objective: objective,
            user: res.data.user,
            badges: arrBadges,
            jwt: res.data.token,
            role: res.data.user.role
          };
          objUser.user.level = objForm.level;
          console.log("objUser:", objUser);
          console.log("badges: ", objUser.badges);
          //objUser.quota=objUser.fnGetQuota(res.data.token);
          context.fnUpdate(objUser);
          localStorage.setItem("user", JSON.stringify(objUser));
          console.log("Intended destination:", destination);
          props.history.push(objForm.destination || destination);
        })
        .catch(err => {
          console.log(err);
          console.log("Check your self-signed certificate");
          //https://gist.github.com/cecilemuller/9492b848eb8fe46d462abeb26656c4f8
          //https://flaviocopes.com/express-https-self-signed-certificate/
        });
    }
  };
  // const fnForgot = function() {
  //   if (!objForm.un) {
  //     message.error("Please supply your email to reset your password");
  //   } else {
  //     axios
  //       .post(context.api + "/api/forgot", { email: objForm.un })
  //       .then(res => {
  //         message.info(
  //           "An email was sent with a password reset link to follow."
  //         );
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   }
  // };

  const fnChangeLevel = e => {
    objForm.level = parseInt(e.target.value, 10);
    switch (objForm.level) {
      case 1:
        objForm.destination = "/map";
        break;
      case 2:
        objForm.destination = "/elkhorn/map";
        break;
      case 3:
        objForm.destination = "/tidepools/map";
        break;
      default:
        objForm.destination = destination;
        break;
    }
    console.log("level is:", objForm.level);
    console.log("destination:", objForm.destination);
  };

  console.log("destination:", objForm.destination);

  return (
    <>
      <div className="admin_page">
        <OImg
          src={imgBG}
          thumbnail={imgBGSmall}
          aspectRatio="1920x1080"
          alt="Login Page"
          color={"#132962"}
        />
        <div className="admin_form">
          <Form className="login-form">
            <Form.Item>
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="email"
                onChange={e => (objForm.un = e.target.value)}
                maxLength={255}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                visibilityToggle={true}
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="password"
                onChange={e => (objForm.pw = e.target.value)}
                name="pw"
              />
            </Form.Item>
            <Form.Item>
              <Input
                name="level"
                prefix={
                  <Icon type="number" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="level"
                onChange={fnChangeLevel}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="fullWidth b"
                span={6}
                onClick={fnLogin}
              >
                Log in
              </Button>
            </Form.Item>
            <a style={{ float: "right" }} href="/register">
              Register
            </a>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
