import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import imgSlough0 from "img/kayak_collect/bg_kc_0.jpg";
import imgSlough0Small from "img/kayak_collect/bg_kc_0_sm.jpg";
import imgSlough1 from "img/kayak_collect/bg_kc_1.jpg";
import imgSlough1Small from "img/kayak_collect/bg_kc_1_sm.jpg";
import imgSlough8 from "img/kayak_collect/bg_kc_8.jpg";
import imgSlough8Small from "img/kayak_collect/bg_kc_8_sm.jpg";
import imgKayak from "img/kayak_collect/kayak_first_person.png";
import iconForward from "img/kayak_collect/nav_arrow_forward.svg";
import tabletBG from "img/tablet_background.jpg";
import iconKayak from "img/kayak_collect/icon_kayak.svg";
import UserContext from "context/User";
import { IconInstruction } from "components/TabletApps";
import imgHUD from "img/kayak_collect/hud_background.svg";
import imgHUDNorth from "img/kayak_collect/hud_north.svg";
import imgInfoMarker from "img/kayak_collect/info_marker.svg";
import imgTestTubeMarker from "img/kayak_collect/hud_testtube_marker.svg";
import imgPlantMarker from "img/kayak_collect/hud_plant_marker.svg";
import imgAnimalMarker from "img/kayak_collect/hud_animal_marker.svg";
import imgTestTube1Icon from "img/ks_testtube1.svg";
import imgTestTube2Icon from "img/ks_testtube2.svg";
import imgTestTube3Icon from "img/ks_testtube3.svg";
import imgTestTube4Icon from "img/ks_testtube4.svg";
import imgTestTube5Icon from "img/ks_testtube5.svg";
import imgAnimalMarkerIcon from "img/kayak_collect/marker_animal_icon.svg";
import imgPlantMarkerIcon from "img/kayak_collect/marker_plant_icon.svg";
import imgBobCat from "img/kayak_collect/info_samples/bobcat.jpg";
import imgEnsatina from "img/kayak_collect/info_samples/ensatina.jpg";
import imgSouthernSeaOtter from "img/kayak_collect/info_samples/southern_sea_otter.jpg";
import imgHarborSeals from "img/kayak_collect/info_samples/harbor_seals.jpg";
import imgPickleweed from "img/kayak_collect/info_samples/pickleweed.jpg";
import imgPoisonOak from "img/kayak_collect/info_samples/poison_oak.jpg";
import imgSampleCounter from "img/kayak_collect/sample_counter.svg";

import appData from "components/appCall/CallingCharlesNye";
import { ImageCard, InfoPage } from "components/TabletApps";

const objSampleMessage = {
  1: "First water sample obtained.  Great start!",
  2: "That is your second sample. Keep going!",
  3: "Third sample!  Can you collect two more?",
  4: "That is your fourth sample.  One more!",
  5: "Fifth sample. Great job!  These will really help."
};

const startMapPosition = [541, 962];
const plantIcon = {
  src: imgPlantMarkerIcon,
  style: {
    position: "relative",
    width: "60%",
    left: "20%",
    bottom: "4.7vw"
  }
};
const animalIcon = {
  src: imgAnimalMarkerIcon,
  style: {
    position: "relative",
    width: "60%",
    left: "20%",
    bottom: "4.6vw"
  }
};
const objSamples = {
  water_1: {
    id: 1,
    type: "water",
    color: "yellow",
    position: ["80vw", "15vw"],
    width: "5vw",
    mapPosition: [397, 861],
    icon: {
      src: imgTestTube1Icon,
      style: {
        width: "100%",
        bottom: "7vw",
        position: "relative",
        transform: "translate(.7vw,-.5vw) rotate(10deg) scale(1.25)"
      }
    }
  },
  water_2: {
    id: 2,
    type: "water",
    color: "blue",
    position: ["70vw", "10vw"],
    width: "6vw",
    mapPosition: [374, 758],
    icon: {
      src: imgTestTube2Icon,
      style: {
        width: "100%",
        bottom: "7vw",
        position: "relative",
        transform: "translate(-.25vw,-1vw) rotate(0deg) scale(1.25)"
      }
    }
  },
  water_3: {
    id: 3,
    type: "water",
    color: "purple",
    position: ["26vw", "10vw"],
    width: "6vw",
    mapPosition: [414, 592],
    icon: {
      src: imgTestTube3Icon,
      style: {
        width: "100%",
        bottom: "7vw",
        position: "relative",
        transform: "translate(.4vw,-2vw) rotate(0deg) scale(1.25)"
      }
    }
  },
  water_4: {
    id: 4,
    type: "water",
    color: "red",
    position: ["20vw", "15vw"],
    width: "5vw",
    mapPosition: [486, 443],
    icon: {
      src: imgTestTube4Icon,
      style: {
        width: "100%",
        bottom: "7vw",
        position: "relative",
        transform: "translate(.2vw,0vw) rotate(10deg) scale(1.25)"
      }
    }
  },
  water_5: {
    id: 5,
    type: "water",
    color: "green",
    position: ["40vw", "20vw"],
    width: "5vw",
    mapPosition: [512, 305],
    icon: {
      src: imgTestTube5Icon,
      style: {
        width: "100%",
        bottom: "7vw",
        position: "relative",
        transform: "translate(-.5vw,-.2vw) rotate(-10deg) scale(1.25)"
      }
    }
  },
  info_1: {
    id: 6,
    type: "info",
    kingdom: "plant",
    position: ["76vw", "27vw"],
    width: "4vw",
    mapPosition: [469, 891],
    icon: plantIcon,
    title: "Pickleweed",
    image: imgPickleweed,
    description:
      "Pickleweed is a very common succulent, and a perennial plant that is usually a gray-green but turns bright red in the fall. It gets its name from the pickle-like appearance of it's stem and its salty taste. It is food to both birds and mammals in estuaries."
  },
  info_2: {
    id: 7,
    type: "info",
    kingdom: "animal",
    width: "4vw",
    position: ["15vw", "26vw"],
    mapPosition: [385.5, 809.5],
    icon: animalIcon,
    title: "Southern Sea Otter",
    image: imgSouthernSeaOtter,
    description:
      "With over 100 and counting, Elkhorn Slough has the highest concentration of southern sea otters on the California coast. Otters eat crabs, oysters, clams, abalone, sea urchin and other small marine species.  They often use ‘tools’ like rocks to crack open hard-shelled prey. They eat 25% of their weight in food each day. Mother otters give birth to a pup almost every year. Their pups have such long hair that it makes them buoyant, and unable to dive underwater. "
  },
  info_3: {
    id: 8,
    type: "info",
    kingdom: "animal",
    width: "4vw",
    position: ["22vw", "28vw"],
    mapPosition: [394, 675],
    icon: animalIcon,
    title: "Bobcat",
    image: imgBobCat,
    description:
      "These wild cats are twice the size of a house cat and weigh 20-30 lbs. Bobcats are one of the most common predators found in the slough. Bobcats usually eat small mammals such as skunks, racoons, rabbits, and moles, as well as small birds and reptiles.  Bobcats have a powerful pounce of about 3 feet and they are nocturnal hunters."
  },
  info_4: {
    id: 9,
    type: "info",
    kingdom: "plant",
    position: ["60vw", "28vw"],
    width: "4vw",
    mapPosition: [394, 675],
    icon: plantIcon,
    title: "Poison Oak",
    image: imgPoisonOak,
    description:
      "Poison oak is widely found in moist, wooded areas and along freshwater waterways. Deer, birds, and other wildlife commonly eat the leaves and berries as poison oak has high concentrations of phosphorous, sulfur, and calcium. Poison oak contains a potent allergen to humans, called urushiol. This skin-irritating oil produces minor irritation to severe inflammation in more than half of the people who come in contact with it. Remember the rhyme, “leaves of three, let it be.”"
  },
  info_5: {
    id: 10,
    type: "info",
    kingdom: "animal",
    width: "4vw",
    position: ["60vw", "22vw"],
    mapPosition: [450, 517.5],
    icon: animalIcon,
    image: imgHarborSeals,
    title: "Harbor Seals",
    description:
      "Harbor seals have a thick layer of fat beneath their skin, which keeps them warm in California’s cold water temperatures. They spend their entire lives along the same stretch of coastline, and are most commonly seen on a sandbar or rocky island soaking up some warm sunshine. They eat fish, crabs, and shellfish during high tide. "
  },
  info_6: {
    id: 11,
    type: "info",
    kingdom: "animal",
    width: "4vw",
    position: ["30vw", "20vw"],
    icon: animalIcon,
    image: imgEnsatina,
    mapPosition: [499, 374],
    title: "Ensatina",
    description:
      "A very interesting animal that is very common in this area. An ensatina is a lungless salamander that breathes through its skin and tissues lining its mouth. They secrete milky toxin from poison glands at the base of its tail, which can affect humans. "
  }
};

const objScenes = [
  {
    id: "start",
    heading: -45,
    background: { large: imgSlough1, small: imgSlough1Small },
    mapPosition: [541, 962]
  },
  {
    id: "1",
    heading: -90,
    background: { large: imgSlough0, small: imgSlough0Small },
    sampleIDs: ["info_1"],
    mapPosition: [469, 891]
  },
  {
    id: "2",
    heading: -2,
    background: { large: imgSlough8, small: imgSlough8Small },
    sampleIDs: ["water_1"],
    mapPosition: [397, 861]
  },
  {
    id: "3",
    heading: -1,
    background: { large: imgSlough1, small: imgSlough1Small },
    sampleIDs: ["info_2"],
    mapPosition: [385.5, 809.5]
  },
  {
    id: "4",
    heading: 2,
    background: { large: imgSlough0, small: imgSlough0Small },
    sampleIDs: ["water_2"],
    mapPosition: [374, 758]
  },
  {
    id: "5",
    heading: 1,
    background: { large: imgSlough8, small: imgSlough8Small },
    sampleIDs: ["info_3", "info_4"],
    mapPosition: [394, 675]
  },
  {
    id: "6",
    heading: -2,
    background: { large: imgSlough1, small: imgSlough1Small },
    sampleIDs: ["water_3"],
    mapPosition: [414, 592]
  },
  {
    id: "7",
    heading: 80,
    background: { large: imgSlough8, small: imgSlough8Small },
    sampleIDs: ["info_5"],
    mapPosition: [450, 517.5]
  },
  {
    id: "8",
    heading: 2,
    background: { large: imgSlough0, small: imgSlough0Small },
    sampleIDs: ["water_4"],
    mapPosition: [486, 442]
  },
  {
    id: "9",
    heading: 2,
    background: { large: imgSlough1, small: imgSlough1Small },
    sampleIDs: ["info_6"],
    mapPosition: [499, 374]
  },
  {
    id: "end",
    heading: 1,
    background: { large: imgSlough8, small: imgSlough8Small },
    sampleIDs: ["water_5"],
    mapPosition: [512, 305]
  }
];

const findHeading = (myHeading, cur, poi) => {
  // console.log(`cur=${cur}, poi=${poi}`);
  const r = Math.sqrt(
    Math.pow(poi[1] - cur[1], 2) + Math.pow(poi[0] - cur[0], 2)
  );
  const heading =
    (r === 0 ? 0 : (Math.asin((poi[1] - cur[1]) / r) * 180) / Math.PI) +
    90 +
    myHeading;
  return heading;
};
const hudPosition = heading => {
  // console.log("heading:", heading);
  const percentage = 49.5 - (heading / 180) * 39.5;
  return percentage + "%";
};
export const envMachine = Machine(
  {
    id: "kayakCollect",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: true,

        background: {
          optimize: true,
          src: imgSlough1,
          thumbnail: imgSlough1Small,
          alt: "Elkorn Slough",
          color: "#132962",
          aspectRatio: "1920x1080",
          style: { width: "100%" },
          zoomable: true
        },

        clickables: {
          kayak: {
            visible: true,
            isClickable: false,
            ringing: false,
            locked: false,
            showHighlight: false,
            action: "TAKE",
            className: "kc_kayak",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgKayak,
                style: {
                  width: "100%"
                }
              }
            ]
          },
          forwardNav: {
            visible: false,
            isClickable: true,
            ringing: false,
            locked: false,
            showHighlight: false,
            showHover: true,
            action: "PRESS",
            layers: [
              {
                name: "overlay",
                handlesHover: true,
                handlesClick: true,
                style: {
                  position: "absolute",
                  top: "50%",
                  left: "46%",
                  width: "11%"
                },
                optimize: false,
                src: iconForward
              }
            ]
          },
          hud: {
            visible: false,
            isClickable: false,
            ringing: false,
            locked: false,
            showHighlight: true,
            action: "PRESS",
            layers: [
              {
                name: "overlay",
                visible: true,
                style: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%"
                },
                optimize: false,
                src: imgHUD
              },
              {
                name: "overlay",
                visible: true,
                style: {
                  position: "absolute",
                  top: "3vw",
                  left: "49.5%",
                  width: "1vw"
                },
                optimize: false,
                src: imgHUDNorth
              }
            ]
          },
          sampleCounter: {
            visible: false,
            isClickable: false,
            ringing: false,
            locked: false,
            showHighlight: false,
            layers: [
              {
                name: "background",
                visible: true,
                style: {
                  position: "absolute",
                  right: "2vw",
                  top: "14vw",
                  width: "4vw"
                },
                optimize: false,
                src: imgSampleCounter
              },
              {
                name: "overlay",
                type: "counter",
                style: {
                  position: "absolute",
                  fontWeight: "bold",
                  right: "1.25vw",
                  top: "19vw",
                  width: "4vw"
                },
                handlesOverlay: true,
                data: [0, 5]
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      tabletIcon: iconKayak,
      tabletTitle: "Get in the boat!",
      tabletMessage: [
        <React.Fragment key={0}>
          Great job on land! Now, it's time to get your hands dirty. I mean,
          wet! On your way up the slough collecting water samples, you will also
          get to learn about some organisms that live here. <br />
          <br />
          <b>Thanks for grabbing the samples for us!</b>
        </React.Fragment>
      ],
      showTablet: true,
      itemObtained: null,
      showBadge: true,
      sceneCount: 0,
      currentMapPosition: startMapPosition,
      intScenesLeftWSamples: 10
    },
    initial: "kayakStart",
    states: {
      startKayaking: {
        on: {
          "": {
            target: "navigating",
            actions: [
              "initialize",
              "recalculateMarkers",
              "setScene1",
              "showForwardNav"
            ]
          }
        }
      },
      navigating: {
        on: {
          PRESS: [
            {
              cond: (context, event) => {
                return context.sceneCount < 10;
              },
              actions: [
                "deleteMarkers",
                "nextScene",
                "changeScene",
                "recalculateMarkers",
                "createMarkers",
                "showForwardNav"
                // "hideHUDMarkers"
              ]
            },
            {
              target: "done"
            }
          ],
          READ: {
            target: "showInfoTablet",
            actions: ["setCurrentSample"]
          },
          SAMPLE: {
            target: "showSampleTablet",
            actions: ["setCurrentSample"]
          }
        }
      },
      showInfoTablet: {
        entry: ["showTablet"],
        on: {
          NEXT: {
            target: "navigating",
            actions: ["hideMarker", "hideTablet", "showForwardNav"]
          }
        }
      },
      showSampleTablet: {
        entry: ["showTablet"],
        on: {
          CLAIM: {
            target: "navigating",
            actions: [
              "incrementSampleCount",
              "hideMarker",
              "hideTablet",
              "showForwardNav"
            ]
          }
        }
      },
      kayakStart: {
        on: {
          GO: {
            target: "startKayaking"
          }
        }
      },
      scene1: {
        on: {
          PRESS: {
            target: "scene2",
            actions: ["setScene2"]
          }
        }
      },
      scene2: {
        on: {
          PRESS: {
            target: "scene3",
            actions: ["setScene3"]
          }
        }
      },
      scene3: {
        on: {
          PRESS: {
            target: "done"
          }
        }
      },
      done: {
        entry: ["finishScene"],
        type: "final"
      }
    }
  },
  {
    actions: {
      showForwardNav: (context, event) => {
        const scene = objScenes[context.sceneCount];
        // console.log("scene:", scene);
        // console.log("scene: clickables:", context.environment.clickables);

        context.environment.clickables.forwardNav.visible = true;
        for (let i = 0; scene.sampleIDs && i < scene.sampleIDs.length; i++) {
          if (context.environment.clickables["sample_" + i].visible === true) {
            console.log(`sample_${i} is visible`);
            context.environment.clickables.forwardNav.visible = false;
            return;
          }
        }
      },
      incrementSampleCount: (context, event) => {
        context.environment.clickables.sampleCounter.layers[1].data[0] =
          context.environment.clickables.sampleCounter.layers[1].data[0] + 1;
        context.environment.clickables.sampleCounter.visible = true;
      },
      setCurrentSample: (context, event) => {
        // context.showNavigation = false;
        console.log("event=", event);
        console.log("sampleID=", event.sampleID);
        console.log("objSample=", objSamples[event.sampleID]);
        context.currentClickableName = event.clickableName;
        context.currentSample = objSamples[event.sampleID];
        if (event.type === "SAMPLE") {
          console.log(String(objSamples[event.sampleID].id));
          context.collectedSample = {
            image: objSamples[event.sampleID].icon.src,
            message: objSampleMessage[objSamples[event.sampleID].id]
          };
        }
      },
      showTablet: (context, event) => {
        // context.showNavigation = false;
        context.shiftBackground = true;
        context.showBadge = false;
        context.showTablet = true;
        // context.showNavigation = true;
        context.environment.clickables.hud.visible = false;
        context.environment.clickables.forwardNav.visible = false;
        context.environment.clickables.kayak.className = "kc_kayak";
      },
      hideTablet: (context, event) => {
        // context.showNavigation = false;
        context.shiftBackground = false;
        context.showTablet = false;
        context.environment.clickables.hud.visible = true;
        context.environment.clickables.kayak.className = "kc_kayak center";
      },
      initialize: (context, event) => {
        context.kayakHeading = objScenes[0].heading;
        let hudIndex = 2;
        objScenes.forEach((scene, sIndex) => {
          if (scene.sampleIDs)
            scene.sampleIDs.forEach((sampleID, sampleIndex) => {
              var objSample = objSamples[sampleID];
              var hudMarker = imgTestTubeMarker;
              var width = "1.5vw";
              let boolVisible = true;
              if (objSample.type === "info") {
                if (objSample.kingdom === "plant") {
                  hudMarker = imgPlantMarker;
                  width = "1.2vw";
                } else {
                  hudMarker = imgAnimalMarker;
                  width = "1.3vw";
                }
                boolVisible = true;
              }
              context.environment.clickables.hud.layers[hudIndex] = {
                name: "overlay",
                realName: `${objSample.type}:${objSample.kingdom}:${hudIndex}`,
                style: {
                  position: "absolute",
                  top: "3.6vw",
                  left: hudPosition(
                    findHeading(
                      context.kayakHeading,
                      context.currentMapPosition,
                      objSample.mapPosition
                    )
                  ),
                  opacity: `${(context.intScenesLeftWSamples - (sIndex - 1)) /
                    context.intScenesLeftWSamples}`,
                  width: width
                },
                optimize: false,
                src: hudMarker,
                visible: boolVisible
              };
              if (scene.arrHudIndex === undefined) scene.arrHudIndex = [];
              scene.arrHudIndex.push(hudIndex);
              hudIndex += 1;
            });
        });
        console.log("objScenes =", objScenes);
      },
      recalculateMarkers: (context, event) => {
        console.log("sceneCount:", context.sceneCount);
        const scene = objScenes[context.sceneCount];

        context.kayakHeading = scene.heading;
        context.currentMapPosition = scene.mapPosition;
        console.log("context.sceneCount-1:", context.sceneCount - 1);

        // console.log("objSamples.length:", objSamples.length);
        Object.keys(objSamples).forEach((key, index) => {
          let i = objSamples[key].id - 1;
          let lIndex = 2 + i;
          let hP = hudPosition(
            findHeading(
              context.kayakHeading,
              context.currentMapPosition,
              objSamples[key].mapPosition
            )
          );
          let opacity =
            (context.intScenesLeftWSamples - (context.sceneCount - lIndex)) /
            context.intScenesLeftWSamples;

          context.environment.clickables.hud.layers[lIndex].style = {
            ...context.environment.clickables.hud.layers[lIndex].style,
            left: hP,
            opacity: opacity
          };

          console.log(
            "opacity:",
            context.sceneCount,
            context.intScenesLeftWSamples,
            lIndex,
            lIndex - 2 - context.sceneCount,
            context.intScenesLeftWSamples,
            opacity
          );
          console.log(
            "visible:",
            i,
            context.environment.clickables.hud.layers[i].visible,
            context.environment.clickables.hud.layers[i].realName,
            context.intScenesLeftWSamples,
            context.sceneCount,
            lIndex - 2
          );
        });

        context.environment.clickables.hud.layers[1].style.left = hudPosition(
          scene.heading
        );

        if (scene.arrHudIndex) {
          context.intScenesLeftWSamples -= 1;
        }
      },
      // Should this hide them instead?!
      deleteMarkers: (context, event) => {
        const scene = objScenes[context.sceneCount];
        console.log("scene.sampleIDs:", scene.sampleIDs);

        if (scene.sampleIDs && scene.sampleIDs.length > 0) {
          for (let i = 0; i < scene.sampleIDs.length; i++) {
            delete context.environment.clickables["sample_" + i];
            console.log(" DELETED:", "sample_" + i);
          }
        }

        console.log("clickables=", context.environment.clickables);
      },
      createMarkers: (context, event) => {
        const scene = objScenes[context.sceneCount];
        console.log(`sceneCount: ${context.sceneCount}, scene: `, scene);

        for (let i = 0; scene.sampleIDs && i < scene.sampleIDs.length; i++) {
          const sampleID = scene.sampleIDs[i];
          const markerLayer = {
            name: "overlay",
            handlesHover: true,
            handlesClick: true,

            optimize: false,
            src: imgInfoMarker,
            style: {
              width: "100%"
            }
          };
          const iconOverlay =
            objSamples[sampleID].icon === undefined
              ? null
              : {
                  name: "icon_overlay",
                  visible: true,
                  src: objSamples[sampleID].icon.src,
                  style: objSamples[sampleID].icon.style
                };
          const layers = [markerLayer];
          if (iconOverlay) {
            layers.push(iconOverlay);
          }
          context.environment.clickables["sample_" + i] = {
            className: "kayak_marker",
            sampleID: sampleID,
            hudIndex: scene.arrHudIndex[i],
            visible: true,
            isClickable: true,
            showHover: true,
            type: "info",
            action: objSamples[sampleID].type === "info" ? "READ" : "SAMPLE",
            style: {
              position: "absolute",
              bottom: objSamples[sampleID].position[1],
              left: objSamples[sampleID].position[0],
              width: objSamples[sampleID].width
            },
            layers: layers
          };
        }
      },
      hideMarker: (context, event) => {
        const clickable =
          context.environment.clickables[context.currentClickableName];
        const hudIndex = clickable.hudIndex;
        console.log("hideMarker hudIndex=", hudIndex);
        clickable.visible = false;
        context.environment.clickables.hud.layers[hudIndex].visible = false;
      },
      setScene1: (context, event) => {
        context.showBadge = false;
        context.showTablet = false;
        context.showNavigation = true;
        context.environment.clickables.hud.visible = true;
        context.environment.clickables.forwardNav.visible = true;
        context.environment.clickables.kayak.className = "kc_kayak center";
        context.sceneCount = 0;
      },
      changeScene: (context, event) => {
        const scene = objScenes[context.sceneCount];
        context.environment.background.src = scene.background.large;
        context.environment.background.thumbnail = scene.background.small;
      },
      nextScene: (context, event) => {
        context.sceneCount = context.sceneCount + 1;
        console.log("sceneCount:", context.sceneCount);
        // {
        //   id: "start",
        //   heading: -45,
        //   background: { large: imgSlough1, small: imgSlough1Small }
        // },
      },
      setScene2: (context, event) => {
        context.environment.background.style = {
          transition: "all .2s ease-in-out",
          transform: "scale(1.5)"
        };
      },
      setScene3: (context, event) => {
        context.environment.background.style = {
          transition: "all .2s ease-in-out",
          transform: "scale(2)"
        };
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },

      finishScene: (context, event) => {
        context.done = true;
      }
    }
  }
);

const KayakCollect = props => {
  const [objUser] = useContext(UserContext);
  // const ringRef = useRef();
  objUser.level = props.lesson;
  objUser.objective = "Elkhorn Slough & Kayak Shack";

  const [current, mSend] = useMachine(envMachine);

  console.log("KayakCollect, current_page:", current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    const { context } = current;
    // console.log(current.value);
    switch (current.value) {
      case "showInfoTablet":
        return (
          <ImageCard
            {...props} // mSend is already set
            title={context.currentSample.title}
            src={context.currentSample.image}
            // thumbnail={current.context.focusSample.thumbnail}
            alt={context.currentSample.title}
            // image={imgPHTestingBeakers}
            message={context.currentSample.description}
            // instructions="Click on the markers to read about each one."
            buttonText="Continue"
            buttonAction="NEXT"
            buttonDisabled={false}
          />
        );
      case "showSampleTablet":
        return (
          <InfoPage
            {...props} // mSend is already set
            title="Sample Obtained"
            image={context.collectedSample.image}
            alt="Test Tube"
            message={context.collectedSample.message}
            buttonDisabled={false}
            buttonText="Claim"
            buttonAction="CLAIM"
          />
        );
      case "kayakStart":
      default:
        return (
          <IconInstruction
            {...props}
            alt={"Kayak"}
            title={props.current.context.tabletTitle}
            message={props.current.context.tabletMessage}
            icon={props.current.context.tabletIcon}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakCollect;
