import React from "react";
import "./ErrorPage.css";
import errorImage from "../img/404.png";

const ErrorPage = () => {
  return (
    <div className="error_page">
      <img
        alt="The page you are looking for does not exist."
        src={errorImage}
      />
    </div>
  );
};

export default ErrorPage;
