import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import appData from "components/appCall/CallingCharlesNye";
import UserContext from "context/User";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "intro",
  scientistSubTitles: [
    '"Welcome to the MBARI Lab. Here we develop and test new ways to apply technology in exploring the Monterey Bay. In this lab you will learn about some of the basic tools you will use during your..."',
    '"assigned missions. Let’s start by clicking on the chalkboard to learn the terms you’ll need to know as you adventure on.  Have fun!"'
  ],
  scientistData: appData
};

const LabIntro = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default LabIntro;
