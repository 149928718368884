import callImg from "img/appCall/charles_nye_calling.jpg";
import callWithScientist from "img/appCall/call_with_charles_nye.jpg";
import imgOnFloorTablet from "img/appCall/ks_shack_charles_nye_tablet.png";
import dtCharlesNye from "img/appCall/dt_charles_nye.png";
import imgOnPicnicTable from "img/pg_tidepool_tablet_charles_nye.png";
import imgVideoStill from "img/appCall/call_with_charles_nye.jpg";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Charles Nye",
  scientistTitle: "MBARI Research Assistant",
  imgOnFloorTablet: imgOnFloorTablet,
  imgOnPicnicTable: imgOnPicnicTable,
  deskTopImg: dtCharlesNye,
  videoStill: imgVideoStill
};
export default appData;
