import React from "react";
// import { Redirect } from "react-router-dom";
// import { Environment } from "components/Environment";
// import { NewLargeTablet, DisplayObtainedItem } from "components/UIComponents";
import { Machine, assign } from "xstate";
// import { useMachine } from "@xstate/react";
import imgBackground from "img/pg_table_bg.jpg";
import imgBackgroundSmall from "img/pg_table_bg_sm.jpg";
import imgBackgroundTablet from "img/pg_table_bg_shifted.jpg";
import imgBackgroundTabletSmall from "img/pg_table_bg_shifted_sm.jpg";
import imgTabletHighlight from "img/pg_tablet_highlight.svg";
import imgClipboardHighlight from "img/pg_clipboard_highlight.svg";
import imgBackpackLocked from "img/pg_backpack_locked.svg";
import imgBackpackHighlight from "img/pg_backpack_highlight.svg";
import tabletBG from "img/tablet_background.jpg";
import imgPHCharts from "img/pg_ph_charts_obtained.png";

// import UserContext from "context/User";
// import appData from "components/appCall/CallingCharlesNye";
import {
  CallWSubTitles,
  ManualInitiateCall,
  ShowVideo
} from "components/TabletApps";
import NewAppBlastQuiz from "components/NewAppBlastQuiz";

export const envMachine = Machine(
  {
    id: "picnicTable",
    context: {
      environment: {
        className: "env_tidepool",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgBackground,
          thumbnail: imgBackgroundSmall,
          alt: "Pacific Grove Tide Pool",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            isClickable: true,
            showHighlight: true,
            ringing: true,
            action: "ANSWER",
            className: "table_tablet",
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: null, //appData.imgOnPicnicTable,
                style: {
                  position: "absolute",
                  width: "74%",
                  left: "1.6vw",
                  top: "2.2vw"
                }
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgTabletHighlight,
                style: {
                  position: "absolute",
                  width: "100%"
                }
              }
            ]
          },
          backPack: {
            visible: true,
            isClickable: false,
            showHighlight: false,
            ringing: false,
            action: "OPEN",
            className: "backpack",
            locked: true,
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: imgBackpackLocked,
                handlesLock: true,
                style: {
                  position: "absolute",
                  width: "100%"
                }
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgBackpackHighlight,
                style: {
                  position: "absolute",
                  width: "137%",
                  left: "-2.6vw",
                  top: "-2.7vw"
                }
              }
            ]
          },
          clipboard: {
            visible: true,
            isClickable: false,
            ringing: false,
            showHighlight: false,
            action: "TAKE",
            className: "clipboard",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgClipboardHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          phCharts: {
            title: "Blank pH Charts",
            src: imgPHCharts,
            description: "Clipboard with the blank pH chart to fill."
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistSubTitles: [
        "“We are going to explore two tide pools next: Pacific Grove and Natural Bridges. Tide pools are a part of fascinating ecosystems with ever-changing conditions. The adaptations of the organisms...”"
      ],
      scientistSubTitles2: [
        "Aren’t tidepools pretty sweet? Tide pools are a part of fascinating ecosystems with ever-changing conditions."
      ],
      showTablet: false,
      clipboardIsTest: false,
      itemObtained: null,
      videoAfterCall: false
    },
    initial: "waitForMainScene",
    states: {
      shiftedScene: {
        on: {
          "": {
            target: "mainScene",
            actions: ["shiftScene"]
          }
        }
      },
      waitForMainScene: {
        after: {
          300: { target: "mainScene", actions: ["initializeEnvironment"] }
        }
      },
      delayBeforeTaking: {
        after: {
          300: { target: "mainScene" }
        }
      },
      mainScene: {
        on: {
          BLASTQUIZ: {
            target: "blastQuiz",
            actions: ["shiftScene", "pickUpTablet"]
          },
          ANSWER: {
            target: "pickUpTablet",
            actions: ["shiftScene", "pickUpTablet"]
          },
          OPEN: {
            target: "done"
          },
          STORE: {
            target: "done",
            actions: ["storeItem"]
          },
          TAKE: {
            target: "delayBeforeTaking",
            actions: "obtainItem"
          },
          TEST: {
            target: "done"
          }
        }
      },
      testingTable: {
        on: {
          DONE: {
            target: "waitForFinish",
            actions: ["unshiftScene", "prepareForNext"]
          }
        }
      },
      blastQuiz: {
        on: {
          DONE: {
            target: "waitForFinish",
            actions: ["unshiftScene", "prepareForNext"]
          }
        }
      },
      waitForFinish: {
        after: {
          5000: "done"
        }
      },
      waitForPage: {
        after: {
          100: "startCall"
        }
      },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "onCall",
            actions: ["startCall"]
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            actions: [assign({})]
          }
        }
      },
      onCall: {
        on: {
          FORWARD: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              cond: (context, event) => {
                return context.videoAfterCall;
              },
              target: "showVideo",
              actions: assign({ watchVideo: true })
            },
            {
              target: "mainScene",
              actions: ["unshiftScene", "finishCall"]
            }
          ],
          BACK: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      // watchVideo: {
      //   on: {
      //     CLICK: {
      //       target: "done",
      //       actions: assign({
      //         showTablet: false,
      //         tabletClickable: true,
      //         tabletRinging: true,
      //         calling: "blast_quiz",
      //         done: true
      //       })
      //     }
      //   }
      // },
      showVideo: {
        on: {
          VIDEODONE: {
            target: "videoDone",
            actions: assign({
              showTablet: false,
              tabletClickable: true,
              done: true
            })
          }
        }
      },
      videoDone: {
        on: {
          "": {
            target: "secondCall",
            actions: assign({
              done: true
            })
          }
        }
      },
      secondCall: {
        entry: ["startCall", "prepareSecondCall"],
        on: {
          FORWARD: [
            {
              target: "onCall",
              cond: (context, event) => {
                return (
                  context.callStep + 1 < context.scientistSubTitles2.length
                );
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "mainScene",
              actions: ["unshiftScene", "finishCall"]
            }
          ],
          BACK: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        entry: ["finishScene"],
        type: "final"
      }
    }
  },
  {
    actions: {
      initializeEnvironment: (context, event) => {
        console.log(`initializeEnvironment: ${context}`);
        context.environment.clickables.tableTablet.layers[0].src =
          context.scientistData.imgOnPicnicTable;
        if (context.blastQuiz) {
          context.environment.clickables.tableTablet.action = "BLASTQUIZ";
          context.environment.clickables.tableTablet.visible = true;
          context.environment.clickables.tableTablet.isClickable = true;
          context.environment.clickables.tableTablet.ringing = true;
          context.environment.clickables.tableTablet.showHighlight = true;
        }
        if (context.clipboardIsTest) {
          context.environment.clickables.clipboard.action = "TEST";
        }
        if (context.backPackUnlocked) {
          context.environment.clickables.tableTablet.visible = false;
          context.environment.clickables.tableTablet.isClickable = false;
          context.environment.clickables.backPack.visible = true;
          context.environment.clickables.backPack.isClickable = true;
          context.environment.clickables.backPack.showHighlight = true;
          context.environment.clickables.backPack.ringing = true;
          context.environment.clickables.backPack.locked = false;
        }
        if (context.lesson) {
          context.environment.clickables.tableTablet.visible = true;
          context.environment.clickables.tableTablet.showHighlight = true;
          context.environment.clickables.tableTablet.ringing = true;
          context.environment.clickables.clipboard.action = "TAKE";
        }
        console.log(context.scientistData);
      },
      shiftScene: (context, event) => {
        console.log("shifting:");
        context.environment.shiftBackground = true;
        context.environment.background.src = imgBackgroundTablet;
        context.environment.background.thumbnail = imgBackgroundTabletSmall;
        console.log(context.environment);
      },
      startCall: (context, event) => {
        console.log("startCall");
        context.showTablet = true;
        context.callConnected = true;
        context.environment.clickables.tableTablet.visible = false;
      },
      prepareSecondCall: (context, event) => {
        context.scientistSubTitles = context.scientistSubTitles2;
      },
      unshiftScene: (context, event) => {
        console.log("unshifting:");
        context.environment.shiftBackground = false;
        context.environment.background.src = imgBackground;
        context.environment.background.thumbnail = imgBackgroundSmall;
      },
      prepareForNext: (context, event) => {
        console.log("prepareForNext:");
        // I have a bug where the next scene does not get set up properly.  May need to preset the scene.
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
      },
      finishScene: (context, event) => {
        context.showTablet = true;
        context.environment.clickables.tableTablet.visible = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
        context.environment.clickables.clipboard.ringing = false;
        context.environment.clickables.clipboard.showHighlight = false;

        context.done = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.visible = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        console.log("obtainItem");
        context.itemObtained = "phCharts";
        context.environment.clickables.clipboard.showHighlight = false;
        context.environment.clickables.clipboard.isClickable = false;
        context.environment.clickables.clipboard.ringing = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.clickables.clipboard.isClickable = true;
        context.environment.clickables.clipboard.ringing = true;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);
export const TabletContent = props => {
  const { current } = props;
  console.log("State is now:", current.value);
  switch (current.value) {
    case "pickUpTablet":
    case "waitForPage":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={props.current.context.scientistData.callImg}
        />
      );
    case "secondCall":
    case "onCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "mainScene":
    case "blastQuiz":
      return <NewAppBlastQuiz tabletSend={props.mSend} {...props} />;
    case "showVideo":
      return (
        <ShowVideo
          videoId="wpySMliPqNc"
          videoTitle="Tide Pool Adventure"
          videoIntroduction="Tidepools are a living home to extreme animals."
          videoCaption="Can you guess how barnacles eat?"
          onEnd={() => props.mSend("VIDEODONE")}
          mSend={props.mSend}
          start={43}
          end={385}
          noButton={true}
        />
      );
    case "ringScientist":
    default:
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={props.current.context.scientistData.callImg}
        />
      );
  }
};
