import React, { useContext } from "react";
import UserContext from "context/User";
import imgNotesIntro1 from "img/notes_intro_img_1.png";
import imgNotesIntro2 from "img/notes_intro_img_2.png";
import iconIntroFolder from "img/notes_intro_folder.png";
import imgMbariNotesFolder from "img/notes_auv_folder.png";
import ContentEditable from "react-contenteditable";
import imgKG1 from "img/kayak_gallery/kc_touch_water.jpg";
import imgKG2 from "img/kayak_gallery/kc_birds.jpg";
import imgKG3 from "img/kayak_gallery/kc_kayak.jpg";
import imgKG4 from "img/kayak_gallery/kc_bobcat.jpg";
import imgKG5 from "img/kayak_gallery/kc_marsh.jpg";
import imgKG6 from "img/kayak_gallery/kc_otter.jpg";
import imgTPG1 from "img/tide_pool_gallery/tp_small_octupus.jpg";
import imgTPG2 from "img/tide_pool_gallery/tp_tube_snail.jpg";
import imgTPG3 from "img/tide_pool_gallery/tp_sea_hare.jpg";
import imgTPG4 from "img/tide_pool_gallery/tp_sea_star.jpg";
import imgTPG5 from "img/tide_pool_gallery/tp_sculpin.jpg";
import imgTPG6 from "img/tide_pool_gallery/tp_sea_anemone.jpg";
import imgDefaultRelfectionPlaceholder from "img/default_reflection_image.svg";
import { AppScreen, AppBGScreen } from "components/TabletApps";
import { Join } from "components/UIComponents";

const arrSavedImages = {
  ELKHORN: {
    order: [
      "TouchWater",
      "TakeFlight",
      "KayakExploration",
      "SloughEdge",
      "BobCat",
      "OtterSwimming"
    ],
    images: {
      TouchWater: {
        src: imgKG1,
        title: "Touching Water",
        time: "12:20PM, 12 Sep 2019",
        source: "Kayak Camera"
      },
      TakeFlight: {
        src: imgKG2,
        title: "Take Flight",
        time: "12:22PM, 12 Sep 2019",
        source: "Kayak Camera"
      },
      KayakExploration: {
        src: imgKG3,
        title: "The Open Water",
        time: "12:24PM, 12 Sep 2019",
        source: "Kayak Camera"
      },
      SloughEdge: {
        src: imgKG5,
        title: "The Water's Edge",
        time: "12:28PM, 12 Sep 2019",
        source: "Kayak Camera"
      },
      BobCat: {
        src: imgKG4,
        title: "A Bobcat Rests",
        time: "12:25PM, 12 Sep 2019",
        source: "Kayak Camera"
      },
      OtterSwimming: {
        src: imgKG6,
        title: "Otter Swimming",
        time: "12:32PM, 12 Sep 2019",
        source: "Kayak Camera"
      }
    }
  },
  TIDEPOOLS: {
    order: [
      "SmallOctopus",
      "TubeSnail",
      "SeaHare",
      "Sculpin",
      "SeaStar",
      "SeaAnemone"
    ],
    images: {
      SmallOctopus: {
        src: imgTPG1,
        time: "12:20PM, 12 Sep 2019",
        title: "Small Octopus",
        source: "Natural Bridges"
      },
      TubeSnail: {
        src: imgTPG2,
        time: "12:22PM, 12 Sep 2019",
        title: "Tube Snails",
        source: "Natural Bridges"
      },
      SeaHare: {
        src: imgTPG3,
        time: "12:24PM, 12 Sep 2019",
        title: "Sea Hare",
        source: "Natural Bridges"
      },
      Sculpin: {
        src: imgTPG5,
        time: "12:28PM, 12 Sep 2019",
        title: "Sculpin",
        source: "Pacific Grove"
      },
      SeaStar: {
        src: imgTPG4,
        time: "12:25PM, 12 Sep 2019",
        title: "Sea Star",
        source: "Natural Bridges"
      },
      SeaAnemone: {
        src: imgTPG6,
        time: "12:32PM, 12 Sep 2019",
        title: "Sea Anemone",
        source: "Pacific Grove"
      }
    }
  }
};

const galleryImageType = {
  MBARI: "Memory",
  ELKHORN: "Memory",
  TIDEPOOLS: "Organism"
};

const savedImageSrc = (context, notesID) => {
  console.log("CI, reflectionPhoto:", context.reflection);
  console.log("CI, notesID:", notesID);
  console.log("CI, context.reflection :", context.reflection);
  const imageID =
    context.reflection &&
    notesID in context.reflection &&
    context.reflection[notesID] !== null &&
    context.reflection[notesID] !== undefined
      ? context.reflection[notesID].imageID
      : null;
  console.log("CI, imageID:", imageID);

  return imageID
    ? arrSavedImages[notesID].images[imageID].src
    : imgDefaultRelfectionPlaceholder;
};
const NavBar = props => {
  const { mSend, current } = props;
  const { backClickable, parentDir, currentFile } = current.context;
  return (
    <div className="n_navbar">
      <div
        className={"back" + (backClickable === false ? " n_no_click" : "")}
        onClick={() => mSend(props.backCommand)}
      >
        &lt; Back
      </div>
      <div className="breadcrumb">
        {parentDir && (
          <>
            <div
              className="root"
              onClick={() =>
                mSend("NOTES", {
                  userID: props.userID,
                  api: props.api
                })
              }
            >
              {parentDir}
            </div>
            &gt;
          </>
        )}
        <div className="current">{currentFile}</div>
      </div>
    </div>
  );
};

export const NotesIntro = props => {
  const { mSend, current } = props;
  const { page, backArrowClickable, forwardArrowClickable } = current.context;
  console.log("NotesIntro, props:", props);
  return (
    <AppBGScreen>
      <NavBar {...props} />
      <div className="n_info">
        <h1>Introduction</h1>
        <h2>Hello, fellow explorer...</h2>
        <p>Welcome to your Science and Engineering Notebook!</p>
        <p>
          Famous scientists like Edison, Galileo and Einstein have used
          notebooks to record their ideas, which changed our world. It's
          important for you, too, to write down what you discover and learn
          about science!
        </p>
        <figure>
          <img
            src={imgNotesIntro1}
            className="first_img"
            alt="Galileo Galileis Sunspot Drawings"
          />
          <figcaption>Galileo Galileis Sunspot Drawings</figcaption>
        </figure>
        <figure>
          <img
            src={imgNotesIntro2}
            className="second_img"
            alt="Albert Enstein Notebook"
          />
          <figcaption>Albert Enstein Notebook (below)</figcaption>
        </figure>
        <div className="n_bottom_nav">
          <div
            className={
              "n_back_arrow" +
              (backArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() => mSend(props.backCommand)}
          >
            &lt;
          </div>
          {page}
          <div
            className={
              "n_forward_arrow" +
              (forwardArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() =>
              mSend("NOTES", {
                userID: props.userID,
                api: props.api
              })
            }
          >
            &gt;
          </div>
        </div>
      </div>
    </AppBGScreen>
  );
};

// const initialNotes = {
//   // "Despite practicing navigating the AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed!",
//   MBARI: "",
//   ELKHORN: "",
//   TIDEPOOLS: ""
// };

const ModifiedDateTime = (context, notesID) => {
  // console.log("NOTES, context:", context);
  // console.log("NOTES, notesID:", notesID);
  return context.reflection &&
    notesID in context.reflection &&
    context.reflection[notesID] !== null &&
    "savedDateTime" in context.reflection[notesID]
    ? context.reflection[notesID].savedDateTime
    : "Draft";
};

export const NotesSelection = props => {
  const { mSend, current } = props;
  const { context } = current;
  // const notesID = props.notesID || "MBARI";

  // console.log("NOTES, NotesSelection backCommand: ", props.backCommand);

  const objFolders = {
    1: (
      <div
        className="n_folder"
        onClick={() =>
          mSend("REFLECT", {
            notesID: "MBARI",
            currentFile: "MBARI Reflection"
          })
        }
        key={1}
      >
        <figure>
          <img src={imgMbariNotesFolder} alt="MBARI Notebook" />
          <figcaption>
            <h3>{ModifiedDateTime(context, "MBARI")}</h3>
            <h1>MBARI Reflection</h1>
            <h2>Which tool is your favorite...</h2>
          </figcaption>
        </figure>
      </div>
    ),
    2: (
      <div
        className="n_folder"
        onClick={() =>
          mSend("REFLECT", {
            notesID: "ELKHORN",
            currentFile: "Elkhorn Slough Reflection"
          })
        }
        key={2}
      >
        <figure>
          <img
            src={savedImageSrc(context, "ELKHORN")}
            alt="Elkhorn Slough Notebook"
          />
          <figcaption>
            <h3>{ModifiedDateTime(context, "ELKHORN")}</h3>
            <h1>Elkhorn Slough Reflection</h1>
            <h2>My favorite moment was...</h2>
          </figcaption>
        </figure>
      </div>
    ),
    3: (
      <div
        className="n_folder"
        onClick={() =>
          mSend("REFLECT", {
            notesID: "TIDEPOOLS",
            currentFile: "Tide Pool Reflection"
          })
        }
        key={3}
      >
        <figure>
          <img
            src={savedImageSrc(current.context, "TIDEPOOLS")}
            alt="Tide Pool Notebook"
          />
          <figcaption>
            <h3>{ModifiedDateTime(context, "TIDEPOOLS")}</h3>
            <h1>Tide Pool Reflection</h1>
            <h2>My favorite organism was...</h2>
          </figcaption>
        </figure>
      </div>
    )
  };
  let arrFolders = [];
  const lessonCount = props.lesson || 1;
  console.log("lessonCount: ", lessonCount);
  for (let i = 1; i <= lessonCount; i++) {
    arrFolders.push(objFolders[i]);
  }

  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_folders">
        <div className="n_folder" onClick={() => mSend("INTRO")}>
          <figure>
            <img
              src={iconIntroFolder}
              className="Foreword"
              alt="Introduction"
            />
            <figcaption>
              <h3>Notes from MBARI</h3>
              <h1>Introduction</h1>
              <h2>Hello, fellow explorer...</h2>
            </figcaption>
          </figure>
        </div>
        {arrFolders}
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("DONE")}>
            Return to Lab
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};

export const objQuestions = {
  MBARI: [
    {
      id: "FAVORITETOOL",
      text: "Which tool is your favorite and why?",
      imageSrc: imgMbariNotesFolder,
      imageEditable: false,
      alt: "MBARI Building",
      answer: ""
    },
    { id: "LEARNCODING", text: "What did you learn about coding?" },
    {
      id: "ADJUSTMENTSCODING",
      text:
        "What adjustments did you make to your program to successfully collect the targets?",
      answer: ""
    }
  ],
  ELKHORN: [
    {
      id: "FAVORITEORGANISMELKHORN",
      text:
        "Which was your favorite moment from your kayaking journey and why?",
      imageEditable: true,
      alt: "Click here to upload your favorite photo from your journey.",
      subTitle: "Click here to upload your favorite photo from your journey.",
      answer: ""
    },
    {
      id: "IMPORTANCEOFESTUARIES",
      text: "Do you think estuaries are important? Prove your point.",
      answer: ""
    },
    {
      id: "CONSERVERESTUARIES",
      text: "Do you have any ideas of how we can help conserve our estuaries?",
      answer: ""
    }
  ],
  TIDEPOOLS: [
    {
      id: "FAVORITEORGANISMTIDEPOOLS",
      text:
        "Which was your favorite organism from your exploration of the tide pools?",
      imageEditable: true,
      alt: "Click here to upload your favorite photo from your exploration.",
      subTitle:
        "Click here to upload your favorite photo from your exploration.",
      answer: ""
    },
    {
      id: "PICTURETIDEPOOL",
      text:
        "Choose a picture that will help you remember your visit to the tide pools.  Why is this picture interesting to you?",
      answer: ""
    },
    {
      id: "WATERQUALITY",
      text: "Why is it important to monitor water quality?",
      answer: ""
    }
  ]
};
const currentFile = {
  MBARI: "MBARI Reflection",
  ELKHORN: "Elkhorn Slough Reflection",
  TIDEPOOLS: "Tide Pool Reflection"
};

export const fnDefaultAnswers = questions => {
  let objAnswers = {};
  for (let i = 0; i < questions.length; i++) {
    let question = questions[i];
    objAnswers[question.id] = question.answer;
  }
  return objAnswers;
};

const ReflectionImage = (props, notesID, context, question, mSend, answers) => {
  if (question.imageEditable) {
    return (
      <figure className="notes_figure">
        <img
          src={savedImageSrc(context, notesID)}
          alt={question.alt}
          className="inset_clickable"
          onClick={() => mSend("IMAGECHOOSE", { answers: answers })}
        />
        <figcaption>{question.subTitle}</figcaption>
      </figure>
    );
  } else if (question.imageSrc) {
    return (
      <figure className="notes_figure">
        <img src={question.imageSrc} alt={question.alt} />
        <figcaption>{question.subTitle}</figcaption>
      </figure>
    );
  }
};
export const Reflection = props => {
  // const { mSend, current, answers, setAnswers } = props;
  const { mSend, current } = props;
  const { context } = current;
  const notesID = props.notesID || "MBARI";

  const answers = context.reflectionAnswers[context.notesID];
  console.log("CI, answers:", answers);
  console.log(
    "CI, starting questions:",
    objQuestions[notesID].map(question => question.answer)
  );
  console.log("CI, answers=", answers);

  const savedDateTime =
    context.reflection &&
    context.reflection[notesID] &&
    context.reflection[notesID].savedDateTime
      ? context.reflection[notesID].savedDateTime
      : null;

  return (
    <AppBGScreen>
      <NavBar {...props} />
      <div className="n_reflect">
        <div className="n_file_info">
          <div>{savedDateTime ? "Last Saved" : "Draft"}</div>
          <div>{savedDateTime}</div>
        </div>
        <h1>{currentFile[props.notesID]}</h1>
        <div className="n_questions">
          {objQuestions[notesID].map((question, id) => {
            const answer =
              answers && question.id in answers
                ? answers[question.id]
                : { text: "" };
            console.log("CI, answer=", answer);

            return (
              <div key={"div" + question.id}>
                <div>
                  {id + 1}. {question.text}
                  {ReflectionImage(
                    props,
                    notesID,
                    context,
                    question,
                    mSend,
                    answers
                  )}
                </div>
                <ContentEditable
                  data-reactid={question.id}
                  key={question.id}
                  html={answer.text} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  // onChange={e => handleInput(e, question.id)} // handle innerHTML change
                  // onChange={e => handleInput(e.target.value, question.id)} // handle innerHTML change
                  onChange={e => {
                    mSend({
                      type: "TEXTCHANGED",
                      value: e.target.value,
                      questionID: question.id,
                      e: e
                    });
                  }}
                  tagName="p" // Use a custom HTML tag (uses a div by default)
                  className={Join(
                    ["n_answer", answer.saved ? "n_saved" : "n_not_saved"],
                    " "
                  )}
                ></ContentEditable>
              </div>
            );
          })}
        </div>
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("SAVE")}>
            Save
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};

export const GallerySelection = props => {
  const { mSend } = props;

  const imageStr = arrSavedImages[props.notesID];
  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_reflect">
        {imageStr.order.map((imageID, id) => {
          console.log("CI, imageStr:", imageStr);
          const objImage = imageStr.images[imageID]; // this was added to allow images to be ordered in gallery
          // const imageIds = Object.keys(objImageStr);
          // const objImage = objImageStr[imageIds[0]];
          return (
            <figure
              className="kg_gallery_image"
              onClick={() =>
                mSend("SELECT", {
                  selectionID: imageID,
                  notesID: props.notesID,
                  selection: objImage
                })
              }
              key={id}
            >
              <img
                src={objImage.src}
                alt={
                  "Saved Image from Kayak Collection Mission, taken at " +
                  objImage.time
                }
              />
              <figcaption>
                <div className="row">
                  <div className="left">{galleryImageType[props.notesID]}:</div>
                  <div className="right">{objImage.title}</div>
                </div>
                <div className="row">
                  <div className="left">Location:</div>
                  <div className="right">{objImage.source}</div>
                </div>
              </figcaption>
            </figure>
          );
        })}
      </div>
    </AppBGScreen>
  );
};

export const TabletContent = props => {
  const { current } = props;
  const [objUserContext] = useContext(UserContext);
  // console.log("CI, objUserContext:", objUserContext);
  // console.log("NOTES, currentPage:", current.value);

  // console.log("NOTES, notesID:", current.value);
  const lessonCount = props.lesson || 1;
  let backCommand = "BACK";

  switch (current.value) {
    case "waitForMainScene":
    case "loadReflections":
    case "appScreen":
      return (
        <AppScreen
          {...props}
          mSend={props.mSend}
          focus="Notes"
          userID={objUserContext.user ? objUserContext.user.id : null}
          api={objUserContext.api}
          context={current.context}
        />
      );
    case "notesSelection":
      backCommand = lessonCount === 1 ? "BACK" : "BACKTOAPPSCREEN";
      // console.log("NOTES, backCommand:", backCommand);
      return (
        <NotesSelection
          {...props}
          notesID={current.context.notesID}
          userID={objUserContext.user.id}
          backCommand={backCommand}
        />
      );

    case "saveReflections":
    case "reflection":
      return (
        <Reflection
          {...props}
          notesID={current.context.notesID}
          userID={objUserContext.user.id}
          backCommand="BACK"
        />
      );
    case "chooseImage":
      console.log("props=", props);
      return (
        <GallerySelection
          {...props}
          notesID={current.context.notesID}
          lesson={"Lesson " + props.lesson}
          backCommand="BACK"
          // images={arrSavedImages}
        />
      );
    case "loadReflectionsFromIntro":
    case "intro":
    default:
      backCommand = lessonCount === 1 ? "BACKTOAPPSCREEN" : "BACK";
      // console.log("NOTES, backCommand:", backCommand);
      return (
        <NotesIntro
          {...props}
          context={current.context}
          userID={objUserContext.user.id}
          api={objUserContext.api}
          backCommand={backCommand}
        />
      );
  }
};
