import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakStation from "img/ks_background.jpg";
import imgKayakStationSmall from "img/ks_background_sm.jpg";
import imgTableWTablet from "img/table_w_tablet.png";
import imgTableTabletHighlight from "img/table_tablet_highlight.svg";
import imgKayakShackDoorHighlight from "img/ks_door_highlight.svg";
import imgCrate from "img/ks_crate.png";
import imgQPCR from "img/ks_qPCR_obtained.png";
import UserContext from "context/User";
import appData from "components/appCall/CallingCharlesNye";
import itemCollectedImg from "img/ks_qPCR_obtained.png";
import badgeImg from "img/elkhorn_estuary_badge.png";
import { AwardPageRewards, AwardPageNewLevel } from "components/TabletApps";

const arrBadges = [
  {
    img: itemCollectedImg,
    name: "Water Tester",
    type: "Tool",
    style: {
      transform: "rotate(5deg) translate(0%, -5%) scale(.8)"
    }
  },
  {
    img: badgeImg,
    name: "Estuary Badge",
    type: "Badge",
    style: {
      transform: "translate(0%, -5%) scale(.9) "
    }
  }
];

export const envMachine = Machine(
  {
    id: "congratulations",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakStation,
          thumbnail: imgKayakStationSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: false,
            clickable: true,
            showHighlight: true,
            ringing: true,
            action: "pickUpTablet",
            className: "table_tablet",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              }
            ]
          },
          shackDoor: {
            visible: false,
            clickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          title: "Handheld qPCR Device",
          src: imgQPCR,
          description:
            "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
        }
      },
      showTablet: true,
      level: 2
    },
    initial: "awardPageRewards",
    states: {
      awardPageRewards: {
        on: {
          CLAIM: {
            target: "awardPageNewLevel",
            actions: ["levelUp"]
          }
        }
      },
      awardPageNewLevel: {
        on: {
          CONTINUE: {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },

      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      levelUp: (context, event) => {
        context.level = context.level + 1;
      }
    }
  }
);

const Review = props => {
  const [objUser] = useContext(UserContext);

  console.log("User:", objUser);
  if ("user" in objUser) objUser.user.objective = "Review your awards";

  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const levelUpAction = () => {
    var objUpdate = objUser.user;
    objUpdate.level = 3;
    objUser.fnUpdateField("user", objUpdate);
    objUser.fnStoreLevel();

    mSend("CLAIM");
  };
  const TabletContent = props => {
    console.log(current.value);
    switch (current.value) {
      case "awardPageNewLevel":
        return <AwardPageNewLevel {...props} level={objUser.user.level} />;
      case "awardPageRewards":
      default:
        return (
          <AwardPageRewards
            {...props}
            arrBadges={arrBadges}
            title="Elkhorn Slough"
            onClick={levelUpAction}
          />
        );
    }
  };
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
