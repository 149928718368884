import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import imgBeaker1 from "img/ph_beaker_1.svg";
import imgBeaker3 from "img/ph_beaker_3.svg";
import UserContext from "context/User";
import imgPGTidepoolBG from "img/pg_tidepool_closeup_bg.jpg";
import imgPGTidepoolBGSmall from "img/pg_tidepool_closeup_bg_sm.jpg";
import imgNBTidepoolBG from "img/nb_tidepool_closeup_bg.jpg";
import imgNBTidepoolBGSmall from "img/nb_tidepool_closeup_bg_sm.jpg";
import oneMarker from "img/tidepoolCloseup/one_marker.svg";
import oneMarkerHover from "img/tidepoolCloseup/one_marker_hover.svg";
import twoMarker from "img/tidepoolCloseup/two_marker.svg";
import twoMarkerHover from "img/tidepoolCloseup/two_marker_hover.svg";
import threeMarker from "img/tidepoolCloseup/three_marker.svg";
import threeMarkerHover from "img/tidepoolCloseup/three_marker_hover.svg";
import fourMarker from "img/tidepoolCloseup/four_marker.svg";
import fourMarkerHover from "img/tidepoolCloseup/four_marker_hover.svg";
import cardSculpins from "img/tidepoolCloseup/card_sculpins.jpg";
import cardSculpinsSmall from "img/tidepoolCloseup/card_sculpins_sm.jpg";
import cardBarnacles from "img/tidepoolCloseup/card_barnacles.jpg";
import cardBarnaclesSmall from "img/tidepoolCloseup/card_barnacles_sm.jpg";
import cardSeaAnemones from "img/tidepoolCloseup/card_sea_anemones.jpg";
import cardSeaAnemonesSmall from "img/tidepoolCloseup/card_sea_anemones_sm.jpg";
import cardSmallOctupuses from "img/tidepoolCloseup/card_smaller_octupuses.jpg";
import cardSmallOctupusesSmall from "img/tidepoolCloseup/card_smaller_octupuses_sm.jpg";
import cardTubeSnails from "img/tidepoolCloseup/card_tube_snails.jpg";
import cardTubeSnailsSmall from "img/tidepoolCloseup/card_tube_snails_sm.jpg";
import cardSeaHares from "img/tidepoolCloseup/card_sea_hares.jpg";
import cardSeaHaresSmall from "img/tidepoolCloseup/card_sea_hares_sm.jpg";
import cardSeaStars from "img/tidepoolCloseup/card_sea_stars.jpg";
import cardSeaStarsSmall from "img/tidepoolCloseup/card_sea_stars_sm.jpg";

import { InfoPage, ImageCard } from "components/TabletApps";

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }

// const samples = {
//   "Pacific Grove": {
//     ONE: {
//       src: cardSculpins,
//       thumbnail: cardSculpinsSmall,
//       aspectRatio: "713x406",
//       title: "Tide Pool Sculpins",
//       description:
//         "Tide pool sculpins are a small species of sculpin that only grow up to 3.5 inches. They spend their whole lives in a small area of a tide pool and can “home,” which means that they have an adaptation that allows them to find their way back if they ever get displaced. They survive in the intertidal zone because they have adaptations that allow them to withstand extreme temperature and salinity changes, which happens  in the tide pools, when the tide goes out. They can also breathe air for short periods of time if they need to move to a different tide pool because of the conditions where they currently are."
//     },
//     TWO: {
//       src: cardBarnacles,
//       thumbnail: cardBarnaclesSmall,
//       aspectRatio: "713x379",
//       title: "Barnacles",
//       description:
//         "Barnacles are filter feeders that have a feather-like appendage used for catching, or filtering, small organisms from the water flowing around them. They pull the appendage in to their shells to eat what was caught and then send it back into the water for more. This is a necessary adaptation because barnacles cannot move. Barnacles produce a glue-like substance that holds them in place on rocks and other materials. This is an adaptation that helps them survive the waves in the intertidal zone. Another adaptation barnacles have for survival in the intertidal zone is their hard exoskeleton that they can close tightly, which allows them to hold water during low tide."
//     },
//     THREE: {
//       src: cardSeaAnemones,
//       thumbnail: cardSeaAnemonesSmall,
//       aspectRatio: "713x405",
//       title: "Sea Anemones",
//       description:
//         "Sea anemones are circular in shape, have tentacles all around them, and their stomach/waste outlet in the center. Their tentacles can sway in the water flowing over to collect food or they can be pulled in towards the center of an anemone’s body to provide protection for the extra soft middle. Because anemones have soft bodies and do not move, they collect shell pieces on their tentacles as a form of sunscreen. When they pull their tentacles toward the center of their bodies, the shell pieces cover them. This is how they have adapted to life in the intertidal zone."
//     }
//   },
//   "Natural Bridges": {
//     ONE: {
//       src: cardSmallOctupuses,
//       thumbnail: cardSmallOctupusesSmall,
//       aspectRatio: "713x406",
//       title: "Smaller octopuses",
//       description:
//         "Smaller octopuses can be found in tide pools. They like to hide under seaweed and rocks, only being in the open when they are on the move. Since octopuses can move quite easily and live in parts of the ocean besides tide pools, they do not have adaptations specific to that environment. This makes it important for them to find pools deep enough to keep them submerged even during low tide. Octopuses do have a couple of pretty amazing adaptations, though. They are experts in camouflage, having the ability to change their color and skin texture to match the patterns of their surroundings!"
//     },
//     TWO: {
//       src: cardTubeSnails,
//       thumbnail: cardTubeSnailsSmall,
//       aspectRatio: "713x486",
//       title: "Tube Snails",
//       description:
//         "Tube snails live in hard tubes and tend to colonize, creating a maze-like appearance. These colonies can cover a lot of area. The hard tubes protect them from drying out. Tube snails also secrete a “glue” that secures them to the surface that they rest on while waves crash all around."
//     },
//     THREE: {
//       src: cardSeaHares,
//       thumbnail: cardSeaHaresSmall,
//       aspectRatio: "713x486",
//       title: "Sea hares",
//       description:
//         "Sea hares are soft-bodied creatures that live in sheltered areas of tide pools. They have a sticky substance that helps them glide along but also move less during increased water movement. Another adaptation sea hares have for surviving in the intertidal zone is that they can survive up to 30% body mass loss from drying out. This means they can survive out of water for a while when the tide is down."
//     },
//     FOUR: {
//       src: cardSeaStars,
//       thumbnail: cardSeaStarsSmall,
//       aspectRatio: "713x433",
//       title: "Sea Stars",
//       description:
//         "Sea stars have hundreds of tube feet on their underside that they can use to suction onto surfaces. This adaptation stops them from being tossed about by the waves. They can also use these tube feet to move around. Much of their prey is in the intertidal zone so they move up the beach when the tide is coming in for high tide and search for food. Then, they retreat to the low tide line when the tide is going out to avoid being out of water."
//     }
//   }
// };

// const background = {
//   "Pacific Grove": { src: imgPGTidepoolBG, thumbnail: imgPGTidepoolBGSmall },
//   "Natural Bridges": { src: imgNBTidepoolBG, thumbnail: imgNBTidepoolBGSmall }
// };

const contexts = {
  "Pacific Grove": {
    environment: {
      className: "ph_tidepool_closeup",
      background: {
        optimize: false,
        src: imgPGTidepoolBG,
        thumbnail: imgPGTidepoolBGSmall,
        alt: "Pacific Grove Tide Pool Closeup",
        color: "#132962",
        aspectRatio: "1920x1080"
      },
      clickables: {
        ONE: {
          visible: true,
          id: "ONE",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "16%",
            top: "51%"
          },
          layers: [
            {
              name: "background",
              src: oneMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: oneMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        },
        TWO: {
          visible: true,
          id: "TWO",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "11%",
            top: "42%"
          },
          layers: [
            {
              name: "background",
              src: twoMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: twoMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        },
        THREE: {
          visible: true,
          id: "THREE",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "40%",
            top: "35%"
          },
          layers: [
            {
              name: "background",
              src: threeMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: threeMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        }
      }
    },
    samples: {
      ONE: {
        src: cardSculpins,
        thumbnail: cardSculpinsSmall,
        aspectRatio: "713x406",
        title: "Tide Pool Sculpins",
        description:
          "Tide pool sculpins are a small species of sculpin that only grow up to 3.5 inches. They spend their whole lives in a small area of a tide pool and can “home,” which means that they have an adaptation that allows them to find their way back if they ever get displaced. They survive in the intertidal zone because they have adaptations that allow them to withstand extreme temperature and salinity changes, which happens  in the tide pools, when the tide goes out. They can also breathe air for short periods of time if they need to move to a different tide pool because of the conditions where they currently are."
      },
      TWO: {
        src: cardBarnacles,
        thumbnail: cardBarnaclesSmall,
        aspectRatio: "713x379",
        title: "Barnacles",
        description:
          "Barnacles are filter feeders that have a feather-like appendage used for catching, or filtering, small organisms from the water flowing around them. They pull the appendage in to their shells to eat what was caught and then send it back into the water for more. This is a necessary adaptation because barnacles cannot move. Barnacles produce a glue-like substance that holds them in place on rocks and other materials. This is an adaptation that helps them survive the waves in the intertidal zone. Another adaptation barnacles have for survival in the intertidal zone is their hard exoskeleton that they can close tightly, which allows them to hold water during low tide."
      },
      THREE: {
        src: cardSeaAnemones,
        thumbnail: cardSeaAnemonesSmall,
        aspectRatio: "713x405",
        title: "Sea Anemones",
        description:
          "Sea anemones are circular in shape, have tentacles all around them, and their stomach/waste outlet in the center. Their tentacles can sway in the water flowing over to collect food or they can be pulled in towards the center of an anemone’s body to provide protection for the extra soft middle. Because anemones have soft bodies and do not move, they collect shell pieces on their tentacles as a form of sunscreen. When they pull their tentacles toward the center of their bodies, the shell pieces cover them. This is how they have adapted to life in the intertidal zone."
      }
    },
    samplesCount: 3,
    showTablet: true,
    site: "Pacific Grove",
    focusSample: null,
    samplesViewed: {},
    samplesExplored: 0,
    buttonDisabled: true
  },
  "Natural Bridges": {
    environment: {
      className: "ph_tidepool_closeup",
      background: {
        optimize: false,
        src: imgNBTidepoolBG,
        thumbnail: imgNBTidepoolBGSmall,
        alt: "Natural Bridges Tide Pool Closeup",
        color: "#132962",
        aspectRatio: "1920x1080"
      },
      clickables: {
        ONE: {
          visible: true,
          id: "ONE",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "34%",
            top: "56%"
          },
          layers: [
            {
              name: "background",
              src: oneMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: oneMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        },
        TWO: {
          visible: true,
          id: "TWO",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "24%",
            top: "23%"
          },
          layers: [
            {
              name: "background",
              src: twoMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: twoMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        },
        THREE: {
          visible: true,
          id: "THREE",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "26%",
            top: "80%"
          },
          layers: [
            {
              name: "background",
              src: threeMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: threeMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        },
        FOUR: {
          visible: true,
          id: "FOUR",
          type: "marker",
          showHover: true,
          action: "EXAMINE",
          style: {
            position: "absolute",
            width: "4vw",
            height: "4vw",
            left: "10%",
            top: "48%"
          },
          layers: [
            {
              name: "background",
              src: fourMarker,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "48%",
                marginTop: "26%",
                marginLeft: "26%"
              }
            },
            {
              name: "hover",
              src: fourMarkerHover,
              handlesHover: true,
              style: {
                position: "absolute",
                width: "100%"
              }
            }
          ]
        }
      }
    },
    samples: {
      ONE: {
        src: cardSmallOctupuses,
        thumbnail: cardSmallOctupusesSmall,
        aspectRatio: "713x406",
        title: "Smaller octopuses",
        description:
          "Smaller octopuses can be found in tide pools. They like to hide under seaweed and rocks, only being in the open when they are on the move. Since octopuses can move quite easily and live in parts of the ocean besides tide pools, they do not have adaptations specific to that environment. This makes it important for them to find pools deep enough to keep them submerged even during low tide. Octopuses do have a couple of pretty amazing adaptations, though. They are experts in camouflage, having the ability to change their color and skin texture to match the patterns of their surroundings!"
      },
      TWO: {
        src: cardTubeSnails,
        thumbnail: cardTubeSnailsSmall,
        aspectRatio: "713x486",
        title: "Tube Snails",
        description:
          "Tube snails live in hard tubes and tend to colonize, creating a maze-like appearance. These colonies can cover a lot of area. The hard tubes protect them from drying out. Tube snails also secrete a “glue” that secures them to the surface that they rest on while waves crash all around."
      },
      THREE: {
        src: cardSeaHares,
        thumbnail: cardSeaHaresSmall,
        aspectRatio: "713x486",
        title: "Sea hares",
        description:
          "Sea hares are soft-bodied creatures that live in sheltered areas of tide pools. They have a sticky substance that helps them glide along but also move less during increased water movement. Another adaptation sea hares have for surviving in the intertidal zone is that they can survive up to 30% body mass loss from drying out. This means they can survive out of water for a while when the tide is down."
      },
      FOUR: {
        src: cardSeaStars,
        thumbnail: cardSeaStarsSmall,
        aspectRatio: "713x433",
        title: "Sea Stars",
        description:
          "Sea stars have hundreds of tube feet on their underside that they can use to suction onto surfaces. This adaptation stops them from being tossed about by the waves. They can also use these tube feet to move around. Much of their prey is in the intertidal zone so they move up the beach when the tide is coming in for high tide and search for food. Then, they retreat to the low tide line when the tide is going out to avoid being out of water."
      }
    },
    showTablet: true,
    site: "Natural Bridges",
    focusSample: null,
    samplesViewed: {},
    samplesExplored: 0,
    buttonDisabled: true,
    samplesCount: 4
  }
};
const envMachine = Machine(
  {
    id: "PineGroveTidePoolClosup",
    initial: "testingIntro",
    states: {
      testingIntro: {
        entry: ["setSite", "printContext", "resetSamplesViewed"],
        on: {
          EXAMINE: {
            target: "examineSample",
            actions: ["focusOnSample"]
          }
        }
      },
      examineSample: {
        on: {
          EXAMINE: {
            target: "examineSample",
            actions: ["focusOnSample"]
          },
          NEXT: {
            target: "done"
          }
        }
      },
      done: {
        entry: ["finishScene"],
        type: "final"
      }
    }
  },
  {
    actions: {
      resetSamplesViewed: (context, event) => {
        context.samplesViewed = {};
        context.samplesExplored = 0;
      },
      finishScene: (context, event) => {
        // console.log("Finish Scene");
        context.done = true;
      },
      printContext: (context, event) => {
        // console.log("context=", context);
      },
      focusOnSample: (context, event) => {
        // console.log("context=", context);
        // console.log("event=", event);
        context.focusSample = context.samples[event.id];
        if (!(event.id in context.samplesViewed)) {
          context.samplesExplored += 1;
          context.samplesViewed[event.id] = true;
        }
        // console.log(`Compare ${context.samplesExplored} with ${context.samplesCount}`);
        if (context.samplesExplored >= context.samplesCount) {
          context.buttonDisabled = false;
          context.samplesExplored = 0;
          context.samplesViewed = {};
        }
      },
      prepareForTesting: (context, event) => {},
      sceneToEducation: (context, event) => {}
    }
  }
);

const TidePoolCloseup = props => {
  const [objUser] = useContext(UserContext);

  objUser.level = props.lesson;
  objUser.objective = "Elkhorn Slough & Kayak Station";
  const [current, mSend] = useMachine(envMachine, {
    context: contexts[props.site || "Pacific Grove"]
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    // console.log(current.context);

    switch (current.value) {
      case "examineSample":
        return (
          <ImageCard
            {...props} // mSend is already set
            title={current.context.focusSample.title}
            src={current.context.focusSample.src}
            thumbnail={current.context.focusSample.thumbnail}
            alt={current.context.focusSample.title}
            // image={imgPHTestingBeakers}
            message={current.context.focusSample.description}
            // instructions="Click on the markers to read about each one."
            buttonText="Continue Testing"
            buttonAction="NEXT"
            buttonDisabled={current.context.buttonDisabled}
          />
        );
      case "testingIntro":
      default:
        return (
          <InfoPage
            {...props} // mSend is already set
            title="Testing"
            image={props.site === "Natural Bridges" ? imgBeaker3 : imgBeaker1}
            alt="A full beaker"
            // image={imgPHTestingBeakers}
            buttonText="Test"
            message="While the pH meter is calculating results, take a closer look at organisms that are able to live in tide pools."
            instructions="Click on the markers to read about each one."
            buttonDisabled={true}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default TidePoolCloseup;
