import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import UserContext from "context/User";
import itemCollectedImg from "img/ph_meter_obtained.svg";
import badgeImg from "img/tide_pool_badge.svg";
import { AwardPageRewards, AwardPageNewLevel } from "components/TabletApps";
import { rewardMachine as envMachine } from "./NaturalBridgesEnvironment";

const arrBadges = [
  {
    img: itemCollectedImg,
    name: "pH Meter",
    type: "Tool",
    style: {
      width: "9vw",
      transform: "rotate(3deg) translate(-2%, -10%)"
    }
  },
  {
    img: badgeImg,
    name: "Tide Pool Badge",
    type: "Badge",
    style: {
      transform: "scale(.9) "
    }
  }
];

const Review = props => {
  //eslint-disable-next-line
  const [objUser] = useContext(UserContext);
  // const ringRef = useRef();

  console.log("User:", objUser);
  if ("user" in objUser) objUser.user.objective = "Review your awards";

  const [current, mSend] = useMachine(envMachine, {
    initial: "awardPageRewards"
  });
  console.log(current);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const levelUpAction = () => {
    var objUpdate = objUser.user;
    objUpdate.level = 4;
    objUser.fnUpdateField("user", objUpdate);
    objUser.fnStoreLevel();
    console.log("objUpdate:", objUpdate);
    console.log("objUser:", objUser);
    mSend("CLAIM");
  };
  const TabletContent = props => {
    console.log(current.value);
    switch (current.value) {
      case "awardPageNewLevel":
        return <AwardPageNewLevel {...props} level={objUser.user.level} />;
      case "awardPageRewards":
      default:
        return (
          <AwardPageRewards
            {...props}
            arrBadges={arrBadges}
            title="Tide Pool"
            onClick={levelUpAction}
          />
        );
    }
  };
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
