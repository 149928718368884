import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import appData from "components/appCall/CallingCharlesNye";
import UserContext from "context/User";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "intro",
  scientistSubTitles: [
    "Change Charles outro text to: Congrats on completing your first lesson! You are on your way to becoming a scientist or engineer, and maybe even joining our MBARI team one day."
  ],
  scientistData: appData,
  autoAnswer: true,
  doneAfterCall: true
};

const Outro = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Outro;
