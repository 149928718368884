import React, { useContext } from "react";
import UserContext from "context/User";
import DragAndMatchTest from "components/DragAndMatchTest";
import LessonTest from "custom/lab/DragAndDropTest";
import HOVImg from "img/test_hov.jpg";
import AUVImg from "img/test_auv.jpg";
import ROVImg from "img/test_rover.jpg";
import SONARImg from "img/test_sonar.jpg";
import TAGImg from "img/test_turtle.jpg";
import imgBackground from "img/chalkboard_empty.jpg";
import imgBackgroundSmall from "img/chalkboard_empty.jpg";

const objAnswerKey = {
  HOV: { text: "Human Operated Vehicle (HOV)", img_src: HOVImg },
  ROV: { text: "Remotely Operated Vehicle (ROV)", img_src: ROVImg },
  AUV: { text: "Autonomous Underwater Vehicle (AUV)", img_src: AUVImg },
  SONAR: {
    text: "Sound Navigation and Ranging (SONAR)",
    img_src: SONARImg
  },
  SAT_TRACK_TAG: { text: "Satellite Tracking Tag", img_src: TAGImg }
};

const objTest = new LessonTest(objAnswerKey);

const ChalkboardDnD = props => {
  const [objUser] = useContext(UserContext);
  objUser.level = props.lesson;
  objUser.objective = "Elkhorn Slough & Kayak Shack";

  return (
    <DragAndMatchTest
      {...props}
      parentClass={"chalkboard"}
      objTest={objTest}
      background={imgBackground}
      backgroundSmall={imgBackgroundSmall}
    />
  );
};

export default ChalkboardDnD;
