import { Machine, assign } from "xstate";
import React from "react";

export const LabMachineContext = React.createContext([{}, () => {}]);

const readingStates = {
  initial: "one",
  states: {
    one: {
      on: {
        FORWARD: {
          target: "two",
          actions: assign({ step: context => context.step + 1 })
        }
      }
    },
    two: {}
  }
};

export const mapMachine = Machine({
  id: "map",
  context: {
    step: 0
  },
  // initial: "welcome_explorer",
  initial: "reading_welcome_message",
  states: {
    welcome_explorer: {
      on: {
        "": {
          target: "reading_welcome_message.one",
          actions: (context, event) => console.log("I am done")
        }
      } //skip welcome_explorer - might one day be needed
    },
    reading_welcome_message: {
      on: { FORWARD: "explore_map" },
      ...readingStates
    },
    explore_map: {
      on: {
        CLICK: "explore_lab"
      }
    },
    explore_lab: {}
  }
});

const readingCallText = {
  initial: "one",
  states: {
    one: {
      on: {
        FORWARD: {
          target: "two",
          actions: assign({ callStep: context => context.callStep + 1 })
        }
      }
    },
    two: {
      on: {
        PRESS_BACK_SPACE: {
          target: "one",
          actions: assign({ callStep: context => context.callStep - 1 })
        }
      }
    }
  }
};

export const labMachine = Machine({
  id: "lab",
  context: {
    tabletClickable: true,
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: false,
    showTablet: false,
    callInProgress: false,
    callStep: 0,
    tabletApp: ""
  },
  initial: "labIntro",
  states: {
    labIntro: {
      on: {
        "": {
          target: "labFirstRing",
          actions: assign({
            tabletClickable: true,
            tabletRinging: true,
            showTablet: false,
            tabletOverlay: true,
            calling: "charles_nye"
          })
        }
      } //skip welcome_explorer - might one day be needed
    },
    labFirstRing: {
      on: {
        CLICK: {
          target: "labFirstCall",
          actions: assign({
            tabletClickable: false,
            tabletRinging: false,
            showTablet: true,
            calling: "charles_nye"
          })
        }
      }
    },
    labFirstCall: {
      on: {
        SLIDE: {
          target: "firstCall",
          actions: assign({ callInProgress: true, calling: "charles_nye" })
        }
      }
    },
    firstCall: {
      on: {
        FORWARD: {
          target: "labChalkboardFocus",
          actions: assign({
            showTablet: false,
            chalkboardGlows: true,
            chalkboardClickable: true,
            tabletOverlay: false
          })
        }
      },
      ...readingCallText
    },
    labChalkboardFocus: {
      on: {
        PRESS: {
          target: "secondCall",
          actions: assign({
            tabletClickable: false
          })
        }
      }
    },
    backToTheLab: {
      on: {
        "": {
          target: "labSecondCallComing",
          actions: assign({
            tabletClickable: true,
            tabletRinging: true,
            showTablet: false,
            calling: "kelly_benoit_bird",
            tabletOverlay: true
          })
        }
      } //skip welcome_explorer - might one day be needed
    },
    labSecondCallComing: {
      on: {
        CLICK: {
          target: "labSecondCall",
          actions: assign({
            tabletClickable: false,
            tabletRinging: false,
            showTablet: true,
            calling: "kelly_benoit_bird"
          })
        }
      }
    },
    labSecondCall: {
      on: {
        SLIDE: {
          target: "secondCall",
          actions: assign({
            callInProgress: true,
            calling: "kelly_benoit_bird"
          })
        }
      }
    },
    secondCall: {
      on: {
        FORWARD: {
          target: "watchVideo",
          actions: assign({})
        }
      }
    },
    testLabBeforeBlastQuiz: {
      on: {
        "": {
          target: "labBeforeBlastQuiz",
          actions: assign({
            showTablet: false,
            chalkboardGlows: false,
            chalkboardClickable: false,
            tabletClickable: true,
            tabletRinging: true,
            calling: "blast_quiz"
          })
        }
      }
    },
    watchVideo: {
      on: {
        CLICK: {
          target: "labBeforeBlastQuiz",
          actions: assign({
            showTablet: false,
            tabletClickable: true,
            tabletRinging: true,
            calling: "blast_quiz"
          })
        }
      }
    },
    labBeforeBlastQuiz: {
      on: {
        CLICK: {
          target: "appBlastQuiz",
          actions: assign({
            showTablet: true,
            tabletApp: "appBlastQuiz"
          })
        }
      }
    },
    appBlastQuiz: {
      on: {
        DONE: {
          target: "labBeforeNextStep",
          actions: assign({ showTablet: false, tabletApp: "" })
        }
      }
    },
    labBeforeNextStep: {
      type: "last"
    }
  },
  on: {
    PASSED_TEST_1: {
      target: "backToTheLab",
      actions: assign({
        tabletClickable: true
      })
    },

    TEST_BLAST_QUIZ: {
      target: "testLabBeforeBlastQuiz",
      actions: assign({
        tabletClickable: true,
        tabletOverlay: true
      })
    }
  }
});

// I can't get this to work.  using ...mapMachine, creates a weird nesting.  I assume
// that useMachine doesn't start the nested machines properly.
// using invoke works with timeer actions but not user actions.
//  Revisit this.
export const mbariMachine = Machine({
  context: {
    level: 1
  },
  initial: "welcomeMap",
  states: {
    welcomeMap: {
      ...mapMachine

      // invoke: {
      //   id: "mbari_map",
      //   src: mapMachine,
      //   onDone: {
      //     target: "mbariLab",
      //     actions: (context, event) => console.log("here")
      //   }
    },
    mbariLab: {
      invoke: {
        src: labMachine,
        onDone: { target: "welcomeMap" }
      }
    }
  }
});
export default mbariMachine;
