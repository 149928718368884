function shuffle(array) {
  //fisherYates
  var count = array.length,
    randomnumber,
    temp;
  while (count) {
    randomnumber = (Math.random() * count--) | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp;
  }
}

export default class multipleChoice {
  constructor(quizItems) {
    this._quizItems = quizItems;
    this._total = this._quizItems.length;

    for (var i = 0; i < this._total; i++) {
      const quizItem = this._quizItems[i];
      // console.log("quizItem:", quizItem);
      const choicesRandom = Object.keys(quizItem.choices);
      shuffle(choicesRandom);
      // console.log("choices:", quizItem.choices[1]);
      quizItem.choices_random = choicesRandom.map(x => ({
        id: [x],
        question: quizItem.choices[x],
        question_text: "quizItem.question"
      }));
      // console.log("quizItem.choices_random:", quizItem.choices_random);
    }
  }

  get Count() {
    console.log("total:", this._total);
    return this._total;
  }
  get QuizItems() {
    return this._quizItems;
  }

  Answers(itemNum) {
    return this._quizItems[itemNum].choices_random;
  }

  QuestionText(itemNum) {
    console.log("this._quizItems:", this._quizItems[itemNum]);
    return this._quizItems[itemNum].question;
  }

  checkAnswer(qnum, anum) {
    console.log(qnum, anum);
    if (!this._quizItems[qnum].multiSelect) {
      return this._quizItems[qnum].answer === anum;
    } else {
      console.log(anum, this._quizItems[qnum].answers);
      return anum in this._quizItems[qnum].answers;
    }
  }

  calculateScore(userAnswers) {
    const fnSimpleScoring = userAnswers => {
      let score = 0;
      console.log("userAnswers=", userAnswers);
      Object.keys(userAnswers).forEach(qnum => {
        let correct = 0;
        let answerKey = this._quizItems[qnum].answers;
        let answerKeyCount = answerKey.length;

        Object.keys(userAnswers[qnum]).forEach(userAnswer => {
          let userAnswerInt = parseInt(userAnswer);
          if (userAnswers[qnum][userAnswer] === true) {
            // The user may have checked a box and then un-checked it, which would have stored a false
            if (answerKey.includes(userAnswerInt)) correct += 1;
            else correct -= 1;
          }
        });
        if (correct === answerKeyCount) score += 1;
      });
      return (score / this._total) * 100;
    };

    return fnSimpleScoring(userAnswers); // May want to have other scoring mechanisms
  }
}
