import React, { useState, useContext } from "react";
import ExplorerBadge from "./ExplorerBadge";

import fountainPenIcon from "../img/fountain_icon.png";
import largeFountainPen from "../img/large_fountain_pen.png";

import greenCheckMark from "../img/clipboard_checkmark.png";
import whiteCheckMark from "../img/check_mark.png";
import { LargeTablet } from "./UIComponents";
import { NavLink } from "react-router-dom";
import { LabMachineContext } from "./mbariMachine";
import Image from "lqip-react";

const DragAndMatchTest = props => {
  const [state] = useState({
    testInProgress: true,
    incorrectCount: 0
  });
  const [userAnswers, setUserAnswers] = useState({});
  const [, labSend] = useContext(LabMachineContext);
  const { nextStep, objTest } = props;
  const objAnswers = props.horizontal
    ? objTest.answerKeys
    : objTest.randomAnswerKeys;

  // console.log("NextStep: ", nextStep);

  const Board = props => {
    const boardIsEmpty = () => {
      const boardQuestions = document.getElementsByClassName("board hidden");
      return boardQuestions.length === 0 ? true : false;
    };
    const returnCard = card => {
      console.log("props:", props);
      console.log("returnCard:", card);
      const selection =
        props.horizontal === true
          ? "#board-" + card.id
          : "#board-" + card.id + " > div.card_holder";
      const board = document.getElementById("board-" + card.id);
      const cardHome = document.querySelector(selection);
      const placeholder = document.getElementById(card.id + "placeholder");
      console.log("selection=", selection, "cardHome=", cardHome);
      cardHome.appendChild(card);
      board.removeAttribute("style");

      placeholder.remove();
      card.classList.remove("dropped");
      console.log("  cardHome:", cardHome);
      if (boardIsEmpty()) {
        board.classList.remove("hidden");
      } else {
        board.classList.add("hidden");
      }

      document.getElementById("keep_trying").style.display = "none";

      document.getElementById("test_screen").style.display = "block";
    };
    const drop = e => {
      const target = e.target;
      const targetCards = target.getElementsByClassName("card");

      e.preventDefault();
      e.target.classList.remove("can_drop_here");
      // const draggedCard = e.dataTransfer.getData("draggingCard");
      console.log("e.target: ", e.target);
      // const card = draggedCard;
      const card_id = e.dataTransfer.getData("card_id");
      // const card_num = e.dataTransfer.getData("card_num");
      // console.log("card_num=", card_num);
      const card = document.getElementById(card_id);
      const placeholderId = e.dataTransfer.getData("placeholder_id");
      const placeholder = document.getElementById(placeholderId);

      if (targetCards.length) {
        console.log("Target space is filled:", targetCards);
        objTest.removeAnswer(targetCards[0].id);
        returnCard(targetCards[0]);
      } else {
        console.log("Incrementing.");
        objTest.incrementTotal();
      }
      if (placeholder && props.horizontal) {
        placeholder.style.display = "block";
        placeholder.draggable = false;
      }
      card.style.display = "block";
      e.target.insertBefore(card, e.target.firstChild);
      card.classList.remove("dragging");
      card.classList.add("card");
      // hide cards that have already been played (unless this is horizontal mode)
      const origBoard = document.getElementById("board-" + card_id);
      if (props.horizontal !== true) origBoard.style.display = "none";

      if (objTest.checkAnswer(e.target, card_id)) {
        e.target.querySelector(".check_mark").classList.add("correct");
        userAnswers[objTest.posLookup(card_id)] = { [card_id]: true };
      } else {
        userAnswers[objTest.posLookup(card_id)] = { [card_id]: false };
        e.target.querySelector(".check_mark").classList.remove("correct");
      }
      setUserAnswers(userAnswers);
      if (objTest.keepTrying()) {
        console.log("Keep Trying.");
        const textMsg = document.getElementById("keep_trying_message");
        const falseCount = objTest.falseCount();
        textMsg.textContent = `You have ${falseCount} term${
          falseCount !== 1 ? "s" : ""
        } in
        the wrong answer${falseCount !== 1 ? "s" : ""}. Keep trying!`;
        document.getElementById("keep_trying").style.display = "block";
        document.getElementById("test_screen").style.display = "none";
      }
      console.log("perfectScore:", objTest.perfectScore());
      if (objTest.perfectScore()) {
        document.getElementById("test_complete").style.display = "inline";
        document.getElementById("test_screen").style.display = "none";
      }
      // Unhide the next test_card
      if (!card.classList.contains("dropped")) {
        const boardQuestions = document.getElementsByClassName("board hidden");
        if (boardQuestions.length) boardQuestions[0].classList.remove("hidden");
      }

      card.classList.add("dropped");
    };
    const dragOver = e => {
      // console.log("Drag Over, e:", e);
      e.preventDefault();
      e.target.classList.add("can_drop_here");
    };
    const dragLeave = e => {
      // console.log("Drag Leave, e:", e);
      e.preventDefault();
      e.target.classList.remove("can_drop_here");
    };
    return (
      <div
        id={props.id}
        className={props.className}
        onDrop={drop}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
      >
        {props.children}
      </div>
    );
  };

  const Card = props => {
    const dragStart = e => {
      const origCard = e.target;
      // console.log("Drag Start:", origCard.className);
      // console.log(origCard);
      // console.log("Parent is:", origCard.parentNode.id);
      const parentNode = origCard.parentNode;
      e.dataTransfer.setData("card_id", origCard.id);
      // e.dataTransfer.setData("card_num", props.num);
      // alert(target.id);
      const parent = origCard.parentElement;
      // console.log("parent: ", parent);
      if (!parent.classList.contains("answerblank")) {
        const placeHolderCard = origCard.cloneNode(true);
        placeHolderCard.id = placeHolderCard.id + "placeholder";
        placeHolderCard.draggable = false;
        const oldPH = document.getElementById(placeHolderCard.id);
        if (oldPH) {
          oldPH.remove();
        }
        e.dataTransfer.setData("placeholder_id", placeHolderCard.id);
        parentNode.prepend(placeHolderCard);
        placeHolderCard.style.display = "none";
      } else {
        const checkmark = document.getElementById(origCard.id + "checkmark");
        if (checkmark) {
          checkmark.classList.remove("correct");
        }
      }

      // console.log("Drag Start, class:", origCard.className);
      // setDragging(true);
      origCard.classList.add("dragging");
      origCard.classList.remove("card");
      // console.log("Drag Start, adding 'dragging':", origCard);

      // origCard.id = origCard.id + "_old";

      // console.log("oldCard:", oldCard);
      // console.log("card:", placeHolderCard);
      // console.log("Drop:", card_id);
      // setTimeout(() => {
      //   target.style.display = "hidden";
      // }, 0);
    };
    const dragEnd = e => {
      const target = e.target;
      // console.log("Drag End");
      //e.dataTransfer.setData("card_id", target.id);
      // alert(target.id);
      e.target.classList.add("card");
      e.target.classList.remove("dragging");
      // setDragging(false);
      setTimeout(() => {
        target.style.display = "normal";
      }, 0);
    };

    const dragOver = e => {
      e.stopPropagation();
    };

    return (
      <div
        id={props.id}
        // className={isDragging ? "dragging" : props.className}
        className={props.className}
        draggable={props.draggable}
        onDragStart={dragStart}
        onDragOver={dragOver}
        onDragEnd={dragEnd}
        // onDrag={drag}
      >
        {props.children}
      </div>
    );
  };
  const BackgroundImage = props => {
    return (
      <Image
        src={props.background}
        thumbnail={props.backgroundSmall}
        color="#132962"
        aspectRatio={"1920x1080"}
        alt="Answer blanks and questions"
        blur={0}
      />
    );
  };

  const TestQuestion = props => {
    const board_id = "board-" + props.answerKey;
    const text = objTest.Answers[props.answerKey].text;
    const image = objTest.Answers[props.answerKey].img_src;
    const boardClasses =
      "test_question board" +
      (props.horizontal ? "" : props.index > 0 ? " hidden" : "");

    if (props.horizontal) {
      return (
        <Board id={board_id} className={boardClasses} {...props}>
          <Card
            id={props.answerKey}
            num={props.num}
            className="card"
            draggable="true"
          >
            {text}
          </Card>
        </Board>
      );
    } else
      return (
        <Board id={board_id} className={boardClasses} {...props}>
          <img alt={text} src={image} draggable={false} />
          <div className="card_holder">
            <Card
              id={props.answerKey}
              num={props.num}
              className="card"
              draggable="true"
            >
              {text}
            </Card>
          </div>
        </Board>
      );
  };

  // const Plural = props => {
  //   return (
  //     <>
  //       {props.children}
  //       {props.wrongAnswers === "1" ? "" : "s"}
  //     </>
  //   );
  // };

  const CardsToPlay = props => {
    return (
      <>
        <p>
          Drag the matching terms onto the answer sheet . <b>Good luck!</b>
        </p>
        {props.horizontal ? (
          // <Board className="test_question board">
          <img
            alt="Test Guide"
            src={props.guideImageSrc}
            className="guide_image"
          />
        ) : (
          // </Board>
          ""
        )}
        <div className="quiz_cards">{props.testQuestions}</div>
      </>
    );
  };
  const TestScreen = props => {
    const testQuestions = objAnswers.map((answerKey, index) => (
      <TestQuestion
        key={answerKey}
        answerKey={answerKey}
        index={index}
        num={index}
        {...props}
      />
    ));

    return (
      <div id="test_screen">
        <h1>
          Drag &amp; Match
          <img alt="Fountain Pen Icon" src={fountainPenIcon} />
        </h1>
        <CardsToPlay {...props} testQuestions={testQuestions} />
      </div>
    );
  };
  const WellDoneScreen = props => {
    return (
      <div
        className="test_message"
        id="test_complete"
        style={{ display: "none" }}
      >
        <img alt="Large Fountain Pen" src={largeFountainPen} />
        <h1>Well Done!</h1>
        <p>
          You've performed great. These tools will come in handy right away.
        </p>
        <p> Now you're ready to proceed!</p>
        <NavLink
          className="btn-push"
          to={nextStep}
          onClick={() => labSend("PASSED_TEST_1")}
        >
          Next
        </NavLink>
      </div>
    );
  };
  // const KeepGoing = props => {
  //   return (
  //     <div id="keep_trying" style={{ display: "none" }}>
  //       <h2>Almost there!</h2>
  //       <p id="keep_trying_message">You have wrong answers. Keep trying!</p>
  //     </div>
  //   );
  // };
  const KeepTrying = props => {
    return (
      <div
        className="test_message"
        id="keep_trying"
        style={{ display: "none" }}
      >
        <img alt="Large Fountain Pen" src={largeFountainPen} />
        <h1>Almost there!</h1>
        <p id="keep_trying_message">You have wrong blanks. Keep trying!</p>
      </div>
    );
  };

  const TestTablet = props => {
    return (
      <div className="test_tablet_container">
        <WellDoneScreen />
        <KeepTrying />
        <TestScreen {...props} />
      </div>
    );
  };

  const AnswerBoard = props => {
    console.log("TestAnswers:", props.testAnswers);
    console.log("UserAnswers:", userAnswers);
    const checkMark = props.check === "green" ? greenCheckMark : whiteCheckMark;
    const AnswerText = props => {
      const CheckMark = props => {
        return (
          <img
            alt="Check Mark"
            className={props.className}
            src={checkMark}
            id={props.id}
          />
        );
      };
      if (state.complete && userAnswers) {
        const uaDict = userAnswers[props.index];
        const uaAbbr = Object.keys(uaDict)[0];
        const uaCorrect = uaDict[uaAbbr];
        const imgClass = "check_mark" + (uaCorrect ? " correct" : "");

        return (
          <>
            <div id={props.answerAbbr} className="card" draggable="true">
              {objTest.AnswerText(uaAbbr)}
            </div>

            <CheckMark
              className={imgClass}
              id={props.answerAbbr + "checkmark"}
            />
          </>
        );
      } else {
        return (
          <CheckMark
            className="check_mark"
            id={props.answerAbbr + "checkmark"}
          />
        );
      }
    };
    return (
      <div className="answer_sheet">
        {props.testAnswers.map((answerAbbr, index) => {
          const board_id = "answer-blank-" + answerAbbr;
          return (
            <Board
              key={answerAbbr}
              id={board_id}
              className={"board answerblank"}
              {...props}
            >
              <AnswerText index={index} answerAbbr={answerAbbr} />
            </Board>
          );
        })}
      </div>
    );
  };

  const answerOverlay = props.overlay ? (
    <div className="overlay">
      <img src={props.overlay} alt="answers" />
    </div>
  ) : null;
  return (
    <div
      className={
        "test_container" + (props.parentClass ? " " + props.parentClass : "")
      }
    >
      <BackgroundImage {...props} />
      {answerOverlay}
      <AnswerBoard testAnswers={objTest.AnswerAbbrs} {...props} />

      <ExplorerBadge objective="Explore MBARI" {...props} />
      <LargeTablet showTablet={true}>
        <TestTablet objAnswers={objAnswers} {...props} />
      </LargeTablet>
    </div>
  );
};

export default DragAndMatchTest;
