import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import UserContext from "context/User.js";
import appData from "components/appCall/CallingKellyBenoitBird";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "lesson",
  scientistSubTitles: [
    "Hi FIRSTNAME, I am SCIENTISTNAME, a SCIENTISTTITLE here at MBARI. Have you ever wished you could dive deep down in the ocean to discover mysterious creatures?  Unfortunately, the deep ocean has extreme pressure, near-freezing temperatures, and blinding darkness.  Here at the MBARI Lab,",
    "we use many tools to help us study the deep ocean environment.  These tools can help us study places deeper than where we can dive. They can also help us learn more about life in the ocean.  I hope you will get to use some of these tools soon.",
    "Have you ever used a drone?  If you have, you will probably be good at using some of our vehicles.  We use Autonomous Underwater Vehicles (AUVs) we program to meet us at specific locations. They can take readings of large areas and measure physical ",
    "characteristics underwater.  We also have Remotely Operated Vehicles (ROVs) that are controlled through a cable.  MBARI’s ROVs—Ventana and Doc Ricketts— often carry several tools. For example, they have a suction sampler, which is kind of like a “slurp gun,”",
    "that sucks gelatinous animals from the midwater into a plastic canister so that it can be brought to shore and studied more closely.  Ocean researchers also use Human Occupied Vehicles (HOVs) that are piloted by marine scientists.  Deep Sea Submergence Vehicles ",
    "(DSVs) are one type of HOV and are designed to dive into the deepest parts of the ocean.  HOVs that dive this deep are only used for very special missions. Even when we are in the safety of a DSV, we have to be careful because the pressure in the deep water is ",
    "enough to kill a human.  Why risk going down that deep? Because there are many interesting organisms to study living deep in the ocean.  They are adapted to the extreme dark, cold, and high pressure environment. Sometimes, they need to be studied more closely ",
    "than is possible with the AUVs or ROVs.  Another tool used by many scientists, including those at MBARI, comes from the sky instead of the ocean.  Satellites are used in conjunction with tracking tags to follow the movement of animals.  Tracking tags are devices ",
    "placed on animals that allow us to learn about their natural behavior.  Scientists use this method because animals often change their behavior when they are around people.  For example, we have used these tags at MBARI to track white sharks.  We have learned many ",
    "new details about their behavior and travels because of the tags.  Have you ever opened your eyes underwater?  It is difficult to see through water because it is denser than air.  We use Sound Navigation and Ranging (SONAR) to “see” the ocean floor.  SONAR uses ",
    "sound waves echoing through the water to create images of the ocean floor.  At MBARI, we discover new ways to use tools and engineer tools to help us learn more about Monterey Bay.  Using tools to explore the ocean is one of my favorite parts of working here at MBARI."
  ],
  scientistData: appData,
  videoAfterCall: true,
  lesson: true,
  videoTitle: "Welcome to MBARI",
  videoIntroduction:
    "In this lesson, you will learn the basics of aquarium research and tools we are using here at MBARI.",
  videoCaption:
    "Have you ever used a drone? If you have, you will probably be good at using some of our vehicles...",
  videoId: "jwt_JU3lhSo"
};

const Lesson = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
  // const [objUser] = useContext(UserContext);
  // if ("user" in objUser) labMachine.context["firstName"] = objUser.user.first;
  // objVideoCallInfo.text = labMachine.context["scientistSubTitles"]; // I want to migrate to having labMachine store this data.

  // // console.log(objVideoCallInfo.text);
  // const [current, labSend] = useMachine(labMachine);
  // // console.log("Current state:" + current.value);
  // if (current.value === "done") {
  //   return <Redirect to={props.nextStep} />;
  // }
  // return (
  //   <Lab
  //     objVideoCallInfo={objVideoCallInfo}
  //     objState={current}
  //     labSend={labSend}
  //     nextStep={props.nextStep}
  //     videoTitle="Welcome to MBARI"
  //     videoIntroduction="In this lesson, you will learn the basics of aquarium research and tools we are using here at MBARI."
  //     videoCaption="Have you ever used a drone? If you have, you will probably be good at using some of our vehicles..."
  //     onEnd={() => labSend("VIDEODONE")}
  //     mSend={labSend}
  //     noButton={true}
  //     {...props}
  //   />
  // );
};

export default Lesson;
