import React from "react";
// import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import largeTabletFrame from "../img/large_tablet_frame.png";

export const Tablet = props => {
  const FrameDiv = styled.div`
    /* box-sizing: border-box; */
    position: absolute;
    width: calc(950 / 1920 * 91%);
    right: 0px;
    top: 0px;
    /* border: 1px solid red; */
  `;

  const FrameImg = styled.img`
    width: 100%;
  `;

  const AppDiv = styled.div`
    position: absolute;
    right: 8%;
    top: 12%;
    width: 81.5%;
    height: 75.5%;
    text-align: center;
    /* border:1px solid red; */
}
    /* border: 1px solid hotpink; */
  `;

  return (
    <FrameDiv>
      <FrameImg src={largeTabletFrame} />
      <AppDiv>{props.children}</AppDiv>
    </FrameDiv>
  );
};

export const AppBGImg = styled.img`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
`;

export const TabletButton = styled.button`
  padding: 0.5% 2%;
  background-color: #ffd702;
  font-size: 20px;
  color: #052c94;
  font-weight: bold;
  &:hover {
    color: white;
  }
  font-size: 1vw;
  font-family: Montserrat;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
`;

export const AppButton = props => {
  // console.log("but now, nextStep:", props.nextStep);
  if (props.nextStep) {
    return (
      <NavLink to={props.nextStep}>
        <TabletButton {...props} />
      </NavLink>
    );
  } else {
    return <TabletButton {...props} />;
  }
};

export const ButtonCenter = styled.div`
  width: 100%;
  text-align: center;
`;
