import React, { useEffect, useState, useContext } from "react";
import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import testTubeIcon from "img/test_tube_icon.png";
// import droneVideo from "img/drone_video_example.png";
// import playIcon from "img/play_icon.png";
import tabletBG from "img/tablet_background.jpg";
import cropRings from "img/crop_rings.svg";
import questImg from "img/quest_ocean_discovery.svg";
import btnQuestApp from "img/quest_app_button.png";
import btnVideoChat from "img/video_chat_button.png";
import btnNotes from "img/notes_button.png";
import btnAskScientist from "img/ask_scientist_button.svg";
import imgNotesIntro1 from "img/notes_intro_img_1.png";
import imgNotesIntro2 from "img/notes_intro_img_2.png";
import imgFlag from "img/elkDnD/flag.svg";
import iconIntroFolder from "img/notes_intro_folder.png";
import imgMbariNotesFolder from "img/notes_auv_folder.png";
import newLevelPhotoBG from "img/new_level_photo_bg.png";
import awardTabletBG from "img/outro_award_bg.jpg";
import questionIcon from "img/question_mark_icon.png";
import ContentEditable from "react-contenteditable";
import { ButtonCenter, AppButton } from "components/Tablet";
import UserContext from "context/User";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import { OImg, QuizIntroWrapper, Join } from "components/UIComponents";

const SlideButton = props => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={() => setTimeout(() => props.mSend("SLIDE"), 400)}
      />
      <span className="new_slider round"></span>
      <h1>Slide to Answer</h1>
    </label>
    // {/* <Slider {...props} /> */}
    // </div>
  );
};
export const IconInstruction = props => {
  const buttonLabel = props.buttonText ? props.buttonText : "Go!";
  const buttonAction = props.buttonAction ? props.buttonAction : "GO";
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="icon_instructions">
        <div
          className={Join(["icon_holder", props.iconClass], " ")}
          style={props.iconStyle}
        >
          <img alt={props.alt || "Kayak"} src={props.icon || questionIcon} />
        </div>
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        <div className="button_holder">
          <button
            className="app_button"
            onClick={() => props.mSend(buttonAction)}
            disabled={props.buttonDisabled}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
const VideoScreen = props => {
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: !("autoplay" in props) ? 1 : props.autoplay ? 1 : 0,
      start: props.start,
      end: props.end,
      fs: 0,
      modestbranding: 1
    }
  };
  const videoId = props.videoId ? props.videoId : "jwt_JU3lhSo";
  const title = props.videoTitle ? props.videoTitle : "Welcome to MBARI";
  const introduction = props.videoIntroduction
    ? props.videoIntroduction
    : "In this lesson, you will learn the basics of aquarium research and tools we are using here at MBARI.";
  const videoCaption = props.videoCaption
    ? props.videoCaption
    : "Have you ever used a drone? If you have, you will probably be good at using some of our vehicles...";
  const buttonLabel = props.buttonLabel ? props.buttonLabel : "Got it";
  const buttonAction = props.buttonAction ? props.buttonAction : "CLICK";
  const className = props.className ? props.className : "tablet_video";
  const onEnd = props.onEnd ? props.onEnd : null;
  const finishButton = props.noButton ? null : (
    <ButtonCenter>
      <AppButton {...{ onClick: () => props.mSend(buttonAction) }}>
        {buttonLabel}
      </AppButton>
    </ButtonCenter>
  );
  return (
    <>
      <h1>
        {title}
        <img alt="Test Tube" src={testTubeIcon} />
      </h1>

      <p>{introduction}</p>
      <div className="video_screen">
        {/* <img alt="Underwater Drone" src={droneVideo} /> */}
        <YouTube
          videoId={videoId} // defaults -> null
          // id={string} // defaults -> null
          className={className} // defaults -> null
          // containerClassName={string} // defaults -> ''
          opts={opts} // defaults -> {}
          // onReady={func} // defaults -> noop
          // onPlay={func} // defaults -> noop
          // onPause={func} // defaults -> noop
          onEnd={onEnd} // defaults -> noop
          // onError={func} // defaults -> noop
          // onStateChange={func} // defaults -> noop
          // onPlaybackRateChange={func} // defaults -> noop
          // onPlaybackQualityChange={func} // defaults -> noop
        />
      </div>
      <p>{videoCaption}</p>
      {finishButton}
    </>
  );
};
export const ShowVideo = props => {
  const classes = "video_tablet_container";
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className={classes}>
        <VideoScreen {...props} />
      </div>
    </>
  );
};

export const LessonPage = props => {
  const classes = "video_tablet_container";
  const ShowVideo = props => {
    if (props.videoId) {
      if (props.videoHost === "Vimeo") {
        const vimeoCode = (
          <Vimeo
            video={props.videoId}
            autoplay={props.autoplay ? true : false}
            controls={true}
            onEnd={() => props.mSend("VIDEODONE")}
          />
        );
        console.log("VIDEO:", vimeoCode);
        return vimeoCode;
      } else {
        const opts = {
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: props.autoplay ? 1 : 0,
            start: props.start,
            end: props.end,
            fs: 0,
            modestbranding: 1
          }
        };

        return (
          <YouTube
            videoId={props.videoId} // defaults -> null
            // id={string} // defaults -> null
            className={props.className} // defaults -> null
            // containerClassName={string} // defaults -> ''
            opts={opts} // defaults -> {}
            // onReady={func} // defaults -> noop
            // onPlay={func} // defaults -> noop
            // onPause={func} // defaults -> noop
            onEnd={() => props.mSend("VIDEODONE")}
            // onError={func} // defaults -> noop
            // onStateChange={func} // defaults -> noop
            // onPlaybackRateChange={func} // defaults -> noop
            // onPlaybackQualityChange={func} // defaults -> noop
          />
        );
      }
    } else return <img alt={props.videoStillAlt} src={props.videoStill} />;
  };

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className={classes}>
        <h1>
          {props.title}
          <img alt="Test Tube" src={testTubeIcon} />
        </h1>

        <p>{props.introduction}</p>
        <div className="video_screen">
          <ShowVideo {...props} />
        </div>
        <p>{props.videoCaption}</p>
        {/* <ButtonCenter>
          <AppButton {...{ onClick: () => props.mSend(props.buttonAction) }}>
            {props.buttonText}
          </AppButton>
        </ButtonCenter> */}
        <ButtonCenter>
          <button
            className="app_button"
            onClick={() => props.mSend(props.buttonAction)}
            disabled={props.buttonDisabled}
            style={{ margin: "0 auto" }}
          >
            {props.buttonText}
          </button>
        </ButtonCenter>
      </div>
      {/* <div className="play_icon">
        <img alt="play" src={playIcon} />
      </div>
      <div className="play_button" /> */}
    </>
  );
};

const OneSample = props => {
  console.log("one sample: ", props);

  return (
    <div className="wt_sample">
      <div className="wt_marker_container">
        <div
          className="wt_sample_marker"
          style={{
            backgroundColor: props.sample.color,
            borderColor: props.sample.color
          }}
        />
        <div className="wt_marker_label">{props.sample.marker}</div>
      </div>

      <div
        className={"wt_sample_text" + (props.delayResults ? " delay_show" : "")}
        id={props.id}
        style={props.delayResults ? { opacity: "0" } : null}
      >
        {props.sample.name}
      </div>
    </div>
  );
};

export const TestingPage = props => {
  const [animateDelay, setAnimateDelay] = useState(true);
  const arrSamples =
    props.samples && props.samples.length > 0
      ? props.samples
      : [{ color: "blank", marker: "?", name: "No samples found" }];
  console.log("props.pageName=", props.pageName);

  const { mSend, delayResults } = props;

  if (animateDelay) {
    const sampleCount = Object.keys(arrSamples).length;
    if (props.delayResults) {
      console.log(sampleCount * delayResults);
      for (let i = 0; i < sampleCount; i++) {
        setTimeout(() => {
          document.getElementById("sample_" + i).style.opacity = 1;
        }, i * delayResults + delayResults);
      }
      setTimeout(() => {
        console.log("Transfer is now allowed");
        mSend("ALLOWTRANSFER");
      }, sampleCount * delayResults);
      setAnimateDelay(false);
    }
  }

  //   return () => {};
  // }, [arrSamples, delayResults, mSend]);
  const ordinals = ["First", "Second", "Third", "Fourth", "Fifth"];
  Object.keys(arrSamples).forEach((sampleKey, count) => {
    if (props.showData) {
      arrSamples[sampleKey].name = arrSamples[sampleKey].data + " cfu / 100mL";
    } else {
      console.log("props=", props);
      if (props.samples.length !== 0)
        arrSamples[sampleKey].name =
          ordinals[arrSamples[sampleKey].index] + " sample loaded";
    }
  });

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className="video_tablet_container">
        <h1>
          {props.title}
          <img alt="Test Tube" src={testTubeIcon} />
        </h1>
        <div className="wt_sample_list">
          {Object.keys(arrSamples).map((sampleKey, id) => (
            <OneSample
              key={id}
              id={"sample_" + id}
              index={id}
              sample={arrSamples[sampleKey]}
              {...props}
            />
          ))}
          <h4>{props.instructions}</h4>
        </div>

        <ButtonCenter>
          <button
            className="app_button"
            {...{ onClick: () => props.mSend(props.buttonAction) }}
            disabled={props.buttonDisabled}
            style={{ margin: "0 auto" }}
          >
            {props.buttonText}
          </button>
        </ButtonCenter>
      </div>
    </>
  );
};
export const BlankScreen = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
    </div>
  );
};

export const ManualInitiateCall = props => {
  // const ringRef = useRef();
  // const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  // useEffect(() => {
  //   ringTimeLine
  //     .from(ringRef.current, 0.1, { opacity: 1 })
  //     .to(ringRef.current, 0.1, {
  //       opacity: 0
  //     })
  //     .yoyo(true)
  //     .repeat(5);
  // });
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="incall">
        <h1>Now Calling</h1>
        <div className="calling_img_crop">
          <img
            onClick={() => props.mSend("CLICK")}
            alt="Large Tablet - Call Scientist"
            src={props.current.context.scientistData.callImg}
          />
        </div>
        <div className="as_crop_circles ringing">
          <img alt="Call coming in!" src={cropRings} />
        </div>

        <div className="as_text_box">
          <h1>{props.current.context.scientistData.scientistName}</h1>
          <h2>({props.current.context.scientistData.scientistTitle})</h2>
        </div>
        <SlideButton text="Slide to Pick Up" mSend={props.mSend} />
      </div>
    </div>
  );
};
export const NewManualInitiateCall = props => {
  // const ringRef = useRef();
  // const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  // useEffect(() => {
  //   // console.log("INITIALIZING!");
  //   // const ringTimeLine = new TimelineLite({ paused: false });
  //   ringTimeLine
  //     .from(ringRef.current, 0.1, { opacity: 1 })
  //     .to(ringRef.current, 0.1, {
  //       opacity: 0
  //     })
  //     .yoyo(true)
  //     .repeat(5);
  //   // ringTimeLine.restart();
  // });
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
        <div className="incall">
          <h1>Now Calling</h1>
          <div className="calling_img_crop">
            <img
              onClick={() => props.mSend("CLICK")}
              alt="Large Tablet - Call Scientist"
              src={props.current.context.scientistData.callImg}
            />
          </div>
          <div className="as_crop_circles">
            <img alt="Call coming in!" src={cropRings} />
          </div>

          <div className="as_text_box">
            <h1>{props.current.context.scientistData.scientistName}</h1>
            <h2>({props.current.context.scientistData.scientistTitle})</h2>
          </div>
          <SlideButton text="Slide to Pick Up" mSend={props.mSend} />
        </div>
      </div>
    </>
  );
};
export const AutoInitiateCall = props => {
  // const ringRef = useRef();
  // const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  // useEffect(() => {
  //   console.log("INITIALIZING!");
  //   // const ringTimeLine = new TimelineLite({ paused: false });
  //   ringTimeLine
  //     .from(ringRef.current, 0.1, { opacity: 1 })
  //     .to(ringRef.current, 0.1, {
  //       opacity: 0
  //     })
  //     .yoyo(true)
  //     .repeat(5);
  //   // ringTimeLine.restart();
  // });
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />

        <div className="incall">
          <div className="calling_img_crop">
            <img
              onClick={() => props.mSend("CLICK")}
              alt="Large Tablet - Call Scientist"
              src={props.callImg}
            />
          </div>
          <div className="as_crop_circles ringing">
            <img alt="Call coming in!" src={cropRings} />
          </div>

          <div className="as_text_box">
            <h1>{props.current.context.scientistData.scientistName}</h1>
            <h2>({props.current.context.scientistData.scientistTitle})</h2>
            <SlideButton text="Slide to Pick Up" />
          </div>
        </div>
      </div>
    </>
  );
};

export const CallWSubTitles = props => {
  const { mSend, videoImg, current, callStep } = props;
  const [objUser] = useContext(UserContext);
  console.log("objUser:", objUser);
  const handleUserKeyPress = event => {
    const { keyCode } = event;

    if (keyCode === 32 && event.target === document.body) {
      mSend("FORWARD");
    }
    if (keyCode === 8 && event.target === document.body) {
      mSend("BACK");
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });
  const objReplacements = {};
  if ("user" in objUser && objUser.user.first)
    objReplacements["FIRSTNAME"] = objUser.user.first;
  if (current.context.scientistData.scientistName)
    objReplacements["SCIENTISTNAME"] =
      current.context.scientistData.scientistName;
  if (current.context.scientistData.scientistTitleShort)
    objReplacements["SCIENTISTTITLE"] =
      current.context.scientistData.scientistTitleShort;
  // console.log(objReplacements);
  const re = new RegExp(Object.keys(objReplacements).join("|"), "g");
  // console.log(re);
  const scientistSubTitles =
    current.context.callCount && current.context.callCount > 0
      ? current.context.scientistSubTitles2
      : current.context.scientistSubTitles;
  const thisSubTitle = scientistSubTitles[callStep].replace(re, function(
    matched
  ) {
    return objReplacements[matched];
  });

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
        <div className="incall">
          <div className="as_video">
            <img
              src={videoImg}
              alt={current.context.scientistData.scientistName}
            />
          </div>
          <div className="incall_text_box">
            <div className="as_video_overlay_text">
              <h1>{current.context.scientistData.scientistName}</h1>
              <h2>({current.context.scientistData.scientistTitle})</h2>
            </div>
            <div className="as_text">
              <p>{thisSubTitle}</p>
              <div className="incall_navigation">
                <FaArrowCircleLeft
                  className={callStep === 0 ? "noclick" : null}
                  onClick={callStep !== 0 ? () => mSend("BACK") : null}
                />
                <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const NewCallWSubTitles = props => {
  const { mSend, videoImg, current, callStep } = props;
  const [objUser] = useContext(UserContext);
  const handleUserKeyPress = event => {
    const { keyCode } = event;

    if (keyCode === 32 && event.target === document.body) {
      mSend("FORWARD");
    }
    if (keyCode === 8 && event.target === document.body) {
      mSend("BACK");
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });
  const objReplacements = {};
  console.log("objUser.user:", objUser);
  if (objUser.user.first) objReplacements["FIRSTNAME"] = objUser.user.first;
  if (current.context.scientistData.scientistName)
    objReplacements["SCIENTISTNAME"] =
      current.context.scientistData.scientistName;
  if (current.context.scientistData.scientistTitleShort)
    objReplacements["SCIENTISTTITLE"] =
      current.context.scientistData.scientistTitleShort;
  console.log(objReplacements);
  const re = new RegExp(Object.keys(objReplacements).join("|"), "g");
  console.log(re);
  const thisSubTitle = current.context.scientistSubTitles[callStep].replace(
    re,
    function(matched) {
      return objReplacements[matched];
    }
  );

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />

        <div className="incall">
          <div className="as_video">
            <img
              src={videoImg}
              alt={current.context.scientistData.scientistName}
            />
          </div>
          <div className="incall_text_box">
            <div className="as_video_overlay_text">
              <h1>{current.context.scientistData.scientistName}</h1>
              <h2>({current.context.scientistData.scientistTitle})</h2>
            </div>
            <div className="as_text">
              <p>{thisSubTitle}</p>
              <div className="incall_navigation">
                <FaArrowCircleLeft
                  className={callStep === 0 ? "noclick" : null}
                  onClick={callStep !== 0 ? () => mSend("BACK") : null}
                />
                <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AppBGScreen = props => {
  // console.log(props);
  return (
    <div className="nas-screen" style={props.style}>
      <div className="padding">{props.children}</div>
    </div>
  );
};

export const AppScreen = props => {
  const { mSend } = props;
  const arrButtons = [
    { srcImg: btnQuestApp, label: "QuestApp" },
    { srcImg: btnVideoChat, label: "VideoChat" },
    {
      srcImg: btnNotes,
      label: "Notes",
      clickable: props.focus === "Notes",
      onClick: () => {
        mSend("UPDATE_USER", {
          userID: props.userID,
          api: props.api
        });
        mSend(props.startWithIntro ? "INTRO" : "NOTES");
      }
    },
    {
      srcImg: btnAskScientist,
      label: "AskScientist",
      clickable: props.focus === "AskScientist",
      onClick: () => mSend("ASK")
    }
  ];
  // console.log("USER, props.userID:", props.userID);
  return (
    <AppBGScreen>
      <div className="nas-logo">
        <img src={questImg} alt="Quest" />
      </div>
      <div className="nas-app-buttons">
        {arrButtons.map(({ srcImg, label, clickable, onClick }, id) => (
          <div
            key={id}
            className={"nas-app-button" + (clickable ? " nclickable" : "")}
            onClick={onClick ? onClick : null}
          >
            <img src={srcImg} alt={label} />
            <h4>{label}</h4>
          </div>
        ))}
      </div>
    </AppBGScreen>
  );
};

const NavBar = props => {
  const { mSend, current } = props;
  const { backClickable, parentDir, currentFile } = current.context;
  return (
    <div className="n_navbar">
      <div
        className={"back" + (backClickable === false ? " n_no_click" : "")}
        onClick={backClickable ? () => mSend("BACK") : null}
      >
        &lt; Back
      </div>
      <div className="breadcrumb">
        {parentDir && (
          <>
            <div className="root" onClick={() => mSend("BACK")}>
              {parentDir}
            </div>
            &gt;
          </>
        )}
        <div className="current">{currentFile}</div>
      </div>
    </div>
  );
};

export const Intro = props => {
  const { mSend, current } = props;
  const { page, backArrowClickable, forwardArrowClickable } = current.context;

  return (
    <AppBGScreen>
      <NavBar {...props} />
      <div className="n_info">
        <h1>Introduction</h1>
        <h2>Hello, fellow explorer...</h2>
        <p>Welcome to your Science and Engineering Notebook!</p>
        <p>
          Famous scientists like Edison, Galileo and Einstein have used
          notebooks to record their ideas, which changed our world. It's
          important for you, too, to write down what you discover and learn
          about science!
        </p>
        <figure>
          <img
            src={imgNotesIntro1}
            className="first_img"
            alt="Galileo Galileis Sunspot Drawings"
          />
          <figcaption>Galileo Galileis Sunspot Drawings</figcaption>
        </figure>
        <figure>
          <img
            src={imgNotesIntro2}
            className="second_img"
            alt="Albert Enstein Notebook"
          />
          <figcaption>Albert Enstein Notebook (below)</figcaption>
        </figure>
        <div className="n_bottom_nav">
          <div
            className={
              "n_back_arrow" +
              (backArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() => mSend("BACKARROW")}
          >
            &lt;
          </div>
          {page}
          <div
            className={
              "n_forward_arrow" +
              (forwardArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() =>
              mSend("NOTES", {
                userID: props.userID,
                api: props.api
              })
            }
          >
            &gt;
          </div>
        </div>
      </div>
    </AppBGScreen>
  );
};

const initialNotes = {
  MBARI:
    "Despite practicing navigating the AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed!",
  ELKHORN: ""
};

const objQuestions = {
  MBARI: [
    {
      id: 1,
      text: "Which tool is your favorite and why?",
      image: imgMbariNotesFolder,
      alt: "MBARI Building"
    },
    { id: 2, text: "What did you learn about coding?" },
    {
      id: 3,
      text:
        "What adjustments did you make to your program to successfully collect the targets?"
    }
  ],
  ELKHORN: [
    {
      id: 1,
      text:
        "Which was your favorite organism along your kayaking journey and why?",
      image: true,
      alt: "Click here to upload your favorite photo from your journey.",
      subTitle: "Click here to upload your favorite photo from your journey."
    },
    {
      id: 2,
      text: "Do you think estuaries are important? Prove your point."
    },
    {
      id: 3,
      text: "Do you have any ideas of how we can help conserve our estuaries?"
    }
  ],
  TIDEPOOLS: [
    {
      id: 1,
      text:
        "Which was your favorite organism from your exploration of the tide pools?",
      image: true,
      alt: "Click here to upload your favorite photo from your exploration.",
      subTitle:
        "Click here to upload your favorite photo from your exploration."
    },
    {
      id: 2,
      text:
        "Choose a picture that will help you remember your visit to the tide pools."
    },
    {
      id: 3,
      text: "Why is it important to monitor water quality?"
    }
  ]
};

export const Reflection = props => {
  const { mSend, current } = props;
  const notesID = props.notesID || "MBARI";
  const [state, setState] = useState([initialNotes[notesID], "", ""]);

  const handleInput = (event, id) => {
    console.log("state=", state);
    console.log("id=", id);

    console.log("event=", event);
    let newState = [...state];
    newState[id - 1] = event.target.value;
    setState(newState);
  };

  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_reflect">
        <div className="n_file_info">
          <div>Draft Saved</div>
          <div>17:05</div>
        </div>
        <h1>MBARI Reflection</h1>
        <div className="n_questions">
          {objQuestions[notesID].map((question, id) => {
            return (
              <div key={id}>
                <div>
                  {question.id}. {question.text}
                  {question.image && (
                    <figure className="notes_figure">
                      <img
                        src={
                          current.context.savedImage
                            ? current.context.savedImage
                            : question.image
                        }
                        alt={question.alt}
                        className="inset_clickable"
                        onClick={() => mSend("IMAGECHOOSE")}
                      />
                      <figcaption>{question.subTitle}</figcaption>
                    </figure>
                  )}
                </div>
                <ContentEditable
                  html={state[question.id - 1]} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  onChange={e => handleInput(e, question.id)} // handle innerHTML change
                  tagName="p" // Use a custom HTML tag (uses a div by default)
                  className="n_answer n_saved"
                ></ContentEditable>
              </div>
            );
          })}
        </div>
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("SAVE")}>
            Save
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};

export const GallerySelection = props => {
  const { mSend, images } = props;

  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_reflect">
        {images.map((objImage, id) => (
          <figure
            className="kg_gallery_image"
            onClick={() => mSend("SELECT", { selection: objImage })}
            key={id}
          >
            <img
              src={objImage.src}
              alt={
                "Saved Image from Kayak Collection Mission, taken at " +
                objImage.time
              }
            />
            <figcaption>
              <div className="row">
                <div className="left">Organism:</div>
                <div className="right">{objImage.title}</div>
              </div>
              <div className="row">
                <div className="left">Location:</div>
                <div className="right">{objImage.source}</div>
              </div>
            </figcaption>
          </figure>
        ))}
      </div>
    </AppBGScreen>
  );
};

export const NotesSelection = props => {
  const { mSend, current } = props;
  const objFolders = {
    1: (
      <div className="n_folder" onClick={() => mSend("MBARIREFLECT")} key={1}>
        <figure>
          <img src={imgMbariNotesFolder} alt="MBARI Notebook" />
          <figcaption>
            <h3>{current.context.reflection["MBARI"].savedDateTime}</h3>
            <h1>MBARI Reflection</h1>
            <h2>Which tool is your favorite...</h2>
          </figcaption>
        </figure>
      </div>
    ),
    2: (
      <div className="n_folder" onClick={() => mSend("ELKHORNREFLECT")} key={2}>
        <figure>
          <img src={current.context.savedImage} alt="Elkhorn Slough Notebook" />
          <figcaption>
            <h3>{current.context.reflection["ELKHORN"].savedDateTime}</h3>
            <h1>Elkhorn Slough Reflection</h1>
            <h2>My favorite organism along...</h2>
          </figcaption>
        </figure>
      </div>
    ),
    3: (
      <div
        className="n_folder"
        onClick={() => mSend("TIDEPOOLREFLECT")}
        key={2}
      >
        <figure>
          <img src={current.context.savedImage} alt="Tide Pool Notebook" />
          <figcaption>
            <h3>{current.context.reflection["TIDEPOOLS"].savedDateTime}</h3>
            <h1>Tide Pool Reflection</h1>
            <h2>My favorite organism along...</h2>
          </figcaption>
        </figure>
      </div>
    )
  };
  let arrFolders = [];
  const lessonCount = props.lesson || 1;
  console.log("lessonCount: ", lessonCount);
  for (let i = 1; i <= lessonCount; i++) {
    arrFolders.push(objFolders[i]);
  }
  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_folders">
        <div className="n_folder" onClick={() => mSend("INTRO")}>
          <figure>
            <img
              src={iconIntroFolder}
              className="Foreword"
              alt="Introduction"
            />
            <figcaption>
              <h3>01/01/2020</h3>
              <h1>Introduction</h1>
              <h2>Hello, fellow explorer...</h2>
            </figcaption>
          </figure>
        </div>
        {arrFolders}
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("DONE")}>
            Return to Lab
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};
export const AwardPageNewLevel = props => {
  const [objUser] = useContext(UserContext);
  const { mSend, level } = props;
  // const ringRef = useRef();
  const bgStyle = {
    backgroundImage: "url(" + awardTabletBG + ")"
  };
  console.log("BACKGROUND, AwardPageNewLevel bgStyle:", bgStyle);
  return (
    <AppBGScreen style={bgStyle}>
      <h1 className="nl_title">New Level</h1>
      <div className="nl_highlight">
        <div className="nl_crop_circles">
          <img alt="Award!" src={cropRings} />
        </div>
        <div className="nl_medal">
          <img src={newLevelPhotoBG} alt="Medal" />
        </div>
        <div className="nl_profile">
          <img
            src={"/images/user_images/" + objUser.user.profile_image}
            alt="You"
          />
        </div>
        <div className="nl_level">{level}</div>
      </div>
      <div className="button_holder">
        <button className="app_button_blue" onClick={() => mSend("CONTINUE")}>
          Continue
        </button>
      </div>
    </AppBGScreen>
  );
};

export const AwardPageRewards = props => {
  const { onClick, arrBadges, title } = props;
  // const ringRef = useRef();

  const bgStyle = {
    backgroundImage: "url(" + awardTabletBG + ")"
  };
  console.log("BACKGROUND, AwardPageRewards bgStyle:", bgStyle);

  return (
    <AppBGScreen style={bgStyle}>
      <h1 className="nl_title">{title}: Cleared</h1>
      <h2 className="nl_subtitle">Rewards</h2>
      <div className="badges">
        {arrBadges.map((badge, index) => {
          return (
            <div className="reward_badge" key={index}>
              <div className="badge_crop_circles">
                <figure>
                  <img alt="Highlight" src={cropRings} />
                  <figcaption>
                    <h1>{badge.name}</h1>
                    <h2>{badge.type}</h2>
                  </figcaption>
                </figure>
              </div>

              <img
                alt={badge.name + " " + badge.type}
                src={badge.img}
                style={badge.style}
                className="claimed_reward"
              />
            </div>
          );
        })}
      </div>
      <div className="button_holder">
        <button className="app_button_blue" onClick={onClick}>
          Claim
        </button>
      </div>
    </AppBGScreen>
  );
};
export const AskScientist = props => {
  // const ringRef = useRef();
  const { current } = props;

  // useEffect(() => {
  //   console.log("INITIALIZING!");
  //   // const ringTimeLine = new TimelineLite({ paused: false });
  //   props.ringTimeLine
  //     .from(ringRef.current, 0.1, { opacity: 1 })
  //     .to(ringRef.current, 0.1, {
  //       opacity: 0
  //     })
  //     .yoyo(true)
  //     .repeat(5);
  //   // ringTimeLine.restart();
  // });
  console.log("RUNNING!");
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="incall">
        <div className="calling_img_crop">
          <img
            onClick={() => props.mSend("CLICK")}
            alt="Large Tablet - Call Scientist"
            src={current.context.scientistData.callImg}
          />
        </div>
        <div className="as_crop_circles ringing">
          <img alt="Call coming in!" src={cropRings} />
        </div>

        <div className="as_text_box">
          <h1>{current.context.scientistData.scientistName}</h1>
          <h2>({current.context.scientistData.scientistTitle})</h2>
        </div>
      </div>
    </div>
  );
};

export const InfoPage = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="info_page">
        <img alt={props.alt} src={props.image} />
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        {props.instructions && <h4>{props.instructions}</h4>}
        {!props.buttonDisabled && (
          <AppButton
            {...{ onClick: () => props.mSend(props.buttonAction || "PRESS") }}
          >
            {props.buttonText}
          </AppButton>
        )}
      </div>
    </div>
  );
};

export const ImageCard = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="image_card">
        <OImg {...props} />
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        {props.instructions && <h4>{props.instructions}</h4>}
        <div className="button_holder">
          <button
            className="app_button"
            onClick={() => props.mSend(props.buttonAction)}
            disabled={props.buttonDisabled}
            style={{ margin: "0 auto" }}
          >
            {props.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export const PHTesting = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="info_table">
        <h1 className={props.titleCapitalize === false ? "no_cap" : null}>
          {props.title || "pH TESTING"}
          <img alt="Test Tube" src={testTubeIcon} />
        </h1>

        <span className="sample">No samples found.</span>
        <h2>Drag’n’drop beakers on water to collect samples.</h2>
        <ButtonCenter>
          <AppButton {...{ onClick: () => props.mSend(props.buttonAction) }}>
            {props.buttonText}
          </AppButton>
        </ButtonCenter>
      </div>
    </div>
  );
};
export const DragAndTestResults = props => {
  // console.log("props.samples:", props.samples);
  const arrSamples =
    props.samples && props.samples.length
      ? props.samples
      : [{ color: "blank", marker: "?", name: "No samples found" }];

  // console.log("objSamples:", arrSamples);
  // console.log("buttonDisabled:", props.buttonDisabled);
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />

      <div className="info_table">
        <h1 className={props.titleCapitalize === false ? "no_cap" : null}>
          {props.title}
          <img alt="Test Tube" src={testTubeIcon} />
        </h1>
        <div className="wt_sample_list">
          {arrSamples.map((sample, id) => (
            <OneSample sample={sample} key={id} />
          ))}
          {props.isDraggable && <h4>{props.instructions}</h4>}
        </div>
        <div className="button_holder">
          <button
            className={props.buttonClass || "app_button"}
            onClick={() => {
              console.log("buttonAction=", props.buttonAction);
              props.mSend(props.buttonAction);
            }}
            disabled={props.buttonDisabled}
          >
            {props.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export const QuizIntro = props => {
  return (
    <QuizIntroWrapper>
      <img alt={props.title} src={questionIcon} />
      <h1>{props.title}</h1>
      {props.children}
      <AppButton
        {...{
          onClick: () =>
            props.mSend({
              type: props.buttonAction,
              deadline: Date.now() + (props.duration ? props.duration : 80),
              startTime: Date.now(),
              questionCount: props.questionCount,
              multiSelect: props.multiSelect
            })
        }}
      >
        {props.buttonText}
      </AppButton>
    </QuizIntroWrapper>
  );
};

export const IntroPage = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="quiz_intro_wrapper">
        <img alt={props.title} src={questionIcon} />
        <h1>{props.title}</h1>
        {props.children}
        <AppButton
          {...{
            onClick: () =>
              props.mSend({
                type: props.buttonAction,
                deadline: props.deadline,
                startTime: props.startTime
                // deadline: Date.now() + props.timerDuration,
                // startTime: Date.now(),
                // questionCount: props.theQuiz.Count,
                // multiSelect: props.quizItem.multiSelect
              })
          }}
        >
          {props.buttonText}
        </AppButton>
      </div>
    </div>
  );
};

const QuizCard = props => {
  const cardTitle = Object.keys(props.choice)[0];
  const cardImgSrc = props.choice[cardTitle];
  const fnOnDragStart = e => {
    console.log("Dragging");
    e.dataTransfer.setData("id", e.target.id);
    e.target.style.zIndex = 1000;
    e.target.classList.add("being_dragged");
  };
  const fnOnDragEnd = e => {
    e.target.classList.remove("being_dragged");
  };
  return (
    <div
      className={"quiz_card c" + props.count}
      draggable={props.isDraggable ? "true" : "false"}
      onDragStart={fnOnDragStart}
      onDragEnd={fnOnDragEnd}
      id={"quiz_card_" + props.id}
    >
      <img src={cardImgSrc} alt={cardTitle} draggable={false} />
      <h1>{cardTitle}</h1>
    </div>
  );
};

const fnShowInstruction = state => {
  if (state === false)
    document.getElementById("instructions").classList.add("hide");
  else document.getElementById("instructions").classList.remove("hide");
};
const fnShowGameInstruction = state => {
  if (state === false)
    document.getElementById("game_instruction").classList.add("hide");
  else document.getElementById("game_instruction").classList.remove("hide");
};

const fnSetInstruction = instruction => {
  document.getElementById("instructions").innerText = instruction;
};

export const DnDGame = props => {
  switch (props.quizItem.type) {
    default:
    case "dragToBoard":
      return <DragToBoard {...props} />;
    case "dragAFlag":
      return <DragAFlag {...props} />;
    case "dragACard":
      return <DragACard {...props} />;
  }
};
const DragToBoard = props => {
  const fnOnDragOver = e => {
    e.preventDefault();
    // console.log(e.target);
    fnShowInstruction(false);
    e.target.classList.add("can_drop_here");
  };
  const fnOnDragLeave = e => {
    e.preventDefault();
    fnShowInstruction(true);
    e.target.classList.remove("can_drop_here");
  };
  const fnOnDrop = e => {
    e.preventDefault();
    // console.log("QuizItem:", props.quizItem);
    fnShowInstruction(false); // this should not be needed but there may be occasions where the drag event was not quick enough
    const id = e.dataTransfer.getData("id");
    // e.target.appendChild(document.getElementById(id));
    const droppedObj = document.getElementById(id);
    // console.log("id=", id);
    const target = e.target;
    const rect = target.getBoundingClientRect();
    let newLeft = e.pageX - droppedObj.offsetWidth / 2 - rect.left;
    var targetWidth = rect.right - rect.left;
    // console.log("obj right:", newLeft + droppedObj.offsetWidth);
    // console.log("target right:", targetWidth);

    if (newLeft < 0) {
      newLeft = 0;
    } else if (newLeft + droppedObj.offsetWidth > targetWidth) {
      newLeft = targetWidth - droppedObj.offsetWidth;
    }

    console.log("object left:", newLeft);
    console.log("e.clientX:", e.clientX);
    console.log("left:", newLeft + "px");
    droppedObj.setAttribute("origleft", droppedObj.style.left);
    droppedObj.style.left = newLeft + "px";
    droppedObj.classList.add("dropped");
    setTimeout(() => {
      droppedObj.classList.add("clear");
      if ("quiz_card_" + props.quizItem.answer === id) {
        fnShowInstruction(true);
        fnSetInstruction(props.correctMessage);
        setTimeout(() => {
          props.mSend(props.buttonAction);
        }, 1000);
      } else {
        fnShowInstruction(true);
        fnSetInstruction(props.incorrectMessage);
        setTimeout(() => {
          droppedObj.classList.remove("dropped");
          droppedObj.style.left = droppedObj.getAttribute("origLeft");
          fnShowInstruction(false);
          fnSetInstruction(props.instructionMessage);
        }, 2000);
      }
    }, 1000);
    target.classList.remove("can_drop_here");
  };

  const answers = props.theQuiz.Answers(props.questionNumber);
  console.log("props.theQuiz:", props.theQuiz);
  return (
    <div className="as-bg">
      {/* <button onClick={() => props.mSend("NEXT")} /> */}
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="app_margin">
        <div className="quiz_game">
          <h1>
            <div className="quiz_title">{props.gameTitle}</div>
            <div className="quiz_icon">
              <img src={questionIcon} alt="Question Mark" />
            </div>
            <div className="question_number">
              {"#" + (props.questionNumber + 1)}
            </div>
          </h1>
          <h2>{props.theQuiz.QuestionText(props.questionNumber)}</h2>
          <div className="quiz_pad">
            {answers.map((objChoice, count) => {
              console.log("objChoice:", objChoice);
              return (
                <QuizCard
                  key={count}
                  choice={objChoice.question}
                  id={objChoice.id}
                  count={count}
                  isDraggable={props.draggingEnabled}
                />
              );
            })}
          </div>
          <div
            className="drop_pad"
            onDrop={fnOnDrop}
            onDragOver={fnOnDragOver}
            onDragLeave={fnOnDragLeave}
          >
            <div className="instructions" id="instructions">
              {props.instructionMessage}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WordCard = props => {
  return (
    <div
      className={"word_card c" + props.count}
      id={props.id}
      style={props.style}
      draggable={props.draggable}
      onDrop={props.onDrop}
      onDragOver={props.onDragOver}
      onDragLeave={props.onDragLeave}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
    >
      {props.title}
    </div>
  );
};
const DragAFlag = props => {
  const fnOnDragStart = e => {
    console.log("Dragging");
    const { target } = e;
    e.dataTransfer.setData("id", target.id);
    setTimeout(() => {
      target.style.display = "none";
    }, 0); // this 'delay' gives the browser just enough time to hide the original card without effecting the dragged card.
    target.style.zIndex = 1000;
    target.classList.add("being_dragged");
  };
  const fnOnDragEnd = e => {
    const { target } = e;

    target.style.display = "";
    target.classList.remove("being_dragged");
  };

  const answers = props.theQuiz.Answers(props.questionNumber);

  const proxyDropPad = e => {
    return e.target; //document.getElementById(e.target.id.replace("_overlay", ""));
  };

  const fnOnDragLeave = e => {
    e.preventDefault();
    e.target.classList.remove("can_drop_here");
  };
  const fnOnDrop = e => {
    e.preventDefault();
    console.log("QuizItem:", props.quizItem);
    fnShowInstruction(false);
    const id = e.dataTransfer.getData("id");
    // e.target.appendChild(document.getElementById(id));
    const droppedObj = document.getElementById(id);
    console.log("id=", id);
    const target = e.target;
    const rect = target.getBoundingClientRect();
    let newLeft = e.pageX - droppedObj.offsetWidth / 2 - rect.left;
    var targetWidth = rect.right - rect.left;
    console.log("obj right:", newLeft + droppedObj.offsetWidth);
    console.log("target right:", targetWidth);

    if (newLeft < 0) {
      newLeft = 0;
    } else if (newLeft + droppedObj.offsetWidth > targetWidth) {
      newLeft = targetWidth - droppedObj.offsetWidth;
    }

    console.log("object left:", newLeft);
    console.log("e.clientX:", e.clientX);
    console.log("left:", newLeft + "px");
    droppedObj.setAttribute("origleft", droppedObj.style.left);
    droppedObj.style.left = newLeft + "px";
    droppedObj.classList.add("dropped");
    setTimeout(() => {
      droppedObj.classList.add("clear");
      console.log("id=", id);
      const answer = target.id.replace("word", "flag");
      console.log("answer:", answer);
      console.log("id:", id);

      if (answer === id) {
        fnShowGameInstruction(false);
        fnSetInstruction(props.correctMessage);
        fnShowInstruction(true);
        setTimeout(() => {
          props.mSend(props.buttonAction);
        }, 1000);
      } else {
        fnShowGameInstruction(false);
        fnSetInstruction(props.incorrectMessage);
        fnShowInstruction(true);
        setTimeout(() => {
          droppedObj.classList.remove("dropped");
          droppedObj.style.left = droppedObj.getAttribute("origLeft");
          fnSetInstruction(props.incorrectMessage);
        }, 1000);
        setTimeout(() => {
          fnShowInstruction(false);
          fnShowGameInstruction(true);
        }, 1500);
      }
    }, 0);
    target.classList.remove("can_drop_here");
  };

  const fnOnDragOver = e => {
    e.preventDefault();
    const dropTarget = proxyDropPad(e);
    dropTarget.classList.add("can_drop_here");
  };
  return (
    <div className="as-bg">
      {/* <button onClick={() => props.mSend("NEXT")} /> */}
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="app_margin">
        <div className="quiz_game drag_a_flag">
          <h1>
            <div className="quiz_title">{props.gameTitle}</div>
            <div className="quiz_icon">
              <img src={questionIcon} alt="Question Mark" />
            </div>
            <div className="question_number">
              {"#" + (props.questionNumber + 1)}
            </div>
          </h1>
          <h2>{props.theQuiz.QuestionText(props.questionNumber)}</h2>
          {console.log("theQuiz:", props.quizItem)}
          <div className="image_pad">
            <OImg {...props.quizItem} draggable={false} />
            {answers.map((objChoice, count) => {
              console.log("objChoice:", objChoice);
              const cardTitle = Object.keys(objChoice.question)[0];
              const cardStyle = {
                left: objChoice.question[cardTitle].x + "%",
                top: objChoice.question[cardTitle].y + "%"
              };
              return (
                <WordCard
                  key={count}
                  choice={objChoice.question}
                  id={"word_card_" + objChoice.id}
                  count={count}
                  style={cardStyle}
                  title={cardTitle}
                  draggable={false}
                  onDrop={fnOnDrop}
                  onDragOver={fnOnDragOver}
                  onDragLeave={fnOnDragLeave}
                  {...props}
                />
              );
            })}
          </div>

          <div className="drop_pad">
            <div className="instructions" id="instructions"></div>
            <div className="game_instruction" id="game_instruction">
              {props.instructionMessage}
              <div
                className="quiz_flag"
                draggable={props.draggingEnabled ? "true" : "false"}
                onDragStart={fnOnDragStart}
                onDragEnd={fnOnDragEnd}
                id={"flag_card_" + props.quizItem.answer}
              >
                <img
                  src={imgFlag}
                  alt="Flag - drag it to the proper answer"
                  draggable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DragACard = props => {
  const answers = props.theQuiz.Answers(props.questionNumber);

  console.log("mSend=", props.mSend);
  const fnShow = (className, state) => {
    [].forEach.call(document.getElementsByClassName(className), obj => {
      obj.style.visibility = state ? "visible" : "hidden";
    });
  };
  const proxyDropPad = e => {
    return document.getElementById(e.target.id.replace("_overlay", ""));
  };
  const fnOnDragLeave = e => {
    e.preventDefault();
    if (e.target.getElementsByClassName("word_card").length === 0)
      fnShowInstruction(true);
    e.target.classList.remove("can_drop_here");
  };
  const fnOnDragStart = e => {
    console.log("Dragging");
    const { target } = e;
    e.dataTransfer.setData("id", target.id);
    setTimeout(() => {
      target.style.display = "none";
    }, 100); // this 'delay' gives the browser just enough time to hide the original card without effecting the dragged card.
    target.style.zIndex = 1000;
    target.classList.add("being_dragged");
  };
  const fnOnDragEnd = e => {
    const { target } = e;
    target.style.display = "";
    target.classList.remove("being_dragged");
  };
  const fnOnDrop = e => {
    e.preventDefault();
    // console.log("QuizItem:", props.quizItem);
    fnShowInstruction(false); // this should not be needed but there may be occasions where the drag event was not quick enough
    const id = e.dataTransfer.getData("id");
    const dropTarget = proxyDropPad(e);
    // e.target.appendChild(document.getElementById(id));
    const droppedObj = document.getElementById(id);
    const randomAngle = Math.random() * 6 - 3;
    droppedObj.style.transform = "rotate(" + randomAngle + "deg)";
    dropTarget.appendChild(droppedObj);
    dropTarget.classList.remove("can_drop_here");
    props.mSend("DROP");
  };
  const fnOnDragOver = e => {
    e.preventDefault();
    const dropTarget = proxyDropPad(e);
    dropTarget.classList.add("can_drop_here");
    fnShowInstruction(false);
  };

  const fnFindIds = objs => {
    let ids = [];
    [].forEach.call(objs, obj => {
      ids.push(obj.id);
    });
    return ids;
  };
  const fnRemoveHints = props => {
    [].forEach.call(fnFindIds(document.getElementsByClassName("hint")), id => {
      document.getElementById(id).classList.remove("hint");
    });
  };
  const fnCompareAnswers = props => {
    let incorrect_count = 0;
    const drop_pad = document.getElementById("drop_pad");
    const card_pad = document.getElementById("card_pad");
    let word_cards = drop_pad.getElementsByClassName("word_card");
    for (let i = 0; i < word_cards.length; i++) {
      const anum = word_cards[i].id.replace("word_card_", "");
      if (!props.theQuiz.checkAnswer(props.questionNumber, anum)) {
        word_cards[i].classList.add("incorrect");
        incorrect_count += 1;
      }
    }
    word_cards = card_pad.getElementsByClassName("word_card");
    for (let i = 0; i < word_cards.length; i++) {
      const anum = word_cards[i].id.replace("word_card_", "");
      if (props.theQuiz.checkAnswer(props.questionNumber, anum)) {
        incorrect_count += 1;
        word_cards[i].classList.add("hint");
      }
    }
    if (incorrect_count > 0) {
      word_cards = drop_pad.getElementsByClassName("word_card");
      for (let i = 0; i < word_cards.length; i++) {
        const anum = word_cards[i].id.replace("word_card_", "");
        if (props.theQuiz.checkAnswer(props.questionNumber, anum)) {
          word_cards[i].classList.add("hint");
        }
      }
    }

    return incorrect_count;
  };

  const fnResetDom = props => {
    const card_pad = document.getElementById("card_pad");
    const drop_pad = document.getElementById("drop_pad");
    const fragment = document.createDocumentFragment();

    [].forEach.call(
      fnFindIds(drop_pad.getElementsByClassName("word_card")),
      id => {
        const word_card = document.getElementById(id);
        word_card.classList.remove("incorrect");
        fragment.appendChild(word_card);
      }
    );

    card_pad.appendChild(fragment);
  };

  const fnNextAction = props => {
    if (fnCompareAnswers(props) > 0) {
      setTimeout(() => {
        fnResetDom();
        fnShowInstruction(true);
        fnSetInstruction(props.incorrectMessage);
      }, 2000);
      setTimeout(() => {
        fnSetInstruction(props.instructionMessage);
        fnRemoveHints();
      }, 4000);
    } else {
      fnResetDom();
      fnShowInstruction(true);
      fnSetInstruction(props.correctMessage);
      fnShow("word_card", false);
      setTimeout(() => {
        fnSetInstruction(props.instructionMessage);
        fnShow("word_card", true);
        props.mSend(props.buttonAction);
      }, 2000);
    }
  };
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="app_margin">
        <div className="quiz_game drag_a_card">
          <h1>
            <div className="quiz_title">{props.gameTitle}</div>
            <div className="quiz_icon">
              <img src={questionIcon} alt="Question Mark" />
            </div>
            <div className="question_number">
              {"#" + (props.questionNumber + 1)}
            </div>
          </h1>
          <h2>{props.theQuiz.QuestionText(props.questionNumber)}</h2>
          {/* {console.log("theQuiz:", props.quizItem)} */}
          <div className="image_header">
            <OImg {...props.quizItem} />
          </div>
          <div className="card_pad" id="card_pad">
            {answers.map((objChoice, count) => {
              // console.log("objChoice:", objChoice);
              const cardTitle = Object.keys(objChoice.question)[0];

              return (
                <WordCard
                  key={count}
                  choice={objChoice.question}
                  id={"word_card_" + objChoice.id}
                  count={count}
                  title={cardTitle}
                  draggable={props.draggingEnabled ? "true" : "false"}
                  onDragStart={fnOnDragStart}
                  onDragEnd={fnOnDragEnd}
                  {...props}
                />
              );
            })}
          </div>

          <div
            className="drop_pad"
            id="drop_pad"
            onDrop={fnOnDrop}
            onDragOver={fnOnDragOver}
            onDragLeave={fnOnDragLeave}
          >
            <div className="instructions" id="instructions">
              {props.instructionMessage}
            </div>
          </div>
          {/* <div className="drop_pad_overlay" id="drop_pad_overlay" /> */}

          <div className="button_holder">
            <button
              className="app_button"
              onClick={e => fnNextAction(props)}
              disabled={props.buttonDisabled}
            >
              {props.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
