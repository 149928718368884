import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import UserContext from "context/User.js";
import appData from "components/appCall/CallingBlastQuiz";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";
import multipleChoice from "custom/lab/BlastQuiz";
import bqHammerheadShark from "img/bq_hammerhead_shark.png";
import bqSunkenShip from "img/bq_sunken_ship.png";
import bqROV from "img/bq_rov.png";
import bqDSV from "img/bq_dsv.png";

const quizItems = [
  {
    imgSrc: bqHammerheadShark,
    question:
      "We need to track the movements of a shark. Which tool would be best for this task?",
    choices: {
      1: "Autonomous Underwater Vehicle (AUV)",
      2: "Satellite Tracking Tag",
      3: "Remotely Operated Vehicle (ROV)",
      4: "Sound Navigation and Ranging (SONAR)"
    },
    answers: [2]
  },
  {
    imgSrc: bqSunkenShip,
    question:
      "We need to create an image of a shipwreck.  Which tool would be able to create this image?",
    choices: {
      1: "Satellite Tracking Tag",
      2: "Autonomous Underwater Vehicle (AUV)",
      3: "Sound Navigation and Ranging (SONAR)",
      4: "Remotely Operated Vehicle (ROV)"
    },

    answers: [3]
  },
  {
    imgSrc: bqDSV,
    question: "Which statement best explains why using a DSV can be dangerous?",
    choices: {
      1: "DSVs are controlled by a computer program",
      2: "SONAR controls the DSV",
      3: "DSV pilots can die due to a serious malfunction",
      4: "DSVs can be attacked"
    },
    answers: [3]
  },
  {
    imgSrc: bqROV,
    question:
      "Which tool is best to measure physical characteristics (such as temperature and salinity) of the water?",
    choices: {
      1: "Remotely Operated Vehicle (ROV)",
      2: "Sound Navigation and Ranging (SONAR)",
      3: "Autonomous Underwater Vehicle (AUV)",
      4: "Deep-submergence vehicle (DSV)"
    },
    answers: [3]
  }
];
const theQuiz = new multipleChoice(quizItems);
const context = {
  id: "lab_in_between",
  scientistData: appData,
  blastQuiz: true
};

const InBetween = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          theQuiz={theQuiz}
        />
      </NewLargeTablet>
    </Environment>
  );
};

export default InBetween;
