import React, { useContext } from "react";
import badgeBackground from "img/badge_background.png";
import UserContext from "context/User";
// import styled from "@emotion/styled";
import { Link } from "react-router-dom";
export const TrophyExplorerBadge = props => {
  const [context] = useContext(UserContext);
  console.log(context);

  return (
    <div className="trophy_explorer">
      <div className="explorer_thumbnail">
        {"user" in context && (
          <img
            alt="Thumbnail"
            src={"/images/user_images/" + context.user.profile_image}
          />
        )}
      </div>
      <h1>{context.username}</h1>
      <h2>{"user" in context ? context.user.title : ""}</h2>
      <hr />
      <h3>
        <label>{"user" in context ? context.user.objective : ""}</label>
      </h3>
      <h4 style={props.levelStyle}>
        {"user" in context ? context.user.level : ""}
      </h4>
    </div>
  );
};

// Keep this outside of ExploreBadge to prevent needless redraws.
const ExplorerLevel = props => {
  return (
    <div className="explorer_level">
      <h1>{props.children}</h1>
    </div>
  );
};

const ExplorerBadge = props => {
  const [context] = useContext(UserContext);

  const itemStored =
    "context" in props && "obtainable" in props.context.environment
      ? props.context.environment.obtainable[props.context.itemStored]
      : null;
  // console.log("CONTEXT=", context);
  const level = props.lesson || ("user" in context ? context.user.level : 1);
  if (!("user" in context))
    return (
      <div className="login_block">
        <a href="/login">Login</a> | <a href="/register">Register</a>
      </div>
    );
  else
    return (
      <>
        <div className="explorer_badge">
          <img alt="Badge" src={badgeBackground} />
          <div className="explorer_thumbnail">
            <img
              alt="Thumbnail"
              src={"/images/user_images/" + context.user.profile_image}
            />
          </div>
          <h1>{context.username}</h1>
          <h2>{context.user.title}</h2>
          {/* {"objective" in context.user && context.user.objective !== null && ( */}
          {"objective" in props && (
            <h3>
              <label>Objective: </label>
              <div>{props.objective}</div>
            </h3>
          )}
          <ExplorerLevel>{level}</ExplorerLevel>
          {itemStored && (
            <div className="stored_item">
              <img src={itemStored.src} alt={itemStored.title} />
            </div>
          )}
        </div>
        <Link className="logout_block" to="/" onClick={context.fnLogout}>
          Logout
        </Link>
      </>
    );
};

export default ExplorerBadge;
