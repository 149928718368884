import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
// import { TabletContent } from "./LabEnvironment";
import { notesMachine as envMachine } from "components/NotesMachine";
import imgBackground from "img/lab_background_tablet.jpg";
import imgBackgroundSmall from "img/lab_background_tablet_sm.jpg";
import { TabletContent } from "components/NotesApp";
import { Modal } from "antd";

const contextEnv = {
  environment: {
    className: "env_lab",
    shiftBackground: false,
    background: {
      optimize: true,
      src: imgBackground,
      thumbnail: imgBackgroundSmall,
      alt: "Lab Background",
      color: "#132962",
      aspectRatio: "1920x1080"
    },
    clickables: {},
    obtainable: {}
  }
};

const Notes = props => {
  // const [objUser] = useContext(UserContext);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...contextEnv }
  });
  const { context } = current;
  console.log("Notes, current page:", current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          startWithIntro={true}
        />
      </NewLargeTablet>
      <Modal
        title={context.errorMessage}
        visible={context.showModal}
        onOk={() => mSend(context.okCommand)}
        onCancel={() => mSend("CANCEL")}
      >
        <p>{context.errorInstructions}</p>
      </Modal>
    </Environment>
  );
};

export default Notes;
