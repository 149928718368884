import React, { useContext } from "react";
import { useMachine } from "@xstate/react";
import { NewLargeTablet } from "components/UIComponents";
import { Redirect } from "react-router-dom";
import auvBadgeImg from "img/auv_badge.png";
import mbariBadgeImg from "img/mbari_badge.png";
import UserContext from "context/User";
import { Environment } from "components/Environment";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "intro",
  awardPageRewards: true,
  autoAnswer: true,
  doneAfterCall: true
};

const arrBadges = [
  {
    img: auvBadgeImg,
    name: "AUV",
    type: "Action Figure",
    style: { marginTop: "-5%" }
  },
  {
    img: mbariBadgeImg,
    name: "MBARI Badge",
    type: "Enamel Pin"
  }
];

const Congratulations = props => {
  const [objUser] = useContext(UserContext);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const levelUpAction = () => {
    var objUpdate = objUser.user;
    if (objUpdate) {
      objUpdate.level = 2;
      objUser.fnUpdateField("user", objUpdate);
    }
    objUser.fnStoreLevel();

    mSend("CLAIM");
  };

  if ("user" in objUser) objUser.user.objective = "Review your awards";

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          levelUpAction={levelUpAction}
          objUser={objUser}
          arrBadges={arrBadges}
        />
      </NewLargeTablet>
    </Environment>
  );
};

export default Congratulations;
