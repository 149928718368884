function shuffle(array) {
  //fisherYates
  var count = array.length,
    randomnumber,
    temp;
  while (count) {
    randomnumber = (Math.random() * count--) | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp;
  }
}

export default class LessonTest {
  constructor(objAnswerKey) {
    this._answers = objAnswerKey;

    this._answerAbbrs = Object.keys(this._answers); //"HOV", "ROV", "AUV", "SONAR", "SAT_TRACK_TAG"];

    this._correct = 0;
    this._incorrect = 0;
    this._playedCount = 0;
    this._total = Object.keys(this._answers).length;
    this._testRecord = {};
    this._answerAbbrLookup = {};
    for (let i = 0; i < this._answerAbbrs.length; i++) {
      this._answerAbbrLookup[this._answerAbbrs[i]] = i;
    }
  }

  AnswerText(abbr) {
    // console.log("answers: ", abbr, this._answers[abbr]);
    return this._answers[abbr].text;
  }
  get Answers() {
    return this._answers;
  }
  get AnswerAbbrs() {
    return this._answerAbbrs;
  }

  get answerKeys() {
    return Object.keys(this.Answers);
  }

  get randomAnswerKeys() {
    const randomAnswers = Object.keys(this.Answers);
    shuffle(randomAnswers);
    return randomAnswers;
  }

  falseCount() {
    return Object.keys(this._testRecord).reduce((total, key) => {
      return (this._testRecord[key] === false ? 1 : 0) + total;
    }, 0);
  }
  perfectScore() {
    console.log("falseCount:", this.falseCount());
    return (
      Object.keys(this._testRecord).length === this._total &&
      this.falseCount() === 0
    );
  }

  keepTrying() {
    console.log(
      "Object.keys(this._testRecord).length, falseCount()=",
      Object.keys(this._testRecord).length,
      this.falseCount()
    );
    return (
      Object.keys(this._testRecord).length === this._total && this.falseCount()
    );
  }

  incorrectCount() {
    console.log("Incorrect:", this._incorrect);
    return this._incorrect;
  }

  posLookup(abbr) {
    // console.log("abbr:", abbr);
    // console.log("_answerAbbrLookup:", this._answerAbbrLookup);
    return this._answerAbbrLookup[abbr];
  }

  incrementTotal() {
    this._playedCount = this._playedCount + 1;
  }

  checkAnswer(target, card_id) {
    if (target.id === "answer-blank-" + card_id) {
      this._testRecord[card_id] = true;
      this._correct = this._correct + 1;
      console.log(this._testRecord);
      return true;
    } else {
      this._testRecord[card_id] = false;
      this._incorrect = this._incorrect + 1;
      console.log("incorrect:", this._incorrect);
      console.log(this._testRecord);
      return false;
    }
  }

  removeAnswer(card_id) {
    delete this._testRecord[card_id];
    console.log("removeAnswer:", this._testRecord, " card_id:", card_id);
  }

  // isCorrect(answerKey) {
  //   console.log("answerKey:", answerKey);
  //   console.log("_testRecord:", this._testRecord);
  //   console.log("_testRecord:", this._testRecord);

  //   if (!(answerKey in this._testRecord)) {
  //     //   console.log("here");
  //     this._testRecord[answerKey] = true;
  //     this._correct = this._correct + 1;
  //     this._incorrect = this._incorrect > 0 ? this._incorrect - 1 : 0;
  //   }
  //   // console.log(answerKey + ": " + this._correct);
  //   return this._correct;
  // }

  // isIncorrect(answerKey) {
  //   if (!(answerKey in this._testRecord)) {
  //     this._testRecord[answerKey] = false;
  //     this._correct = this._correct > 0 ? this._correct - 1 : 0;
  //     this._incorrect += 1;
  //   }
  //   return this._correct;
  // }
}
