import callImg from "img/appCall/kelly_benoit-bird_calling.jpg";
import callWithScientist from "img/appCall/call_with_kelly_benoit_bird.jpg";
import imgOnTableTablet from "img/appCall/table_tablet_kelly.png";
import imgOnPicnicTable from "img/pg_tidepool_tablet_kelly_benoit_bird.png";
import dtKellyBenoitBird from "img/appCall/dt_kelly_benoit_bird.png";
import imgVideoStill from "img/appCall/call_with_kelly_benoit_bird.jpg";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Kelly Benoit-Bird",
  scientistTitleShort: "Senior Scientist",
  scientistTitle: "MBARI Senior Scientist",
  imgOnTableTablet: imgOnTableTablet,
  imgOnPicnicTable: imgOnPicnicTable,
  deskTopImg: dtKellyBenoitBird,
  videoStill: imgVideoStill
};
export default appData;
