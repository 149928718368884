import React from "react";
import questionIcon from "img/question_mark_icon.png";
import { AppButton } from "components/Tablet";
import tabletBG from "img/tablet_background.png";
import styled from "@emotion/styled/macro";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Statistic from "antd/es/statistic";
import imgCheckboxCheck from "img/checkbox_check.svg";
// import { createUnionOrIntersectionTypeNode } from "typescript";

const { Countdown } = Statistic;
const quizTimerDuration = 90000;

function onFinish() {
  console.log("finished!");
}

export const quizMachine = Machine(
  {
    id: "quiz",

    context: {
      questionNumber: 0,
      pageIs: "QuizIntro",
      quizInProgress: false,
      savedAnswers: {},
      latestAnswers: {},
      deadline: 0,
      startTime: 0,
      multiSelect: false,
      showTablet: true,
      buttonDisabled: true
    },
    initial: "intro",
    states: {
      start: {
        on: {
          "": {
            target: "intro",
            actions: ["introduce"]
          }
        }
      },
      _intro: {
        on: {
          "": {
            target: "startQuiz",
            actions: ["announceStart", "startQuiz"]
          }
        }
      },
      intro: {
        on: {
          GO: {
            target: "startQuiz",
            actions: ["announceStart", "startQuiz"]
          }
        }
      },
      startQuiz: {
        type: "parallel",
        states: {
          takingQuiz: {
            initial: "answerQuestion", // can you submit with no answers?  This assumes that you can't
            states: {
              answerQuestion: {
                on: {
                  CHOOSE: {
                    target: "readyToSubmit",
                    actions: ["enableSubmit", "recordAnswer"]
                  }
                }
              },
              readyToSubmit: {
                on: {
                  CHOOSE: {
                    target: "readyToSubmit",
                    actions: ["recordAnswer"]
                  },
                  NEXT: [
                    {
                      target: "answerQuestion",
                      actions: [
                        "disableSubmit",
                        "storeAnswers",
                        "moveToNextPage"
                      ],
                      cond: "moreQuestions"
                    },
                    {
                      target: "results",
                      actions: [
                        "storeAnswers",
                        "scoreQuiz",
                        "moveToNextPage",
                        "quizComplete"
                      ],
                      cond: "noMoreQuestions"
                    }
                  ]
                }
              },
              results: {
                on: {
                  DONE: {
                    target: "done",
                    actions: ["closeTablet"]
                  }
                }
              },
              done: {
                type: "final"
              }
            }
          },
          countdownTimer: {
            initial: "startTimer",
            states: {
              startTimer: {
                after: {
                  [quizTimerDuration]: {
                    target: "results",
                    actions: ["storeAnswers", "scoreQuiz", "quizComplete"]
                  }
                }
              },
              results: {
                "": {
                  type: "final"
                }
              }
            }
          }
        }
      }
    }
  },

  {
    actions: {
      disableSubmit: (context, event) => {
        console.log("disableSubmit");
        context.buttonDisabled = true;
      },
      enableSubmit: (context, event) => {
        console.log("allowSubmit");
        context.buttonDisabled = false;
      },
      introduce: (context, event) => {
        // console.log("Ready for a Blast Quiz?!");
      },
      startQuiz: assign({
        quizInProgress: context => true,
        pageIs: context => "Quiz",
        questionCount: (context, event) => event.questionCount,
        deadline: (context, event) => event.deadline,
        startTime: (context, event) => event.startTime,
        multiSelect: (context, event) => event.multiSelect
      }),
      storeAnswers: (context, event) => {
        console.log("here:");

        console.log("questionNumber:", context.questionNumber);
        context.savedAnswers = {
          ...context.savedAnswers,
          ...{ [context.questionNumber]: context.latestAnswers }
        };
      },
      moveToNextPage: (context, event) => {
        context.questionNumber = context.questionNumber + 1;
        context.latestAnswers = {};
      },
      recordAnswer: assign({
        latestAnswers: (context, event) => {
          return {
            ...context.latestAnswers,
            ...{ [event.choice]: event.checked }
          };
        }
      }),
      announceStart: (context, event) => {
        // context = console.log("starting quiz.  90 Seconds!");
      },
      scoreQuiz: (context, event) => {
        // console.log("Score the quiz!");
        //  We may want to allow the student to go back and correct answers.
      },
      quizComplete: assign({
        quizInProgress: context => false,
        pageIs: context => "QuizResults"
      }),
      closeTablet: assign({
        showTablet: false
      })
    },
    guards: {
      moreQuestions: (context, event) => {
        // console.log(context.questionNumber, context.questionCount);
        return context.questionNumber < context.questionCount - 1;
      },
      noMoreQuestions: (context, event) => {
        // console.log(context.questionNumber, context.questionCount);
        return !(context.questionNumber < context.questionCount - 1);
      }
    }
  }
);

// const AppWrapper = styled.div`
//   box-sizing: border-box;
//   background-image: url(${props => props.background});
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-color: #0022b6;
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: bold;
//   text-align: center;
//   line-height: 2vw;
//   width: 100%;
//   height: 100%;
//   /* border: 1px solid red; */
//   color: white;
//   padding: 2%;

//   h1,
//   h2,
//   h3,
//   h4,
//   p,
//   label,
//   span {
//     text-align: left;
//     white-space: normal;
//     word-break: break-all;
//   }
//   span {
//     font-size: 1vw;
//     text-transform: uppercase;
//   }
// `;

const QuizTitle = styled.h1`
  font-size: 1.6vw;
  text-transform: uppercase;
  /* border: 1px solid green; */
  line-height: 2vw;
  padding: 0;
  margin-top: 5%;
  width: 35%;
`;

const QuizQuestion = styled.h2`
  font-size: 1.25vw;
  line-height: 1.25vw;
`;

const QuizIcon = styled.img`
  display: block;
  float: right;
  width: 15%;
  opacity: 0.3;
  margin: 0%;
  padding: 0;
`;
const QuizImage = styled.img`
  width: 100%;
  margin: 0%;
  padding: 0;
`;
const QuizQuestionList = styled.div`
  height: auto;
  width: 100%;
  padding: 2% 0px 0px 0px;
  box-sizing: border-box;
`;
export const Checkbox = props => {
  return (
    <div className="checkbox">
      <img
        src={imgCheckboxCheck}
        alt="Check Mark"
        className={props.checked ? null : "hidden"}
      />
      <input
        type="checkbox"
        value={props.value}
        className={props.className}
        style={props.style}
        checked={props.checked}
        onChange={props.onChange}
        // disabled="disabled"
      />
    </div>
  );
};
const QuizCountdown = styled.div`
  float: right;
  font-size: 1.6vw;
  line-height: 2vw;
  padding: 0;
  opacity: 0.5;
  width: 9%;
  word-break: break-word;
  /* border: 1px solid red; */

  span {
    float: left;
    /* border: 1px solid red; */
    white-space: nowrap;
    font-size: 1.6vw;
  }
`;
// const QuizIntroWrapper = styled.div`
//   text-align: center;
//   h1 {
//     font-size: 1.6vw;
//     text-transform: uppercase;
//     /* border: 1px solid green; */
//     line-height: 2vw;
//     margin-bottom: 8%;
//   }
//   p {
//     font-size: 0.75vw;
//     margin: 0 14% 0 14%;
//     line-height: 2vw;
//     display: block;
//     word-break: break-word;
//   }
//   h1,
//   p {
//     text-align: center;
//   }
//   img {
//     padding-top: 20%;
//     width: 15%;
//     height: 15%;
//   }
//   button {
//     margin-top: 8%;
//   }
// `;

const AppBlastQuiz = props => {
  const { nextStep, tabletSend } = props;
  const theQuiz = props.quiz;
  const [quizState, mSend] = useMachine(quizMachine);
  const {
    pageIs,
    questionNumber,
    savedAnswers,
    latestAnswers,
    deadline,
    startTime,
    buttonDisabled
    // showTablet
  } = quizState.context;

  console.log("savedAnswers:", savedAnswers);
  console.log("latestAnswers:", latestAnswers);

  const fnCheck = e => {
    console.log(e.target);
    const input = e.target.querySelector("input[type=checkbox]");
    const img = e.target.querySelector("img");
    console.log(input);
    input.checked = !input.checked;
    if (input.checked) img.classList.remove("hidden");
    else img.classList.add("hidden");
    mSend({
      type: "CHOOSE",
      choice: input.value,
      checked: input.checked
    });
  };

  const fnCheckboxChanged = event => {
    console.log("Choosing", event.target.value);
    mSend({
      type: "CHOOSE",
      choice: event.target.value,
      checked: event.target.checked
    });
  };
  const quizItem = theQuiz.QuizItems[questionNumber];

  const Quiz = props => {
    const answers = theQuiz.Answers(questionNumber);

    const QuizImages = props => {
      // console.log(quizItem.imgSrc.constructor.name);
      if (quizItem.imgSrc.constructor.name === "Array") {
        return (
          <div className="quiz_img_photos">
            {quizItem.imgSrc.map((obj, count) => (
              <QuizImage src={obj} key={count} />
            ))}
          </div>
        );
      } else {
        return <QuizImage src={quizItem.imgSrc} />;
      }
    };
    return (
      <div className="quiz_wrapper">
        <QuizCountdown>
          <Countdown value={deadline} onFinish={onFinish} format="ss" />s
        </QuizCountdown>
        <QuizTitle>
          Blast-Quiz<QuizIcon src={questionIcon}></QuizIcon>
        </QuizTitle>

        <QuizQuestion>{quizItem.question}</QuizQuestion>
        <QuizImages {...props} />

        <QuizQuestionList>
          {answers.map((obj, count) => (
            <div className="quiz_item" onClick={fnCheck} key={obj.id}>
              <label>
                <Checkbox
                  value={obj.id}
                  checked={latestAnswers[obj.id]}
                  onChange={fnCheckboxChanged}
                />
                <span>{obj.question}</span>
              </label>
            </div>
          ))}
        </QuizQuestionList>

        <button
          className="app_button"
          onClick={() => mSend("NEXT")}
          disabled={buttonDisabled ? "disabled" : null}
        >
          Next&#x2192;
        </button>

        {/* <AppButton {...{ onClick: () => mSend("NEXT") }}>
          Next&#x2192;
        </AppButton> */}
      </div>
    );
  };

  const QuizIntro = props => {
    return (
      <div className="quiz_intro_wrapper">
        <img alt="Blast-Quiz" src={questionIcon} />
        <h1>Blast-Quiz</h1>
        <p>
          You can unlock the lab by taking this short quiz. Some questions may
          have a few correct answers, so read carefully.
        </p>
        <p>You’ve got {quizTimerDuration / 1000} seconds. Good luck!</p>
        <AppButton
          {...{
            onClick: () =>
              mSend({
                type: "GO",
                deadline: Date.now() + quizTimerDuration,
                startTime: Date.now(),
                questionCount: theQuiz.Count,
                multiSelect: quizItem.multiSelect
              })
          }}
        >
          Go!
        </AppButton>
      </div>
    );
  };
  const QuizResults = props => {
    console.log("savedAnswers:", savedAnswers);
    const percentCorrect = theQuiz.calculateScore(savedAnswers).toFixed(1);
    const timeForQuiz = Math.floor((Date.now() - startTime) / 1000);
    console.log("Score is ", percentCorrect);
    return (
      <div className="quiz_intro_wrapper">
        <img alt="Blast Quiz" src={questionIcon} />
        <h1>{percentCorrect}% Correct</h1>
        <p>{timeForQuiz} seconds! Great job!</p>
        <AppButton
          nextStep={nextStep}
          {...{ onClick: () => tabletSend("DONE") }}
        >
          Got it
        </AppButton>
      </div>
    );
  };
  const PageChoice = props => {
    switch (pageIs) {
      case "Quiz":
        return <Quiz {...props} />;
      case "QuizIntro":
        return <QuizIntro {...props} />;
      default:
        return <QuizResults {...props} />;
    }
  };

  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="incall">
        <PageChoice {...props} />
      </div>
    </div>
  );
};

export default AppBlastQuiz;
