// import "antd/dist/antd.css";
import "./App.scss";
import React, { useState, message, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Welcome from "components/welcome";
import MBARIMap from "components/MBARIMap";
import ErrorPage from "components/ErrorPage";
import UserContext from "context/User.js";
import L1Intro from "components/Lesson1/intro";
import L1Lesson from "components/Lesson1/lesson";
import L1BlastQuiz from "components/Lesson1/blastQuiz";
import L1ChalkboardText from "components/Lesson1/testChalkboardDnM";
import AUVGame from "components/Lesson1/AUVGame";
import L1LabOpens from "components/Lesson1/labOpens";
import L1GameFinish from "components/Lesson1/gameFinish";
import L1AskScientist from "components/Lesson1/askScientist";
import L1Notes from "components/Lesson1/notes";
import L1OutroA from "components/Lesson1/outroA";
import L1Congratulations from "components/Lesson1/outroB";
import L1Final from "components/Lesson1/final";
import L2Intro from "components/Lesson2/intro";
import L2KayakShack from "components/Lesson2/kayakShack";
import L2Clipboard from "components/Lesson2/testClipboardDnM";
import L2Quiz from "components/Lesson2/quiz";
import L2KayakUnlocked from "components/Lesson2/kayakUnlocked";
import L2KayakCollect from "components/Lesson2/kayakCollect";
import L2Review from "components/Lesson2/kayakReview";
import L2WaterTesting from "components/Lesson2/waterTesting";
import L2WaterTestingMap from "components/Lesson2/waterTestingMap";
import L2AskScientist from "components/Lesson2/askScientist";
import L2FinalTest from "components/Lesson2/finalTest";
import L2OutroA from "components/Lesson2/outroA";
import L2Notes from "components/Lesson2/notes";
import L2OutroB from "components/Lesson2/outroB";
import L2Congratulations from "components/Lesson2/congratulations";
import L2Final from "components/Lesson2/final";
import L3Intro from "components/Lesson3/intro";
import L3Test from "components/Lesson3/testPicnicTableDnM";
import L3Lesson from "components/Lesson3/lesson";
import L3BlastQuiz from "components/Lesson3/blastQuiz";
import L3TestSamples from "components/Lesson3/testSamples";
import L3SampleA from "components/Lesson3/testSamplesA";
import L3SampleB from "components/Lesson3/testSamplesBIntro";
import L3PHTestingB from "components/Lesson3/testSamplesB";

import L3PHTesting from "components/Lesson3/phTesting";
import L3TidePoolCloseup from "components/Lesson3/phTestingCloseup";
import L3Outro from "components/Lesson3/outro";
import L3Notes from "components/Lesson3/notes";
import L3AskScientist from "components/Lesson3/askScientist";
import L3Congratulations from "components/Lesson3/congratulations";
import L3Final from "components/Lesson3/final";

import L3AltMap from "components/MBARIMap";

import { createBrowserHistory } from "history";
// import createBrowserHistory from 'history/createBrowserHistory';
import Login from "components/login";
import NotFinished from "components/notfinished";
import Register from "components/register.js";
import Email from "components/email.js";
import axios from "axios";
import { Redirect } from "react-router-dom";
const history = createBrowserHistory();

const App = (props) => {
  const [userMeta, setValues] = useState({
    jwt: "",
    username: "",
    badges: [],
  });
  // console.log(
  //   "process.env.REACT_APP_API_HOST is:",
  //   process.env.REACT_APP_API_HOST
  // );
  const context = {
    ...userMeta,
    api:
      (process.env.REACT_APP_API_HOST || "https://mbari.soundinsight.com") +
      ":" +
      (process.env.REACT_APP_API_PORT || "3000"), // no final slash
    fnLevelUp: function (prevLevel) {
      let userCopy = this.userMeta;
      // console.log("Leveling up!");
      userCopy.level = prevLevel + 1;
      setValues((userMeta) => ({ ...userMeta, user: { ...userCopy.level } }));
      // console.log("user is now:", user);
      this.fnSave(userMeta);
      this.fnStoreLevel();
      console.log("userCopy.level:", userCopy.level);
      // this.fnSave(user);
    },
    fnUpdate: function (objParams) {
      // console.log("fnUpdate:", objParams);
      setValues(objParams);
      // console.log("fnUpdate called, userMeta is:", userMeta);
      this.fnSave(userMeta);
    },
    fnLogout: function () {
      localStorage.clear();

      return <Redirect to={"/"} />;
    },
    fnUpdateField: function (k, v) {
      // console.log("fnUpdateField");

      setValues({ ...userMeta, [k]: v });
      this.fnSave(userMeta);
      // if (k === "badges") {
      //   this.fnUpdateBadges(v);
      // }
    },
    fnProgress: function (strStep) {
      // var d = new Date();
      // var n = d.toString();
      // console.log("fnProgress");

      this.fnStore();
    },
    fnSave: function (objSaveMe) {
      // console.log("fnSave");
      // console.log("objSaveMe=", objSaveMe);
      if (objSaveMe && objSaveMe.jwt && objSaveMe.jwt !== "") {
        //console.log('saving to local store',objSaveMe);
        //dont save empty
        // console.log("objSaveMe:", objSaveMe);
        if (objSaveMe.login === true) {
          objSaveMe = false;
        }
        localStorage.setItem("user", JSON.stringify(objSaveMe));
      }
    },
    fnStoreLevel: function () {
      // console.log("fnStoreLevel");

      // console.log("save to db", this);
      var objStore = {
        id: this.user.id,
        level: this.user.level,
      };

      axios
        .post(this.api + "/api/userlevelup", {
          d: objStore,
          token: userMeta.jwt,
        })
        .then((objResponse) => {
          // message.info("experiment updates saved to the database");
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    fnStore: function (objTarget) {
      var objStore = {
        id: objTarget.id,
        name: objTarget.name,
        json: {
          progress: objTarget.progress,
          data: objTarget.data,
          steps: objTarget.steps,
          questions: objTarget.questions,
        },
        status: objTarget.status,
        phase: objTarget.phase,
        lcd: objTarget.lcd,
      };

      axios
        .post(this.api + "/api/expupdate", { d: objStore, token: userMeta.jwt })
        .then((objResponse) => {
          message.info("experiment updates saved to the database");
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    fnUpdateBadges: function (v) {
      // console.log("fnUpdateBadges");

      axios
        .post(this.api + "/api/userbadges", { d: v, token: userMeta.jwt })
        .then((objResponse) => {
          console.log("saved badges: ", v);
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  };
  // console.log("host:", process.env);
  // console.log("api is:", context.api);

  // console.log("starting");

  useEffect(() => {
    if (!userMeta.jwt) {
      var jsonUser = localStorage.getItem("user");
      var go = true;
      try {
        var a = JSON.parse(jsonUser);
        // console.log("a:", a);
      } catch (e) {
        go = false;
      }

      if (go === true && a && a.jwt && a.jwt !== "") {
        //console.log('local storage loaded: ',a)
        var objStoredUser = a;
        //console.log('load',objStoredUser);
        if (objStoredUser.jwt && objStoredUser.jwt !== "") {
          // console.log("1. context is:", objStoredUser);
          context.fnUpdate(objStoredUser);
          // console.log("1.5 context is:", context);
        } else {
          //props.history.push('/');
        }
      }
    } else {
      // console.log("jwt: ", user.jwt);
    }
    // console.log("4. context is:", context);
  }, [context, userMeta.jwt]);

  // console.log("2. context is:", context);
  return (
    <UserContext.Provider value={[context, setValues]}>
      <BrowserRouter>
        {/* <Navigation /> */}
        <Switch>
          <Route
            path="/welcome"
            history={history}
            exact
            render={(props) => <Welcome {...props} destination="/map" />}
          />
          <Route
            path="/"
            history={history}
            exact
            render={(props) => <Welcome {...props} destination="/map" />}
          />
          {/* <Route exact path="/" render={props => <MBARIMap {...props} />} /> */}
          <Route path="/map" component={MBARIMap} />
          <Route
            path="/login"
            history={history}
            exact
            render={(props) => (
              <Login {...props} objective="Welcome!" destination={"/map"} />
            )}
          />
          <Route path="/register" history={history} component={Register} />
          <Route path="/email" history={history} component={Email} />

          <Route
            path="/lab/intro"
            render={(props) => (
              <L1Intro {...props} history={history} nextStep={"/lab/test"} />
            )}
          />
          <Route
            path="/lab/test"
            history={history}
            render={(props) => (
              <L1ChalkboardText {...props} nextStep={"/lab/lesson"} />
            )}
          />
          <Route
            path="/lab/lesson"
            history={history}
            render={(props) => <L1Lesson {...props} nextStep={"/lab/quiz"} />}
          />
          <Route
            path="/lab/quiz"
            history={history}
            render={(props) => (
              <L1BlastQuiz {...props} nextStep={"/lab/labopens"} />
            )}
          />
          <Route
            path="/lab/(blastquiz|quiz)"
            history={history}
            render={(props) => (
              <L1BlastQuiz {...props} nextStep={"/lab/labopens"} />
            )}
          />
          <Route
            path="/lab/labopens"
            history={history}
            render={(props) => (
              <L1LabOpens {...props} nextStep={"/lab/auvgame"} />
            )}
          />
          <Route
            path="/lab/auvgame"
            history={history}
            render={(props) => (
              <AUVGame {...props} nextStep={"/lab/gamefinish"} />
            )}
          />
          <Route
            path="/lab/gamefinish"
            history={history}
            render={(props) => (
              <L1GameFinish {...props} nextStep={"/lab/askscientist"} />
            )}
          />
          <Route
            path="/lab/askscientist"
            history={history}
            render={(props) => (
              <L1AskScientist {...props} nextStep={"/lab/notes"} />
            )}
          />
          <Route
            path="/lab/notes"
            history={history}
            render={(props) => (
              <L1Notes
                {...props}
                nextStep={"/lab/outro"}
                userContext={context}
              />
            )}
          />
          <Route
            path="/lab/outro"
            history={history}
            render={(props) => (
              <L1OutroA {...props} nextStep={"/lab/congratulations"} />
            )}
          />
          <Route
            path="/lab/congratulations"
            history={history}
            render={(props) => (
              <L1Congratulations {...props} nextStep={"/lab/final"} />
            )}
          />
          <Route
            path="/lab/final"
            history={history}
            render={(props) => <L1Final {...props} nextStep={"/elkhorn/map"} />}
          />
          <Route
            path="/lab/mainscreen"
            history={history}
            render={(props) => <MBARIMap {...props} mainScreen={true} />}
          />
          <Route
            path="/elkhorn/map"
            history={history}
            render={(props) => <MBARIMap {...props} lesson={2} />}
          />
          <Route
            path="/elkhorn/intro"
            history={history}
            render={(props) => (
              <L2Intro {...props} lesson={2} nextStep={"/elkhorn/kayakshack"} />
            )}
          />
          <Route
            path="/elkhorn/kayakshack"
            history={history}
            render={(props) => (
              <L2KayakShack
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/test"}
              />
            )}
          />
          <Route
            path="/elkhorn/test"
            history={history}
            render={(props) => (
              <L2Clipboard
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/quiz"}
              />
            )} //nextStep={"/elkhorn/clipboard"}
          />
          <Route
            path="/elkhorn/quiz"
            history={history}
            render={(props) => (
              <L2Quiz
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/kayakunlocked"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakunlocked"
            history={history}
            render={(props) => (
              <L2KayakUnlocked
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/kayakcollect"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakcollect"
            history={history}
            render={(props) => (
              <L2KayakCollect
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/kayakreview"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakreview"
            history={history}
            render={(props) => (
              <L2Review
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/watertesting"}
              />
            )}
          />
          <Route
            path="/elkhorn/watertesting"
            exact
            history={history}
            render={(props) => (
              <L2WaterTesting
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/watertesting/map"}
              />
            )}
          />
          <Route
            path="/elkhorn/watertesting/map"
            history={history}
            render={(props) => (
              <L2WaterTestingMap
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/askscientist"}
              />
            )}
          />
          <Route
            path="/elkhorn/askscientist"
            history={history}
            render={(props) => (
              <L2AskScientist
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/finaltest"}
              />
            )}
          />
          <Route
            path="/elkhorn/finaltest"
            history={history}
            render={(props) => (
              <L2FinalTest
                {...props}
                lesson={2}
                objective="Elkhorn Slough & Kayak Station"
                nextStep={"/elkhorn/outro"}
              />
            )}
          />
          <Route
            path="/elkhorn/outro"
            history={history}
            render={(props) => (
              <L2OutroA {...props} lesson={2} nextStep={"/elkhorn/notes"} />
            )}
          />
          <Route
            path="/elkhorn/notes"
            history={history}
            render={(props) => (
              <L2Notes
                {...props}
                lesson={2}
                nextStep={"/elkhorn/outro_b"}
                userContext={context}
              />
            )}
          />
          <Route
            path="/elkhorn/outro_b"
            history={history}
            render={(props) => (
              <L2OutroB
                {...props}
                lesson={2}
                nextStep={"/elkhorn/congratulations"}
              />
            )}
          />
          <Route
            path="/elkhorn/congratulations"
            history={history}
            render={(props) => (
              <L2Congratulations
                {...props}
                lesson={2}
                nextStep={"/elkhorn/final"}
              />
            )}
          />
          <Route
            path="/elkhorn/final"
            history={history}
            render={(props) => (
              <L2Final {...props} lesson={3} nextStep={"/tidepools/map"} />
            )}
          />
          <Route
            path="/elkhorn/map"
            history={history}
            render={(props) => <MBARIMap {...props} lesson={2} />}
          />
          {/* <Route
            path="/elkhorn/newoutro_a"
            render={props => (
              <L2NewOutroA {...props} lesson={2} nextStep={"/elkhorn/map"} />
            )}
          /> */}
          {/* <Route
            path="/test"
            render={props => <EnvTest {...props} lesson={2} />}
          /> */}
          <Route
            path="/tidepools/map"
            history={history}
            render={(props) => (
              <MBARIMap {...props} lesson={3} nextStep={"/tidepools/intro"} />
            )}
          />
          <Route
            path="/tidepools/intro"
            history={history}
            render={(props) => (
              <L3Intro
                {...props}
                lesson={3}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/test"}
              />
            )}
          />
          <Route
            path="/tidepools/test"
            history={history}
            render={(props) => (
              <L3Test {...props} lesson={3} nextStep={"/tidepools/lesson"} />
            )}
          />
          <Route
            path="/tidepools/lesson"
            history={history}
            render={(props) => (
              <L3Lesson
                {...props}
                lesson={3}
                nextStep={"/tidepools/blastquiz"}
              />
            )}
          />
          <Route
            path="/tidepools/(blastquiz|quiz)"
            history={history}
            render={(props) => (
              <L3BlastQuiz
                {...props}
                lesson={3}
                nextStep={"/tidepools/testsamples/pacificgrove"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove"
            exact
            history={history}
            render={(props) => (
              <L3TestSamples
                {...props}
                lesson={3}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/testsamples/pacificgrove/sample"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove/sample"
            history={history}
            render={(props) => (
              <L3SampleA
                {...props}
                lesson={3}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/testsamples/pacificgrove/phtesting/1"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove/phtesting/1"
            history={history}
            render={(props) => (
              <L3PHTesting
                {...props}
                lesson={3}
                phase={1}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/testsamples/pacificgrove/tidepoolcloseup"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove/tidepoolcloseup"
            history={history}
            render={(props) => (
              <L3TidePoolCloseup
                {...props}
                lesson={3}
                phase={1}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/testsamples/pacificgrove/phtesting/2"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove/phtesting/2"
            history={history}
            render={(props) => (
              <L3PHTesting
                {...props}
                lesson={3}
                phase={2}
                objective="Pacific Grove Tide Pool"
                nextStep={"/tidepools/testsamples/map"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/pacificgrove/notfinished"
            history={history}
            render={(props) => (
              <NotFinished
                {...props}
                lesson={3}
                nextStep="/tidepools/testsamples/map"
                message="Still working on this..."
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/map"
            history={history}
            render={(props) => (
              <L3AltMap
                {...props}
                lesson={3}
                alt="natural_bridges"
                nextStep={"/tidepools/testsamples/naturalbridges"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/naturalbridges"
            exact
            history={history}
            render={(props) => (
              <L3SampleB
                {...props}
                lesson={3}
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/testsamples/naturalbridges/sample"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/naturalbridges/sample"
            history={history}
            render={(props) => (
              <L3PHTestingB
                {...props}
                lesson={3}
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/testsamples/naturalbridges/phtesting/1"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/naturalbridges/phtesting/1"
            history={history}
            render={(props) => (
              <L3PHTesting
                {...props}
                lesson={3}
                phase={1}
                sampleSite={2}
                skipVideoIntro={true}
                objective="Natural Bridges Tide Pool"
                nextStep={
                  "/tidepools/testsamples/naturalbridges/tidepoolcloseup"
                }
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/naturalbridges/tidepoolcloseup"
            history={history}
            render={(props) => (
              <L3TidePoolCloseup
                {...props}
                lesson={3}
                site="Natural Bridges"
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/testsamples/naturalbridges/phtesting/2"}
              />
            )}
          />
          <Route
            path="/tidepools/testsamples/naturalbridges/phtesting/2"
            history={history}
            render={(props) => (
              <L3PHTesting
                {...props}
                lesson={3}
                phase={2}
                sampleSite={2}
                skipVideoIntro={true}
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/outro"}
              />
            )}
          />
          <Route
            path="/tidepools/outro"
            history={history}
            render={(props) => (
              <L3Outro
                {...props}
                lesson={3}
                nextStep={"/tidepools/askscientist"}
              />
            )}
          />
          <Route
            path="/tidepools/askscientist"
            history={history}
            render={(props) => (
              <L3AskScientist
                {...props}
                lesson={3}
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/notes"}
              />
            )}
          />
          <Route
            path="/tidepools/notes"
            history={history}
            render={(props) => (
              <L3Notes
                {...props}
                lesson={3}
                nextStep={"/tidepools/congratulations"}
                userContext={context}
              />
            )}
          />
          <Route
            path="/tidepools/congratulations"
            history={history}
            render={(props) => (
              <L3Congratulations
                {...props}
                lesson={3}
                objective="Natural Bridges Tide Pool"
                nextStep={"/tidepools/final"}
              />
            )}
          />
          <Route
            path="/tidepools/final"
            history={history}
            render={(props) => (
              <L3Final
                {...props}
                lesson={4}
                objective="Claim Rewards"
                nextStep={"/tidepools/map"}
              />
            )}
          />
          <Route path="/map" component={MBARIMap} history={history} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
