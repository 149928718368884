import React, { useContext } from "react";
import { useMachine } from "@xstate/react";
import { Redirect } from "react-router-dom";
import UserContext from "context/User.js";
import TrophyTablet from "components/TrophyTablet";
import { Environment } from "components/Environment";
import { labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "tropy_tablet",
  trophyTablet: true
};

const Final = props => {
  const [objUser] = useContext(UserContext);
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  objUser.level = 2;
  if ("user" in objUser) objUser.user.objective = "Claim Reward";

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <TrophyTablet mSend={mSend} {...props} />
    </Environment>
  );
};

export default Final;
