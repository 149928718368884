import React from "react";
import { Machine } from "xstate";
import imgBackground from "img/pg_testing_a_bg.jpg";
import imgBackgroundSmall from "img/pg_testing_a_bg_sm.jpg";
import imgTableWSamples from "img/pg_table_w_samples.svg";
import imgBeakersHighlight from "img/pg_table_beaker_highlight.svg";
import tabletBG from "img/tablet_background.jpg";
import imgPHTestingBeakers from "img/ph_testing_beakers.svg";
import imgLeftBeaker from "img/pg_sample_left_beaker.svg";
import imgLeftBeakerFull from "img/pg_sample_left_beaker_full.svg";
import imgRightBeaker from "img/pg_sample_right_beaker.svg";
import imgRightBeakerFull from "img/pg_sample_right_beaker_full.svg";
import imgRings from "img/pg_sample_rings.svg";

import { DragAndTestResults, InfoPage } from "components/TabletApps";

export const envMachine = Machine(
  {
    id: "samplingTable",
    context: {
      environment: {
        className: "env_samplingtable",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgBackground,
          thumbnail: imgBackgroundSmall,
          // src: imgBackgroundTablet,
          // thumbnail: imgBackgroundTabletSmall,
          alt: "Pacific Grove Tide Pool",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableWSamples: {
            visible: true,
            isClickable: false,
            showHighlight: false,
            ringing: false,
            className: "sample_table",
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: imgTableWSamples, //appData.imgOnPicnicTable,
                style: {
                  position: "absolute",
                  width: "100%",
                  left: "0",
                  bottom: "0"
                }
              }
            ]
          },
          beakers: {
            visible: true,
            isClickable: false,
            showHighlight: true,
            ringing: false,
            action: "OPEN",
            className: "beakers",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgBeakersHighlight,
                style: {
                  position: "absolute",
                  width: "18.5%",
                  left: "29%",
                  bottom: "25.5%"
                }
              }
            ]
          },
          rings: {
            visible: false,
            isClickable: false,
            showHighlight: true,
            ringing: true,
            action: "OPEN",
            className: "beakers",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgRings,
                style: {
                  position: "absolute",
                  width: "40%",
                  left: "6%",
                  bottom: "0",
                  filter: "opacity(.75)"
                }
              }
            ]
          },
          LEFTBEAKER: {
            visible: false,
            isClickable: true,
            showHover: true,
            transform: "translate(-2.5vw,-15vw)",
            action: "LEFTBEAKER",
            ph: 6.1,
            layers: [
              {
                name: "leftBeaker",
                handlesClick: true,
                handlesHighlight: false,
                handlesHover: true,
                handlesRing: true,
                optimize: false,
                src: imgLeftBeaker,
                altSrc: imgLeftBeakerFull,
                style: {
                  position: "absolute",
                  width: "16%",
                  left: "11%",
                  top: "75%",
                  transform: "rotate(-2.65deg)",
                  filter: "brightness(1.2)"
                }
              }
            ]
          },
          RIGHTBEAKER: {
            visible: false,
            isClickable: true,
            showHover: true,
            transform: "translate(5vw, -10vw)",
            action: "RIGHTBEAKER",
            ph: 6.2,
            layers: [
              {
                name: "rightBeaker",
                handlesClick: true,
                handlesRing: true,
                handlesHover: true,
                optimize: false,
                src: imgRightBeaker,
                altSrc: imgRightBeakerFull,
                style: {
                  position: "absolute",
                  width: "15%",
                  left: "27%",
                  top: "75%",
                  transform: "rotate(4.04deg)",
                  filter: "brightness(1.2)"
                }
              }
            ]
          }
        }
      },
      tabletBG: tabletBG,
      scientistSubTitles: [
        "“We are going to explore two tide pools next: Pacific Grove and Natural Bridges. Tide pools are a part of fascinating ecosystems with ever-changing conditions. The adaptations of the organisms...”"
      ],
      showTablet: true,
      clipboardIsTest: false,
      itemObtained: null,
      samples: [],
      submitDisabled: true
    },
    initial: "waitForScene",
    states: {
      waitForScene: {
        after: {
          500: "phTestingIntro"
        }
      },
      phTestingIntro: {
        entry: ["initializeEnvironment"],
        on: {
          PRESS: {
            target: "phTesting",
            actions: ["initializePHTesting"]
          }
        }
      },
      phTesting: {
        on: {
          LEFTBEAKER: {
            target: "takeSample",
            actions: ["takeSample", "ringsEndRing"]
          },
          RIGHTBEAKER: {
            target: "takeSample",
            actions: ["takeSample", "ringsEndRing"]
          },
          TEST: {
            target: "done"
          }
        }
      },
      takeSample: {
        after: {
          500: { target: "returnBeaker", actions: ["fillBeaker"] }
        }
      },
      returnBeaker: {
        after: {
          500: {
            target: "phTesting",
            actions: ["returnBeaker", "ringsStartRing"]
          }
        }
      },
      waitForFinish: {
        after: {
          5000: "done"
        }
      },
      done: {
        entry: ["finishScene"],
        type: "final"
      }
    }
  },
  {
    guards: {
      samplesComplete: (context, event) => {
        console.log("samplesComplete:", context.samples.length);
        return context.samples.length > 1;
      }
    },
    actions: {
      initializeEnvironment: (context, event) => {
        console.log("initializeEnvironment");
      },
      initializePHTesting: (context, event) => {
        console.log("initializePHTesting");
        context.environment.clickables.LEFTBEAKER.visible = true;
        context.environment.clickables.LEFTBEAKER.isClickable = true;
        context.environment.clickables.rings.visible = true;
        context.environment.clickables.RIGHTBEAKER.visible = true;
        context.environment.clickables.RIGHTBEAKER.isClickable = true;
        context.environment.clickables.tableWSamples.visible = false;
        context.environment.clickables.beakers.visible = false;
      },
      takeSample: (context, event) => {
        console.log("takeLeftSample:", event);
        console.log("event.type:", event);
        context.environment.clickables[event.type].layers[0].style.transform =
          context.environment.clickables[event.type].transform;
        context.focusClickable = context.environment.clickables[event.type];
        context.environment.clickables[event.type].showHover = false;
        context.environment.clickables[event.type].isClickable = false;
      },
      fillBeaker: (context, event, actionMeta) => {
        console.log("actionMeta:", actionMeta);
        const focus = actionMeta.state.context.focusClickable;
        focus.layers[0].oldSrc = focus.layers[0].src;
        focus.layers[0].src = focus.layers[0].altSrc;
      },
      returnBeaker: (context, event, actionMeta) => {
        console.log("actionMeta:", actionMeta);
        const focus = actionMeta.state.context.focusClickable;
        context.samples.push(focus.ph);
        focus.layers[0].style.transform = "translate(0,0)";
        if (context.samples.length > 1) {
          context.submitDisabled = false;
          context.buttonAction = "TEST";
        }
      },
      ringsStartRing: (context, event, actionMeta) => {
        if (context.samples.length < 2) {
          context.environment.clickables.rings.ringing = true;
        }
      },
      ringsEndRing: (context, event, actionMeta) => {
        context.environment.clickables.rings.ringing = false;
      },
      shiftScene: (context, event) => {
        console.log("shifting:");
      },
      startCall: (context, event) => {
        console.log("startCall");
      },
      unshiftScene: (context, event) => {
        console.log("unshifting:");
      },
      prepareForNext: (context, event) => {
        console.log("prepareForNext:");
      },
      storeItem: (context, event) => {},
      finishScene: (context, event) => {
        console.log("scene done");
        context.showTablet = true;
        context.done = true;
      },
      pickUpTablet: (context, event) => {},
      obtainItem: (context, event) => {},
      finishCall: (context, event) => {}
    }
  }
);
export const TabletContent = props => {
  const { current } = props;
  // const colorMap = ["#FFB802", "#008EE1"];
  console.log("State is now:", current.value);
  switch (current.value) {
    case "phTesting":
    default:
      return (
        <DragAndTestResults
          {...props} // mSend is already set
          isDraggable={current.context.submitDisabled}
          title="pH TESTING"
          titleCapitalize={false}
          image={imgPHTestingBeakers}
          buttonText="Test"
          alt="pH Testing"
          message="First of all you gotta collect samples to test."
          instructions="Click each beaker to collect samples."
          action="TEST"
          buttonDisabled={current.context.submitDisabled}
          buttonAction={current.context.buttonAction}
          samples={current.context.samples.map((sample, count) => ({
            // color: colorMap[count],
            key: count,
            index: count,
            color: "blank",
            marker: `?`,
            name: `Pacific Grove Tide Pool, pH #${count + 1}: ??`
          }))}
        />
      );
    case "phTestingIntro":
    case "waitForPage":
    case "waitForScene":
      return (
        <InfoPage
          {...props} // mSend is already set
          title="pH Testing"
          image={imgPHTestingBeakers}
          buttonText="I'm ready"
          alt="pH Testing"
          message="First, collect your samples for testing."
          action="PRESS"
        />
      );
  }
};
