import imgBackground from "img/nb_tidepool_bg.jpg";
import imgBackgroundSmall from "img/nb_tidepool_bg_sm.jpg";
import { Machine, assign } from "xstate";
import tabletBG from "img/tablet_background.jpg";
import appData from "components/appCall/CallingJamesBarry";
import appDataCharlesNye from "components/appCall/CallingCharlesNye";
import imgNBTesting from "img/nb_testing_bg_sm.jpg";
import imgNBTestingSmall from "img/nb_testing_bg_sm.jpg";
import largeRings from "img/ks_large_rings.svg";
import largePHMeter from "img/ph_meter.png";
import imgBeaker1 from "img/ph_beaker_1.svg";
import imgBeaker2 from "img/ph_beaker_2.svg";
import imgBeaker3 from "img/ph_beaker_3.svg";
import imgBeaker4 from "img/ph_beaker_4.svg";
import imgSampleCue from "img/ph_sample_cue.svg";
import imgLargeClipboard from "img/ph_clipboard_large.png";
import imgClipboardData1 from "img/ph_clipboard_data_1.svg";
import imgClipboardData2 from "img/ph_clipboard_data_2.svg";
// import axios from "axios";

const phData = [
  { ph: "0.0", tempF: "0.0", tempC: "0.0", color: "blank" },
  { label: "#1", ph: "8.1", tempF: "64.9", tempC: "18.2", color: "#FFB802" },
  { label: "#2", ph: "7.5", tempF: "64.9", tempC: "18.2", color: "#008EE1" },
  { label: "#3", ph: "8.7", tempF: "64.9", tempC: "18.2", color: "#72B900" },
  { label: "#4", ph: "7.8", tempF: "64.9", tempC: "18.2", color: "#DB0000" }
];
const context = {
  environment: {
    className: "env_elkhorn",
    shiftBackground: false,
    // shiftLeft: "8.6%",
    background: {
      optimize: true,
      src: imgBackground,
      thumbnail: imgBackgroundSmall,
      alt: "Natural Bridges-",
      color: "#132962",
      aspectRatio: "1920x1080"
    },
    clickables: {},
    obtainable: {}
  },
  callStep: 0,
  tabletBG: tabletBG,
  scientistData: appData,
  scientistSubTitles: [
    "How much does pH have to change for us to be concerned and why is ocean acidification a problem?  Hi I am Jim Barry, a Senior Scientist and Ecologist here at MBARI.",
    "The pH scale is a logarithmic scale. This means that a 0.001 change in pH is 10x different. Therefore, very little change in pH levels means large changes in the environment. The current trend in the ocean is a decreasing pH, making it more acidic. This is what is referred to as ocean acidification.",
    "It is a problem because many organisms have shells that are made of materials that are weakened by this change in pH. As a result, their shells break easier, survival rates are decreased, and lifespans are shortened.  Ocean acidification also has an economic impact. The changes to the ocean that acidification",
    "is causing will impact tourism, fishing industries, and property values or insurance costs as natural shoreline protection features such as coral reefs disappear. The impacts have the potential to snowball, with ocean acidification affecting certain things directly and then many more things  indirectly. ",
    "One example is the decrease in fishing industries.  This will have a major impact on the local economy, but it also impacts the local population by changing what food is available to them.  Check out how we are using new technology to measure changes in the ocean."
  ],
  showTablet: true,
  itemObtained: null,
  // savedImage: null,
  reflectionPhoto: { MBARI: null, ELKHORN: null, TIDEPOOLS: null },
  videoAfterCall: true,
  reflectionAnswers: []
};
export const rewardMachine = Machine(
  {
    id: "rewards",
    context: context,
    initial: "awardPageRewards",
    states: {
      awardPageRewards: {
        on: {
          CLAIM: {
            target: "awardPageNewLevel",
            actions: ["levelUp"]
          }
        }
      },
      awardPageNewLevel: {
        on: {
          CONTINUE: {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      levelUp: (context, event) => {
        context.level = context.level + 1;
      }
    }
  }
);

export const askMachine = Machine(
  {
    id: "askScientist",
    context: context,
    initial: "showVideo",
    // initial: "appScreen",

    states: {
      appScreen: {
        on: {
          ASK: {
            target: "waitForPage",
            actions: assign({
              callInProgress: true,
              callConnected: true
            })
          }
        }
      },
      waitForPage: {
        after: {
          100: "askScientist"
        }
      },
      askScientist: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "engageInCall",
            actions: [
              () => console.log("IT RAN"),
              assign({ callConnected: false, inCall: true })
            ]
          }
        }
      },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "showVideo"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      showVideo: {
        on: {
          VIDEODONE: {
            target: "videoDone",
            actions: assign({
              showTablet: false,
              tabletClickable: true,
              done: true
            })
          }
        }
      },
      videoDone: {
        on: {
          "": {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

// const fnLoadReflectionImages = (context, event) => {
//   console.log(
//     "fnLoadReflectionImages:",
//     context.api + "/api/reflectionimages/" + context.userID
//   );

//   return axios
//     .get(context.api + "/api/reflectionimages/" + context.userID)
//     .then(data => data.data); // Not sure why I have a data structure under the data structure.
// };
// const fnLoadReflections = (context, event) => {
//   console.log("fnLoadReflections:", context.userID, context.notesID);

//   return axios
//     .get(context.api + `/api/reflections/${context.userID}/${context.notesID}`)
//     .then(data => data.data); // Not sure why I have a data structure under the data structure.
// };
// const fnSaveReflection = (context, event) => {
//   // since axios returns a promise this will return a promise and therefore can be declared as asyncronous
//   const imageID =
//     context.reflection && context.reflection[context.notesID]
//       ? context.reflection[context.notesID].imageID
//       : null;

//   const questionIDs = Object.keys(context.reflectionAnswers);
//   const answers = questionIDs.map(qid => {
//     return context.reflectionAnswers[qid].text;
//   });

//   let objReflections = {
//     notesid: context.notesID,
//     questionids: questionIDs,
//     answers: answers,
//     userid: context.userID,
//     imageid: imageID
//   };
//   console.log("RI, objReflections:", objReflections);

//   return axios
//     .post(context.api + "/api/reflections", objReflections)
//     .then(data => data.data);
// };

// const morePages = (context, event) => {
//   return context.page > 1;
// };

// const DateToLocal = strDate => {
//   const dateUTC = new Date(strDate); // - offsetMs);
//   return dateUTC.toLocaleString();
// };

// export const notesMachine = Machine(
//   {
//     id: "NotesMachine",
//     context: context,
//     initial: "appScreen",
//     states: {
//       appScreen: {
//         on: {
//           NOTES: {
//             target: "loadReflectionImages",
//             actions: ["notesSettings"]
//           },
//           CANCEL: {
//             actions: assign({ showModal: false })
//           }
//         }
//       },
//       intro: {
//         on: {
//           NOTES: {
//             target: "notesSelection",
//             actions: assign({
//               backClickable: true,
//               currentFile: "Notes",
//               parentDir: null
//             })
//           },
//           FORWARDARROW: [
//             {
//               target: "intro",
//               cond: morePages,
//               actions: ["nextPage"]
//             },
//             {
//               target: "notesSelection",
//               actions: assign({
//                 page: 1,
//                 backClickable: true
//               })
//             }
//           ],
//           BACKARROW: [
//             {
//               target: "intro",
//               cond: morePages,
//               actions: ["prevPage"]
//             },
//             {
//               target: "notesSelection",
//               actions: assign({
//                 page: 1,
//                 backClickable: true
//               })
//             }
//           ]
//         }
//       },
//       notesSelection: {
//         on: {
//           BACK: {
//             target: "appScreen",
//             actions: assign({
//               backClickable: false,
//               currentFile: "Foreword",
//               parentDir: "Notes"
//             })
//           },
//           INTRO: {
//             target: "intro",
//             actions: assign({
//               backClickable: true,
//               currentFile: "Foreword",
//               parentDir: "Notes"
//             })
//           },
//           REFLECT: {
//             target: "loadReflections",
//             actions: ["reflectionSettings"]
//           },
//           DONE: {
//             target: "done",
//             actions: assign({
//               backClickable: true,
//               currentFile: "MBARI Reflection",
//               parentDir: "Notes",
//               done: true
//             })
//           }
//         }
//       },
//       reflection: {
//         on: {
//           TEXTFINISHED: [
//             {
//               target: "reflection",
//               cond: morePages,
//               actions: assign({
//                 qNum: context => context.qNum + 1
//               })
//             },
//             {
//               target: "reflection",
//               actions: assign({
//                 canSave: true
//               })
//             }
//           ],
//           TEXTCHANGED: {
//             target: "reflection",
//             actions: "changeAnswer"
//           },
//           IMAGECHOOSE: {
//             target: "chooseImage",
//             actions: ["chooseImage"]
//           },
//           BACK: {
//             target: "notesSelection",
//             actions: () => console.log("Should save")
//           },
//           SAVE: {
//             target: "saveReflections"
//           }
//         }
//       },
//       saveReflections: {
//         invoke: {
//           id: "saveReflections",
//           src: (context, event) => fnSaveReflection(context, event),
//           onDone: {
//             target: "notesSelection",
//             actions: ["reflectionSaved"]
//           },
//           onError: {
//             target: "reflection",
//             actions: ["reflectionNotSaved"]
//           }
//         }
//       },
//       loadReflectionImages: {
//         invoke: {
//           src: (context, event) => fnLoadReflectionImages(context, event),
//           onDone: {
//             target: "notesSelection",
//             actions: ["loadedReflectionImages"]
//           },
//           onError: {
//             target: "appScreen",
//             actions: ["couldNotConnect"]
//           }
//         }
//       },
//       loadReflections: {
//         invoke: {
//           src: (context, event) => fnLoadReflections(context, event),
//           onDone: {
//             target: "reflection",
//             actions: ["loadedReflections"]
//           },
//           onError: {
//             target: "notesSelection",
//             actions: assign({
//               errorMessage: "Could Not Load Notes"
//             })
//           }
//         }
//       },

//       chooseImage: {
//         on: {
//           SELECT: {
//             target: "reflection",
//             actions: ["chooseImageFromGallery"]
//           }
//         }
//       },
//       done: {
//         type: "final"
//       }
//     }
//   },
//   {
//     actions: {
//       couldNotConnect: (context, event) => {
//         context.errorMessage = "Could Not Load Notes";
//         context.errorInstructions = "Press OK to try again.";
//         context.showModal = true;
//         context.okCommand = "NOTES";
//       },
//       loadedReflectionImages: (context, event) => {
//         console.log("loadedReflectionImages:", event.data);
//         context.backClickable = true;
//         context.currentFile = "Notes";
//         context.parentDir = null;

//         if (event.data.reflectionimages) {
//           context.reflection = { MBARI: null, ELKHORN: null, TIDEPOOLS: null };
//           for (let i = 0; i < event.data.reflectionimages.length; i++) {
//             let rImg = event.data.reflectionimages[i];
//             context.reflection[rImg.notesid] = {
//               imageID: rImg.imageid,
//               savedDateTime: DateToLocal(rImg.modified)
//             };
//           }
//         }
//         console.log("axios, context.reflectionPhoto=", context.reflectionPhoto);
//       },
//       loadedReflections: (context, event) => {
//         console.log("loadedReflections:", event.data);
//         if (event.data.reflections) {
//           context.reflectionAnswers = [];
//           for (let i = 0; i < event.data.reflections.length; i++) {
//             let rData = event.data.reflections[i];
//             context.reflectionAnswers[rData.questionid] = {
//               text: rData.answer,
//               saved: true
//             };
//             context.reflectionSavedDateTime = DateToLocal(rData.modified);
//           }
//         }
//         console.log(
//           "axios, context.reflectionAnswers=",
//           context.reflectionAnswers
//         );
//       },
//       reflectionSaved: (context, event) => {
//         console.log("reflection Saved: ", event.data);
//         context.showModal = false;
//         context.backClickable = true;
//         context.currentFile = "Notes";
//         context.parentDir = null;
//         Object.keys(context.reflectionAnswers).forEach(qid => {
//           context.reflectionAnswers[qid].saved = true;
//         });
//         let rData = event.data.reflections[0];
//         context.reflectionSavedDateTime = DateToLocal(rData.modified);
//         for (let i = 0; i < event.data.reflections.length; i++) {
//           let rData = event.data.reflections[i];
//           context.reflection[rData.notesid] = {
//             ...context.reflection[rData.notesid],
//             savedDateTime: DateToLocal(rData.modified)
//           };
//         }
//       },
//       reflectionNotSaved: (context, event) => {
//         console.log("reflection NOT Saved");
//         context.showModal = true;
//         context.okCommand = "SAVE";
//         context.errorMessage = "Your Notes Did Not Save";
//         context.errorInstructions = "Press OK to try again.";
//       },
//       notesSettings: (context, event) => {
//         console.log("CI, event:", event);
//         context.showModal = false;
//         context.backClickable = false;
//         context.currentFile = "Notes";
//         context.forwardArrowClickable = true;
//         context.page = 1;
//         if ("userID" in event) context.userID = event.userID;
//         if ("api" in event) context.api = event.api;
//       },
//       reflectionSettings: (context, event) => {
//         context.notesID = event.notesID;
//         context.currentFile = event.currentFile;
//         context.backClickable = true;
//         context.parentDir = "Notes";
//       },
//       // typing: assign((ctx, e) => ({ answer: e.value })),
//       changeAnswer: (context, event) => {
//         console.log("CI, event:", event);
//         context.reflectionAnswers[event.questionID] = {
//           ...context.reflectionAnswers[event.questionID],
//           text: event.value
//         };
//         // Might want to load text from database into "originalText", to detect when code needs saving.
//         // context.reflectionAnswers[event.questionID].originalText;
//         context.reflectionAnswers[event.questionID].saved = false;
//         console.log("CI, answers:", context.reflectionAnswers);
//       },
//       chooseImage: (context, event) => {
//         // context.notesID = event.notesID;
//         context.backClickable = true;
//         // context.currentFile = event.currentFile;
//         // context.parentDir = "Notes";
//       },
//       chooseImageFromGallery: (context, event) => {
//         console.log("CI, event is:", event);
//         // context.reflectionPhotoImage = event.selection.src;
//         context.reflection[event.notesID] = {
//           imageID: event.selectionID
//         };
//         context.savedImage = event.selectionID;
//       },
//       finishCall: (context, event) => {
//         context.callStep = 0;
//         context.callInProgress = false;
//         context.showTablet = false;
//         context.done = true;
//       }
//     },
//     guards: {
//       morePages
//     }
//   }
// );

export const outroMachine = Machine(
  {
    id: "kayakReview",
    context: {
      ...context,
      scientistData: appDataCharlesNye,
      scientistSubTitles: [
        "“Did you enjoy the tide pools? Isn’t the intertidal zone amazing? The adaptations of the organisms living there are remarkable...“"
      ]
    },
    initial: "pickUpTablet",
    states: {
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "done"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        entry: ["finishCall"],
        type: "final"
      }
    }
  },
  {
    actions: {
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.done = true;
      }
    }
  }
);

export const testingMachine = Machine(
  {
    id: "naturalBridgesTesting",
    context: {
      ...context
    },
    initial: "pickUpTablet",
    states: {
      pickUpTablet: {
        on: {
          PRESS: {
            target: "done"
          }
        }
      },
      done: {
        entry: ["finishCall"],
        type: "final"
      }
    }
  },
  {
    actions: {
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.done = true;
      }
    }
  }
);

export const phtestMachine = Machine(
  {
    id: "phTesting",
    context: {
      environment: {
        className: "ph_testing",
        id: "ph_testing",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          thumbnail: imgNBTestingSmall,
          src: imgNBTesting,
          alt: "Tide Pool",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          largeRings: {
            visible: true,
            className: "large_rings",
            layers: [
              {
                name: "background",
                optimize: false,
                src: largeRings,
                handlesDrop: true,
                style: {
                  position: "absolute",
                  top: "18%",
                  left: "6%",
                  width: "40%",
                  opacity: "0.5"
                }
              }
            ]
          },
          phMeter: {
            visible: true,
            className: "ph_meter",
            id: "phMeter",
            draggable: false,
            showOverlay: true,
            ringing: false,
            layers: [
              {
                name: "background",
                optimize: false,
                src: largePHMeter,
                // handlesDrag: true,
                handlesHover: true
              },
              {
                handlesRing: true,
                name: "overlay",
                type: "lcd_display",
                handlesOverlay: true,
                className: "lcd_screen",
                data: phData[0]
              }
            ]
          },
          sampleCueLeft: {
            visible: false,
            ringing: true,
            layers: [
              {
                name: "background",
                src: imgSampleCue,
                handlesRing: true,
                style: {
                  position: "absolute",
                  top: "37%",
                  left: "15%",
                  width: "10%"
                }
              }
            ]
          },
          sampleCueRight: {
            visible: false,
            ringing: true,
            layers: [
              {
                name: "background",
                src: imgSampleCue,
                handlesRing: true,
                style: {
                  position: "absolute",
                  top: "37%",
                  left: "28%",
                  width: "10%",
                  transform: "scaleX(-1)"
                }
              }
            ]
          },
          beaker1: {
            visible: false,
            className: "beaker_1",
            droppable: true,
            dropClass: "left",
            dropAction: "TESTSAMPLE1",
            layers: [
              {
                name: "background",
                handlesDrop: true,
                optimize: false,
                src: imgBeaker1,
                style: {
                  position: "absolute",
                  top: "37%",
                  left: "1%",
                  width: "16%"
                }
              }
            ]
          },
          beaker2: {
            visible: false,
            className: "beaker_2",
            droppable: true,
            dropClass: "right",
            dropAction: "TESTSAMPLE2",
            layers: [
              {
                name: "background",
                handlesDrop: true,
                optimize: false,
                src: imgBeaker2,
                style: {
                  position: "absolute",
                  top: "35%",
                  left: "35%",
                  width: "16%"
                }
              }
            ]
          },
          beaker3: {
            visible: false,
            className: "beaker_3",
            droppable: true,
            dropClass: "left",
            dropAction: "TESTSAMPLE1",
            layers: [
              {
                name: "background",
                handlesDrop: true,
                optimize: false,
                src: imgBeaker3,
                style: {
                  position: "absolute",
                  top: "37%",
                  left: "2%",
                  width: "16%"
                }
              }
            ]
          },
          beaker4: {
            visible: false,
            className: "beaker_4",
            droppable: true,
            dropClass: "right",
            dropAction: "TESTSAMPLE2",
            layers: [
              {
                name: "background",
                handlesDrop: true,
                optimize: false,
                src: imgBeaker4,
                style: {
                  position: "absolute",
                  top: "35%",
                  left: "35%",
                  width: "16%"
                }
              }
            ]
          },
          clipboard: {
            visible: true,
            className: "clipboard",
            isClickable: true,
            showHover: true,
            ringing: true,
            showOverlay: true,
            showHighlight: true,
            action: "NEXT",
            layers: [
              {
                name: "background",
                handlesDrop: true,
                optimize: false,
                src: imgLargeClipboard,
                handlesClick: true,
                handlesHover: true,
                style: {
                  position: "absolute",
                  top: "1%",
                  left: "53%",
                  width: "47%"
                }
              },
              {
                name: "overlay",
                src: imgClipboardData1,
                style: {
                  position: "absolute",
                  top: "39%",
                  left: "74.5%",
                  width: "14%",
                  transform: "rotate(3deg)"
                }
              },
              {
                name: "overlay",
                visible: false,
                src: imgClipboardData2,
                style: {
                  position: "absolute",
                  top: "49%",
                  left: "74.5%",
                  width: "14%",
                  transform: "rotate(3deg)"
                }
              },
              {
                name: "highlight",
                type: "message",
                handlesRing: true,
                handlesHighlight: true,
                text: "Click the clipboard to advance"
              }
            ]
          }
        }
      },
      tabletBG: tabletBG,
      showTablet: true,
      itemObtained: null,
      buttonDisabled: true,
      phase: 1,
      // markerColors: ["#FFB802", "#008EE1", "#72B900", "#DB0000"],
      sampleData: [8.1, 7.5, 8.7, 7.8],
      sampleIndex: 1,
      sample1Index: 1,
      sample2Index: 2,
      sampleResults: [
        { index: 0, color: "blank", marker: "#1", value: "??" },
        { index: 1, color: "blank", marker: "#2", value: "??" }
      ],
      videoAutoplay: true
    },
    initial: "phaseToStart",
    // initial: "reviewClipBoard",
    states: {
      phaseToStart: {
        after: {
          10: [
            {
              cond: "phase2",
              target: "animateToCenter",
              actions: ["prepareNextPhase"]
            },
            {
              target: "testingFirstSample",
              cond: "skipVideo"
            },
            {
              target: "howToSample"
            }
          ]
          // 1000: [
          //   {
          //     cond: "phase2",
          //     target: "animateToCenter",
          //     actions: ["changeScreen"]
          //   }
          // ]
        }
      },
      howToSample: {
        entry: ["setupScene"],
        on: {
          VIDEODONE: {
            actions: ["enableSubmit"]
          },
          READY: {
            target: "testingFirstSample"
          }
        }
      },
      playVideo: {
        on: {
          "": [
            {
              target: "howToSample",
              actions: [assign({})]
            }
          ]
        }
      },
      testingFirstSample: {
        entry: ["setupScene", "prepareForFirstTest", "dimSampleTwo"],
        on: {
          DROP: [
            {
              target: "testingEducation"
            }
          ],
          TEST: [
            {
              target: "testResults",
              actions: ["setupTestResults"]
            }
          ],
          TESTSAMPLE1: [
            {
              target: "testResults",
              actions: ["startPHTest", "positionForLeftBeaker"]
            }
          ]
        }
      },
      animateToCenter: {
        after: {
          1000: { actions: ["changeScreen"] },
          2000: { actions: ["returnMeterToCenter"] },
          4000: { target: "testingSecondSample" }
        }
      },
      testingSecondSample: {
        entry: ["prepareForSecondTest", "dimSampleOne"],
        on: {
          TEST: [
            {
              target: "testResults",
              actions: ["setupTestResults"]
            }
          ],
          TESTSAMPLE2: [
            {
              target: "delayForResults",
              actions: ["startPHTest"]
            }
          ]
        }
      },
      delayForResults: {
        entry: ["startPHTest", "dimSampleTwo"],
        after: {
          1000: { actions: ["changeScreen", "readPH"] },
          2000: { actions: ["returnMeterToCenter"] },
          4000: { target: "showResults", actions: ["prepareForReview"] }
        }
      },
      showResults: {
        // entry: ["readPH", "returnMeterToCenter"],
        on: {
          TEST: {
            target: "reviewClipBoard"
          }
        }
      },
      reviewClipBoard: {
        entry: ["setClipBoardScene"],
        on: {
          NEXT: {
            target: "done"
          }
        }
      },
      testingSamples: {
        entry: ["prepareForFirstTest"],
        on: {
          TEST: [
            {
              target: "testResults",
              actions: ["setupTestResults"]
            }
          ],
          TESTSAMPLE1: [
            {
              target: "testResults",
              actions: [assign({})]
            }
          ],
          TESTSAMPLE2: [
            {
              target: "testResults",
              actions: [assign({})]
            }
          ]
        }
      },
      testingEducation: {
        entry: ["sceneToEducation"]
      },
      testResults: {
        entry: ["allowSubmit"],
        on: {
          TEST: [
            {
              target: "done",
              actions: ["prepareNextPhase"]
            }
          ]
        }
      },
      displayOnMap: {
        on: {
          TRANSFER: [
            {
              target: "displayOnMap",
              actions: [assign({})]
            }
          ]
        }
      },

      done: {
        entry: ["dimSampleOne", "finishScene"],
        type: "final"
      }
    }
  },
  {
    actions: {
      enableSubmit: (context, event) => {
        console.log("VIDEODONE sent");
        context.buttonDisabled = false;
        context.videoAutoplay = false;
      },
      buttonDisabled: (context, event) => {
        context.buttonDisabled = true;
      },
      allowSubmit: (context, event) => {
        context.buttonDisabled = false;
        context.buttonClass = "app_button ringing";
      },
      returnMeterToCenter: (context, event) => {
        context.environment.clickables.phMeter.style = {
          left: "18%",
          top: "34%",
          transform: "scale(1)",
          transitionProperty: "left, top, width, transform",
          transitionDuration: "2s"
        };
        context.buttonDisabled = true;
      },
      setupScene: (context, event) => {
        console.log("SetupScene:", context.sampleSite);
        context.environment.clickables.phMeter.layers[1].data = phData[0];
        if (context.sampleSite !== 2) {
          context.environment.clickables.beaker1.visible = true;
          context.environment.clickables.beaker2.visible = true;
        } else {
          context.environment.clickables.beaker1.visible = false;
          context.environment.clickables.beaker2.visible = false;
          context.environment.clickables.beaker3.visible = true;
          context.environment.clickables.beaker4.visible = true;
          context.environment.clickables.clipboard.showOverlay = true;
          context.sampleIndex = 3;
          context.sample1Index = 3;
          context.sample2Index = 4;
          context.sampleResults = [
            { index: 0, color: "blank", marker: "#3", value: "??" },
            { index: 1, color: "blank", marker: "#4", value: "??" }
          ];
        }
      },
      prepareNextPhase: (context, event) => {
        console.log("context.sampleSite:", context.sampleSite);
        context.buttonText = "Review Data";
        context.buttonDisabled = true;
        context.environment.clickables.phMeter.draggable = false;
        context.environment.clickables.phMeter.showHover = false;
        context.environment.clickables.phMeter.layers[1].data = phData[0];
        context.environment.clickables.phMeter.className =
          "ph_meter measuring left";
        const beaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker4
            : context.environment.clickables.beaker2;
        beaker.layers[0].style.filter = "";
        if (context.sampleSite === 2) {
          context.sampleIndex = 3;
          context.sample1Index = 3;
          context.sample2Index = 4;
          context.environment.clickables.clipboard.showOverlay = true;
          context.sampleResults = [
            { index: 0, color: "blank", marker: "#3", value: "??" },
            { index: 1, color: "blank", marker: "#4", value: "??" }
          ];
          context.environment.clickables.beaker3.visible = true;
          context.environment.clickables.beaker4.visible = true;
        } else {
          context.environment.clickables.beaker1.visible = true;
          context.environment.clickables.beaker2.visible = true;
        }
        console.log("context.sampleIndex:", context.sampleIndex);
      },
      finishScene: (context, event) => {},
      setClipBoardScene: (context, event) => {
        context.environment.clickables.clipboard.visible = true;
        context.environment.clickables.phMeter.showHover = false;
        if (context.sampleSite === 2)
          context.environment.clickables.clipboard.layers[2].visible = true;

        context.showTablet = false;
      },
      //      sampleData: [8.1, 7.5, 8.7, 7.8],
      changeScreen: (context, event) => {
        console.log("context.sampleIndex:", context.sampleIndex);
        context.environment.clickables.phMeter.ringing = false;
        context.environment.clickables.phMeter.layers[1].data =
          phData[context.sampleIndex];
        context.sampleIndex += 1;
      },
      readPH: (context, event) => {
        context.sampleResults[1].color = phData[context.sample2Index].color;
        context.sampleResults[1].value = phData[context.sample2Index].ph;
        context.environment.clickables.phMeter.showOverlay = true;
      },
      prepareForReview: (context, event) => {
        context.environment.clickables.phMeter.className = "ph_meter home"; // weird bug.  Will not take change if I change to ph_meter alone
        context.environment.clickables.phMeter.style = null;
        context.buttonDisabled = false;
        context.buttonClass = "app_button ringing";
        context.buttonText = "Review Data";
      },
      dimSampleOne: (context, event) => {
        const beaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker3
            : context.environment.clickables.beaker1;
        beaker.layers[0].style.filter = "brightness(.6)";
      },
      dimSampleTwo: (context, event) => {
        const beaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker4
            : context.environment.clickables.beaker2;
        beaker.layers[0].style.filter = "brightness(.6)";
      },
      prepareForFirstTest: (context, event) => {
        const beaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker4
            : context.environment.clickables.beaker2;
        context.environment.clickables.phMeter.draggable = true;
        context.environment.clickables.phMeter.showHover = true;
        context.environment.clickables.phMeter.ringing = false;
        context.environment.clickables.sampleCueLeft.visible = true;
        beaker.droppable = false;

        context.buttonAction = "TEST";
        context.buttonText =
          "Test Sample #" + (context.sampleSite === 2 ? 3 : 1);
      },
      prepareForSecondTest: (context, event) => {
        context.environment.clickables.phMeter.className = "ph_meter";
        context.environment.clickables.phMeter.style = null;
        const firstBeaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker3
            : context.environment.clickables.beaker1;
        const secondBeaker =
          context.sampleSite === 2
            ? context.environment.clickables.beaker4
            : context.environment.clickables.beaker2;

        context.sampleResults[0].color = phData[context.sample1Index].color;
        context.sampleResults[0].value = phData[context.sample1Index].ph;
        context.environment.clickables.phMeter.draggable = true;
        context.environment.clickables.phMeter.showHover = true;
        context.environment.clickables.phMeter.ringing = false;
        context.environment.clickables.sampleCueRight.visible = true;
        firstBeaker.droppable = false;
        secondBeaker.droppable = true;
        context.buttonAction = "TEST";
      },
      startPHTest: (context, event) => {
        console.log("hiding Cues");

        context.environment.clickables.phMeter.showHover = false;
        context.environment.clickables.phMeter.draggable = false;
        context.environment.clickables.sampleCueLeft.visible = false;
        context.environment.clickables.sampleCueRight.visible = false;
        context.environment.clickables.phMeter.ringing = true;
        console.log(
          "sampleCueRight.visible:",
          context.environment.clickables.sampleCueRight
        );
        // context.buttonDisabled = false;
      },
      setupTestResults: (context, event) => {
        console.log("setupTestResults");
      }
    },
    guards: {
      skipVideo: (context, event) => {
        return context.skipVideoIntro === true;
      },
      phase2: (context, event) => {
        // console.log(context.questionNumber, context.questionCount);
        console.log("Phase=", context.phase);
        return context.phase === 2;
      }
    }
  }
);
