import React, { useContext, useEffect, message } from "react";
import { Row, Col, Layout } from "antd";
import axios from "axios";
import UserContext from "context/User.js";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

const { Content } = Layout;

const Email = function(props) {
  const [objUser] = useContext(UserContext);
  const fnResend = function() {
    axios
      .post(objUser.api + "/api/forgot", {
        d: objUser.user.email,
        jwt: undefined
      })
      .then(objResponse => {})
      .catch(err => {
        //console.log(err);
      });
  };
  useEffect(() => {
    var objQuery = queryString.parse(props.history.location.search);
    if (objQuery.code) {
      axios
        .post(objUser.api + "/api/verify", {
          email: objQuery.email,
          code: objQuery.code
        })
        .then(objResponse => {
          console.log(objResponse);
          message.info("Email Verified please login");
          return <Redirect to={"/login"} />;
        })
        .catch(err => {
          //console.log(err);
        });
    }
  }, [props.history, objUser.api]);
  return (
    <Content className="contentPane">
      {props.history.location.pathname === "/email" && (
        <Row>
          <Col span={6} offset={9} className="container">
            <h2>Almost there!</h2>
            Check your inbox for verification email. Once you get it, click the
            verification link to access you account.
            <br />
            <br />
            {objUser.user && <>Email sent to: {objUser.user.email}</>}
            <br />
            <br />
            <div className="linkText" onClick={fnResend}>
              Resend link
            </div>
          </Col>
        </Row>
      )}
      {props.history.location.pathname === "/verify" && (
        <Row>
          <Col span={6} offset={9} className="container">
            <h2>Email verified</h2>
          </Col>
        </Row>
      )}
    </Content>
  );
};

export default Email;
