import React from "react";
import { TabletButton } from "components/Tablet";
import trophyFrame from "img/trophy_frame.png";
import trophyBG from "img/trophy_bg.jpg";
import trophyMBARI from "img/trophy_MBARI.png";
import trophyKayak from "img/trophy_kayak.png";
import trophyTidePool from "img/trophy_tide_pool.png";
import mbariTrophyPic from "img/mbari_trophy_room_pic.jpg";
import FaCheckCircle from "react-icons/lib/fa/check-circle";
import trophyLevelBG from "img/trophy_counter_circle.png";
import { TrophyExplorerBadge } from "components/ExplorerBadge";

const sections = [
  "Objectives",
  "Locations",
  "Data Base",
  "Activities",
  "Gallery"
];
const trophies = [
  { img: trophyMBARI, name: "MBARI Trophy" },
  { img: trophyKayak, name: "Kayak Trophy" },
  { img: trophyTidePool, name: "Tide Pool Trophy" }
];
const MC = props => {
  return (
    <div className={props.className}>
      <img src={props.src} alt={props.alt} />
      {props.children}
    </div>
  );
};

const Tabs = props => {
  return (
    <div className="trophy_tab_container">
      {sections.map((section, count) => {
        return (
          <div
            key={count}
            className={
              "trophy_tabs" + (props.highlight === count ? " selected" : "")
            }
          >
            {section}
          </div>
        );
      })}
    </div>
  );
};
const InfoCell = props => {
  const classDef = ["info_cell", props.selected, props.size]
    .filter(n => n)
    .join(" ");

  const baseStyle = { marginLeft: "2%", textAlign: "center" };
  return (
    <div className={classDef}>
      {props.src && <img src={props.src} alt={props.alt} />}

      <h1>
        {props.title}
        <FaCheckCircle
          style={
            props.selected ? baseStyle : { ...baseStyle, color: "#ffd702" }
          }
          size={"2vw"}
        />
      </h1>
      <p>{props.children}</p>
      {props.button && (
        <TabletButton onClick={props.action}>{props.button}</TabletButton>
      )}
    </div>
  );
};
const LeftView = props => {
  return <div className="trophy_cell left">{props.children}</div>;
};
const RightView = props => {
  return <div className="trophy_cell right">{props.children}</div>;
};
const TrophyTablet = props => {
  const { mSend } = props;

  return (
    <div className="trophy_tablet">
      <MC className="trophy_bg" src={trophyBG} alt="Underwater" />
      <MC
        className="trophy_frame"
        src={trophyFrame}
        alt="Review your trophies"
      />
      <div className="trophy_screen">
        <Tabs highlight={0} />
        <LeftView>
          <InfoCell title="Welcome to MBARI" selected="selected" size="thirty">
            Monterey Bay Aquarium Research Institute (MBARI) is a non-profit
            ocean research center in California. Well... Investigate!
          </InfoCell>
          <InfoCell title="Explore the Map" size="seventy">
            Welcome to Monterey Bay area. Explore the map and start
            investigating.
          </InfoCell>
        </LeftView>
        <RightView>
          <InfoCell
            title="Time to Learn"
            src={mbariTrophyPic}
            size="onehundred"
            button="Done!"
            action={() => mSend("DONE")}
          >
            Welcome to Monterey Bay Aquarium Research Institute. MBARI was
            founded in 1987 by David Packard (yes, the one who helped start
            Hewlett Packard) and and it has been a leading center for ocean
            research ever since. Guided by MBARI scientists, you will learn all
            about the underwater world. You will get to go behind-the-scenes and
            learn of their discoveries, experiments and even get to operate some
            equipment in real time.
          </InfoCell>
        </RightView>
      </div>

      {/* <MC className="trophy_profile_pic" src={profilePicture} alt="You!" /> */}
      <MC
        className="trophy_level_bg"
        src={trophyLevelBG}
        alt="Your new Level"
      ></MC>

      <TrophyExplorerBadge />
      <div className="trophy_label">Trophies:</div>
      <div className="trophy_room_label">Trophy Room</div>
      <div className="trophy_collection">
        {trophies.slice(0, 1).map((trophy, count) => {
          return (
            <MC
              className="trophy_badge"
              src={trophy.img}
              alt={trophy.name}
              key={count}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TrophyTablet;
