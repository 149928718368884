import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgBG from "img/elk_water_testing_map_bg.jpg";
import imgBGSmall from "img/elk_water_testing_map_bg_sm.jpg";
import imgSloughPath from "img/elk_slough_path.svg";
import imgTestTubeIcon from "img/test_tube_icon.svg";
import imgTesttube1 from "img/ks_testtube1.svg";
import imgTesttube2 from "img/ks_testtube2.svg";
import imgTesttube3 from "img/ks_testtube3.svg";
import imgTesttube4 from "img/ks_testtube4.svg";
import imgTesttube5 from "img/ks_testtube5.svg";
import tabletBG from "img/tablet_background.jpg";
import appData from "components/appCall/CallingKellyBenoitBird";
import {
  CallWSubTitles,
  ManualInitiateCall,
  IconInstruction
} from "components/TabletApps";

export const envMachine = Machine(
  {
    id: "waterTesting",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: false,
          src: imgBG,
          thumbnail: imgBGSmall,
          alt: "Elkorn Slough Map",
          color: "#132962",
          aspectRatio: "1919x1078"
        },
        clickables: {
          sloughPath: {
            visible: true,
            layers: [
              {
                name: "background",
                src: imgSloughPath,
                style: {
                  position: "absolute",
                  width: "35%",
                  top: "13%",
                  left: "6.9%"
                }
              }
            ]
          },
          sample_1: {
            visible: true,
            showHover: false,
            className: "markertag",
            action: "CHOOSE",
            type: "markertag",
            icon: imgTesttube1,
            data: "143 cfu/100mL",
            style: {
              top: "25%",
              left: "26%"
            },
            layers: [
              {
                type: "rect",
                name: "dotted_line",
                className: "dotted_line"
              },
              {
                type: "rect",
                name: "colored_flag",
                className: "colored_square one",
                handlesHover: true
              },

              {
                type: "label",
                data: "#1",
                className: "marker_number"
              },
              {
                type: "tag",
                className: "marker_value"
              }
            ]
          },
          sample_2: {
            visible: true,
            showHover: false,
            className: "markertag",
            type: "markertag",
            action: "CHOOSE",
            icon: imgTesttube2,
            data: "150 cfu/100mL",
            style: {
              top: "78%",
              left: "20%"
            },
            layers: [
              {
                type: "rect",
                name: "dotted_line",
                className: "dotted_line"
              },
              {
                type: "rect",
                name: "colored_flag",
                className: "colored_square two",
                handlesHover: true
              },

              {
                type: "label",
                data: "#2",
                className: "marker_number"
              },
              {
                type: "tag",
                className: "marker_value"
              }
            ]
          },
          sample_3: {
            visible: true,
            showHover: false,
            className: "markertag",
            type: "markertag",
            action: "CHOOSE",
            icon: imgTesttube3,
            data: "498 cfu/100mL",
            style: {
              top: "38%",
              left: "24.5%"
            },
            layers: [
              {
                type: "rect",
                name: "dotted_line",
                className: "dotted_line"
              },
              {
                type: "rect",
                name: "colored_flag",
                className: "colored_square three",
                handlesHover: true
              },

              {
                type: "label",
                data: "#3",
                className: "marker_number"
              },
              {
                type: "tag",
                className: "marker_value"
              }
            ]
          },
          sample_4: {
            visible: true,
            showHover: false,
            className: "markertag",
            type: "markertag",
            action: "CHOOSE",
            icon: imgTesttube4,
            data: "425 cfu/100mL",
            style: {
              top: "53%",
              left: "21%"
            },
            layers: [
              {
                type: "rect",
                name: "dotted_line",
                className: "dotted_line"
              },
              {
                type: "rect",
                name: "colored_flag",
                className: "colored_square four",
                handlesHover: true
              },

              {
                type: "label",
                data: "#4",
                className: "marker_number"
              },
              {
                type: "tag",
                className: "marker_value"
              }
            ]
          },
          sample_5: {
            visible: true,
            showHover: false,
            className: "markertag",
            type: "markertag",
            action: "CHOOSE",
            icon: imgTesttube5,
            data: "302 cfu/100mL",
            style: {
              top: "68%",
              left: "19%"
            },
            layers: [
              {
                type: "rect",
                name: "dotted_line",
                className: "dotted_line"
              },
              {
                type: "rect",
                name: "colored_flag",
                className: "colored_square five",
                handlesHover: true
              },

              {
                type: "label",
                data: "#5",
                className: "marker_number"
              },
              {
                type: "tag",
                className: "marker_value"
              }
            ]
          }
        }
      },
      callStep: 0,
      scientistData: appData,
      tabletBG: tabletBG,
      callCount: 0,
      scientistSubTitles: [
        "“Thanks for testing those sites! Let’s look at the data you recorded. Think about this question as we take a look:  Where do you think the contamination started?”"
      ],
      scientistSubTitles2: [
        "“You're right, the water sample with the highest CFU's per ml gives us a good clue as to where the contamination started. Now, here's a tougher question: Why do you think the CFU's we measured...”"
      ],
      showTablet: true,
      itemObtained: null,
      submitDisabled: true
    },
    initial: "pickUpTablet",
    states: {
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: ["startCall"]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "makeADecision",
              actions: ["finishCall", "enableHover"]
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      makeADecision: {
        on: {
          CHOOSE: [
            {
              actions: ["chooseSample", "enableSubmit"]
            }
          ],
          CONFIRM: [
            {
              target: "engageIn2ndCall",
              actions: []
            }
          ]
        }
      },
      showResults: {
        on: {
          ALLOWTRANSFER: {
            target: "done",
            actions: ["enableSubmit"]
          }
        }
      },
      engageIn2ndCall: {
        entry: [assign({ callCount: context => 1 })],
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return (
                  context.callStep + 1 < context.scientistSubTitles2.length
                );
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "done",
              actions: ["finishCall", "enableHover"]
            }
          ],
          BACK: [
            {
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },

      done: {
        entry: "finishScene",
        type: "final"
      }
    }
  },
  {
    actions: {
      chooseSample: (context, event) => {
        console.log("event=", event);
        if (context.chosen) {
          context.environment.clickables[context.chosen.sample].className =
            "markertag";
        }
        const chosenClickable =
          context.environment.clickables[event.clickableName];
        context.chosen = {
          sample: event.clickableName,
          icon: chosenClickable.icon,
          data: chosenClickable.data
        };
        console.log(chosenClickable.data);
        chosenClickable.className = "markertag chosen";
      },
      enableHover: (context, event) => {
        context.environment.clickables.sample_1.showHover = true;
        context.environment.clickables.sample_2.showHover = true;
        context.environment.clickables.sample_3.showHover = true;
        context.environment.clickables.sample_4.showHover = true;
        context.environment.clickables.sample_5.showHover = true;
      },
      enableSubmit: (context, event) => {
        console.log("Transfer is now allowed");
        context.submitDisabled = false;
      },
      disableSubmit: (context, event) => {
        context.submitDisabled = true;
      },
      addSample: (context, event) => {
        const clickableId = event.id;
        const idIndex = clickableId.replace("testtube_", "") - 1;
        context.samplesDropped[idIndex] = context.samplesData[idIndex];
        if (Object.keys(context.samplesDropped).length > 4) {
          context.submitDisabled = false;
        }
      },
      finishScene: (context, event) => {
        context.done = true;
      },
      allowDrag: (context, event) => {
        const clickables = context.environment.clickables;
        clickables.testtube_1.draggable = true;
        clickables.testtube_2.draggable = true;
        clickables.testtube_3.draggable = true;
        clickables.testtube_4.draggable = true;
        clickables.testtube_5.draggable = true;
      }
    }
  }
);

const Review = props => {
  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    switch (current.value) {
      case "pickUpTablet":
      case "waitForPage":
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={current.context.scientistData.callImg}
          />
        );
      case "engageIn2ndCall":
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.scientistData.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "ringScientist":
      default:
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={current.context.scientistData.callImg}
          />
        );
      case "makeADecision":
        return (
          <IconInstruction
            {...props}
            icon={
              current.context.chosen
                ? current.context.chosen.icon
                : imgTestTubeIcon
            }
            // iconStyle={current.context.chosenSample ? { width: "50%" } : null}
            iconClass={current.context.chosen ? "sample_icon" : null}
            alt={"Test Tube"}
            title={
              current.context.chosen
                ? current.context.chosen.data
                : "Make a Decision"
            }
            message={
              current.context.chosen
                ? "Water Sample from Elkhorn Slough"
                : "Click on the sample closest to where you think the contamination started."
            }
            buttonText={"Confirm Answer"}
            buttonAction={"CONFIRM"}
            buttonDisabled={current.context.submitDisabled}
          />
        );
    }
  };

  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
