import React, { useContext } from "react";
import UserContext from "context/User";
import { Machine, assign } from "xstate";
import imgBackground from "img/lab_background.jpg";
import imgBackgroundSmall from "img/lab_background_sm.jpg";
import imgBackgroundTablet from "img/lab_background_tablet.jpg";
import imgBackgroundTabletSmall from "img/lab_background_tablet_sm.jpg";
import imgLockedDoor from "img/locked_area.png";
import imgUnLockedDoor from "img/lab_door.png";
import imgPHCharts from "img/pg_ph_charts_obtained.png";
import imgChalkboardOverlay from "img/lab_chalkboard_overlay.svg";
import imgDeskTabletOverlay from "img/lab_desktablet_overlay.svg";
import appData from "components/appCall/CallingCharlesNye";
import NewAppBlastQuiz from "components/NewAppBlastQuiz";

import {
  CallWSubTitles,
  ManualInitiateCall,
  ShowVideo,
  AppScreen,
  AskScientist,
  // NotesSelection,
  Reflection,
  // Intro,
  AwardPageNewLevel,
  AwardPageRewards,
  BlankScreen
} from "components/TabletApps";

import {
  NotesIntro,
  NotesSelection
  // Reflection,
  // GallerySelection,
  // objQuestions,
  // fnDefaultAnswers
} from "components/NotesApp";

const morePages = (context, event) => {
  return context.page > 1;
};
export const labMachine = Machine(
  {
    id: "lab",
    context: {
      environment: {
        className: "env_lab",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgBackground,
          thumbnail: imgBackgroundSmall,
          alt: "Lab Background",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          deskTablet: {
            visible: true,
            isClickable: true,
            showHighlight: true,
            ringing: true,
            action: "ANSWER",
            className: "desk_tablet aclickable",
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: appData.deskTopImg,
                handlesClick: true
                // style: {
                //   position: "absolute",
                //   width: "5.0%",
                //   left: "38.3%",
                //   top: "52.2%"
                // }
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                src: imgDeskTabletOverlay
                // style: {
                //   position: "absolute",
                //   width: "6.5%",
                //   left: "37.5%",
                //   top: "50.5%"
                // }
              }
            ]
          },
          labDoor: {
            visible: true,
            isClickable: true,
            showHighlight: true,
            showHover: false,
            action: "OPEN",
            className: "labdoor",
            locked: true,
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgUnLockedDoor,
                handlesHighlight: true,
                handlesClick: true,
                handlesHover: true,
                handlesRing: true,
                // handlesRing: true,
                style: {
                  position: "absolute",
                  left: "50%",
                  top: "30%",
                  width: "14%"
                }
              },
              {
                name: "overlay",
                optimize: false,
                src: imgLockedDoor,
                handlesLock: true,
                style: {
                  position: "absolute",
                  left: "50%",
                  top: "30%",
                  width: "14%"
                }
              }
            ]
          },
          chalkBoard: {
            visible: false,
            isClickable: true,
            ringing: true,
            showHighlight: true,
            action: "CLICK",
            layers: [
              {
                name: "overlay",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgChalkboardOverlay,
                style: {
                  position: "absolute",
                  width: "13.6%",
                  left: "66.2%",
                  top: "35.4%"
                }
              }
            ]
          }
        },
        obtainable: {
          phCharts: {
            title: "Blank pH Charts",
            src: imgPHCharts,
            description: "Clipboard with the blank pH chart to fill."
          }
        }
      },
      showTablet: false,
      callStep: 0,
      tabletApp: "",
      done: false,
      calling: false,
      startWith: "labIntro",
      videoAfterCall: false,
      labLocked: true,
      autoAnswer: false,
      doneAfterCall: false,
      askAScientist: false
    },
    initial: "delayStart",
    states: {
      delayStart: {
        entry: ["initializeEnvironment"],
        after: {
          100: { target: "chooseStart" }
        }
      },
      chooseStart: {
        on: {
          "": [
            {
              cond: (context, event) => {
                console.log();
                return context.awardPageRewards === true;
              },
              target: "awardPageRewards"
            },
            {
              cond: (context, event) => {
                console.log();
                return context.notesAppScreen === true;
              },
              target: "notesAppScreen"
            },
            {
              cond: (context, event) => {
                console.log("askAScientist:", context.askAScientist);
                return context.askAScientist === true;
              },
              target: "appScreen"
            },
            {
              cond: (context, event) => {
                console.log();
                return context.autoAnswer === true;
              },
              target: "labFirstCall"
            },
            {
              cond: (context, event) => {
                console.log();
                return context.labLocked === false;
              },
              target: "unlockScene",
              actions: ["setupUnlockScene"]
            },
            {
              cond: (context, event) => {
                return context.startsWith === "labIntro";
              },
              target: "waitForMainScene"
            },
            {
              cond: (context, event) => {
                return context.trophyTablet === true;
              },
              target: "trophyTablet"
            },
            { target: "waitForMainScene", actions: ["setupScene"] }
          ]
        }
      },
      waitForMainScene: {
        after: {
          300: { target: "mainScene", actions: ["initializeEnvironment"] }
        }
      },
      awardPageRewards: {
        on: {
          CLAIM: {
            target: "awardPageNewLevel",
            actions: ["levelUp"]
          }
        }
      },
      awardPageNewLevel: {
        on: {
          CONTINUE: {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },
      unlockScene: {
        after: {
          1000: { target: "mainScene", actions: ["unlockDoor"] }
        }
      },
      mainScene: {
        on: {
          ANSWER: [
            {
              cond: (context, event) => {
                console.log("context.blastQuiz:", context.blastQuiz);
                return context.blastQuiz === true;
              },
              target: "appBlastQuiz",
              actions: ["shiftScene", "pickUpTablet"]
            },
            {
              target: "labFirstCall",
              actions: ["shiftScene", "pickUpTablet"]
            }
          ],
          CLICK: {
            target: "done"
          },
          OPEN: {
            target: "done"
          }
        }
      },
      trophyTablet: {
        on: {
          DONE: {
            target: "done"
          }
        }
      },
      appBlastQuiz: {
        entry: ["setupBlastQuiz"],
        on: {
          DONE: {
            target: "done",
            actions: [
              "unshiftScene",
              assign({ showTablet: false, tabletApp: "" })
            ]
          }
        }
      },
      labFirstCall: {
        on: {
          SLIDE: {
            target: "onCall",
            actions: ["startCall"]
          }
        }
      },
      onCall: {
        on: {
          FORWARD: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              cond: (context, event) => {
                return context.videoAfterCall;
              },
              target: "showVideo",
              actions: assign({ watchVideo: true })
            },
            {
              cond: (context, event) => {
                return context.doneAfterCall;
              },
              target: "done"
            },
            {
              target: "mainScene",
              actions: ["unshiftScene", "showChalkboard", "closeTablet"]
            }
          ],
          BACK: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      showVideo: {
        on: {
          VIDEODONE: {
            target: "videoDone",
            actions: assign({
              showTablet: false,
              tabletClickable: true,
              done: true
            })
          }
        }
      },
      videoDone: {
        on: {
          "": [
            {
              cond: (context, event) => {
                return context.secondCall === true;
              },
              target: "secondCall",
              actions: assign({
                done: true
              })
            },
            {
              target: "done"
            }
          ]
        }
      },
      secondCall: {
        entry: ["startCall", "prepareSecondCall"],
        on: {
          FORWARD: [
            {
              target: "onCall",
              cond: (context, event) => {
                return (
                  context.callStep + 1 < context.scientistSubTitles2.length
                );
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "mainScene",
              actions: ["unshiftScene", "finishCall"]
            }
          ],
          BACK: [
            {
              target: "onCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      appScreen: {
        on: {
          ASK: {
            target: "waitForAskAScientist",
            actions: assign({
              callInProgress: true,
              callConnected: true
            })
          }
        }
      },
      waitForAskAScientist: {
        after: {
          100: "askScientist"
        }
      },
      askScientist: {
        after: {
          3000: {
            target: "onCall",
            actions: [assign({ callConnected: false, inCall: true })]
          }
        }
        // invoke: {
        //   src: "animateRing",
        //   onDone: {
        //     target: "onCall",
        //     actions: [
        //       () => console.log("IT RAN"),
        //       assign({ callConnected: false, inCall: true })
        //     ]
        //   }
        // }
      },
      notesAppScreen: {
        on: {
          NOTES: {
            target: "intro",
            actions: assign({
              backClickable: false,
              forwardArrowClickable: true,
              page: 1
            })
          }
        }
      },
      intro: {
        on: {
          NOTES: {
            target: "notesSelection",
            actions: assign({
              backClickable: true,
              currentFile: "Notes",
              parentDir: null
            })
          },
          FORWARDARROW: [
            {
              target: "intro",
              cond: morePages,
              actions: ["nextPage"]
            },
            {
              target: "notesSelection",
              actions: assign({
                page: 1,
                backClickable: true
              })
            }
          ],
          BACKARROW: [
            {
              target: "intro",
              cond: morePages,
              actions: ["prevPage"]
            },
            {
              target: "notesSelection",
              actions: assign({
                page: 1,
                backClickable: true
              })
            }
          ]
        }
      },
      notesSelection: {
        on: {
          BACK: {
            target: "intro",
            actions: assign({
              backClickable: false,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          INTRO: {
            target: "intro",
            actions: assign({
              backClickable: true,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          MBARIREFLECT: {
            target: "reflection",
            actions: assign({
              backClickable: true,
              currentFile: "MBARI Reflection",
              parentDir: "Notes"
            })
          },
          DONE: {
            target: "done",
            actions: assign({
              backClickable: true,
              currentFile: "MBARI Reflection",
              parentDir: "Notes",
              done: true
            })
          }
        }
      },
      reflection: {
        on: {
          TEXTFINISHED: [
            {
              target: "reflection",
              cond: morePages,
              actions: assign({
                qNum: context => context.qNum + 1
              })
            },
            {
              target: "reflection",
              actions: assign({
                canSave: true
              })
            }
          ],
          BACK: {
            target: "intro",
            actions: () => console.log("Should save")
          },
          SAVE: {
            target: "notesSelection",
            actions: assign({
              backClickable: true,
              currentFile: "Notes",
              parentDir: null
            })
          }
        }
      },
      done: {
        entry: ["finishScene", "unshiftScene"],
        type: "final"
      }
    }
  },
  {
    actions: {
      initializeEnvironment: (context, event) => {
        console.log("initializeEnvironment:", context);
        if (context.notesAppScreen) {
          context.environment.clickables.deskTablet.visible = true;
          context.environment.shiftBackground = true;
          context.environment.background.src = imgBackgroundTablet;
          context.environment.background.thumbnail = imgBackgroundTabletSmall;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
          context.showTablet = true;
          context.environment.clickables.deskTablet.visible = false;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
          context.environment.clickables.labDoor.visible = false;
        }
        if (context.askAScientist) {
          context.environment.shiftBackground = true;
          context.environment.background.src = imgBackgroundTablet;
          context.environment.background.thumbnail = imgBackgroundTabletSmall;
          context.showTablet = true;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
          context.environment.clickables.labDoor.visible = false;
          context.environment.clickables.deskTablet.visible = false;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
        }
        if (context.autoAnswer) {
          context.environment.clickables.deskTablet.visible = true;
          context.environment.shiftBackground = true;
          context.environment.background.src = imgBackgroundTablet;
          context.environment.background.thumbnail = imgBackgroundTabletSmall;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
          context.showTablet = true;
        }
        if (context.awardPageRewards) {
          context.environment.clickables.labDoor.visible = false;
          context.environment.clickables.deskTablet.visible = false;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.clickables.deskTablet.showHighlight = false;
        }
        if (context.scientistData) {
          context.environment.clickables.deskTablet.layers[0].src =
            context.scientistData.deskTopImg;
        }
        if (context.blastQuiz) {
          context.environment.clickables.deskTablet.visible = true;
          context.environment.clickables.deskTablet.isClickable = true;
          context.environment.clickables.deskTablet.ringing = true;
          context.environment.clickables.deskTablet.showHighlight = true;
        }
        if (context.gameFinish) {
          context.environment.clickables.labDoor.visible = false;
        }

        if (context.lesson) {
          context.environment.clickables.deskTablet.visible = true;
          context.environment.clickables.deskTablet.showHighlight = true;
          context.environment.clickables.deskTablet.ringing = true;
        }
        if (context.trophyTablet) {
          console.log("showBadge: ", context.showBadge);
          context.environment.className += " blur_bg";
          context.environment.clickables.labDoor.visible = false;
          context.showBadge = false;
          context.environment.clickables.deskTablet.visible = false;
          context.environment.clickables.deskTablet.showHighlight = false;
          context.environment.clickables.deskTablet.ringing = false;
          context.environment.shiftBackground = false;
          context.environment.background.src = imgBackground;
          context.environment.background.thumbnail = imgBackgroundSmall;
        }
        console.log(context.scientistData);
      },
      pickUpTablet: (context, event) => {
        context.environment.clickables.deskTablet.ringing = false;
        context.environment.clickables.deskTablet.showHighlight = false;
        context.showTablet = true;
      },
      levelUp: (context, event) => {
        context.level = context.level + 1;
      },
      nextPage: (context, event) => {
        context.page = context.page + 1;
        context.backArrowClickable = true;
        context.forwardArrowClickable = false;
      },
      prevPage: (context, event) => {
        if (context.page > 1) {
          context.page = context.page - 1;
          context.backArrowClickable = true;
        } else {
          context.backArrowClickable = false;
        }
      },
      blankScreen: (context, event) => {
        context.environment.clickables.deskTablet.visible = false;
      },
      autoAnswer: (context, event) => {
        context.environment.clickables.deskTablet.ringing = false;
        context.environment.clickables.deskTablet.showHighlight = false;
        context.environment.clickables.labDoor.visible = false;
      },
      setupUnlockScene: (context, event) => {
        context.environment.clickables.deskTablet.visible = false;
        // context.environment.clickables.labDoor.visible = true;
        context.environment.clickables.labDoor.ringing = true;
      },
      unlockDoor: (context, event) => {
        context.environment.clickables.labDoor.locked = false;
      },
      setupBlastQuiz: (context, event) => {
        context.environment.clickables.deskTablet.visible = false;
      },
      finishScene: (context, event) => {
        context.environment.clickables.chalkBoard.ringing = false;
        context.environment.clickables.chalkBoard.showHighlight = false;
        context.done = true;
      },
      closeTablet: (context, event) => {
        context.showTablet = false;
      },
      showChalkboard: (context, event) => {
        context.environment.clickables.chalkBoard.visible = true;
      },
      setupScene: (context, event) => {
        context.environment.clickables.deskTablet.layers[0].src =
          context.scientistData.deskTopImg;
        context.environment.clickables.deskTablet.ringing = true;
      },
      setupIntro: (context, event) => {
        context.environment.clickables.deskTablet.visible = true;
        context.environment.clickables.deskTablet.ringing = true;
      },
      startCall: (context, event) => {
        console.log("startCall");
        context.showTablet = true;
        context.callConnected = true;
        context.environment.clickables.deskTablet.visible = false;
      },
      shiftScene: (context, event) => {
        console.log("shifting:");
        context.environment.shiftBackground = true;
        context.environment.background.src = imgBackgroundTablet;
        context.environment.background.thumbnail = imgBackgroundTabletSmall;
        context.showTablet = true;
        console.log(context.environment);
      },
      unshiftScene: (context, event) => {
        console.log("unshifting:");
        context.environment.shiftBackground = false;
        context.environment.background.src = imgBackground;
        context.environment.background.thumbnail = imgBackgroundSmall;
      }
    },
    guards: {
      morePages
    }
  }
);
export const TabletContent = props => {
  const { current } = props;
  const [objUserContext] = useContext(UserContext);
  console.log("State is now:", current.value);
  switch (current.value) {
    case "pickUpTablet":
    case "waitForPage":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={props.current.context.scientistData.callImg}
        />
      );
    case "onCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "showVideo":
      return (
        <ShowVideo
          videoId={current.context.videoId}
          videoTitle={current.context.videoTitle}
          videoIntroduction={current.context.videoIntroduction}
          videoCaption={current.context.videoCaption}
          onEnd={() => props.mSend("VIDEODONE")}
          mSend={props.mSend}
          start={current.context.videoStart}
          end={current.context.videoEnd}
          noButton={true}
        />
      );
    // case "showVideoForAskAScientist":
    //   return (
    //     <ShowVideo
    //       videoId="Hq-XERexZL4"
    //       videoTitle="High-tech robots reveal the deep"
    //       videoIntroduction="On land, we map details of the Earth with our eyes and hands, and use satellite images to trace out broader regions."
    //       videoCaption="Underwater robots can help map underwater landscapes."
    //       onEnd={() => props.mSend("VIDEODONE")}
    //       mSend={props.mSend}
    //       noButton={true}
    //     />
    //   );
    case "appScreen":
      console.log("display appScreen");
      return <AppScreen mSend={props.mSend} focus="AskScientist" />;
    case "appBlastQuiz":
      return <NewAppBlastQuiz tabletSend={props.mSend} quiz={props.theQuiz} />;
    case "askScientist":
      return (
        <AskScientist
          current={current}
          msend={props.mSend}
          ringTimeLine={props.ringTimeLine}
        />
      );
    // case "chooseStart":
    // case "delayStart":
    case "notesAppScreen":
      return <AppScreen mSend={props.mSend} focus="Notes" />;
    case "notesSelection":
      return (
        <NotesSelection
          {...props}
          notesID={current.context.notesID}
          userID={objUserContext.user.id}
        />
      );
    case "reflection":
      return <Reflection {...props} />;
    case "intro":
      return <NotesIntro {...props} />;
    case "awardPageNewLevel":
      return <AwardPageNewLevel {...props} level={props.objUser.user.level} />;
    case "awardPageRewards":
      return (
        <AwardPageRewards
          {...props}
          arrBadges={props.arrBadges}
          title="MBARI Lab"
          onClick={props.levelUpAction}
        />
      );
    case "ringScientist":
    case "labFirstCall":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={props.current.context.scientistData.callImg}
        />
      );
    default:
      return <BlankScreen />;
  }
};
