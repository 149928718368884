import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
import appData from "components/appCall/CallingCharlesNye";
import UserContext from "context/User";
import { TabletContent, labMachine as envMachine } from "./LabEnvironment";

const context = {
  id: "intro",
  scientistSubTitles: [
    "Great work! Now you know how to use basic block code to control the AUV. Now, let’s take a look at how our tools, lab and team of scientists and engineers come together to conduct ocean research."
  ],
  scientistData: appData,
  autoAnswer: true,
  doneAfterCall: true,
  gameFinish: true
};

const GameFinish = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });

  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default GameFinish;
