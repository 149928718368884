import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";

import { useMachine } from "@xstate/react";

import imgVideoStill from "img/ph_testing_video_still.svg";
// import gsap from "gsap/dist/gsap";
// import { TimelineLite, CSSPlugin } from "gsap";
// import UserContext from "context/User";
import { phtestMachine as envMachine } from "./NaturalBridgesEnvironment";
import {
  DragAndTestResults,
  LessonPage
  // TestingPage
} from "components/TabletApps";
// //eslint-disable-next-line
// const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
// gsap.registerPlugin(CSSPlugin);

const Review = props => {
  const [current, mSend] = useMachine(envMachine, {
    context: {
      ...envMachine.context,
      ...{
        phase: props.phase,
        skipVideoIntro: props.skipVideoIntro,
        sampleSite: props.sampleSite
      }
    }
  });
  // console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    console.log(current.value);
    console.log("props.sampleSite:", props);
    const siteName =
      props.sampleSite === 2 ? "Natural Bridges" : "Pacific Grove";

    switch (current.value) {
      case "phaseToStart":
      case "delayForResults":
      case "showResults":
      case "testingFirstSample":
      case "testingSecondSample":
      case "testingSamples":
      case "testResults":
      case "animateToCenter":
        const siteCount = props.sampleSite ? props.sampleSite : 1;
        const beakerNumber = (siteCount - 1) * 2 + current.context.phase;
        const instruction = `Drag’n’drop pH meter on beaker #${beakerNumber} to test sample.`;
        console.log("instruction:", instruction);
        return (
          <DragAndTestResults
            {...props} // mSend is already set
            isDraggable={
              current.context.environment.clickables.phMeter.draggable
            }
            title={
              "pH TESTING" + (props.sampleSite ? " " + props.sampleSite : "")
            }
            titleCapitalize={false}
            // image={imgPHTestingBeakers}
            buttonClass={current.context.buttonClass || "app_button"}
            buttonText={current.context.buttonText || "Test"}
            alt="pH Testing"
            instructions={instruction}
            action="TEST"
            buttonDisabled={current.context.buttonDisabled}
            buttonAction={current.context.buttonAction}
            samples={current.context.sampleResults.map((sample, count) => ({
              // color: colorMap[count],
              color: sample.color,
              marker: `${sample.marker}`,
              name: `${siteName} Tide Pool, pH #${count + 1} - ${
                sample.value
              } pH`
            }))}
          />
        );
      case "howToSample":
      default:
        return (
          <LessonPage
            title="Water Testing"
            introduction="How we prepare the water samples in the lab for testing"
            videoStillAlt="Lab technician, testing samples"
            videoStill={imgVideoStill}
            videoCaption="Since you are on the field, drag the pH meter into each water sample.  What do you think the readings will be?"
            buttonText="I'm ready"
            buttonAction="READY"
            videoId="lIX0ky1IBkM"
            buttonDisabled={current.context.buttonDisabled}
            start="50"
            end="130"
            mSend={mSend}
            autoplay={current.context.videoAutoplay}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
