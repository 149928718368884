import React, { useEffect, useContext } from "react";
import background from "img/mb_map.jpg";
import backgroundSmall from "img/mb_map_sm.jpg";

import markerSign from "img/marker_sign.png";
import markerLab from "img/marker_icon_lab.svg";
import markerElkhorn from "img/marker_icon_elkhorn.svg";
import markerTidepool from "img/marker_icon_tidepool.svg";
import mapBannerBG from "img/map_banner_bg.png";
import mapInfoPhoto from "img/map_corner_photo_charles_nye.png";
import mapInfoPhotoText from "img/map_banner_text_charles_nye.png";
import journalTab from "img/journal_tab.png";

import questOceanDiscoveryLogo from "img/ocean_discovery_logo.png";
import { NavLink } from "react-router-dom";
import "./MBARIMap.css";
import ExplorerBadge from "./ExplorerBadge";
import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";
// import { setupMaster } from "cluster";
import UserContext from "context/User";
import Image from "lqip-react";

export const mapMachine = Machine(
  {
    id: "map",
    context: {
      step: 0,
      lesson: 1,
      objective: "Welcome!",
      showInfoBanner: true,
      lessonName: { 1: "lab", 2: "elkhorn", 3: "tidepool" },
      sendCommand: {
        1: "EXPLORELAB",
        2: "EXPLOREELKORN",
        3: "EXPLORETIDEPOOL"
      },
      canClick: {
        1: { lab: false, elkhorn: false, tidepool: false },
        2: { lab: false, elkhorn: false, tidepool: false },
        3: { lab: false, elkhorn: false, tidepool: false }
      },
      showMarker: {
        1: { lab: true, elkhorn: false, tidepool: false },
        2: { lab: true, elkhorn: true, tidepool: false },
        3: { lab: true, elkhorn: true, tidepool: true }
      },
      nextObjective: {
        1: ["Head to MBARI Station", "Explore MBARI"],
        2: ["Head to Elkhorn Slough", "Explore Elkhorn Slough"],
        3: [
          "Head to Pacific Grove Tide Pool",
          "Explore Pacific Grove Tide Pool"
        ]
      },
      bannerText: [
        [
          "First off, I’d like to show you around the Monterey Bay Aquarium Research Institute. MBARI’s mission is to be a world center for advanced research and education in ocean science and technology. ",
          "We will meet many scientists and engineers (real life ones) who work here along our journey. We will also learn of recent discoveries, get to participate in experiments and even operate some of MBARI’s underwater machinery."
        ],
        [
          "I know you learned a lot in the lab. Next, we’ll head over to Elkhorn Slough to explore the area and take a ride in the kayak. It is a beautiful place where the river meets the ocean.  There are many intriguing organisms to see in this ecosystem."
        ],
        [
          "We are going to explore two tide pools next: Pacific Grove and Natural Bridges. Tide pools are a part of fascinating ecosystems with ever-changing conditions. The adaptations of the organisms that live in them are incredible."
        ]
      ],
      altBannerText: {
        natural_bridges: [
          "Aren’t those barnacles interesting? I’ve learned the hard way that their shells are very, very sharp! Thanks for getting the pH reading for us. Onto the second tide pool location, Natural Bridges- it’s a cool one! "
        ]
      },
      currentText: function() {
        if (this.alt) return [this.altBannerText[this.alt]];
        else return [this.bannerText[this.lesson - 1]];
      }
    },
    initial: "initial",
    states: {
      initial: {
        entry: ["changeObjective"],
        on: {
          "": {
            target: "reading"
          }
        }
      },
      reading: {
        on: {
          FORWARD: [
            {
              target: "reading",
              cond: (context, event) => {
                console.log(
                  "This test:",
                  context.step + 1 < context.currentText().length
                );
                return context.step + 1 < context.currentText().length;
              },
              actions: [
                assign({
                  step: context => context.step + 1
                })
              ]
            },
            {
              target: "explore_map"
            }
          ]
        } //skip welcome_explorer - might one day be needed
      },
      explore_map: {
        entry: ["exploreMap", "changeObjective"],
        on: {
          EXPLORELAB: "explore_lab",
          EXPLOREELKHORN: "explore_elkorn"
        }
      },
      explore_lab: {
        entry: ["exploreLab", "changeObjective"],
        type: "final"
      },
      explore_elkorn: {
        type: "final"
      }
    }
  },
  {
    actions: {
      exploreMap: (context, event) => {
        let setting = context.canClick[context.lesson];
        setting[context.lessonName[context.lesson]] = true;
        context.step = 0;
        context.canClick = { ...context.canClick, setting };
        context.showInfoBanner = false;
        context.showJournalTab = true;
        context.objective = context.nextObjective[context.lesson][0];
      },
      exploreLab: (context, event) => {
        context.objective = context.nextObjective[context.lesson][1];
      }
    }
  }
);

const MBARIMap = props => {
  const [objUser] = useContext(UserContext);
  mapMachine.context.lesson = props.lesson || 1;
  mapMachine.context.alt = props.alt;
  objUser.level = mapMachine.context.lesson;
  objUser.level = props.lesson;
  if (props.mainScreen) {
    mapMachine.context.showJournalTab = true;
  }

  // const changeObjective = context => {
  //   console.log(context.objective);
  //   console.log(objUser);
  //   if ("user" in objUser) {
  //     var objUpdate = objUser.user;
  //     objUpdate.objective = context.objective;
  //     objUser.fnUpdateField("user", objUpdate);
  //   }
  //   // objUser.fnStoreLevel();
  // };

  const [current, send] = useMachine(mapMachine, {
    actions: {
      changeObjective: (context, event) => {
        console.log(context.objective);
        console.log(objUser);
        if ("user" in objUser) {
          var objUpdate = objUser.user;
          objUpdate.objective = context.objective;
          objUser.fnUpdateField("user", objUpdate);
        }
      }
    }
  });
  const { step, showInfoBanner, showJournalTab } = current.context;

  const QuestCornerBranding = () => {
    /* <div className="corner_swirl" /> This should be included to darken the area below the Quest logo.  Futur */

    return (
      <div className="corner_logo">
        <img
          alt="Quest Ocean Discovery Logo"
          className="quest_ocean_discovery_logo"
          src={questOceanDiscoveryLogo}
        />
      </div>
    );
  };

  const LabMarker = props => {
    // console.log("Props.to:", props.to);
    const canClick =
      current.context.canClick[current.context.lesson][props.nick];
    const marker_classes = "marker1" + (canClick ? " canclick" : "");
    const Navigation = props => {
      if (canClick) {
        // console.log("Can click:", true);
        // console.log(props.to);
        return (
          <NavLink
            to={props.to}
            onClick={() =>
              send(current.context.sendCommand[current.context.lesson])
            }
          >
            {props.children}
          </NavLink>
        );
      } else {
        // console.log("Can click:", false);
        // console.log(props.to);
        return <>{props.children}</>;
      }
    };
    const signPostClasses = "sign_post" + (!canClick ? " visited" : "");
    return (
      <Navigation {...props}>
        <div className={marker_classes} style={props.style}>
          <div className={signPostClasses}>
            <img alt="Marker" src={markerSign} />
          </div>
          <div className="marker_icon" style={props.iconStyle}>
            <img alt={props.alt} src={props.overlaySrc} />
          </div>
        </div>
      </Navigation>
    );
  };

  const LabMarkers = props => {
    const markers = [
      {
        nick: "lab",
        alt: "MBARI Lab",
        overlaySrc: markerLab,
        to: "/lab/intro",
        iconStyle: { left: "27%", top: "19%", width: "110%" }
      },
      {
        nick: "elkhorn",
        alt: "Elkhorn Slough",
        overlaySrc: markerElkhorn,
        to: "/elkhorn/intro",
        style: { top: "27%", left: "79%" },
        iconStyle: { left: "25%", top: "19%", width: "110%" }
      },
      {
        nick: "tidepool",
        alt: "Pacific Grove and Natural Bridges",
        overlaySrc: markerTidepool,
        to: "/tidepools/intro",
        style: { top: "52%", left: "67%" },
        iconStyle: { left: "22%", top: "20%", width: "125%" }
      }
    ];
    const altmarker = {
      natural_bridges: [
        {
          nick: "tidepool",
          alt: "Pacific Grove and Natural Bridges",
          overlaySrc: markerTidepool,
          to: props.nextStep,
          style: { top: "9.5%", left: "52.5%" },
          iconStyle: { left: "24%", top: "21%", width: "125%" }
        }
      ]
    };
    const markerRef = current.context.alt
      ? altmarker[current.context.alt]
      : markers;
    return (
      <>
        {markerRef.map((marker, count) => [
          current.context.showMarker[current.context.lesson][marker.nick] && (
            <LabMarker
              overlaySrc={marker.overlaySrc}
              alt={marker.alt}
              key={count}
              style={marker.style}
              to={marker.to}
              iconStyle={marker.iconStyle}
              nick={marker.nick}
            />
          )
        ])}
      </>
    );
  };

  const JournalTab = prop => {
    return (
      <img
        src={journalTab}
        alt="Journal Tab"
        style={{ position: "absolute", bottom: "8%", left: "0", width: "5%" }}
      />
    );
  };
  const InfoBanner = () => {
    //const [_current, send] = useMachine(mapMachine);
    //console.log(current.value);
    const handleUserKeyPress = event => {
      const { keyCode } = event;

      if (keyCode === 32 && event.target === document.body) {
        send("FORWARD");
      }
    };

    useEffect(() => {
      window.addEventListener("keyup", handleUserKeyPress);

      return () => {
        window.removeEventListener("keyup", handleUserKeyPress);
      };
    });

    return (
      <div>
        <div className="map_banner_bg">
          <img alt="Info Banner Background" src={mapBannerBG} />
        </div>
        <div className="map_banner_photo">
          <img alt="Info Banner" src={mapInfoPhoto} />
        </div>
        <div className="map_banner_photo_text">
          <img alt="Info Banner Text" src={mapInfoPhotoText} />
        </div>
        <p className="map_banner_text">
          {current.context["currentText"]()[step]}
        </p>
        <div className="press_space">Press space to continue</div>
      </div>
    );
  };
  // const MBARIMap = () => {
  //   return <img alt="Map of Monteray Bay" src={background} />;
  // };

  return (
    <div className="mbari_container">
      <Image
        src={background}
        thumbnail={backgroundSmall}
        color="#132962"
        aspectRatio={"1920x1080"}
        alt="Map of Monteray Bay"
      />
      <ExplorerBadge {...props} />
      {/* noObjective={true} */}
      <QuestCornerBranding />
      <LabMarkers {...props} />
      {showInfoBanner && <InfoBanner />}
      {showJournalTab && <JournalTab />}
    </div>
  );
};

export default MBARIMap;
