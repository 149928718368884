import React, { useContext } from "react";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { TabletButton } from "components/Tablet";
import trophyFrame from "img/trophy_frame.png";
import trophyBG from "img/trophy_bg.jpg";
import trophyMBARI from "img/trophy_MBARI.png";
import trophyKayak from "img/trophy_kayak.png";
import trophyTidePool from "img/trophy_tide_pool.png";
import trophyImg from "img/elkhorn_trophy_slough.jpg";
import { Redirect } from "react-router-dom";
import FaCheckCircle from "react-icons/lib/fa/check-circle";
import trophyLevelBG from "img/trophy_counter_circle.png";
import { TrophyExplorerBadge } from "components/ExplorerBadge";
import UserContext from "context/User.js";
import { Environment } from "components/Environment";
import imgBackgroundSmall from "img/nb_tidepool_bg_sm.jpg";

export const finalMachine = Machine({
  id: "trophy_room",
  context: {
    environment: {
      className: "env_elkhorn blur_bg",
      shiftBackground: false,
      background: {
        optimize: false,
        src: imgBackgroundSmall,
        alt: "Natural Bridges Tide Pool",
        color: "#132962",
        aspectRatio: "1920x1080"
      },
      done: false
    },
    showBadge: false
  },
  initial: "trophyPanel",
  states: {
    trophyPanel: {
      on: {
        DONE: {
          target: "done",
          actions: assign({ done: true })
        }
      }
    },

    done: {
      type: "final"
    }
  }
});

const sections = [
  "Objectives",
  "Locations",
  "Data Base",
  "Activities",
  "Gallery"
];
const trophies = [
  { img: trophyMBARI, name: "MBARI Trophy" },
  { img: trophyKayak, name: "Kayak Trophy" },
  { img: trophyTidePool, name: "Tide Pool Trophy" }
];

// const myTrophies = ["MBARI Trophy"];
const MC = props => {
  return (
    <div className={props.className}>
      <img src={props.src} alt={props.alt} />
      {props.children}
    </div>
  );
};

const Tabs = props => {
  return (
    <div className="trophy_tab_container">
      {sections.map((section, count) => {
        return (
          <div
            className={
              "trophy_tabs" + (props.highlight === count ? " selected" : "")
            }
          >
            {section}
          </div>
        );
      })}
    </div>
  );
};
const InfoCell = props => {
  const classDef = ["info_cell", props.selected, props.size]
    .filter(n => n)
    .join(" ");

  const baseStyle = { marginLeft: "2%", textAlign: "center" };
  return (
    <div className={classDef}>
      {props.src && <img src={props.src} alt={props.alt} />}

      <h1>
        {props.title}
        <FaCheckCircle
          style={
            props.selected ? baseStyle : { ...baseStyle, color: "#ffd702" }
          }
          size={"2vw"}
        />
      </h1>
      <p>{props.children}</p>
      {props.button && (
        <TabletButton onClick={props.action}>{props.button}</TabletButton>
      )}
    </div>
  );
};
const LeftView = props => {
  return <div className="trophy_cell left">{props.children}</div>;
};
const RightView = props => {
  return <div className="trophy_cell right">{props.children}</div>;
};

const Final = props => {
  const [current, mSend] = useMachine(finalMachine);
  const { done } = current.context;
  const [objUser] = useContext(UserContext);

  if ("user" in objUser) objUser.user.objective = "Claim Reward";

  console.log(done);
  if (done) {
    return <Redirect to={props.nextStep} />;
  }
  const TrophyTablet = props => {
    return (
      <div className="trophy_tablet">
        <MC className="trophy_bg" src={trophyBG} alt="Underwater" />
        <MC
          className="trophy_frame"
          src={trophyFrame}
          alt="Review your trophies"
        />
        <div className="trophy_screen">
          <Tabs highlight={0} />
          <LeftView>
            <InfoCell
              title="Pacific Grove Tide Pool"
              selected="selected"
              size="twentyfive"
            >
              Tide pools form in depressions on rocky shorelines in the
              intertidal zone. The intertidal zone is the area between the high
              and low tide on the ocean shore.
            </InfoCell>
            <InfoCell title="ELKHORN SLOUGH AND KAYAK STATION">
              The Elkhorn Slough is a magical place full of wildlife and beauty.
              Meandering seven miles inland from the coast in the center of the
              picturesque Monterey Bay.
            </InfoCell>
            <InfoCell title="Welcome to MBARI">
              Monterey Bay Aquarium Research Institute (MBARI) is a non-profit
              ocean research center in California. Well... Investigate!
            </InfoCell>
            <InfoCell title="Explore the Map">
              Welcome to Monterey Bay area. Explore the map and start
              investigating.
            </InfoCell>
          </LeftView>
          <RightView>
            <InfoCell
              title="Pacific Grove Tide Pool"
              src={trophyImg}
              size="onehundred"
              button="Done!"
              action={() => mSend("DONE")}
            >
              Tide pools form in depressions on rocky shorelines in the
              intertidal zone. The intertidal zone is the area between the high
              and low tide on the ocean shore. Tide pools stay filled with water
              in low tide, providing habitat for many marine organisms. That is
              why we need your help. Will you go to the Pacific Grove and
              Natural Bridges tide pools to measure the pH? We’ll need two
              readings from each of the tide pools to fill in the data we are
              missing on our chart. I suggest spending time between readings
              exploring the tide pools and getting to know the organisms that
              live there. They are fascinating to watch and have many
              interesting adaptations.
            </InfoCell>
          </RightView>
        </div>

        <MC
          className="trophy_level_bg"
          src={trophyLevelBG}
          alt="Your new Level"
        ></MC>

        <TrophyExplorerBadge levelStyle={{ left: "1.7vw" }} />
        <div className="trophy_label">Trophies:</div>
        <div className="trophy_room_label">Trophy Room</div>
        <div className="trophy_collection">
          {trophies.slice(0, 2).map(trophy => {
            return (
              <MC className="trophy_badge" src={trophy.img} alt={trophy.name} />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <TrophyTablet mSend={mSend} {...props} />
    </Environment>
  );
};

export default Final;
