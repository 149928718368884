import React from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet } from "components/UIComponents";
import { useMachine } from "@xstate/react";
// import UserContext from "context/User";
import {
  dndMachine as envMachine,
  FinalTestContent as TabletContent
} from "./StationEnvironment";
import multipleChoice from "custom/lab/BlastQuiz";
import imgSlough from "img/elkDnD/slough.jpg";
import imgSloughSmall from "img/elkDnD/slough_sm.jpg";
import imgOcean from "img/elkDnD/ocean.jpg";
import imgEstuary from "img/elkDnD/estuary.jpg";
import imgBobcat from "img/elkDnD/bobcat.jpg";
import imgEnsatina from "img/elkDnD/ensatina.jpg";
import imgGrizzlyBear from "img/elkDnD/grizzly_bear.jpg";
import imgHarborSeal from "img/elkDnD/harbor_seal.jpg";

const quizItems = [
  {
    multiSelect: false,
    type: "dragToBoard",
    question: (
      <>
        Which of these creatures <b>is not</b> present in Elkhorn Slough?
      </>
    ),
    choices: {
      1: { Bobcat: imgBobcat },
      2: { Ensatina: imgEnsatina },
      3: { "Grizzly Bear": imgGrizzlyBear },
      4: { "Harbor Seal": imgHarborSeal }
    },
    answer: 3
  },
  {
    src: imgSlough,
    thumbnail: imgSloughSmall,
    aspectRatio: "717x346",
    alt: "Slough",
    color: "#132962",
    multiSelect: false,
    type: "dragAFlag",
    question:
      "Which feature of an estuary plays a key role in water purification?",
    choices: {
      1: { "Wildlife Habitat": { x: 50, y: 15 } },
      2: { "Water Outflow": { x: 60, y: 25 } },
      3: { "Groundwater Flow": { x: 0, y: 30 } },
      4: { "Saturated Peat": { x: 30, y: 40 } },
      5: { Stream: { x: 80, y: 45 } }
    },
    answer: 4
  },
  {
    src: imgEstuary,
    aspectRatio: "717x346",
    optimize: false,
    alt: "Slough",
    color: "#132962",
    multiSelect: true,
    type: "dragACard",
    question:
      "Besides helping to purify our waters, what are other benefits of estuaries?",
    choices: {
      1: { "Absorb Pollution": { x: 50, y: 15 } },
      2: { "Control Invasive Species": { x: 60, y: 25 } },
      3: { "Serve as Nurseries": { x: 0, y: 30 } },
      4: { "Great Building Space": { x: 30, y: 40 } }
    },
    answers: { 1: true, 3: true }
  },
  {
    src: imgOcean,
    optimize: false,
    aspectRatio: "717x208",
    alt: "Slough",
    color: "#132962",
    multiSelect: true,
    type: "dragACard",
    question: "How can you contribute to keeping our waters pure?",
    choices: {
      1: { "Dispose of Chemicals Properly": { x: 50, y: 15 } },
      2: { "Report Sewage Leaks": { x: 60, y: 25 } },
      3: { "Support Local Farmers": { x: 0, y: 30 } },
      4: { "Buy Paper Instead of Plastic": { x: 30, y: 40 } }
    },
    answers: { 1: true, 2: true }
  }
];
// console.log("Calling multipleChoice");
const theQuiz = new multipleChoice(quizItems);
const DnDQuiz = props => {
  const [current, mSend] = useMachine(envMachine);

  // console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent
          {...props}
          mSend={mSend}
          current={current}
          timerDuration={90}
          theQuiz={theQuiz}
        />
      </NewLargeTablet>
    </Environment>
  );
};

export default DnDQuiz;
