import React from "react";
import { Machine, assign } from "xstate";
import imgKayakStation from "img/ks_background.jpg";
import imgKayakStationSmall from "img/ks_background_sm.jpg";
import imgKayakStationTablet from "img/ks_background_tablet.jpg";
import imgKayakStationTabletSmall from "img/ks_background_tablet_sm.jpg";
import imgTableWTablet from "img/table_w_tablet.png";
import imgTableTabletHighlight from "img/table_tablet_highlight.svg";
import imgKayakShackDoorHighlight from "img/ks_door_highlight.svg";
import imgCrate from "img/ks_crate.png";
import imgSensorHighlight from "img/ks_sensor_highlight.svg";
import imgSensorSmall from "img/ks_sensor_small.png";
import tabletBG from "img/tablet_background.jpg";
import imgQPCR from "img/ks_qPCR_obtained.png";
import kellyAppData from "components/appCall/CallingKellyBenoitBird";
import charlesAppData from "components/appCall/CallingCharlesNye";
import alexandraAppData from "components/appCall/CallingAlexandraZoeWorden";
import {
  ShowVideo,
  AskScientist,
  AppScreen,
  CallWSubTitles,
  ManualInitiateCall,
  IntroPage,
  DnDGame,
  IconInstruction
  // QuizIntro
} from "components/TabletApps";

function subTwoPercentages(one, two) {
  return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
}
export const stationMachine = Machine(
  {
    id: "kayakStation",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakStation,
          thumbnail: imgKayakStationSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            isClickable: true,
            showHighlight: true,
            ringing: true,
            action: "pickUpTablet",
            className: "table_tablet",
            showOverlay: true,
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: kellyAppData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            isClickable: true,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              },
              {
                name: "overlay",
                optimize: false,
                src: imgSensorSmall,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "40%",
                  top: "73%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgSensorHighlight,
                style: {
                  position: "absolute",
                  left: "46%",
                  width: "18%",
                  top: "73.0%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          shackDoor: {
            visible: false,
            isClickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          qPCR: {
            title: "Handheld qPCR Device",
            src: imgQPCR,
            description:
              "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
          }
        }
      },
      callStep: 0,
      scientistData: kellyAppData,
      tabletBG: tabletBG,
      scientistSubTitles: [
        "Hi FIRSTNAME, I am SCIENTISTNAME, a SCIENTISTTITLE here at MBARI. Welcome to Elkhorn Slough!  It’s a beautiful estuary (which is where fresh and saltwater meet) and a very important ecosystem.  Estuaries like Elkhorn Slough are an important habitat for many",
        "organisms, as they are a great place to raise their young, but they face environmental challenges. Pesticide run-off from the farms around here is one problem that affects the health of the slough inhabitants. But another is dangerous bacteria in the water.",
        "Sewer leaks, damage to septic tanks, and runoff from farm animals can contain bacteria.  We have learned to track a bacteria called enterococci because it alerts us to sewage material in the water. To do this, MBARI has created a machine called an",
        "Environmental Sample Processor (ESP).  We have paired it with another machine called a Quantitative Polymerase Chain Reaction (qPCR) to make testing water for the enterococci bacteria quick and easy. The ESP prepares water samples,",
        "which are then put into the qPCR to be tested for the DNA, or genetic code, of enterococci bacteria.  Soon, you will get a chance to kayak up the slough. Even with the constant threat of pollution, the slough is a beautiful place, full of wonder.",
        "I hope you see some otters and much more!"
      ],
      showTablet: false,
      itemObtained: null,
      start: "deckScene"
    },
    initial: "chooseStart",
    states: {
      chooseStart: {
        on: {
          "": [
            {
              target: "pickUpTablet",
              cond: "startIsPickUpTablet"
            },
            {
              target: "deckScene"
            }
          ]
        }
      },
      initialize: {
        on: {
          "": {
            target: "waitForDeckScene",
            actions: ["createShiftedStyle"]
          }
        }
      },
      waitForDeckScene: {
        after: {
          100: "deckScene"
        }
      },
      deckScene: {
        on: {
          pickUpTablet: {
            target: "pickUpTablet",
            actions: ["pickUpTablet"]
          },
          TAKE: {
            target: "deckScene",
            actions: "obtainItem"
          },
          STORE: {
            target: "deckScene",
            actions: ["storeItem"]
          },
          ENTER: {
            target: "done",
            actions: assign({ done: true })
          }
        }
      },
      waitForPage: {
        after: {
          100: "startCall"
        }
      },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: ["startCall"]
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "deckScene",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          // console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.shackDoor.showHighlight = true;
        context.environment.clickables.shackDoor.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakStationTablet;
        context.environment.background.thumbnail = imgKayakStationTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = "qPCR";
        context.environment.clickables.crateWSensor.visible = false;
      },
      startCall: (context, event) => {
        context.callConnected = true;
        context.environment.clickables.tableTablet.showOverlay = false;
        context.environment.clickables.tableTablet.showHighlight = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.crateWSensor.showHighlight = true;
        context.environment.background.src = imgKayakStation;
        context.environment.background.thumbnail = imgKayakStationSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    },
    guards: {
      startIsPickUpTablet: (context, event) => {
        console.log("Starting with:", context.start);
        return context.start === "pickUpTablet";
      }
    }
  }
);
export const stationMachine2 = Machine(
  {
    id: "kayakReview",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakStation,
          thumbnail: imgKayakStationSmall,
          // src: imgKayakStationTablet,
          // thumbnail: imgKayakStationTabletSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            isClickable: true,
            showHighlight: false,
            ringing: true,
            showOverlay: true,
            action: "pickUpTablet",
            className: "table_tablet",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: charlesAppData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            isClickable: false,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              }
            ]
          },
          shackDoor: {
            visible: false,
            isClickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          qPCR: {
            title: "Handheld qPCR Device",
            src: imgQPCR,
            description:
              "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistData: charlesAppData,
      scientistSubTitles: [
        "“Glad to see you once again. Remember that blue box that you found earlier? That is qPCR device. I've sent you video instructions on how to use it. Follow the instructions and help us test the samples.”"
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "pickUpTablet",
    states: {
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: [
              "initializeCall",
              assign({
                callConnected: true
              })
            ]
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "done",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      initializeCall: (context, event) => {
        context.itemStored = "qPCR";
        console.log(context.itemStored);
      },
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
          // console.log("HERE! shiftedStyle = ", clickable.shiftedStyle);
          // console.log("HERE! style = ", clickable.style);
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.shackDoor.showHighlight = true;
        context.environment.clickables.shackDoor.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakStationTablet;
        context.environment.background.thumbnail = imgKayakStationTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.crateWSensor.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.crateWSensor.showHighlight = true;
        context.environment.background.src = imgKayakStation;
        context.environment.background.thumbnail = imgKayakStationSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

export const stationMachine3 = Machine(
  {
    id: "askScientist",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakStation,
          thumbnail: imgKayakStationSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            isClickable: true,
            showHighlight: false,
            showOverlay: false,
            ringing: true,
            action: "pickUpTablet",
            className: "table_tablet",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: alexandraAppData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            isClickable: false,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              }
            ]
          },
          shackDoor: {
            visible: false,
            isClickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          qPCR: {
            title: "Handheld qPCR Device",
            src: imgQPCR,
            description:
              "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistData: alexandraAppData,
      scientistSubTitles: [
        "Hi FIRSTNAME, I am SCIENTISTNAME, a SCIENTISTTITLE here at MBARI. Water flows in a particular direction. Movement with the water direction is called downstream. Movement opposite the water direction is called upstream. The testing site at the top of the map was upstream from the",
        "contamination site. Pollution moves with the water so only sites downstream from the contamination site were affected. If you recall from earlier, estuaries filter water and chemicals. The data collected downstream from the contamination site shows decreased levels of enterococci bacteria.",
        "The plants and peat in the estuary have been filtering the water, collecting some of the bacteria so less is present in the water as it moves downstream to the open ocean. The further the water travels, the more plants and peat it has passed through and the more it has been filtered.",
        "Want to see our Deep ESP at work? Check this out..."
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "appScreen",
    states: {
      appScreen: {
        on: {
          ASK: {
            target: "waitForPage",
            actions: assign({
              callInProgress: true,
              callConnected: true
            })
          }
        }
      },
      waitForPage: {
        after: {
          100: "askScientist"
        }
      },
      askScientist: {
        after: {
          3000: {
            target: "engageInCall",
            actions: [assign({ callConnected: false, inCall: true })]
          }
        }
      },
      // askScientist: {
      //   invoke: {
      //     src: "animateRing",
      //     onDone: {
      //       target: "engageInCall",
      //       actions: [
      //         () => console.log("IT RAN"),
      //         assign({ callConnected: false, inCall: true })
      //       ]
      //     }
      //   }
      // },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "showVideo"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      showVideo: {
        on: {
          VIDEODONE: {
            target: "videoDone",
            actions: assign({
              showTablet: false,
              tabletClickable: true,
              done: true
            })
          }
        }
      },
      videoDone: {
        on: {
          "": {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakStationTablet;
        context.environment.background.thumbnail = imgKayakStationTabletSmall;
        context.environment.shiftBackground = true;
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.crateWSensor.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.crateWSensor.showHighlight = true;
        context.environment.background.src = imgKayakStation;
        context.environment.background.thumbnail = imgKayakStationSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

export const StationContent = props => {
  const { current } = props;
  // console.log(current.value);
  switch (current.value) {
    case "pickUpTablet":
    case "waitForPage":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
    case "engageInCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "ringScientist":
    default:
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
  }
};

export const StationContent2 = props => {
  const { current } = props;
  // console.log(current.value);
  switch (current.value) {
    case "engageInCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "pickUpTablet":
    case "waitForPage":
    case "ringScientist":
    default:
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
  }
};

export const StationContent3 = props => {
  const { current } = props;
  // console.log(current.value);
  switch (current.value) {
    case "showVideo":
      return (
        <ShowVideo
          videoId="XeuCkLRxUS8"
          videoTitle="ESP provides remote sampling"
          videoIntroduction="Science in the ocean is difficult."
          videoCaption="Can you guess how MBARI is making it easier?"
          onEnd={() => props.mSend("VIDEODONE")}
          mSend={props.mSend}
          noButton={true}
        />
      );
    case "engageInCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "askScientist":
    case "waitForPage":
      return (
        <AskScientist
          mSend={props.mSend}
          current={current}
          ringTimeLine={props.ringTimeLine}
        />
      );
    case "pickUpTablet":
    case "ringScientist":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
    default:
      return <AppScreen mSend={props.mSend} focus="AskScientist" />;
  }
};

export const dndMachine = Machine(
  {
    id: "dndMachine",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakStation,
          thumbnail: imgKayakStationSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          tableTablet: {
            visible: true,
            isClickable: true,
            showHighlight: false,
            showOverlay: false,
            ringing: true,
            action: "pickUpTablet",
            className: "table_tablet",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTableWTablet,
                style: { width: "100%" },
                aspectRatio: "671x273"
              },
              {
                name: "overlay",
                optimize: false,
                src: alexandraAppData.imgOnTableTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  top: "2%"
                },
                aspectRatio: "76x84"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgTableTabletHighlight,
                style: {
                  position: "absolute",
                  left: "44.5%",
                  width: "15%",
                  top: "-1%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          crateWSensor: {
            visible: true,
            isClickable: false,
            showHighlight: false,
            ringing: false,
            action: "TAKE",
            className: "crate_w_sensor",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgCrate,
                style: { width: "100%" }
              }
            ]
          },
          shackDoor: {
            visible: false,
            isClickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "shack_door",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgKayakShackDoorHighlight,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        },
        obtainable: {
          qPCR: {
            title: "Handheld qPCR Device",
            src: imgQPCR,
            description:
              "A qPCR device can detect the genetic material of the enterococci and send this data to an attached smartphone."
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      showTablet: true,
      itemObtained: null,
      questionNumber: 0,
      questionCount: 4,
      buttonDisabled: true,
      buttonText: "Next",
      buttonAction: "NEXT"
    },
    // initial: "dragNDropIntro",
    initial: "dragNDropIntro",
    states: {
      dragNDropIntro: {
        on: {
          GO: {
            target: "waitForPage",
            actions: assign({
              callInProgress: true,
              callConnected: true
            })
          }
        }
      },
      waitForPage: {
        after: {
          500: { target: "waitForGame", actions: ["setupGame"] }
        }
      },
      waitForGame: {
        entry: ["disableDragging"],
        after: {
          500: { target: "dndGame", actions: ["enableDragging"] }
        }
      },
      dndGame: {
        on: {
          DROP: {
            actions: ["enableButton"]
          },
          NEXT: [
            {
              cond: "moreQuestions",
              target: "waitForGame",
              actions: ["advanceQuestion"]
            },
            {
              target: "quizResults"
            }
          ]
        }
      },
      quizResults: {
        entry: ["reviewQuiz"],
        on: {
          DONE: {
            target: "done"
          }
        }
      },
      done: {
        entry: ["finishGame"],
        type: "final"
      }
    }
  },
  {
    actions: {
      disableDragging: (context, event) => {
        context.draggingEnabled = false;
      },
      enableDragging: (context, event) => {
        console.log("EnableDragging");
        context.draggingEnabled = true;
      },
      reviewQuiz: (context, event) => {
        context.buttonText = "Hooray!";
        context.buttonAction = "DONE";
      },
      finishQuestion: (context, event) => {
        console.log("Finish Question");
      },
      enableButton: (context, event) => {
        context.buttonDisabled = false;
      },
      dropMade: (context, event) => {
        context.drop += 1;
      },
      advanceQuestion: (context, event) => {
        context.questionNumber += 1;
        context.questionsRemaining =
          context.questionCount - context.questionNumber - 1;
      },
      setupGame: (context, event) => {
        console.log("setupGame");
        context.questionsRemaining =
          context.questionCount - context.questionNumber - 1;
      },
      finishGame: (context, event) => {
        console.log("Finished");
        context.done = true;
      }
    },
    guards: {
      moreQuestions: (context, event) => {
        console.log("Starting with:", context.start);
        return context.questionNumber < context.questionCount - 1;
      }
    }
  }
);
// console.log("ENVIRONMENT:", current.context.environment);
export const FinalTestContent = props => {
  const { current } = props;
  console.log("current.value=", current.value);
  console.log(
    "current.context.questionNumber=",
    current.context.questionNumber
  );
  const value =
    typeof current.value === "object"
      ? Object.keys(current.value)[0]
      : current.value;
  // console.log(value);
  switch (value) {
    default:
    case "waitForPage":
    case "dragNDropIntro":
      return (
        <IntroPage
          {...props}
          title="“DRAG’N’DROP” quiz"
          buttonAction="GO"
          buttonText="Go!"
        >
          <p>
            You've explored a lot here at Elkhorn Slough, let's review what you
            have learned in this short quiz.
          </p>
          <p>
            Read each question carefully. Some of the questions may have
            multiple correct answers.
          </p>
        </IntroPage>
      );
    case "waitForGame":
    case "dndGame":
      const quizItem = props.theQuiz.QuizItems[current.context.questionNumber];
      const instruction =
        quizItem.type === "dragAFlag"
          ? "Drag a flag onto the correct feature."
          : quizItem.multiSelect
          ? "Drag answers to this space."
          : "Drag an answer to this space.";
      const correctMessage =
        current.context.questionsRemaining > 0
          ? "Correct! Next one."
          : "Correct!  Good job!";
      return (
        <>
          <DnDGame
            {...props}
            gameTitle="Drag'N'Drop"
            buttonText={current.context.buttonText}
            buttonAction={current.context.buttonAction}
            instructionMessage={instruction}
            correctMessage={correctMessage}
            incorrectMessage="Not quite... Try again."
            questionNumber={current.context.questionNumber}
            quizItem={quizItem}
            context={current.context}
            draggingEnabled={current.context.draggingEnabled}
            buttonDisabled={current.context.buttonDisabled}
          ></DnDGame>
        </>
      );
    case "quizResults":
      return (
        <IconInstruction
          {...props}
          alt={"Question"}
          title={"100% Correct"}
          message={"Great job!"}
          buttonText={current.context.buttonText}
          buttonAction={current.context.buttonAction}
        />
      );
  }
};
