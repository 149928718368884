import React, { useContext } from "react";
// import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import UserContext from "context/User.js";
import appData from "components/appCall/CallingKellyBenoitBird";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { TabletContent, envMachine } from "./TableEnvironment";
import { NewLargeTablet } from "components/UIComponents";

const context = {
  id: "lesson",
  scientistSubTitles: [
    "FIRSTNAME, you have made it to our first stop, the Pacific Grove tide pools!  Tide pools form in depressions on rocky shorelines in the intertidal zone.  The intertidal zone is the area between the high and low tide on the ocean shore.  Tide pools stay filled with water in low tide, providing habitat for many marine organisms.",
    "Why are there low and high tides?  The water level on the shore changes because of the gravitational pull from the moon and the sun combined with the rotation of earth.  The positions of the earth, moon, and sun are always changing because they move in patterns.  At high tide, the water level is as high as it will get on",
    "shore for that tidal period.  At low tide, the water is as low as it will get on shore for that tidal period.  Most shores have two high tides and two low tides a day.  Organisms that live in the tide pools must have unique adaptations to survive these ever-changing conditions.  For example, some have hard shells to hold in",
    "water when the tide is low.  Another adaptation that organisms need to have is the ability to adjust to large variations in pH.  The pH scale is used to measure how acidic or basic something is. The scale ranges from 0-14, with 7 considered neutral.  Anything with a pH between 0 and 7 is an acid, and anything with a pH between",
    "7 and 14 is a base.  Pure water has a pH of 7, while ocean water usually has a pH of about 8.  Scientists discovered that ocean water is becoming more and more acidic because of the increase of carbon dioxide being released into the air from the burning of fossil fuels.  It is important to monitor the pH level of the ocean",
    "because even small changes can have negative effects on marine organisms and even entire ecosystems.\n\nThat is why we need your help.  Will you go to the Pacific Grove and Natural Bridges tide pools to measure the pH?  We’ll need two",
    "readings from each of the tide pools to fill in the data we are missing on our chart.  I suggest spending time between readings exploring the tide pools and getting to know the organisms that live there.",
    "They are fascinating to watch and have many interesting adaptations."
  ],
  scientistData: appData,
  lesson: true
};

const Lesson = props => {
  const [objUser] = useContext(UserContext);
  if ("user" in objUser) envMachine.context["firstName"] = objUser.user.first;
  const [current, mSend] = useMachine(envMachine, {
    context: { ...envMachine.context, ...context }
  });
  console.log("current:", current);
  console.log("Current state:" + current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }
  console.log("obtained:", current.context.itemObtained);
  console.log("obtainable:", current.context.environment.obtainable);
  return (
    <Environment {...props} context={current.context} mSend={mSend}>
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Lesson;
