import React from "react";
import { Machine, assign } from "xstate";
import imgKayakShack from "img/ks_shack_background.jpg";
import imgKayakShackSmall from "img/ks_shack_background_sm.jpg";
import imgKayakShackTablet from "img/ks_shack_background_tablet.jpg";
import imgKayakShackTabletSmall from "img/ks_shack_background_tablet_sm.jpg";
import imgClipboardHighlight from "img/ks_shack_clipboard_highlight.svg";
import imgFloorTabletHighlight from "img/ks_shack_floor_tablet_highlight.svg";
import imgLockeKayak from "img/ks_shack_locked_kayak.svg";
import tabletBG from "img/tablet_background.jpg";
import appData from "components/appCall/CallingCharlesNye";
import { CallWSubTitles, ManualInitiateCall } from "components/TabletApps";

function subTwoPercentages(one, two) {
  return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
}

export const shackMachine = Machine(
  {
    id: "kayakShack",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakShack,
          thumbnail: imgKayakShackSmall,
          alt: "Elkorn Slough Kayak Shack",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          floorTablet: {
            visible: true,
            isClickable: true,
            showHighlight: true,
            showHover: true,
            ringing: true,
            action: "pickUpTablet",
            className: "floor_tablet",
            // style: {
            //   position: "absolute",
            //   width: "30%",
            //   left: "9%",
            //   bottom: "0"
            // },
            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnFloorTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  bottom: "10%"
                },
                aspectRatio: "179x211"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesHover: true,
                handlesRing: true,
                optimize: false,
                src: imgFloorTabletHighlight,
                style: {
                  position: "absolute",
                  left: "45.5%",
                  width: "12%",
                  bottom: "8%"
                },
                aspectRatio: "179x211"
              }
            ]
          },
          clipboard: {
            visible: true,
            isClickable: true,
            showHighlight: false,
            showHover: true,
            ringing: false,
            action: "TOUCH",
            className: "clipboard",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgClipboardHighlight,
                handlesHover: true,
                style: {
                  position: "absolute",
                  left: "46%",
                  width: "18%",
                  top: "73.0%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          kayak: {
            visible: true,
            isClickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "locked_kayak",

            shiftedStyle: {},
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgLockeKayak,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      tabletBG: tabletBG,
      scientistData: appData,
      scientistSubTitles: [
        "Hey, you’ve made It to the shack! Cool! See that clipboard on the table? Start there and learn some important vocabulary that will help you in this lesson."
      ],
      showTablet: false,
      itemObtained: null
    },
    initial: "shackScene",
    states: {
      initialize: {
        on: {
          "": {
            target: "waitForDeckScene",
            actions: ["createShiftedStyle"]
          }
        }
      },
      waitForDeckScene: {
        after: {
          100: "shackScene"
        }
      },
      shackScene: {
        on: {
          pickUpTablet: {
            target: "pickUpTablet",
            actions: ["pickUpTablet"]
          },
          TOUCH: {
            target: "done",
            actions: assign({ done: true })
          }
        }
      },
      waitForPage: {
        after: {
          100: "startCall"
        }
      },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: ["answerCall"]
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "shackScene",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.clickables.clipboard.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakShackTablet;
        context.environment.background.thumbnail = imgKayakShackTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.floorTablet.showHighlight = false;
        context.environment.clickables.floorTablet.visible = true;
        context.environment.clickables.floorTablet.ringing = false;
      },
      answerCall: (context, event) => {
        context.environment.clickables.floorTablet.visible = false;
        context.callConnected = true;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.clickables.clipboard.ringing = true;

        context.environment.background.src = imgKayakShack;
        context.environment.background.thumbnail = imgKayakShackSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

export const ShackContent = props => {
  const { current } = props;
  // console.log(current.value);
  switch (current.value) {
    case "pickUpTablet":
    case "waitForPage":
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
    case "engageInCall":
      return (
        <CallWSubTitles
          {...props}
          tabletBG={current.context.tabletBG}
          videoImg={current.context.scientistData.videoStill}
          current={current}
          callStep={current.context.callStep}
        />
      );
    case "ringScientist":
    default:
      return (
        <ManualInitiateCall
          {...props}
          mSend={props.mSend}
          callImg={current.context.scientistData.callImg}
        />
      );
  }
};
