import callImg from "img/appCall/james_barry_calling.jpg";
import callWithScientist from "img/appCall/call_with_james_barry.jpg";
import videoStill from "img/appCall/call_with_james_barry.jpg";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "James Barry",
  scientistTitle: "MBARI Senior Scientist",
  scientistTitleShort: "Senior Scientist",
  videoStill: videoStill
};
export default appData;
